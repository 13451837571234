import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Actions as BackendVersionActions } from 'services/backendVersion';

const LinkItem = ({ active = false, to, children }) => {
  return active ? (
    <Link className="nav-link active" aria-current="page" to={to}>
      {children}
    </Link>
  ) : (
    <Link className="nav-link" to={to}>
      {children}
    </Link>
  );
};

const Footer = () => {
  const backendVersion = useSelector((state) => state.backendVersion.value);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(BackendVersionActions.getAppVersion());
  }, [dispatch]);

  const uiVersion = process.env.REACT_APP_VERSION;

  return (
    <footer className="footer-main">
      <div className="container-fluid p-5">
        <div className="row">
          <div className="col-12 col-lg-5">
            <img loading="lazy" src="/img/logo_inteli.gente_w.png" alt="inteli.gente" />
          </div>
          <div className="col-12 col-lg-3">
            <p>
              <LinkItem to="/municipios">Encontre um município</LinkItem>
            </p>
            <p>
              <LinkItem to="/sobre">Sobre a plataforma</LinkItem>
            </p>
            <p>
              <LinkItem to="/metodologias">Metodologias</LinkItem>
            </p>
            <p>
              <LinkItem to="/midia">Cidades Inteligentes Sustentáveis na mídia</LinkItem>
            </p>
          </div>
          <div className="col-12 col-lg-4">
            <p>
              <LinkItem to="/contato">Fale conosco</LinkItem>
            </p>
            <p>
              <LinkItem to="/prefeitura/login">Área da prefeitura</LinkItem>
            </p>
            <p>
              <LinkItem to="/portal/login">
                <i className="fa-solid fa-lock"></i> Área restrita
              </LinkItem>
            </p>
          </div>
        </div>
        <div className="text-end version">
          Versão: <strong>{uiVersion}</strong> / API <strong>{backendVersion}</strong>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
