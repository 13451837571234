import React, { useState, useRef, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Actions as NewslettersActions } from 'slices/admin/newsletters';
import { showToast } from 'components/Toast';
import { ConfirmationModal } from 'components/Modal';
import Pagination from 'components/Pagination';
import debounce from 'lodash.debounce';
import { addTimezoneOffset, formatDate } from 'helpers/dateTimeUtils';

const FilterGroup = ({ statusList, current, onSelected }) => {
  return (
    <div className="btn-group" role="group" aria-label="Basic outlined example">
      {statusList.map((filter) => (
        <button
          key={`btn-newsletter-filter-${filter.value}`}
          onClick={() => onSelected(filter.value)}
          type="button"
          className={`btn btn-sm ${current === filter.value ? 'btn-success' : 'btn-outline-gray-light fw-normal'}`}
        >
          {filter.label}
        </button>
      ))}
    </div>
  );
};

const NewsletterStatus = ({ status }) => {
  switch (status) {
    case 0:
      return <span className="badge text-bg-danger">Lixeira</span>;
    case 1:
      return <span className="badge bg-green-subtle">Enviado</span>;
    case 2:
      return <span className="badge bg-green-subtle">Aguardando Envio</span>;
    case 3:
      return <span className="badge bg-cyan-subtle">Rascunho</span>;
    default:
      return <></>;
  }
};

const NewsletterList = () => {
  const dispatch = useDispatch();

  const toastId = useRef(null);

  const triggerSearch = useRef(
    debounce((inputValue) => dispatch(NewslettersActions.loadNewsletters(page, selectedFilterStatus, inputValue)), 500),
  ).current;

  const searchedTerm = useRef(null);

  const adminNewsletters = useSelector((state) => state.adminNewsletters);
  const adminNewsletterItem = useSelector((state) => state.adminNewsletterItem);

  const [modalInfo, setModalInfo] = useState();
  const [modalDeleteInfo, setModalDeleteInfo] = useState();
  const [toastInfo, setToastInfo] = useState(null);

  const [selectedFilterStatus, setSelectedFilterStatus] = useState(-1);
  const [page, setPage] = useState(1);

  const [queryInputValue, setQueryInputValue] = useState('');

  const newsletterFilterStatus = [
    { value: -1, label: 'Todos' },
    { value: 2, label: 'Aguardando envio' },
    { value: 3, label: 'Rascunho' },
    { value: 1, label: 'Enviados' },
    { value: 0, label: 'Lixeira' },
  ];

  useEffect(() => {
    if (adminNewsletters.success === false) {
      setToastInfo({ type: 'danger', message: adminNewsletters.message });
    }
    if (adminNewsletters.success === true) {
      setPage(adminNewsletters?.page?.pageNumber);
    }
  }, [adminNewsletters?.message, adminNewsletters?.success, adminNewsletters?.page]);

  useEffect(() => {
    dispatch(NewslettersActions.loadNewsletters(page, selectedFilterStatus, searchedTerm.current));
    if (adminNewsletterItem.success !== null) {
      if (adminNewsletterItem.success === true) {
        dispatch(NewslettersActions.loadNewsletters(page, selectedFilterStatus));
      }
      setToastInfo({ type: adminNewsletterItem.success ? 'success' : 'danger', message: adminNewsletterItem.message });
    }
    return () => {
      dispatch(NewslettersActions.clearData());
    };
  }, [dispatch, page, adminNewsletterItem?.success, adminNewsletterItem?.message, selectedFilterStatus]);

  useEffect(() => setPage(1), [selectedFilterStatus]);

  useEffect(() => {
    searchedTerm.current = queryInputValue;
  }, [queryInputValue]);

  useEffect(() => {
    if (toastInfo) {
      toastId.current = showToast(toastInfo.message, toastInfo.type, toastInfo.autoClose);
    }
    return () => {
      dispatch(NewslettersActions.clearData());
    };
  }, [dispatch, toastInfo]);

  const handleStatusChange = (newSelected) => {
    if (newSelected !== selectedFilterStatus) {
      setSelectedFilterStatus(newSelected);
    }
  };

  const handleMoveClick = (id, itemTitle) => {
    if (selectedFilterStatus === 0) {
      setModalInfo({
        id,
        title: 'Restauração',
        message: `Deseja restaurar '${itemTitle}' da lixeira?`,
        onConfirmation: () => handleRestoreFromTrashConfirmation(id),
      });
    } else {
      setModalInfo({
        id,
        title: 'Lixeira',
        message: `Deseja mover '${itemTitle}' para a lixeira?`,
        confirmationClassName: 'btn-danger',
        onConfirmation: () => handleMoveToTrashConfirmation(id),
      });
    }
  };

  const handleDeleteClick = (id, itemTitle) => {
    setModalDeleteInfo({
      id,
      titile: 'Exclusão',
      message: `Deseja excluir permanentemente o item '${itemTitle}' ?`,
      confirmationClassName: 'btn-danger',
      onConfirmation: () => handleDeleteConfirmation(id),
    });
  };

  const handleMoveToTrashConfirmation = (id) => {
    dispatch(NewslettersActions.moveNewsletterToTrash(id));
    closeModal();
  };

  const handleRestoreFromTrashConfirmation = (id) => {
    dispatch(NewslettersActions.restoreFromTrash(id));
    closeModal();
  };

  const handleDeleteConfirmation = (id) => {
    dispatch(NewslettersActions.delete(id));
    closeDeleteModal();
  };

  const closeModal = () => {
    setModalInfo(null);
  };

  const closeDeleteModal = () => {
    setModalDeleteInfo(null);
  };

  const handlePageChange = (pageDirection) => {
    if (typeof pageDirection === 'number') {
      const pageNumber = pageDirection;
      setPage(pageNumber);
      return;
    }

    const currentPage = page;
    const changePage = {
      prev: () => {
        const newPage = currentPage - 1;
        setPage(newPage > 1 ? newPage : 1);
      },
      next: () => {
        const newPage = currentPage + 1;
        setPage(newPage <= adminNewsletters?.page?.totalPages ? newPage : currentPage);
      },
    };

    changePage[pageDirection]();
  };

  const handleQuerySearch = (evt) => {
    const { value } = evt.target;
    setQueryInputValue(value);
    triggerSearch(value);
  };

  return (
    <>
      {/* <!-- Cabeçalho Filtros - start --> */}
      <header className="justify-content-between">
        {/* <!-- Filtros --> */}
        <div className="px-4 w-33">
          <span className="has-icon search w-100">
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="Buscar por nome ou município..."
              name="query"
              value={queryInputValue}
              onChange={handleQuerySearch}
            />
          </span>
        </div>
        <div className="px-4">
          {adminNewsletters?.loading && (
            <span>
              <i className="fa-solid fa-spinner fa-spin fa-lg"></i>
            </span>
          )}
          <FilterGroup
            statusList={newsletterFilterStatus}
            current={selectedFilterStatus}
            onSelected={handleStatusChange}
          />
          <Link to="novo" className="btn btn-success btn-sm ms-3">
            <i className="fa-solid fa-plus"></i> Adicionar boletim
          </Link>
        </div>
      </header>

      {/* <!-- Cabeçalho Filtros - end --> */}

      {/* <!-- Tabela - start --> */}

      <div className="p-5">
        <table className="crud mb-5">
          <thead>
            <tr>
              <th>Título</th>
              <th>Status</th>
              <th>Data de envio</th>
              <th className="text-center">Ações</th>
            </tr>
          </thead>
          <tbody>
            {adminNewsletters.data?.map((item) => (
              <tr key={`newsletter-${item.id}`}>
                <td>{item.title}</td>
                <td>
                  <NewsletterStatus status={item.status} />
                </td>
                <td>{formatDate(addTimezoneOffset(new Date(item.sendDate)))}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle variant="outline-secondary" id={`dropdown-actions-${item.id}`} size="sm">
                      <i className="fa-solid fa-ellipsis-vertical"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {(item.status === 2 || item.status === 3) && (
                        <>
                          <Dropdown.Item as={Link} to={`${item.id}/editar`}>
                            <i className="fa-solid fa-pencil"></i> Editar
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleMoveClick(item.id, item.title)}>
                            <i className="fa-solid fa-trash c-danger"></i> Mover para a lixeira
                          </Dropdown.Item>
                        </>
                      )}
                      {item.status !== 2 && item.status !== 3 && (
                        <Dropdown.Item as={Link} to={`${item.id}/editar`}>
                          <i className="fa-solid fa-eye"></i> Visualizar
                        </Dropdown.Item>
                      )}
                      {item.status === 0 && (
                        <>
                          <Dropdown.Item onClick={() => handleMoveClick(item.id, item.title)}>
                            <i className="fa-solid fa-trash-arrow-up"></i> Restaurar
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleDeleteClick(item.id, item.title)}>
                            <i className="fa-solid fa-xmark c-danger"></i> Excluir
                          </Dropdown.Item>
                        </>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {adminNewsletters?.data?.length > 0 && (
          <Pagination pageInfo={adminNewsletters?.page} onPageChange={handlePageChange} />
        )}
        {adminNewsletters?.data?.length === 0 && <p>Nenhum boletim foi encontrado. </p>}
      </div>
      {/* <!-- Tabela - end --> */}
      <ConfirmationModal show={!!modalInfo} onClose={closeModal} {...modalInfo} />
      <ConfirmationModal show={!!modalDeleteInfo} onClose={closeDeleteModal} {...modalDeleteInfo} />
    </>
  );
};

export default NewsletterList;
