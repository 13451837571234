import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import QuestionsPrint from 'components/survey/print/Questions';
import { Actions as AdminSurveyViewActions } from 'services/adminSurveyView';

const SurveysPrint = ({ surveyCode, showAnswers = false }) => {
  const dispatch = useDispatch();
  const title = useSelector((state) => state.adminSurveyView.groupInfo.surveys[surveyCode]?.title);
  const questions = useSelector((state) => state.adminSurveyView.groupInfo.surveys[surveyCode]?.questions);
  const answers = useSelector((state) => state.adminSurveyView.groupInfo.answers[surveyCode]?.answers);
  const loadStatusValues = useSelector((state) => state.adminSurveyView.loadStatusValues);
  const questionsLoadStatus = useSelector((state) => state.adminSurveyView.questionsLoadStatus);
  const answersLoadStatus = useSelector((state) => state.adminSurveyView.answersLoadStatus);

  // Dispara carregamento das questões
  useEffect(() => {
    if (surveyCode) {
      dispatch(AdminSurveyViewActions.loadQuestions(surveyCode));
    }
  }, [dispatch, surveyCode]);

  // Dispara carregamento das respostas
  useEffect(() => {
    if (surveyCode && questions) {
      dispatch(AdminSurveyViewActions.loadAnswers(surveyCode));
    }
  }, [dispatch, questions, surveyCode]);

  const isLoading = useMemo(() => {
    return questionsLoadStatus === loadStatusValues.LOADING || answersLoadStatus === loadStatusValues.LOADING;
  }, [questionsLoadStatus, answersLoadStatus, loadStatusValues.LOADING]);

  return (
    <>
      {!isLoading && questions && Object.keys(questions).length > 0 && (
        <div className="row">
          <div className="col-12">
            <h2 className="mb-4" id={surveyCode + '-title'}>
              {title}
            </h2>
            <div>
              <QuestionsPrint questions={questions} answers={answers} showAnswers={showAnswers} />
            </div>
          </div>
        </div>
      )}
      {!isLoading && (!questions || Object.keys(questions).length === 0) && (
        <div
          style={{
            padding: '5rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            width: '100%',
            margin: 'auto',
          }}
        >
          <span style={{ fontSize: '1.25em' }}>
            <i className="fa-solid fa-times"></i>
            &nbsp; Nada para mostrar.
          </span>
        </div>
      )}

      {isLoading && (
        <div
          style={{
            padding: '5rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            width: '100%',
            margin: 'auto',
          }}
        >
          <span style={{ fontSize: '1.25em' }}>
            Carregando questões... &nbsp;
            <i className="fa-solid fa-spinner fa-spin"></i>
          </span>
        </div>
      )}
    </>
  );
};

export default SurveysPrint;
