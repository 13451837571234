import React, { useEffect, useState } from 'react';
import { logoutUrl } from 'helpers/govBrUtils';

const Step3 = ({ data, type: registrationType }) => {
  const [approvalMessage, setApprovalMessage] = useState(null);

  useEffect(() => {
    switch (registrationType) {
      case 11:
        // Prefeito/admin com auto-aprovação
        setApprovalMessage({
          msg1: 'Por favor, verificar o e-mail informado no cadastro para ativar sua conta.',
          msg2: null,
        });
        break;
      case 12:
        // Prefeito com aprovação pela governança
        setApprovalMessage({
          msg1: 'Seus dados foram enviados para aprovação pela equipe de governança da plataforma.',
          msg2: 'Você será avisado por e-mail assim que sua identidade for confirmada.',
        });
        break;
      case 22:
        // Assessor com aprovação pelo prefeito
        setApprovalMessage({
          msg1: 'Seus dados foram enviados para aprovação pelo prefeito, que deverá estar cadastrado na plataforma.',
          msg2: 'Você será avisado por e-mail assim que sua identidade for confirmada.',
        });
        break;
      default:
        setApprovalMessage({
          msg1: 'Seus dados foram enviados para aprovação',
          msg2: 'Você será avisado por e-mail assim que sua identidade for confirmada.',
        });
    }
  }, [data, registrationType]);

  return (
    <div className="p-5 my-5">
      <h4 className="fw-bold c-cyan">Dados enviados com sucesso!</h4>
      {approvalMessage && (
        <>
          <h2 className="fw-bold c-blue-deep">
            {approvalMessage.msg1} <i className="fa-solid fa-triangle-exclamation c-yellow"></i>
          </h2>
          {approvalMessage.msg2 && <h5>{approvalMessage.msg2}</h5>}
        </>
      )}
      <div className="text-center mt-5">
        <a href={logoutUrl} className="btn btn-success">
          Voltar para tela inicial
        </a>
      </div>
    </div>
  );
};

export default Step3;
