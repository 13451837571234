import React, { Fragment, useEffect, useState } from 'react';
import Questions from '.';
import OptionComplement from './OptionComplement';
import formSettings from '../formSettings';
import { ModalUncheckConfirmation } from '../ModalToast';
import { QuestionHelper, OptionText, OptionHelper, AnswerChanged, getBulletFromIndex } from './Components';

const MultiOption = ({ groupStatus, option, optCode, bullet, questionCode, selected, handleOptionChange }) => {
  if (groupStatus === 2) {
    if (selected === true) {
      return (
        <p>
          <label className="question-multi-option">
            <input
              type="checkbox"
              id={optCode + '-input'}
              className="me-2"
              name={questionCode}
              value={optCode}
              checked={selected}
              disabled={true}
            />
            <OptionText bullet={bullet} text={option.description} />
            <OptionHelper code={optCode} text={option.helper} />
          </label>
        </p>
      );
    } else {
      return (
        <p>
          <label className="question-multi-option">
            <input
              type="checkbox"
              id={optCode + '-input'}
              className="me-2"
              name={questionCode}
              value={optCode}
              checked={selected}
              disabled={true}
            />
            <OptionText bullet={bullet} text={option.description} />
            <OptionHelper code={optCode} text={option.helper} />
          </label>
        </p>
      );
    }
  }
  return (
    <p>
      <label className="question-multi-option">
        <input
          type="checkbox"
          id={optCode + '-input'}
          className="me-2"
          name={questionCode}
          value={optCode}
          checked={selected}
          onChange={handleOptionChange}
        />
        <OptionText bullet={bullet} text={option.description} />
        <OptionHelper code={optCode} text={option.helper} />
      </label>
    </p>
  );
};

const SingleOption = ({ groupStatus, option, optCode, bullet, questionCode, selected, handleOptionChange }) => {
  if (groupStatus === 2) {
    if (selected) {
      return (
        <p>
          <label className="question-single-option">
            <input
              type="radio"
              id={optCode + '-input'}
              className="me-2"
              name={questionCode}
              value={optCode}
              checked={selected}
              disabled={true}
            />
            <OptionText bullet={bullet} text={option.description} />
            <OptionHelper code={optCode} text={option.helper} />
          </label>
        </p>
      );
    } else {
      return (
        <p>
          <label className="question-single-option">
            <input
              type="radio"
              id={optCode + '-input'}
              className="me-2"
              name={questionCode}
              value={optCode}
              checked={selected}
              disabled={true}
            />
            <OptionText bullet={bullet} text={option.description} />
            <OptionHelper code={optCode} text={option.helper} />
          </label>
        </p>
      );
    }
  }
  return (
    <p>
      <label className="question-single-option">
        <input
          type="radio"
          id={optCode + '-input'}
          className="me-2"
          name={questionCode}
          value={optCode}
          checked={selected}
          onChange={handleOptionChange}
        />
        <OptionText bullet={bullet} text={option.description} />
        <OptionHelper code={optCode} text={option.helper} />
      </label>
    </p>
  );
};

const Options = (props) => {
  const type = props.type;
  const questionCode = props.question.code;
  const changed = props.answers[questionCode]?.changed;
  const answers = props.answers[questionCode]?.options;
  const optionActivation = props.question.optionActivation;
  const groupStatus = props.groupStatus;
  const errors = props.errors;

  const [questionEnabled, setQuestionEnabled] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [eventValue, setEventValue] = useState(null);
  const [eventType, setEventType] = useState(null);
  const [disableSubquestion, setDisableSubquestion] = useState(null);

  useEffect(() => {
    let enabled = false;
    if (Object.keys(optionActivation).length > 0) {
      for (const actQuestionCode in optionActivation) {
        for (const option of props.answers[actQuestionCode].options) {
          if (optionActivation[actQuestionCode].includes(option.code) && option.selected === true) {
            enabled = true;
          }
        }
      }
    } else {
      enabled = true;
    }
    setQuestionEnabled(enabled);
  }, [props.answers, optionActivation]);

  const checkOptionActivation = (optionValue, optionChecked, optionType) => {
    const targetType = optionType === 'checkbox';
    let isActivation = false;

    if (targetType) {
      answers.map((a) => {
        if (a.code === optionValue && !optionChecked) {
          Object.values(props.question.children).map((childQuestion) => {
            const optionActivationArray = childQuestion.optionActivation[questionCode];
            const optionActivation = answers.filter(
              (opt) => opt.code !== optionValue && optionActivationArray.includes(opt.code),
            );
            if (
              optionActivationArray.includes(optionValue) &&
              optionActivation.every((opt) => !opt.selected) &&
              props.answers[childQuestion.code].options.some((opt) => opt.selected)
            ) {
              isActivation = !Object.values(props.answers[childQuestion.code].options).every(
                (answer) => answer.selected === false,
              );
            }
            return childQuestion;
          });
        }
        return a;
      });
    } else {
      answers.map((a) => {
        if (a.code !== optionValue) {
          Object.values(props.question.children).map((childQuestion) => {
            const optionActivationArray = childQuestion.optionActivation[questionCode];
            if (
              optionActivationArray.includes(a.code) &&
              !optionActivationArray.includes(optionValue) &&
              a.selected === true
            ) {
              isActivation = !Object.values(props.answers[childQuestion.code].options).every(
                (answer) => answer.selected === false,
              );
            }
            return childQuestion;
          });
        }
        return a;
      });
    }
    return isActivation;
  };

  const handleOptionChange = (evt, blockOthers) => {
    const targetType = evt.target.type === 'checkbox';
    setEventType(targetType);
    if (checkOptionActivation(evt.target.value, evt.target.checked, evt.target.type)) {
      setModalShow(true);
      setEventValue(evt.target.value);
      setDisableSubquestion(true);
    } else {
      if (blockOthers && evt.target.checked && targetType && answers.some((opt) => opt.selected)) {
        setModalShow(true);
        setEventValue(evt.target.value);
        setDisableSubquestion(false);
      } else {
        handleCheckOption(evt.target.value, evt.target.checked, targetType);
      }
    }
  };

  const handleCheckOption = (valueOption, confirmation, isCheckbox) => {
    props.onOptionChange(questionCode, valueOption, confirmation, null);
  };

  const handleComplementChange = (optCode, complementValue) =>
    props.onComplementChange(questionCode, optCode, complementValue);

  const handleChildrenOptionChange = (childCode, optCode, checked) =>
    props.onOptionChange(childCode, optCode, checked, questionCode);

  const handleChildrenComplementChange = (childCode, optCode, complementValue) =>
    props.onComplementChange(childCode, optCode, complementValue, questionCode);

  const options = Object.entries(props.question.options).map(([optCode, option], index) => {
    // const bullet = optCode.substring(optCode.lastIndexOf('_') + 1);
    const bullet = getBulletFromIndex(index);
    return (
      <Fragment key={`question-${questionCode}-option-${optCode}`}>
        {type === formSettings.questionTypes.multiOption && (
          <MultiOption
            option={option}
            optCode={optCode}
            bullet={bullet}
            questionCode={questionCode}
            selected={answers[index].selected}
            handleOptionChange={(evt) => handleOptionChange(evt, option.blockOthers)}
            groupStatus={groupStatus}
          />
        )}
        {type === formSettings.questionTypes.singleOption && (
          <SingleOption
            option={option}
            optCode={optCode}
            bullet={bullet}
            questionCode={questionCode}
            selected={answers[index].selected}
            handleOptionChange={(evt) => handleOptionChange(evt, option.blockOthers)}
            groupStatus={groupStatus}
          />
        )}
        <span>
          <OptionComplement
            code={optCode}
            show={option.withComplement && answers[index].selected}
            label={option.complementLabel}
            value={answers[index].complement}
            onComplementChange={handleComplementChange}
            groupStatus={groupStatus}
          />
        </span>
      </Fragment>
    );
  });

  return (
    <>
      <ModalUncheckConfirmation
        show={modalShow}
        eventValue={eventValue}
        eventType={eventType}
        disableSubquestion={disableSubquestion}
        handleClose={() => setModalShow(false)}
        handleConfirmation={handleCheckOption}
      />
      <div
        className={
          props.isChild
            ? `subquestion bg-gray-superlight p-4 mt-3 ${questionEnabled ? '' : 'op-40'}`
            : 'question' + (groupStatus !== 2 && questionEnabled ? '' : ' disabled')
        }
      >
        <h4 className="fw-bold mb-4">
          {props.isChild ? (
            <span className="c-green me-2">
              {props.parentPosition}.{props.position}.
            </span>
          ) : (
            <span className="c-green me-2">{props.position}.</span>
          )}
          {props.question.description}
          <QuestionHelper code={questionCode} text={props.question.helper} />
          <AnswerChanged changed={changed} />
        </h4>
        {errors[questionCode] && errors[questionCode].ROOT && (
          <p className="text-danger">{errors[questionCode].ROOT}</p>
        )}
        <fieldset disabled={!questionEnabled}>{options}</fieldset>
        {!!props.question.children && (
          <Questions
            surveyCode={props.surveyCode}
            questions={props.question.children}
            answers={props.answers}
            errors={errors}
            isChild={true}
            parentPosition={props.position}
            onOptionChange={handleChildrenOptionChange}
            onComplementChange={handleChildrenComplementChange}
            groupStatus={groupStatus}
          />
        )}
      </div>
      {!props.isChild && <hr className="my-5" />}
    </>
  );
};

export default Options;
