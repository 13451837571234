import { createSlice } from '@reduxjs/toolkit';

const requestControl = {
  loading: null,
  success: null,
};

const initialStateCity = {
  ...requestControl,
  item: {},
  descriptions: [],
};

export const cityInfoSlice = createSlice({
  name: 'cityInfo',
  initialState: initialStateCity,
  reducers: {
    loadingStart: (state) => {
      state.loading = true;
      state.success = null;
      state.item = {};
      state.descriptions = [];
    },
    loadingStop: (state) => {
      state.loading = false;
    },
    success: (state, action) => {
      state.success = true;
      state.item = action.payload?.item;
      state.descriptions = action.payload?.descriptions;
    },
    error: (state, action) => {
      state.success = false;
      state.message = action.payload;
    },
    reset: () => {
      return { ...initialStateCity };
    },
  },
});
