import React, { useEffect } from 'react';
import OAuth2LoginButton from 'components/OAuth2LoginButton';
import { AUTH_TYPE_GOVERNANCE } from 'helpers/statusUtils';

const AdminLogin = () => {
  useEffect(() => {
    sessionStorage.setItem('authType', AUTH_TYPE_GOVERNANCE);
  }, []);

  return (
    <div className="page bg-cyan">
      <div className="container page">
        <div className="row page justify-content-center">
          <div className="col-12 col-lg-8 bg-white">
            <div className="p-5 m-5">
              <h1 className="fw-bold c-green">Área restrita</h1>
              <h3>Essa área é destinada exclusivamente aos gestores públicos.</h3>
              <OAuth2LoginButton className="mt-5 mx-5 d-flex" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
