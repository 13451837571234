import React, { useState, useEffect, createRef } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Actions as LabelActions } from 'services/labels';
import { Actions as PostActions } from 'services/slices/content';
import { splitTextLineBreak } from 'helpers/replaceChar';

const MaturityLevelDescription = ({ labelItem, extraLabel }) => {
  return (
    <>
      <h6 className="fw-bold larger-2 text-left c-cyan mt-5">
        {extraLabel && <>{extraLabel}: </>}
        {labelItem?.title}
      </h6>
      {splitTextLineBreak(labelItem?.description).map((text, idx) => (
        <p key={`maturity-level-idx${idx}`} className="indent-1">
          {text}
        </p>
      ))}
    </>
  );
};

const Methodologies = () => {
  const dispatch = useDispatch();
  const labels = useSelector((state) => state.labels.labels);
  const dimensions = useSelector((state) => state.posts.dimensions);

  const [activeOptionId, setActiveOptionId] = useState('');

  const modelDimensions = createRef();
  const maturityLevels = createRef();
  const components = createRef();
  const indicators = createRef();
  const characterization = createRef();
  const publishing = createRef();
  const cityRegister = createRef();

  useEffect(() => {
    dispatch(LabelActions.loadLabels());
    dispatch(PostActions.loadFilters());
  }, [dispatch]);

  const handleScroll = (evt, ref) => {
    evt.preventDefault();
    setActiveOptionId(evt.currentTarget.id);
    ref.current.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <>
      {/* <!-- Título da Página - start --> */}
      <section className="page-title">
        <div className="container">
          <div className="title">Metodologia</div>
        </div>
      </section>
      {/* <!-- Título da Página - end --> */}

      {/* <!-- Cidades Inteligentes - start --> */}
      <section className="py-5">
        <div className="container py-5 my-5">
          <div className="row pb-5">
            {/* <!-- Coluna Lateral - start --> */}
            <div className="col-12 col-lg-4">
              {/* <!-- Box Links - start --> */}
              <div className="bg-gray-superlight p-4">
                <a
                  href="#"
                  id="opt-dimensoes-modelo"
                  className={`btn btn-white w-100 has-icon sm end chevron-right text-left fw-normal mb-3 ${
                    activeOptionId === 'opt-dimensoes-modelo' ? 'active' : ''
                  }`}
                  onClick={(evt) => handleScroll(evt, modelDimensions)}
                >
                  Dimensões
                </a>
                <a
                  href="#"
                  id="opt-niveis-maturidade"
                  className={`btn btn-white w-100 has-icon sm end chevron-right text-left fw-normal mb-3 ${
                    activeOptionId === 'niveis-maturidade' ? 'active' : ''
                  }`}
                  onClick={(evt) => handleScroll(evt, maturityLevels)}
                >
                  Níveis de Maturidade
                </a>
                <a
                  href="#"
                  id="opt-components"
                  className={`btn btn-white w-100 has-icon sm end chevron-right text-left fw-normal mb-3 ${
                    activeOptionId === 'opt-components' ? 'active' : ''
                  }`}
                  onClick={(evt) => handleScroll(evt, components)}
                >
                  Capacidades Institucionais
                </a>
                <a
                  href="#"
                  id="opt-indicadores"
                  className={`btn btn-white w-100 has-icon sm end chevron-right text-left fw-normal mb-3 ${
                    activeOptionId === 'opt-indicadores' ? 'active' : ''
                  }`}
                  onClick={(evt) => handleScroll(evt, indicators)}
                >
                  Desenvolvimento Sustentável
                  <br />e TICs
                </a>
                <a
                  href="#"
                  id="opt-caracterizacao"
                  className={`btn btn-white w-100 has-icon sm end chevron-right text-left fw-normal mb-3 ${
                    activeOptionId === 'opt-caracterizacao' ? 'active' : ''
                  }`}
                  onClick={(evt) => handleScroll(evt, characterization)}
                >
                  Caracterizações
                </a>
                <a
                  href="#"
                  id="opt-processo-publicacao"
                  className={`btn btn-white w-100 has-icon sm end chevron-right text-left fw-normal mb-3 ${
                    activeOptionId === 'opt-processo-publicacao' ? 'active' : ''
                  }`}
                  onClick={(evt) => handleScroll(evt, publishing)}
                >
                  Processo de Publicação
                </a>
                <a
                  href="#"
                  id="opt-cadastro"
                  className={`btn btn-white w-100 has-icon sm end chevron-right text-left fw-normal mb-3 ${
                    activeOptionId === 'opt-cadastro' ? 'active' : ''
                  }`}
                  onClick={(evt) => handleScroll(evt, cityRegister)}
                >
                  Cadastro da Prefeitura
                </a>
              </div>
              {/* <!-- Box Links - end --> */}
            </div>
            {/* <!-- Coluna Lateral - end --> */}

            {/* <!-- Coluna Principal - start --> */}
            <div className="col-12 col-lg-6 offset-lg-1 text-justify">
              <h1 className="mb-4 fw-bolder c-cyan text-left">Cidades Inteligentes Sustentáveis</h1>
              <p>
                São cidades comprometidas com o desenvolvimento urbano sustentável e a transformação digital, em seus
                aspectos econômico, meio ambiente, sociocultural e de capacidade institucional, que atuam de forma
                planejada, inovadora, inclusiva e em rede, promovem o letramento digital, a governança e a gestão
                colaborativas e utilizam tecnologias para solucionar problemas concretos, criar oportunidades, oferecer
                serviços com eficiência, reduzir desigualdades, aumentar a resiliência e melhorar a qualidade de vida de
                todas as pessoas, garantindo o uso seguro e responsável de dados e das tecnologias da informação e
                comunicação.
              </p>
              <p>A estrutura geral da plataforma inteli.gente é apresentada a seguir.</p>
            </div>
            {/* <!-- Coluna Principal - end --> */}
          </div>

          {/* <!-- Ilustração --> */}
          <div className="text-center wrap-img mt-5">
            <img src="img/art_dimensoes.png" alt="Dimensões da plataforma inteli.gente" />
            <p className="mt-3 smaller-2">Dimensões da plataforma inteli.gente</p>
          </div>
        </div>
      </section>
      {/* <!-- Cidades Inteligentes - end --> */}

      {/* <!-- Texto Dimensões - start --> */}
      <section ref={modelDimensions} id="dimensoes-modelo" className="py-5 bg-cyan">
        <div className="container py-5 my-5">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-9">
              <h1 className="mb-5 fw-bolder">Dimensões</h1>

              <h6 className="fw-bold larger-2">Meio Ambiente</h6>
              <p className="indent-1 mb-5 text-justify">
                Avalia a proteção do acesso aos recursos do ambiente natural e construído, no presente e no futuro. Tem
                foco no fomento à produção e ao consumo consciente e equilibrado entre recursos naturais, TICs e
                sociedade, com respeito, preservação e recuperação do ambiente natural.
              </p>

              <h6 className="fw-bold larger-2">Econômica</h6>
              <p className="indent-1 mb-5 text-justify">
                Avalia o potencial de gerar renda e emprego para a subsistência das pessoas e o desenvolvimento
                inclusivo da economia urbana local. Tem foco no acesso à infraestrutura urbana e de TIC, a geração de
                oportunidades econômicas pelo uso das TICs nas cidades e a promoção da economia criativa e
                compartilhada.
              </p>

              <h6 className="fw-bold larger-2">Sociocultural</h6>
              <p className="indent-1 mb-5 text-justify">
                Avalia a proteção do bem-estar das pessoas de maneira equitativa, com redução das desigualdades
                socioespaciais, acesso à informação, inclusão e letramento digital, bens e serviços urbanos essenciais.
                Tem foco no respeitar à identidade e à diversidade sociocultural local.
              </p>

              <h6 className="fw-bold larger-2">Capacidades Institucionais da Gestão Pública Municipal</h6>
              <p className="indent-1 text-justify">
                Avalia as capacidades institucionais para a transformação da cidade levando em conta ações-chave da
                administração pública municipal nos âmbitos de estratégia, infraestrutura de <em>Hardware</em> e
                <em>Software</em>, dados abertos, serviços e aplicações e monitoramento.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Texto Dimensões - end --> */}

      {/* <!-- Texto Níveis de Maturidade - start --> */}
      <section ref={maturityLevels} id="niveis-maturidade" className="py-5">
        <div className="container py-5 my-5">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-9 text-justify">
              <h1 className="mb-4 fw-bolder c-cyan text-left">Níveis de Maturidade</h1>
              <p>
                A plataforma inteli.gente apresenta um diagnóstico do nível de maturidade para subsidiar a construção de
                políticas públicas em todas as cidades do país. A plataforma possui sete níveis de maturidade, sendo o
                número sete o mais elevado. Os níveis 1 (Adesão) e 2 (Engajamento) são resultados de uma ampliação para
                o Brasil, a partir do SSC-MM da União Internacional de Telecomunicações (ITU) que recomenda cinco níveis
                de maturidade.
              </p>
              <div className="text-center wrap-img my-5">
                <img
                  src="img/art_piramide_maturidade.png"
                  alt="Representação dos níveis de maturidade da plataforma inteli.gente"
                  title="Representação dos níveis de maturidade da plataforma inteli.gente"
                />
                <div className="mt-3 c-gray smaller-1">
                  Representação dos níveis de maturidade da plataforma inteli.gente
                </div>
              </div>
              <p>
                Cada nível tem seus próprios objetivos, indicadores-chave de performance da cidade e práticas esperadas,
                apresentando um caminho evolutivo. O nível de maturidade não é voltado para o ranqueamento da cidade, e
                sim para fornecer um diagnóstico sobre o desenvolvimento urbano sustentável e a transformação digital de
                forma integrada.
              </p>
              <p>
                Os níveis de maturidade oferecem um guia para as cidades que buscam seu próprio desenvolvimento em
                curto, médio e longo prazos, entendendo que elas são complexas, diversas e estão em constante
                transformação. O conjunto de objetivos, indicadores e práticas delineiam caminhos de políticas públicas,
                federais, estaduais e municipais em direção às cidades tornarem-se inteligentes e sustentáveis.
              </p>
              {labels && (
                <>
                  <MaturityLevelDescription labelItem={labels.dm1} extraLabel="Primeiro nível" />
                  <MaturityLevelDescription labelItem={labels.dm2} extraLabel="Segundo nível" />
                  <MaturityLevelDescription labelItem={labels.dm3} extraLabel="Terceiro nível" />
                  <MaturityLevelDescription labelItem={labels.dm4} extraLabel="Quarto nível" />
                  <MaturityLevelDescription labelItem={labels.dm5} extraLabel="Quinto nível" />
                  <MaturityLevelDescription labelItem={labels.dm6} extraLabel="Sexto nível" />
                  <MaturityLevelDescription labelItem={labels.dm7} extraLabel="Sétimo nível" />
                </>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Texto Níveis de Maturidade - end --> */}

      {/* <!-- Texto Componentes - start --> */}
      <section ref={components} id="components" className="py-5 bg-cyan">
        <div className="container py-5 my-5">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-9 text-justify">
              <h1 className="mb-4 fw-bolder text-left">Capacidades Institucionais</h1>
              <p>
                Os tópicos desta dimensão avaliam a maturidade da gestão municipal em relação a estratégia,
                infraestrutura de Hardware e Software, dados abertos, serviços e aplicações e monitoramento, de maneira
                que a cidade estruture melhores condições para alcançar e se sustentar como uma cidade inteligente.
              </p>
              <p>
                Os Indicadores de Capacidade Institucional foram normalizados e parametrizados por serem provenientes de
                diferentes fontes e escalas sendo necessário converter os dados para um mesmo padrão de medição, ou
                seja, em faixas de valores (intervalos), com critérios de metrificação dos indicadores para auferir o
                nível de maturidade (1 a 7). A seguir são apresentados os tópicos cobertos pelos indicadores de
                Capacidade Institucional.
              </p>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-3">
              {dimensions && (
                <>
                  <table className="bg-gray-light">
                    <thead>
                      <tr className="text-center bg-white c-black">
                        <th className="py-2">Capacidades Institucionais</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <ul className="my-3 mx-2">
                            {dimensions
                              .filter((dItem) => dItem.value === 6)[0]
                              ?.subOptions.map((tItem) => (
                                <li key={tItem.value}>{tItem.text}</li>
                              ))}
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p className="smaller-2 text-center mt-3">Tópicos da Capacidade Institucional</p>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Texto Componentes - end --> */}

      {/* <!-- Texto Indicadores - start --> */}
      <section ref={indicators} id="indicadores" className="py-5">
        <div className="container py-5 my-5">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-9 text-justify">
              <h1 className="mb-4 fw-bolder text-left c-cyan">Desenvolvimento Sustentável e TICs</h1>
              <p>
                Os Indicadores de Desenvolvimento Sustentável e TICs foram normalizados e parametrizados por serem
                provenientes de diferentes fontes e escalas sendo necessário converter os dados para um mesmo padrão de
                medição, ou seja, em faixas de valores (intervalos), com critérios de metrificação dos indicadores para
                auferir o nível de maturidade (1 a 7).
              </p>
              <p>A seguir são apresentados os tópicos cobertos pelos indicadores de desenvolvimento sustentável.</p>
            </div>
          </div>

          <div className="bg-success text-center my-5 p-2 larger-5">Desenvolvimento Sustentável e TICs</div>

          <div className="row justify-content-center">
            <div className="col-lg-10">
              {dimensions && (
                <>
                  <table className="bg-gray-light">
                    <colgroup>
                      <col style={{ width: '40%' }} />
                      <col style={{ width: '30%' }} />
                      <col style={{ width: '30%' }} />
                    </colgroup>
                    <thead>
                      <tr className="text-center bg-cyan c-white">
                        {dimensions
                          .filter((dItem) => [2, 3, 4].includes(dItem.value))
                          .map((dItem) => (
                            <th key={`header-${dItem.value}`}>{dItem.text}</th>
                          ))}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {dimensions
                          .filter((dItem) => [2, 3, 4].includes(dItem.value))
                          .map((dItem) => {
                            return (
                              <td key={`cell-${dItem.value}`}>
                                <ul className="my-3 mx-2">
                                  {dItem.subOptions.map((tItem) => (
                                    <li key={tItem.value}>{tItem.text}</li>
                                  ))}
                                </ul>
                              </td>
                            );
                          })}
                      </tr>
                    </tbody>
                  </table>
                  <p className="smaller-2 text-center mt-3">
                    Tópicos das dimensões de Desenvolvimento Sustentável e TICs
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Texto Indicadores - end --> */}

      {/* <!-- Texto Caracterização - start --> */}
      <section ref={characterization} id="caracterizacao" className="py-5 bg-cyan">
        <div className="container py-5">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-9 text-justify">
              <h1 className="mb-4 fw-bolder text-left">Caracterizações</h1>
              <p>
                Um conjunto de indicadores distribuídos em tópicos são utilizados para descrição dos perfis
                sociodemográfico, transformação digital e institucional da cidade. Estes indicadores são chamados de
                caracterização, e não são utilizados para atribuir um nível de maturidade. Abaixo são apresentados os
                tópicos das Caracterizações.
              </p>
            </div>
          </div>

          <div className="row justify-content-center mt-5">
            <div className="col-lg-8">
              <table className="bg-gray-light">
                <colgroup>
                  <col style={{ width: '33%' }} />
                  <col style={{ width: '34%' }} />
                  <col style={{ width: '33%' }} />
                </colgroup>
                <thead>
                  <tr className="text-center bg-success c-white">
                    {dimensions
                      .filter((dItem) => [1, 10, 11].includes(dItem.value))
                      .map((dItem) => (
                        <th key={`header-${dItem.value}`}>{dItem.text}</th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {dimensions
                      .filter((dItem) => [1, 10, 11].includes(dItem.value))
                      .map((dItem) => {
                        return (
                          <td key={`cell-${dItem.value}`}>
                            <ul className="my-3 mx-2">
                              {dItem.subOptions.map((tItem) => (
                                <li key={tItem.value}>{tItem.text}</li>
                              ))}
                            </ul>
                          </td>
                        );
                      })}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <p className="smaller-2 text-center mt-3">Tópicos das Caracterizações da plataforma inteli.gente</p>
        </div>
      </section>
      {/* <!-- Texto Caracterização - end --> */}

      {/* <!-- Texto Publicação - start --> */}
      <section ref={publishing} id="processo-publicacao" className="py-5">
        <div className="container py-5 my-5">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-9 text-justify">
              <h1 className="mb-4 fw-bolder text-left c-cyan">Processo de Publicação</h1>
              <p>
                A publicação do diagnóstico do nível de maturidade é disponibilizada por meio de uma estimativa, tem
                como referência os indicadores de bases secundárias. As cidades podem aprimorar o nível de maturidade
                estimado, toda vez que preencherem os formulários de Desenvolvimento Sustentável e TICs e de Capacidade
                Institucional, de forma dinâmica, na área restrita da prefeitura.
              </p>

              <p className="fw-bold larger-2 text-left c-cyan mt-4 indent-3">
                Coleta e organização de dados secundários: um conjunto de dados sobre diversas áreas de uma cidade é
                coletado e organizado na forma de indicadores.
              </p>

              <p className="fw-bold larger-2 text-left c-cyan mt-4 indent-3">
                Preenchimento da plataforma: para os indicadores de base primária, os representantes municipais podem
                entrar na plataforma para melhorar o nível de maturidade estimado fornecido pela plataforma respondendo
                um conjunto de perguntas sobre a cidade para obter o nível de maturidade final.
              </p>

              <p className="fw-bold larger-2 text-left c-cyan mt-5 indent-3">
                Análise: o sistema da plataforma analisa todas as informações com base no Método de Auxílio
                Multicritérios à Decisão, AMD (<em>Multiple Criteria Decision Analysis, MCDA</em>) e apresenta um
                diagnóstico do nível de maturidade da cidade.
              </p>

              <p className="fw-bold larger-2 text-left c-cyan mt-5 indent-3">
                Disponibilização: os resultados e recomendações ficam disponíveis em relatórios e painéis de resultados
                por dimensões para os gestores municipais, estaduais e federais.
              </p>

              <p className="fw-bold larger-2 text-left c-cyan mt-5 indent-3">
                Atualização: anualmente há uma revisão e atualização do conjunto de indicadores a fim de acompanhar a
                dinâmica de transformação digital e desenvolvimento urbano. Os indicadores secundários de órgãos
                oficiais acompanham a periodicidade de suas atualizações.
              </p>

              <div className="wrap-img text-center mt-5">
                <figure>
                  <img
                    src="img/art_diagnostico.png"
                    alt="Representação do Sistema de Diagnóstico da plataforma inteli.gente"
                    title="Representação do Sistema de Diagnóstico da plataforma inteli.gente"
                  />
                  <figcaption>Representação do Sistema de Diagnóstico da plataforma inteli.gente</figcaption>
                </figure>
              </div>

              <p className="fw-bold larger-2 text-left c-cyan mt-5 indent-3">
                <a href="/Manual plataforma inteligente V1_Final_ISBN.pdf" download>
                  Download do Manual de Referência da Plataforma inteli.gente
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Texto Publicação - end --> */}

      {/* <!-- Texto Caracterização - start --> */}
      <section ref={cityRegister} id="cadastro" className="py-5 bg-cyan">
        <div className="container py-5 my-5">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-9 text-justify">
              <h1 className="mb-4 fw-bolder text-left">Cadastro da Prefeitura</h1>
              <p>
                Para ter acesso à plataforma, aos formulários auto declaratórios e ao painel de resultados, o Prefeito
                da cidade{' '}
                <Link to="/prefeitura" className="text-white">
                  realiza o cadastro
                </Link>{' '}
                em{' '}
                <a style={{ color: 'white', textDecoration: 'underline' }} href="https://inteligente.mcti.gov.br">
                  https://inteligente.mcti.gov.br
                </a>
                , pelo Gov.br, inserindo as informações solicitadas. A equipe de suporte valida o cadastro e libera o
                primeiro acesso, a partir daí o Prefeito pode cadastrar mais cinco assessores para auxiliar no
                gerenciamento da área exclusiva da Prefeitura.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Texto Caracterização - end --> */}
    </>
  );
};

export default Methodologies;
