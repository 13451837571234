import { useEffect, useLayoutEffect, useState } from 'react';

export function useTitle(pageTitle) {
  const [title, setTitle] = useState('');

  useEffect(() => setTitle(pageTitle), [pageTitle]);

  const defaultTitle = process.env.REACT_APP_DEFAULT_TITLE;
  useLayoutEffect(() => {
    const prevTitle = document.title;
    document.title = `${title} | ${defaultTitle}`;
    return () => {
      document.title = prevTitle;
    };
  }, [defaultTitle, title]);

  return title;
}
