import api from 'services/api';
import { defaultHttpStatusMessages } from 'helpers/statusUtils';

// TODO: Ajustar para o caso
function handleMessage(response, msg) {
  const complementMsg = msg ? ' ' + msg : '';
  if (response && 'status' in response) {
    return defaultHttpStatusMessages[response.status] + complementMsg;
  }
  return 'Não foi possível completar a operação.' + complementMsg;
}

export const Types = {
  LIST_CITIES_REQUEST: 'citiesList/LIST_CITIES_REQUEST',
  LIST_CITIES_SUCCESS: 'citiesList/LIST_CITIES_SUCCESS',
  LIST_CITIES_FAILURE: 'citiesList/LIST_CITIES_FAILURE',
  SEARCH_CITIES_SUCCESS: 'citiesList/SEARCH_CITIES_SUCCESS',
  RESET_CITIES_LIST: 'citiesList/RESET_CITIES_LIST',
  GET_CITIES_BY_ID: 'citiesList/GET_CITIES_BY_ID',
};

const INITIAL_STATE = {
  cities: [],
  searchResult: [],
  loading: false,
  error: {
    status: null,
    tries: 1,
    message: null,
  },
  successful: null,
  citiesById: [],
};

export default function citiesList(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_CITIES_REQUEST:
      return { ...state, loading: true };
    case Types.LIST_CITIES_SUCCESS:
      return { ...state, loading: false, error: { status: false, tries: 1 }, cities: [...action.payload] };
    case Types.LIST_CITIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: { status: true, message: action.message, tries: state.error.tries < 10 ? ++state.error.tries : 1 },
        successful: false,
        cities: [],
      };
    case Types.SEARCH_CITIES_SUCCESS:
      return { ...state, loading: false, error: { status: false, tries: 1 }, searchResult: [...action.payload] };
    case Types.RESET_CITIES_LIST:
      return { ...state, searchResult: [] };
    case Types.GET_CITIES_BY_ID:
      return { ...state, citiesById: action.payload };
    default:
      return state;
  }
}

export const Creators = {
  listCities: () => {
    return (dispatch) => {
      dispatch({
        type: Types.LIST_CITIES_REQUEST,
      });
      api
        .get('api/cities?page=1&qt=5570')
        .then((response) => {
          const cities = response.data.content.map((city) => {
            return {
              id: city.id,
              name: city.name + ' - ' + city.state, // TODO: verificar nome da cidade aqui
            };
          });
          dispatch({
            type: Types.LIST_CITIES_SUCCESS,
            payload: cities,
          });
        })
        .catch((error) => {
          dispatch({
            type: Types.LIST_CITIES_FAILURE,
            message: handleMessage(error.response),
          });
        });
    };
  },
  resetCitiesList: () => {
    return (dispatch) => {
      dispatch({
        type: Types.RESET_CITIES_LIST,
      });
    };
  },
  searchCity: (term = '') => {
    return (dispatch) => {
      dispatch({
        type: Types.LIST_CITIES_REQUEST,
      });
      let searchTerm = term;
      const splitTerm = term.split(',');
      if (splitTerm && splitTerm.length > 1) {
        searchTerm = splitTerm[0];
      }
      // Fazendo dessa forma pois ocorre erro qdo digita '[' ou ']' (bug axios)
      const params = {
        term: searchTerm,
      };
      const urlParams = new URLSearchParams(Object.entries(params));
      api
        .get('api/cities/autocomplete?' + urlParams.toString()) // parâmetro result retorna 'cidade, UF'. Enviar somente 'cidade' para endpoint
        .then((response) => {
          dispatch({
            type: Types.SEARCH_CITIES_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Types.LIST_CITIES_FAILURE,
            message: handleMessage(error.response),
          });
        });
    };
  },
  findCitiesById: (citiesIds) => {
    const params = citiesIds.map((id) => 'citiesIds=' + id).join('&');
    return (dispatch) => {
      api
        .get('api/cities/search-city?' + params)
        .then((response) => {
          // converte para estrutura do payload do search(id/friendlyName/result)
          const cities = response.data.map((item) => ({
            id: item.id,
            friendlyName: item.friendlyName,
            result: `${item.name}, ${item.state}`,
          }));

          dispatch({
            type: Types.GET_CITIES_BY_ID,
            payload: cities,
          });
        })
        .catch((error) => {
          dispatch({
            type: Types.LIST_CITIES_FAILURE,
            message: handleMessage(error.response),
          });
        });
    };
  },
};
