import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Actions as SupportInfoActions } from 'services/slices/supportInfo';
import OAuth2LoginButton from 'components/OAuth2LoginButton';
import api from 'services/api';
import { AUTH_TYPE_GOVERNANCE } from 'helpers/statusUtils';

const VerifiedValid = ({ userData, supportEmail = process.env.REACT_APP_DEFAULT_SUPPORT_EMAIL }) => {
  return (
    <div className="p-5 m-5">
      <h1 className="fw-bold c-green">Olá {userData.name}</h1>
      <p className="mb-3">
        Para seu primeiro acesso na Plataforma <strong>inteli.gente</strong>, será necessário fazer login usando sua
        conta <strong>gov.br</strong>, clicando no botão abaixo.
      </p>
      <p className="mb-3">
        Em caso de dúvidas, favor enviar um e-mail para a equipe de governança da plataforma ({supportEmail}).
      </p>
      <OAuth2LoginButton className="text-center mt-5" />
    </div>
  );
};

const VerifiedInvalid = ({ errorMsg, supportEmail = process.env.REACT_APP_DEFAULT_SUPPORT_EMAIL }) => {
  return (
    <div className="p-5 m-5">
      <h1 className="fw-bold c-cyan">
        Acesso não autorizado <i className="fa-solid fa-circle-exclamation c-danger"></i>
      </h1>
      <h3>{errorMsg}</h3>
      <p>Em caso de dúvidas, favor enviar um e-mail para a equipe de governança da plataforma ({supportEmail}).</p>
      <p className="text-center mt-5">
        <Link to="/home" id="btnRedirectHome" className="btn btn-success">
          Voltar para tela inicial
        </Link>
      </p>
    </div>
  );
};

const Loading = () => {
  return (
    <div className="p-5 m-5">
      <h1 className="fw-bold c-green">Verificando código</h1>
      <h3>
        Aguarde<span className="dot1">.</span>
        <span className="dot2">.</span>
        <span className="dot3">.</span>
      </h3>
    </div>
  );
};

/* Fluxo:
1- Gestor admin cadastra e-mail do usuário
2- Sistema envia e-mail com token de ativação
3- Usuário acessa tela através do link e clica no botão para login com gov.br
   (entra no fluxo do gov.br para login ou para cadastro)
4- Após login/cadastro, preencher e-mail e telefone institucional e finalizar
*/

const ActivationOauth2 = () => {
  const { token } = useParams();
  const dispatch = useDispatch();

  const [message, setMessage] = useState(null);

  const [verified, setVerified] = useState(false);
  const [valid, setValid] = useState(false);
  const [governanceUserData, setGovernanceUserData] = useState();

  const supportEmail = useSelector((state) => state.supportInfo.email);

  useEffect(() => {
    dispatch(SupportInfoActions.getSupportEmail());
  }, [dispatch]);

  useEffect(() => {
    if (!verified) {
      api
        .get('/api/users/verify-token-governance', {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        })
        .then((resp) => {
          setValid(true);
          setGovernanceUserData(resp.data);
          sessionStorage.setItem('governanceUserData', JSON.stringify(resp.data));
          sessionStorage.setItem('authType', AUTH_TYPE_GOVERNANCE);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            setMessage('Este link acessado é inválido ou está expirado.');
          } else {
            setMessage('Erro na validação do código');
          }
        })
        .finally(() => {
          setVerified(true);
        });
    }
  }, [token, verified]);

  return (
    <div className="page bg-cyan">
      <div className="container page">
        <div className="row page justify-content-center">
          <div className="col-12 col-lg-8 bg-white">
            {verified ? (
              valid ? (
                <VerifiedValid userData={governanceUserData} supportEmail={supportEmail} />
              ) : (
                <VerifiedInvalid errorMsg={message} supportEmail={supportEmail} />
              )
            ) : (
              <Loading />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivationOauth2;
