const cleanText = (text) => {
  return text
    .trim()
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
};

const hasTextInside = (text, ...params) => {
  const normalizedText = cleanText(text);
  for (let i = 0; i < params.length; i++) {
    if (cleanText(params[i]).indexOf(normalizedText) > -1) {
      return true;
    }
  }
  return false;
};

export const filterStatusAndText = (status, action, ...params) => {
  return status === action.statusCode && hasTextInside(action.text, ...params);
};

export const filterStatusInAndText = (status, action, ...params) => {
  return action.statusCode.includes(status) && hasTextInside(action.text, ...params);
};

export const filterPostsByStatusAndText = (status, activeFilter, action, ...params) => {
  return activeFilter.includes(status) && hasTextInside(action.text, ...params);
};
