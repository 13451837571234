import React, { useEffect, useState } from 'react';
import { Alert, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Actions as CityUserActions } from 'services/slices/user';

export const ApprovalModal = ({ id, roleCode, cityId, show, title, message, onClose, onConfirmation }) => {
  const mayorData = useSelector((state) => state.users.mayorFound);

  const dispatch = useDispatch();

  useEffect(() => {
    if (show === true && roleCode === 'P') {
      dispatch(CityUserActions.checkMayorFromCityId(cityId));
    }
  }, [dispatch, show, cityId, roleCode]);

  return (
    <Modal
      className="inteligente-modal"
      show={show}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
        {mayorData.length > 0 && (
          <Alert variant="info">
            <Alert.Heading>Desativação de prefeito atual</Alert.Heading>
            <p>Já existe um prefeito para este município: {mayorData[0]?.name}. </p>
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-danger" onClick={onClose}>
          Não
        </button>
        <button type="button" className="btn btn-success" onClick={() => onConfirmation(id)}>
          Sim
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export const DisapprovalModal = ({ id, show, title, message, onClose, onConfirmation }) => {
  const [reason, setReason] = useState('');
  const [actionCode, setActionCode] = useState('1');

  const handleActionCode = (evt) => {
    setActionCode(evt.target.value);
  };

  const handleReason = (evt) => {
    setReason(evt.target.value);
  };

  const handleConfirmation = (id, disapprovalObj) => {
    setReason('');
    setActionCode('1');
    onConfirmation(id, disapprovalObj);
  };

  return (
    <Modal
      className="inteligente-modal"
      show={show}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
        <div className="form-group mb-3">
          <label className="form-label fw-semibold">Motivo da recusa</label> <br />
          <textarea
            className="form-control"
            id="disapproval"
            name="reason"
            rows={5}
            value={reason}
            placeholder="Informe o motivo da recusa"
            onChange={handleReason}
          ></textarea>
        </div>
        <div className="form-group mb-3">
          <label className="form-label fw-semibold">O que fazer com o cadastro recusado? </label> <br />
          <div className="form-check form-check-inline mb-4">
            <input
              name="actionCode"
              className="form-check-input"
              type="radio"
              id="keepIt"
              value="1"
              onChange={handleActionCode}
              checked={(actionCode && actionCode === '1') || false}
            />
            <label className="form-check-label mt-2" htmlFor="keepIt">
              Somente manter recusado
            </label>
          </div>
          <div className="form-check form-check-inline mb-4">
            <input
              name="actionCode"
              className="form-check-input"
              type="radio"
              id="removeIt"
              value="99"
              onChange={handleActionCode}
              checked={(actionCode && actionCode === '99') || false}
            />
            <label className="form-check-label mt-2" htmlFor="removeIt">
              Excluir cadastro
            </label>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-outline-secondary" onClick={onClose}>
          Não
        </button>
        <button type="button" className="btn btn-danger" onClick={() => handleConfirmation(id, { reason, actionCode })}>
          Sim
        </button>
      </Modal.Footer>
    </Modal>
  );
};
