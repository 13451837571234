import { createSlice } from '@reduxjs/toolkit';
import api from 'services/api';

const initialState = {
  loading: false,
  success: null,
  cityData: null,
  errorMsg: null,
};

export const citySearchSlice = createSlice({
  name: 'citySearch',
  initialState,
  reducers: {
    request: (state) => {
      state.loading = true;
      state.success = null;
    },
    // loadSuccess: (state, action) => {
    //   const cityData = action.payload.cityData;
    //   const labels = action.payload.labels;

    //   const dimensionsMapped = cityData.dimensions;
    //   const topicsPerDimension = dimensionsMapped.map((dimension) => {
    //     const topics = dimension.topics.map((topic) => {
    //       topic.title = labels[topic.code]?.title || topic.code;
    //       topic.description = labels[topic.code]?.description;
    //       return topic;
    //     });
    //     return { [dimension.code]: topics };
    //   });

    //   const topicsMapped = topicsPerDimension.reduce((acc, curr) => {
    //     return { ...acc, ...curr };
    //   }, {});

    //   const indicatorsPerDimension = dimensionsMapped.map((dimension) => {
    //     const indicatorsPerTopic = dimension.topics.map((topic) => topic.indicators).flat();
    //     const indicatorsPerTopicWithTitleAndDescription = indicatorsPerTopic.map((indicator) => {
    //       indicator.title = labels[indicator.code]?.title || indicator.code;
    //       indicator.description = labels[indicator.code]?.description;
    //       return indicator;
    //     });
    //     return {
    //       [dimension.code]: indicatorsPerTopicWithTitleAndDescription,
    //     };
    //   });

    //   const indicatorsMapped = indicatorsPerDimension.reduce((acc, cur) => {
    //     return { ...acc, ...cur };
    //   }, {});

    //   // Insere título e descrição dos tópicos/indicadores nos resultados
    //   const result = {
    //     ...cityData,
    //     characterization: cityData.characterization.map((item) => {
    //       item.title = labels[item.code]?.title || item.code;
    //       item.description = labels[item.code]?.description;
    //       return item;
    //     }),
    //     dimensions: dimensionsMapped.map((dimension) => {
    //       dimension.title = labels[dimension.code]?.title || dimension.code;
    //       dimension.description = labels[dimension.code]?.description;
    //       return dimension;
    //     }),
    //     topics: topicsMapped,
    //     indicators: indicatorsMapped,
    //   };
    //   state.loading = false;
    //   state.success = true;
    //   state.cityData = result;
    //   state.errorMsg = null;
    // },
    loadSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.cityData = action.payload.cityData;
      state.errorMsg = null;
    },
    loadError: (state, action) => {
      state.loading = false;
      state.success = false;
      state.cityData = null;
      state.errorMsg = action.payload.errorMsg;
    },
    reset: (state) => {
      state.loading = false;
      state.success = null;
      state.cityData = null;
      state.errorMsg = null;
    },
  },
});

export const { request, loadSuccess, loadError, reset } = citySearchSlice.actions;

export const Actions = {
  searchCityInformation: (cityFriendlyName) => {
    return (dispatch) => {
      dispatch(request());
      api
        .get(`/api/cities/${cityFriendlyName}`)
        .then((resp) => {
          // api
          //   .get('/api/cities/score/labels')
          //   .then((respLabels) => {
          dispatch(
            loadSuccess({
              cityData: resp.data,
              // labels: respLabels.data,
            }),
          );
          //     })
          //     .catch((err) => {
          //       console.log(err.response.message);
          //       dispatch(
          //         loadError({
          //           errorStatus: err.response.status,
          //           errorMsg: 'Erro ao carregar labels',
          //         }),
          //       );
          //     });
        })
        .catch((err) => {
          if (err.response.status === 404) {
            console.error(`Município ${cityFriendlyName} não encontrado`);
            dispatch(
              loadError({
                errorStatus: err.response.status,
                errorMsg: 'Municipio não encontrado',
              }),
            );
          } else {
            console.error(err);
            dispatch(
              loadError({
                errorStatus: err.response.status,
                errorMsg: 'Erro ao carregar dados do municípo',
              }),
            );
          }
        });
    };
  },
  resetSearch: () => {
    return (dispatch) => {
      dispatch(reset());
    };
  },
};

export default citySearchSlice.reducer;
