import { toast } from 'react-toastify';

// type pode ser: info, success, warn, error
export const showToast = (message, type = 'success', autoClose = 5000, onClose = () => {}) => {
  if (type === 'danger') type = 'error';
  return toast[type](message, { autoClose, onClose });
};

export const dismissToast = (toastId) => {
  if (toastId) {
    toast.dismiss(toastId);
  }
};
