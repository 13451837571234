import { createSlice } from '@reduxjs/toolkit';
import api from 'services/api';
import { handleMessage, getDefaultAnswers } from 'helpers/surveyUtils';

const BASE_URL = '/api/admin/survey-viewer';

const loadStatusValues = Object.freeze({
  UNDEFINED: -1,
  LOADING: 0,
  LOADED_SUCCESS: 1,
  LOADED_ERROR: 2,
});

const initialState = {
  loadStatusValues,
  allGroupsInfo: null,
  finishedSurveysGroups: [],
  progressSurveyGroup: null,
  allGroupLoadStatus: loadStatusValues.UNDEFINED,
  groupInfo: null,
  currentSurveyCode: null,
  groupLoadStatus: loadStatusValues.UNDEFINED,
  questionsLoadStatus: loadStatusValues.UNDEFINED,
  answersLoadStatus: loadStatusValues.UNDEFINED,
  groupSaveNotification: 0,
  errorMsg: null,
};

export const adminSurveyViewSlice = createSlice({
  name: 'adminSurveyView',
  initialState,
  reducers: {
    allGroupsLoading: (state) => {
      state.allGroupLoadStatus = loadStatusValues.LOADING;
    },
    allGroupsLoadedSuccess: (state, action) => {
      const availableGroups = [];
      for (const surveyGroup of action.payload) {
        availableGroups.push({
          id: surveyGroup.id,
          code: surveyGroup.code,
          title: surveyGroup.title,
          startDate: surveyGroup.startDate,
          endDate: surveyGroup.endDate,
          status: surveyGroup.status,
        });
      }
      state.allGroupsInfo = availableGroups;
      state.allGroupLoadStatus = loadStatusValues.LOADED_SUCCESS;
      state.groupInfo = null;
      state.errorMsg = null;
    },
    allGroupsLoadedError: (state, action) => {
      state.allGroupLoadStatus = loadStatusValues.LOADED_ERROR;
      state.loadingAllGroups = false;
      state.errorMsg = action.payload.msg;
    },
    groupLoading: (state) => {
      state.groupLoadStatus = loadStatusValues.LOADING;
    },
    groupLoadedSuccess: (state, action) => {
      const actionData = action.payload.data;

      let initialErrors = {};
      let initialValid = {};
      let initialNotifications = {};
      let initialSurveys = {};
      Object.keys(actionData.surveys).forEach((surveyCode) => {
        initialErrors = {
          ...initialErrors,
          [surveyCode]: {},
        };
        initialValid = {
          ...initialValid,
          [surveyCode]: -1,
        };
        initialNotifications = {
          ...initialNotifications,
          [surveyCode]: 0,
        };
        // Mantém questões do survey caso eles já estejam carregados
        const currentSurvey = state.groupInfo?.surveys[surveyCode] || actionData.surveys[surveyCode];
        initialSurveys = {
          ...initialSurveys,
          [surveyCode]: currentSurvey,
        };
      });
      state.groupInfo = {
        id: actionData.id,
        code: actionData.code,
        title: actionData.title,
        status: actionData.status,
        startDate: actionData.startDate,
        endDate: actionData.endDate,
        surveys: initialSurveys,
        answers: actionData.answers,
        valid: initialValid,
        errors: initialErrors,
        notifications: initialNotifications,
      };
      state.progressSurveyGroup = action.payload.progressSurveyGroup || state.progressSurveyGroup;
      state.finishedSurveysGroups = action.payload.finishedSurveysGroups || state.finishedSurveysGroups;
      state.groupLoadStatus = loadStatusValues.LOADED_SUCCESS;
      state.errorMsg = null;
    },
    groupLoadedError: (state, action) => {
      state.groupLoadStatus = loadStatusValues.LOADED_ERROR;
      state.errorMsg = action.payload.msg;
    },
    questionsLoading: (state, action) => {
      const surveyCode = action.payload.surveyCode;
      state.currentSurveyCode = surveyCode;
      state.questionsLoadStatus = loadStatusValues.LOADING;
    },
    questionsLoadedSuccess: (state, action) => {
      const surveyCode = action.payload.surveyCode;
      state.questionsLoadStatus = loadStatusValues.LOADED_SUCCESS;
      state.groupInfo.surveys[surveyCode].questions = action.payload.surveyData.questions;
    },
    questionsLoadedKeep: (state, action) => {
      const surveyCode = action.payload.surveyCode;
      state.currentSurveyCode = surveyCode;
      state.questionsLoadStatus = loadStatusValues.LOADED_SUCCESS;
    },
    questionsLoadedError: (state) => {
      state.questionsLoadStatus = loadStatusValues.LOADED_ERROR;
    },
    answersLoading: (state) => {
      state.answersLoadStatus = loadStatusValues.LOADING;
    },
    answersLoadedKeep: (state) => {
      state.answersLoadStatus = loadStatusValues.LOADED_SUCCESS;
    },
    answersLoadedSuccess: (state, action) => {
      const actionData = action.payload;

      let currentErrors;
      if (state.groupInfo.errors[actionData.surveyCode]) {
        currentErrors = { ...state.groupInfo.errors[actionData.surveyCode] };
      } else {
        currentErrors = {};
      }
      state.groupInfo.answers[actionData.surveyCode] = { ...actionData.answersData };
      state.groupInfo.errors[actionData.surveyCode] = currentErrors;
      state.answersLoadStatus = loadStatusValues.LOADED_SUCCESS;
    },
    answersLoadedError: (state) => {
      state.answersLoadStatus = loadStatusValues.LOADED_ERROR;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});

export const {
  allGroupsLoading,
  allGroupsLoadedSuccess,
  allGroupsLoadedError,
  groupLoading,
  groupLoadedSuccess,
  groupLoadedError,
  questionsLoading,
  questionsLoadedSuccess,
  questionsLoadedKeep,
  questionsLoadedError,
  answersLoading,
  answersLoadedKeep,
  answersLoadedSuccess,
  answersLoadedError,
  reset,
} = adminSurveyViewSlice.actions;

export const Actions = {
  findAllGroupsAvailable: () => {
    return (dispatch) => {
      dispatch(allGroupsLoading());
      api
        .get(BASE_URL)
        .then((resp) => {
          if (resp.data) {
            dispatch(allGroupsLoadedSuccess(resp.data));
          }
        })
        .catch((err) => {
          dispatch(
            allGroupsLoadedError({
              msg:
                handleMessage(err.response, 'Erro desconhecido LOADED_ALL_GROUPS') ||
                `Erro desconhecido LOADING_ALL - ${err.response.status}`,
            }),
          );
        });
    };
  },

  findGroupByCode: (groupCode, cityFriendlyName) => {
    return (dispatch) => {
      dispatch(groupLoading());
      api
        .get(`${BASE_URL}/${groupCode}/${cityFriendlyName}`)
        .then((resp) => {
          if (resp.data) {
            const surveysData = resp.data;
            let progressSurveyGroup = null;
            let finishedSurveysGroupsArr = [];

            api
              .get(`${BASE_URL}/${groupCode}/${cityFriendlyName}/finished`)
              .then((resp2) => {
                const answeredSurveysGroupsArr = resp2.data;
                if (answeredSurveysGroupsArr.length > 0) {
                  // Somente considera surveys em andamento quando o último não estiver finalizado (array com mais recente primeiro)
                  if (answeredSurveysGroupsArr[0].status !== 2) {
                    const progressSurveyGroupArr = answeredSurveysGroupsArr.filter((item) => item.status === 1);
                    if (progressSurveyGroupArr.length > 0) {
                      progressSurveyGroup = progressSurveyGroupArr[0];
                    }
                  }
                  finishedSurveysGroupsArr = answeredSurveysGroupsArr.filter((item) => item.status === 2);
                }
                dispatch(
                  groupLoadedSuccess({
                    data: surveysData,
                    progressSurveyGroup,
                    finishedSurveysGroups: finishedSurveysGroupsArr,
                  }),
                );
              })
              .catch((err) => {
                console.log(JSON.stringify(err));
                dispatch(groupLoadedSuccess({ data: surveysData, msg: err?.response || 'Erro' }));
              });
          }
        })
        .catch((err) => {
          dispatch(
            groupLoadedError({
              msg:
                handleMessage(err.response, 'Erro desconhecido LOADED_GROUP ' + groupCode) ||
                `Erro desconhecido LOADED_GROUP - ${groupCode} (${err.response.status})`,
            }),
          );
        });
    };
  },

  findGroupById: (groupCode, cityFriendlyName, sgcId) => {
    return (dispatch) => {
      dispatch(groupLoading());
      api
        .get(`${BASE_URL}/${groupCode}/answers/${sgcId}`)
        .then((resp) => {
          if (resp.data) {
            const surveysData = resp.data;
            dispatch(groupLoadedSuccess({ data: surveysData }));
          }
        })
        .catch((err) => {
          dispatch(
            groupLoadedError({
              msg:
                handleMessage(err.response, 'Erro desconhecido LOADED_GROUP ' + groupCode + ', ID ' + sgcId) ||
                `Erro desconhecido LOADED_GROUP - ${groupCode} (${err.response.status})`,
            }),
          );
        });
    };
  },

  findGroupByIdOld: (groupCode, cityFriendlyName, sgcId) => {
    return (dispatch) => {
      dispatch(groupLoading());
      api
        .get(`${BASE_URL}/${groupCode}/answers/${sgcId}`)
        .then((resp) => {
          if (resp.data) {
            const surveysData = resp.data;
            let progressSurveyGroup = null;
            let finishedSurveysGroupsArr = [];

            api
              .get(`${BASE_URL}/${groupCode}/${cityFriendlyName}/finished`)
              .then((resp2) => {
                const answeredSurveysGroupsArr = resp2.data;
                if (answeredSurveysGroupsArr.length > 0) {
                  // Somente considera surveys em andamento quando o último não estiver finalizado (array com mais recente primeiro)
                  if (answeredSurveysGroupsArr[0].status !== 2) {
                    const progressSurveyGroupArr = answeredSurveysGroupsArr.filter((item) => item.status === 1);
                    if (progressSurveyGroupArr.length > 0) {
                      progressSurveyGroup = progressSurveyGroupArr[0];
                    }
                  }
                  finishedSurveysGroupsArr = answeredSurveysGroupsArr.filter((item) => item.status === 2);
                }
                dispatch(
                  groupLoadedSuccess({
                    data: surveysData,
                    progressSurveyGroup,
                    finishedSurveysGroups: finishedSurveysGroupsArr,
                  }),
                );
              })
              .catch((err) => {
                console.log(JSON.stringify(err));
                dispatch(groupLoadedSuccess({ data: surveysData, msg: err?.response || 'Erro' }));
              });
          }
        })
        .catch((err) => {
          dispatch(
            groupLoadedError({
              msg:
                handleMessage(err.response, 'Erro desconhecido LOADED_GROUP ' + groupCode + ', ID ' + sgcId) ||
                `Erro desconhecido LOADED_GROUP - ${groupCode} (${err.response.status})`,
            }),
          );
        });
    };
  },

  loadQuestions: (surveyCode) => {
    return (dispatch, getState) => {
      const groupCode = getState().adminSurveyView.groupInfo.code;
      const survey = getState().adminSurveyView.groupInfo.surveys[surveyCode];
      if (survey) {
        if (!survey.questions || Object.keys(survey.questions).length === 0) {
          // Questões não carregadas -> buscar no backend
          dispatch(questionsLoading({ surveyCode }));
          api
            .get(`${BASE_URL}/${groupCode}/${surveyCode}`)
            .then((resp) => {
              // console.log(`***** QUESTOES ${surveyCode}: ${JSON.stringify(resp.data, null, '\t')}`)
              dispatch(questionsLoadedSuccess({ surveyCode, surveyData: resp.data }));
            })
            .catch((err) => {
              dispatch(
                questionsLoadedError({
                  surveyCode,
                  msg:
                    handleMessage(err.response, 'Erro QUESTIONS_LOADED_ERROR ' + groupCode + '/' + surveyCode) ||
                    `Erro desconhecido QUESTIONS_LOADED_ERROR - ${err.response.status}`,
                }),
              );
            });
        } else {
          dispatch(questionsLoadedKeep({ surveyCode }));
        }
      }
    };
  },

  loadAnswers: (surveyCode) => {
    return (dispatch, getState) => {
      const currentState = getState();
      const groupCode = currentState.adminSurveyView.groupInfo.code;
      const surveyAnswer = currentState.adminSurveyView.groupInfo.answers[surveyCode];

      // Se estiver em preenchimento, carrega cada vez que acessa tab para atualizar
      // respostas que podem estar sendo preenchidas por mais de um usuario simultaneamente
      if (!surveyAnswer || surveyAnswer.status === 1) {
        dispatch(answersLoading({ surveyCode }));
        api
          .get(`${BASE_URL}/${groupCode}/${surveyCode}/answers`)
          .then((resp) => {
            dispatch(answersLoadedSuccess({ surveyCode, answersData: resp.data }));
          })
          .catch((err) => {
            if (err.response && err.response.status === 404) {
              const initialAnswers = getDefaultAnswers(currentState.adminSurveyView.groupInfo.surveys[surveyCode]);
              dispatch(
                answersLoadedSuccess({
                  surveyCode,
                  answersData: initialAnswers,
                }),
              );
            } else {
              dispatch(
                answersLoadedError({
                  surveyCode,
                  msg:
                    handleMessage(
                      err.response,
                      'Erro desconhecido ANSWERS_LOADED_ERROR ' + groupCode + '/' + surveyCode,
                    ) || `Erro desconhecido QUESTIONS_LOADED_ERROR - ${err.response.status}`,
                }),
              );
            }
          });
      } else if (surveyAnswer.status !== 1) {
        dispatch(answersLoadedKeep({ surveyCode }));
      }
    };
  },

  reset: () => {
    return (dispatch) => {
      dispatch(reset());
    };
  },
};

export default adminSurveyViewSlice.reducer;
