import React, { useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';

const ColumnChart = ({ chartId, chartData, handleClick }) => {
  useEffect(() => {
    am4core.options.queue = false;
    const levelColor = {
      1: '#A00000',
      2: '#E23A23',
      3: '#E27400',
      4: '#3E8ED0',
      5: '#1C4F9C',
      6: '#3F9D00',
      7: '#375623',
      default: '#000000',
    };

    function applyCustomTheme(target) {
      if (target instanceof am4core.ColorSet) {
        target.list = [
          am4core.color('rgba(255, 255, 255, 1.0)'),
          am4core.color(levelColor[1]),
          am4core.color(levelColor[2]),
          am4core.color(levelColor[3]),
          am4core.color(levelColor[4]),
          am4core.color(levelColor[5]),
          am4core.color(levelColor[6]),
          am4core.color(levelColor[7]),
          am4core.color(levelColor[1]),
          am4core.color(levelColor[2]),
          am4core.color(levelColor[3]),
          am4core.color(levelColor[4]),
          am4core.color(levelColor[5]),
          am4core.color(levelColor[6]),
          am4core.color(levelColor[7]),
        ];
      }
    }

    am4core.useTheme(am4themesAnimated);
    am4core.useTheme(applyCustomTheme);

    function createChart() {
      const chart = am4core.create(chartId, am4charts.XYChart);
      chart.colors.step = 2;
      // chart.logo.disabled = true;
      chart.data = chartData;
      return chart;
    }

    function createXAxis(chart) {
      const xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      xAxis.dataFields.category = 'title';
      xAxis.renderer.cellStartLocation = 0.1;
      xAxis.renderer.cellEndLocation = 0.9;

      // https://www.amcharts.com/docs/v4/concepts/axes/positioning-axis-elements/#Controlling_spacing
      xAxis.renderer.minGridDistance = 30;
      xAxis.renderer.fontSize = chartData.length > 6 ? '0.65rem' : '0.85rem';
      xAxis.renderer.grid.template.location = 0;
      xAxis.renderer.grid.template.disabled = true;
      xAxis.renderer.labels.template.fill = am4core.color('#858585');
      xAxis.renderer.labels.template.wrap = true;
      xAxis.renderer.labels.template.truncate = false;
      xAxis.renderer.labels.template.maxWidth = chartData.length > 6 ? 90 : 150;
    }

    function createYAxis(chart) {
      const yAxis = chart.yAxes.push(new am4charts.ValueAxis());
      yAxis.min = 0;
      yAxis.max = 7;
      yAxis.extraMax = 0.1;
      yAxis.strictMinMax = false;
      yAxis.renderer.grid.template.disabled = true;
      yAxis.renderer.labels.template.disabled = true;
      yAxis.renderer.labels.template.wrap = true;
    }

    function createChartSeries(chart) {
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = 'level';
      series.dataFields.categoryX = 'title';

      series.columns.template.width = 20;
      series.columns.template.column.cornerRadiusTopRight = am4core.percent(95);
      series.columns.template.column.cornerRadiusTopLeft = am4core.percent(95);

      __bindEvents(series);
      __applyAdapters(series);
      __setupBullets(series);
    }

    function __bindEvents(series) {
      series.columns.template.events.on(
        'hit',
        (evt) => {
          const item = evt.target.column.dataItem.dataContext;
          handleClick(item.code);
        },
        this,
      );
      series.columns.template.events.on(
        'over',
        () => {
          document.body.style.cursor = 'pointer';
        },
        this,
      );
      series.columns.template.events.on(
        'out',
        () => {
          document.body.style.cursor = 'default';
        },
        this,
      );
    }

    function __applyAdapters(series) {
      // https://www.amcharts.com/docs/v4/tutorials/different-column-fill-colors-for-positive-and-negative-values/
      series.columns.template.adapter.add('fill', function (fill, target, key) {
        if (target.dataItem) {
          return chart.colors.getIndex(+target.dataItem.valueY);
        } else {
          return fill;
        }
      });
      series.columns.template.adapter.add('stroke', function (fill, target, key) {
        if (target.dataItem) {
          return chart.colors.getIndex(+target.dataItem.valueY + 7);
        } else {
          return fill;
        }
      });
    }

    function __setupBullets(series) {
      const bullet = series.bullets.push(new am4charts.LabelBullet());
      bullet.interactionsEnabled = false;
      bullet.dy = -15;
      bullet.label.truncate = false;
      bullet.label.text = '[bold] {valueY}';
      bullet.label.fontSize = '1rem';
      series.bullets.template.adapter.add('fill', function (fill, target, key) {
        if (target.dataItem) {
          return chart.colors.getIndex(+target.dataItem.valueY);
        } else {
          return fill;
        }
      });
    }

    let chart = null;
    if (chartData && chartData.length > 0) {
      chart = createChart();
      createXAxis(chart);
      createYAxis(chart);
      createChartSeries(chart);
    }

    am4core.unuseTheme(applyCustomTheme);

    return () => chart?.dispose();
  }, [chartId, chartData, handleClick]);

  return <div id={chartId} style={{ position: 'relative', width: '100%', height: '400px', margin: 'auto' }}></div>;
};

export default ColumnChart;
