import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Actions as CitySearchActions } from 'services/citySearch';
import { Actions as LabelActions } from 'services/labels';
import { IndicatorSource, IndicatorValue, LevelGlossary } from 'components/dashboard/IndicatorUtils';
import Characterization from 'components/dashboard/Characterization';
import { getDimensionClassName } from 'helpers/DimensionUtils';
import MapCity from './MapCity';
import CitySignIn from '../CitySignIn';
import NewsletterSubscription from '../NewsletterSubscription';
import ModalIndicatorInfo from 'components/dashboard/ModalIndicatorInfo';
import ScoreChart from 'components/charts/ScoreChart';

const TopicsData = ({ evaluated = false, topics, labels }) => {
  const [allIndicators, setAllIndicators] = useState([]);
  const [showModalIndicator, setShowModalIndicator] = useState(false);
  const [selectedIndicator, setSelectedIndicator] = useState(null);
  const levelRef = useRef(null);

  useEffect(() => {
    let allIndicators = [];
    for (const topic of topics) {
      if (topic.indicators.length > 0) {
        allIndicators = [...allIndicators, ...topic.indicators];
      }
    }
    setAllIndicators(allIndicators);
    setSelectedIndicator(allIndicators[0]);
  }, [topics]);

  const handleChangeIndicatorModal = useCallback(
    (direction) => {
      const currentIndicatorIndex = allIndicators.findIndex((ind) => ind.code === selectedIndicator.code);
      const totalIndicators = allIndicators.length;
      const availableDirections = {
        prev: () => {
          setSelectedIndicator(
            allIndicators[currentIndicatorIndex - 1 <= 0 ? totalIndicators - 1 : currentIndicatorIndex - 1],
          );
        },
        next: () => {
          setSelectedIndicator(
            allIndicators[currentIndicatorIndex + 1 > totalIndicators - 1 ? 0 : currentIndicatorIndex + 1],
          );
        },
      };
      if (availableDirections[direction]) {
        availableDirections[direction]();
      }
    },
    [allIndicators, selectedIndicator?.code],
  );

  const handleShowModalIndicador = (indicatorCode) => {
    const selectedIndicatorIndex = allIndicators.findIndex((ind) => ind.code === indicatorCode);
    if (selectedIndicatorIndex >= 0 && allIndicators.length > 0) {
      setSelectedIndicator(allIndicators[selectedIndicatorIndex]);
      setShowModalIndicator(true);
    }
  };

  return (
    <>
      <div className="p-4">
        <table className="topics-indexes charts">
          <thead>
            <tr>
              <th>Tópicos</th>
              <th>Indicadores</th>
              <th>Valor</th>
              <th colSpan="2" ref={levelRef} style={{ position: 'relative' }}>
                Nível <LevelGlossary placement="bottom-end" containerRef={levelRef.current} />
              </th>
            </tr>
          </thead>
          <tbody>
            {topics.map((topic) => {
              if (topic.indicators?.length > 0) {
                return topic.indicators.map((indicator, idx) => (
                  <tr
                    className={`level-${indicator.level} ${idx === 0 ? 'new-topic' : ''}`}
                    key={`${topic.code}-${indicator.code}`}
                    onClick={() => handleShowModalIndicador(indicator.code)}
                  >
                    {idx === 0 ? <td data-tcode={topic.code}>{topic.title}</td> : <td></td>}
                    <td data-icode={indicator.code}>{indicator.title}</td>
                    <td>
                      <IndicatorValue
                        code={indicator.code}
                        value={indicator.value}
                        digits={indicator.digits}
                        unit={labels[indicator.code]?.valueUnit}
                        outlier={indicator.outlier}
                      />
                      <IndicatorSource code={indicator.code} text={indicator.source} />
                    </td>
                    <td>
                      <div className="bar"></div>
                    </td>
                    <td>{indicator.level || 'ND'}</td>
                  </tr>
                ));
              } else {
                return (
                  <tr key={`${topic.code}`}>
                    <th>{topic.title}</th>
                    <td colSpan="3">Valor não disponível</td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
      <ModalIndicatorInfo
        show={showModalIndicator}
        indicator={selectedIndicator}
        labels={labels}
        onClose={() => setShowModalIndicator(false)}
        handleChangeIndicator={handleChangeIndicatorModal}
      />
    </>
  );
};

const SearchResult = ({ cityData, labels }) => {
  const [activeDimension, setActiveDimension] = useState(null);

  const dimensions = useMemo(() => {
    const val = [];
    if (cityData && labels) {
      for (const dimension of cityData.dimensions) {
        const changedTopics = [];
        for (const topic of dimension.topics) {
          const changedIndicators = [];
          for (const indicator of topic.indicators) {
            changedIndicators.push({
              ...indicator,
              title: labels[indicator.code]?.title || indicator.code,
              description: labels[indicator.code]?.description,
              unit: labels[indicator.code]?.valueUnit ? labels[indicator.code]?.valueUnit : null,
              source: labels[indicator.code]?.source ? labels[indicator.code]?.source : 'Não definido',
              topic: {
                code: topic.code,
                title: labels[topic.code]?.title || topic.code,
                description: labels[topic.code]?.description,
              },
              dimension: {
                code: dimension.code,
                title: labels[dimension.code]?.title || dimension.code,
                description: labels[dimension.code]?.description,
              },
            });
          }
          changedTopics.push({
            ...topic,
            title: labels[topic.code]?.title || topic.code,
            description: labels[topic.code]?.description,
            indicators: changedIndicators,
            dimension: {
              code: dimension.code,
              title: labels[dimension.code]?.title || dimension.code,
              description: labels[dimension.code]?.description,
            },
          });
        }
        val.push({
          ...dimension,
          title: labels[dimension.code]?.title || dimension.code,
          description: labels[dimension.code]?.description,
          topics: changedTopics,
        });
      }
    }
    return val;
  }, [cityData, labels]);

  const topics = useMemo(() => {
    const val = [];
    for (const dimension of dimensions) {
      for (const topic of dimension.topics) {
        val.push(topic);
      }
    }
    return {
      values: val,
      filterByDimension: function (dCode) {
        return this.values.filter((item) => item.dimension.code === dCode);
      },
      getOneByCode: function (tCode) {
        const filtered = this.values.filter((item) => item.code === tCode);
        if (filtered.length > 0) {
          return filtered[0];
        }
        return null;
      },
    };
  }, [dimensions]);

  // eslint-disable-next-line
  const allIndicators = useMemo(() => {
    const val = [];
    for (const dimension of dimensions) {
      for (const topic of dimension.topics) {
        for (const indicator of topic.indicators) {
          val.push(indicator);
        }
      }
    }
    // console.log('allIndicators for city ' + friendlyName + ': ' + JSON.stringify(val))
    return {
      values: val,
      filterByDimension: function (dCode) {
        return this.values.filter((item) => item.dimension.code === dCode);
      },
      filterByTopic: function (tCode) {
        return this.values.filter((item) => item.topic.code === tCode);
      },
      getOneByCode: function (iCode) {
        const filtered = this.values.filter((item) => item.code === iCode);
        if (filtered.length > 0) {
          return filtered[0];
        }
        return null;
      },
    };
  }, [dimensions]);

  const handleChangeDimension = (evt, dCode) => {
    evt.preventDefault();
    setActiveDimension(dCode !== activeDimension ? dCode : null);
  };

  return (
    <>
      {/*  Conteúdo - start  */}
      <div className="bg-blue-page-top py-5">
        {/*  Maturidade Município - start  */}
        <div className="container">
          {/*  Nome do Município  */}
          <header className="city c-white mb-5 py-4">
            <h1 className="mb-3 fw-bolder">{cityData.name || 'Nome do Município'}</h1>
            <h3 className="line right">{cityData.stateName || 'Estado'}</h3>
          </header>

          {/*  Info Município - start  */}
          <div className="bg-white shadow rounded-5 p-4">
            {/*  Gráficos - start  */}
            <div className="row">
              {dimensions.map((dimension) => (
                <div className="col-12 col-lg-3" key={dimension.code}>
                  <a href="#" className="c-gray-dark" onClick={(evt) => handleChangeDimension(evt, dimension.code)}>
                    <div className={`chart ${dimension.code === activeDimension ? 'active' : ''}`}>
                      <div className={`chart-box ${getDimensionClassName(dimension.code)}`}>
                        <ScoreChart chartId={`score-${dimension.code}`} score={dimension.level} />
                      </div>
                      <h6 className="mb-0 mt-4">{dimension.title}</h6>
                    </div>
                  </a>
                </div>
              ))}
              {!cityData.evaluated && (
                <div className="col-12 mt-3">
                  <div className="alert alert-secondary p-4 fw-semibold rounded text-center" role="alert">
                    <h5>
                      <strong>Este nível é estimado nas bases secundárias.</strong>
                    </h5>
                    <p>
                      Se você é representante da gestão municipal, pode iniciar o preenchimento do formulário através da{' '}
                      <Link to="/prefeitura/login">Área da Prefeitura</Link>.
                    </p>
                  </div>
                </div>
              )}
            </div>
            {/*  Gráficos - end  */}

            {/*  Tabela Detalhes e Barras - start  */}
            {activeDimension && <TopicsData topics={topics.filterByDimension(activeDimension) || []} labels={labels} />}
            {/*  Tabela Detalhes e Barras - end  */}
          </div>
          {/*  Info Município - end  */}
        </div>
        {/*  Maturidade Município - start  */}

        {/*  Dados e Mapa do Município - start  */}
        <div className="container-fluid p-5">
          <div className="row justify-content-center mt-3">
            <div className="col-12 col-lg-6">
              <Characterization
                cityData={cityData}
                labels={labels}
                className="border border-secondary-subtle p-4 h-100"
              />
            </div>
            <div className="col-12 col-lg-6">
              <MapCity cityData={cityData} className="border border-secondary-subtle p-2" />
            </div>
          </div>
        </div>
        {/*  Dados e Mapa do Município - end  */}
      </div>
      {/*  Conteúdo - end  */}
    </>
  );
};

const CitySearchDetails = () => {
  const { cityFriendlyName } = useParams();

  const dispatch = useDispatch();

  const labels = useSelector((state) => state.labels.labels);

  useEffect(() => {
    dispatch(LabelActions.loadLabels());
  }, [dispatch]);

  useEffect(() => {
    dispatch(CitySearchActions.searchCityInformation(cityFriendlyName));
  }, [dispatch, cityFriendlyName]);

  const searchInfo = useSelector((state) => state.citySearch);
  if (searchInfo?.loading === false) {
    if (searchInfo?.success === true) {
      const cityData = searchInfo.cityData;
      return (
        <>
          <SearchResult cityData={cityData} labels={labels} />
          <CitySignIn />
          <NewsletterSubscription />
        </>
      );
    } else {
      return <div className="bg-blue-page-top py-5 text-white">Erro ao carregar resultado da pesquisa</div>;
    }
  } else {
    return (
      <div className="bg-blue-page-top py-5 text-white">
        <i className="fa-solid fa-spinner fa-spin fa-lg"></i> Aguarde...
      </div>
    );
  }
};

export default CitySearchDetails;
