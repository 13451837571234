import React from 'react';
import Matrix from './Matrix';
import Options from './Options';
import OrderOptions from './OrderOptions';
import formSettings from '../formSettings';

const Questions = ({
  surveyCode,
  questions,
  answers,
  errors,
  onOptionChange,
  onOrderChange,
  onComplementChange,
  onTextAnswerChange,
  groupStatus,
  isChild = false,
  parentPosition,
}) => {
  const questionsData = Object.entries(questions).map(([code, question], idx) => {
    switch (question.type) {
      case formSettings.questionTypes.matrix:
        return (
          <Matrix
            key={code}
            surveyCode={surveyCode}
            question={question}
            answers={answers}
            errors={errors}
            position={idx + 1}
            isChild={isChild}
            parentPosition={parentPosition}
            onOptionChange={onOptionChange}
            onTextAnswerChange={onTextAnswerChange}
          />
        );
      case formSettings.questionTypes.orderOption:
        return (
          <OrderOptions
            key={code}
            surveyCode={surveyCode}
            question={question}
            answers={answers}
            errors={errors}
            position={idx + 1}
            isChild={isChild}
            parentPosition={parentPosition}
            onOrderChange={onOrderChange}
            onComplementChange={onComplementChange}
            groupStatus={groupStatus}
          />
        );
      case formSettings.questionTypes.multiOption:
      case formSettings.questionTypes.singleOption:
        return (
          <Options
            key={code}
            surveyCode={surveyCode}
            type={question.type}
            question={question}
            answers={answers}
            errors={errors}
            position={idx + 1}
            isChild={isChild}
            parentPosition={parentPosition}
            onOptionChange={onOptionChange}
            onComplementChange={onComplementChange}
            groupStatus={groupStatus}
          />
        );
      default:
        return (
          <>
            <p>Tipo de questão não disponível</p>
            <hr />
          </>
        );
    }
  });

  return questionsData;
};

export default Questions;
