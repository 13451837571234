import { configureStore } from '@reduxjs/toolkit';
import backendVersionReducer from 'services/backendVersion';
import labelsReducer from 'services/labels';
import dashboardGroupDataReducer from 'services/dashboardGroupData';
import citySearchReducder from 'services/citySearch';
import authReducer from 'services/auth';
import userAuthReducer from 'services/userAuth';
import accountManagementReducer from 'services/accountManagement';
import changePasswordReducer from 'services/changePassword';

import citiesList from 'services/ducks/citiesList';
import cityUserRegistration from 'services/ducks/cityUser.registration';
import restrictedAreaMayors from 'services/ducks/admin.mayors';

// import adminMayors from 'services/ducks/admin.mayors';
import citySurvey from 'services/citySurvey';
import adminSurveyView from 'services/adminSurveyView';

import newsletters from 'slices/admin/newsletters';
import subscribers from 'slices/admin/subscribers';

import cityDashboard from 'slices/city/dashboard';
import dashboard from 'slices/admin/dashboard';
import configuration from 'slices/admin/configuration';

import usersReducer from 'services/slices/user';
import postsReducer from 'services/slices/content';
import rolesDataReducer from 'services/slices/roles';
import contactUsReducer from 'services/slices/contactUs';
import supportInfoReducer from 'services/slices/supportInfo';
import { apiService } from 'services/query/apiService';

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    backendVersion: backendVersionReducer,
    labels: labelsReducer,
    rolesData: rolesDataReducer,
    dashboardGroupData: dashboardGroupDataReducer,
    citySearch: citySearchReducder,
    auth: authReducer,
    userAuth: userAuthReducer,
    accountManagement: accountManagementReducer,
    changePassword: changePasswordReducer,
    contactUs: contactUsReducer,
    supportInfo: supportInfoReducer,
    posts: postsReducer,
    users: usersReducer,

    adminNewsletters: newsletters.adminNewslettersReducer,
    adminNewsletterItem: newsletters.adminNewsletterItemReducer,

    adminSubscribers: subscribers.adminSubscribersReducer,

    cityDashboard: cityDashboard.cityInfoReducer,

    adminDashboard: dashboard.adminDashboardReducer,
    adminDashboardCity: dashboard.adminDashboardCityReducer,

    citySurvey,
    adminSurveyView,

    adminConfiguration: configuration.adminConfigurationsReducer,

    // abaixo estão os ducks reducers antigos
    citiesList,
    cityUserRegistration,
    restrictedAreaMayors, // adminMayors,

    [apiService.reducerPath]: apiService.reducer, // RTK Query
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([apiService.middleware]),
});
