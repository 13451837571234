import React from 'react';
import { loginUrl } from 'helpers/govBrUtils';

const OAuth2LoginButton = ({ buttonTextPrefix = 'Entre com sua conta', className }) => {
  return (
    <div className={className}>
      <span className="d-flex align-items-center">
        <img src="/img/logo_govbr.png" alt="gov.br" />
      </span>
      <a href={loginUrl} className="btn btn-outline-gov flex-grow-1 w-100 ms-3 fw-normal">
        {buttonTextPrefix} <strong>gov.br</strong>
      </a>
    </div>
  );
};

export default OAuth2LoginButton;
