import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from 'store';

import './App.scss';
import AppRoutes from './AppRoutes';
import { axiosInterceptorsConfig } from 'services/api';
import ReactGA from 'react-ga4';

axiosInterceptorsConfig.setupInterceptors(store);

ReactGA.initialize(process.env.REACT_APP_GTAG_KEY);

const App = () => (
  <Provider store={store}>
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  </Provider>
);

export default App;
