import api from 'services/api';

const API_BASE_PATH = '/api/public';

const getRoles = async () => {
  return await api.get(`${API_BASE_PATH}/roles`);
};

const sendContact = async (contactForm) => {
  return await api.post(`${API_BASE_PATH}/contact`, contactForm);
};

const getSupportEmail = async () => {
  return await api.get(`${API_BASE_PATH}/support-email`);
};

export default {
  getRoles,
  sendContact,
  getSupportEmail,
};
