import React from 'react';
import { LevelSummary, ResultDetails } from 'components/dashboard/GroupResultDetails';
import { getDimensionImageName } from 'helpers/DimensionUtils';

const GroupInfo = ({ groupSelected = {}, labels }) => {
  return (
    groupSelected &&
    Object.keys(groupSelected).length > 0 &&
    labels && (
      <>
        {Object.values(groupSelected.summary)
          .sort((a, b) => (a.code < b.code ? -1 : 1))
          .map((summary, idx) => {
            const dimensionLabel = labels[summary.code]?.title || summary.code;
            return (
              <div className="mb-5 pb-5" key={`summary-${summary.code}`}>
                <div className="d-flex align-items-center">
                  <img src={`/img/${getDimensionImageName(summary.code)}`} alt={dimensionLabel} />
                  <h4 className="ms-3">{dimensionLabel}</h4>
                </div>
                <LevelSummary summary={summary} handleClick={() => true} />
                <ResultDetails
                  topics={Object.values(groupSelected.topics).filter((item) => item.dimensionCode === summary.code)}
                  dimensionIdx={idx}
                  labels={labels}
                  handleClick={() => true}
                />
              </div>
            );
          })}
      </>
    )
  );
};

export default GroupInfo;
