import React, { useEffect, useState } from 'react';
import CityLevel from './CityLevel';
import Characterization from 'components/dashboard/Characterization';
import ScoreChart from 'components/charts/ScoreChart';
import RadarChart from 'components/charts/admin/RadarChart';
import { getDimensionContainerImg } from 'helpers/DimensionUtils';

const MaturityLevel = ({ cityData, labels, descriptions }) => {
  const DEFAULT_SELECTED_DIMENSION = 'd2';
  const [topicsFromActiveDimension, setTopicFromActiveDimension] = useState([]);
  const [activeDimension, setActiveDimension] = useState(DEFAULT_SELECTED_DIMENSION);

  useEffect(() => {
    if (cityData) {
      const filteredTopics = cityData.dimensions?.filter((d) => d.code === activeDimension)[0]?.topics;

      const allTopics = filteredTopics?.map((ft) => {
        return {
          ...ft,
          title: labels[ft.code]?.title,
        };
      });
      setTopicFromActiveDimension(allTopics);
    }
  }, [activeDimension, cityData, labels]);

  return (
    <>
      {labels && cityData && (
        <div className="row d-flex align-items-stretch ms--1 me-0 gx-5">
          <div className="col-12 col-lg-6 d-flex flex-column align-items-stretch">
            <CityLevel cityData={cityData} labels={labels} descriptions={descriptions} />
            <Characterization cityData={cityData} labels={labels} className="bg-white shadow p-5 flex-grow-1" />
          </div>

          {/* Coluna Gráficos - start */}
          <div className="col-12 col-lg-6 bg-white shadow p-5">
            <h6 className="fw-bold mb-3">Dimensões</h6>
            <div className="mb-5 d-flex flex-wrap justify-content-evenly row-gap-4">
              {cityData.dimensions &&
                cityData.dimensions.map((dimension) => (
                  <div key={`grafico-dimensao-${dimension.code}`} className="col-12 col-lg-5">
                    <div
                      onClick={() => setActiveDimension(dimension.code)}
                      className={`chart ${activeDimension === dimension.code ? 'active' : ''}`}
                    >
                      <h6 className="fw-bold">{labels[dimension.code]?.title}</h6>
                      <div
                        className={`chart-box ${getDimensionContainerImg(
                          dimension.code,
                          labels[dimension.code]?.iconName,
                        )}`}
                      >
                        <ScoreChart chartId={`score-chart-${dimension.code}`} score={dimension.level} maxScore={7} />
                      </div>
                      <h6 className="mb-0 mt-3">{labels && labels[`m${dimension.level}`]?.title}</h6>
                    </div>
                  </div>
                ))}
            </div>
            <div className="row">
              <div className="col-12">
                <h6 className="fw-bold mb-3">
                  Tópicos da dimensão
                  <em> {activeDimension && labels && labels[activeDimension]?.title}</em>
                </h6>
                <div className="text-center mt-4">
                  <RadarChart chartId="radar-chart-area" chartData={topicsFromActiveDimension} />
                </div>
              </div>
            </div>
          </div>
          {/* Coluna Gráficos - end */}
        </div>
      )}
      {(!labels || !cityData) && (
        <div className="row d-flex align-items-stretch ms--1 me-0 gx-5">
          Aguarde...&nbsp;<i className="fa-solid fa-spinner fa-spin fa-lg"></i>
        </div>
      )}
    </>
  );
};

export default MaturityLevel;
