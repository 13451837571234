import React from 'react';
import MatrixType from './MatrixType';
import formSettings from '../../formSettings';
import { QuestionHelper, OptionText, OptionHelper, AnswerChanged, getBulletFromIndex } from '../Components';

const MatrixGroup = (props) => {
  const questionCode = props.question.code;
  const answers = props.answers;
  const errors = props.errors;

  const handleTextAnswerChange = (questionCode, textValue) => props.onTextAnswerChange(questionCode, textValue);

  const handleChildrenOptionChange = (childCode, optCode, checked, complementValue) =>
    props.onOptionChange(childCode, optCode, checked, props.question.code, complementValue);

  const children = Object.entries(props.question.children).map(([questionCode, question]) => {
    const answerInfo = answers[questionCode];

    switch (question.type) {
      case formSettings.questionTypes.singleOption:
        return (
          <tr key={questionCode}>
            <MatrixType
              question={question}
              answer={answerInfo}
              onOptionChange={handleChildrenOptionChange}
              onTextAnswerChange={handleTextAnswerChange}
            />
          </tr>
        );
      case formSettings.questionTypes.multiOption:
        return (
          <tr key={questionCode}>
            <MatrixType
              isMulti
              question={question}
              answer={answerInfo}
              onOptionChange={handleChildrenOptionChange}
              onTextAnswerChange={handleTextAnswerChange}
            />
          </tr>
        );
      default:
        return null;
    }
  });

  // Recupera options da primeira questão.
  const question = Object.values(props.question.children)[0];
  const childrenOptions = Object.entries(question.options).map(([optCode, option], index) => {
    // const bullet = optCode.substring(optCode.lastIndexOf('_') + 1);
    const bullet = getBulletFromIndex(index);
    return (
      <th key={optCode}>
        <OptionText bullet={bullet} text={option.description} />
        <OptionHelper code={optCode} text={option.helper} />
      </th>
    );
  });
  const childrenAnswersCodes = Object.entries(props.question.children).map((child) => child.code);
  const childrenChanged = Object.values(answers)
    .filter((answer) => childrenAnswersCodes.includes(answer.code))
    .some((opt) => opt.changed);

  const tHeadStyle = {};
  if (questionCode === 'SERV01') {
    tHeadStyle.fontSize = '0.7em';
  }

  return (
    <>
      <div className="question">
        <h4 className="fw-bold mb-4">
          <span className="c-green me-2">{props.position}.</span> {props.question.description}
          <QuestionHelper code={questionCode} text={props.question.helper} />
          <AnswerChanged changed={childrenChanged} />
        </h4>
        {errors[questionCode] && errors[questionCode].ROOT && (
          <p className="text-danger">{errors[questionCode].ROOT}</p>
        )}
        <fieldset>
          <table className="table table-striped table-hover">
            <thead style={tHeadStyle}>
              <tr>
                <th>&nbsp;</th>
                {childrenOptions}
              </tr>
            </thead>
            <tbody>{children}</tbody>
          </table>
        </fieldset>
      </div>
      {!props.isChild && <hr className="my-5" />}
    </>
  );
};

export default MatrixGroup;
