import React, { useLayoutEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

const Newsletter = () => {
  const defaultTitle = process.env.REACT_APP_DEFAULT_TITLE;
  useLayoutEffect(() => {
    const prevTitle = document.title;
    document.title = 'Cidades na mídia - ' + defaultTitle;
    return () => {
      document.title = prevTitle;
    };
  }, [defaultTitle]);

  return (
    <>
      <div className="bg-gray-superlight px-5 py-4 w-100">
        {/* <!-- Cabeçalho Página - start --> */}
        <header>
          <h3 className="fw-bolder c-cyan mb-0">Boletins informativos</h3>
          <p className="path">
            <Link to="">Início</Link>
            &rsaquo; <span className="current">Boletins informativos</span>
          </p>
        </header>
        {/* <!-- Cabeçalho Página - end --> */}

        {/* <!-- Conteúdos - start --> */}
        <Outlet />
        {/* <!-- Conteúdos - end --> */}
        <ToastContainer position="bottom-center" />
      </div>
    </>
  );
};

export default Newsletter;
