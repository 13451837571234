import React, { lazy, Suspense } from 'react';

// Lazy precisa de import dinamico
const LazySurveyChart = lazy(() => import('components/charts/admin/SurveyInfo'));

const SurveyChart = ({ groupSelected, chartId = 'score-chart-data' }) => {
  return (
    <>
      <Suspense
        fallback={
          <div className="container d-flex align-items-center justify-content-center">
            <span>
              <i className="fa-solid fa-spinner fa-spin fa-lg"></i> Carregando gráfico...
            </span>
          </div>
        }
      >
        <LazySurveyChart groupSelected={groupSelected} chartId={chartId} />
      </Suspense>
    </>
  );
};

export default SurveyChart;
