import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import api from 'services/api';

export const FinishModal = ({ show, message, onClose }) => {
  return (
    <Modal
      className="inteligente-modal"
      show={show}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
    >
      <Modal.Header>
        <Modal.Title>Cancelar inscrição</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-primary" onClick={onClose}>
          Voltar para tela inicial
        </button>
      </Modal.Footer>
    </Modal>
  );
};

const Unsubscription = () => {
  const { uid } = useParams();

  const navigate = useNavigate();

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  const [data, setData] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [modalMessage, setModalMessage] = useState();

  const handleInputChangeData = (evt) => {
    if (evt.target.type === 'radio') {
      setData((currentData) => ({ ...currentData, [evt.target.name]: evt.target.value === '1' }));
    } else {
      setData((currentData) => ({ ...currentData, [evt.target.name]: evt.target.value }));
    }
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const dataToSend = { origin: 1, reason: data.reason, personType: params.user ? 2 : 1 };
    api
      .post(`api/public/unsubscribe/${uid}`, dataToSend)
      .then((resp) => {
        setModalMessage({
          type: 0,
          text: 'Sua inscrição foi cancelada.',
        });
        setModalShow(true);
      })
      .catch((err) => {
        console.error(err);
        setModalMessage({
          type: 1,
          text: 'ERRO: Não foi possível cancelar a inscrição. Tente novamente mais tarde.',
        });
        setModalShow(true);
      });
  };

  const handleModalClose = () => {
    setModalShow(false);
    navigate('/');
  };

  return (
    <>
      <div className="page bg-cyan">
        <div className="container page">
          <div className="row page justify-content-center">
            <div className="col-12 col-lg-8 bg-white">
              <div className="p-5 m-5 mb-1">
                <h1 className="fw-bold c-green">Cancelar inscrição</h1>
                <h3>Você deseja cancelar o recebimento das atualizações?</h3>
              </div>
              <div className="row">
                <div className="col-12">
                  <form onSubmit={handleSubmit}>
                    <div className="mb-4 text-center">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="radNao"
                          name="confirmation"
                          value="0"
                          checked={!data?.confirmation}
                          onChange={handleInputChangeData}
                          style={{ top: 'unset', backgroundImage: 'none' }}
                        />
                        <label className="form-check-label" htmlFor="radNao">
                          Não
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="radSim"
                          name="confirmation"
                          value="1"
                          checked={data?.confirmation}
                          onChange={handleInputChangeData}
                          style={{ top: 'unset', backgroundImage: 'none' }}
                        />
                        <label className="form-check-label" htmlFor="radSim">
                          Sim
                        </label>
                      </div>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="reason" className="form-label">
                        Motivo para cancelamento
                      </label>
                      <textarea
                        className="form-control"
                        id="reason"
                        name="reason"
                        rows="5"
                        maxLength={1024}
                        disabled={!data?.confirmation}
                        onChange={handleInputChangeData}
                        value={data?.reason}
                      ></textarea>
                    </div>
                    <div className="my-5 text-center">
                      <Link to="/" className="btn btn-outline-secondary me-3">
                        Voltar à página inicial
                      </Link>
                      <button className="btn btn-danger" disabled={!data?.confirmation}>
                        Cancelar inscrição
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FinishModal show={modalShow} message={modalMessage?.text} onClose={handleModalClose} />
    </>
  );
};

export default Unsubscription;
