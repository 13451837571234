import React, { useEffect } from 'react';
import { Routes, Route, Outlet, Navigate, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

import Home from 'pages/public/Home';
import CitySearch from 'pages/public/CitySearch';
import CitySearchDetails from 'pages/public/CitySearchDetails';
import AboutProgram from 'pages/public/AboutProgram';
import Methodologies from 'pages/public/Methodologies';
import MediaPosts from 'pages/public/MediaPost';
import ContactUs from 'pages/public/ContactUs';

import CityUserLogin from 'pages/CityUserLogin';
import AdminLogin from 'pages/AdminLogin';
import OAuth2Callback from 'pages/OAuth2Callback';
import OAuth2Logout from 'pages/OAuth2Logout';
import LoginMaster from 'pages/LoginMaster';
import AccountActivation from 'pages/AccountActivation';
import ActivationOauth2 from 'pages/ActivationOAuth2';

import AdminMain from 'pages/admin';
import AdminDashboardSummary from 'pages/admin/Dashboard/Summary';
import AdminUserManagement from 'pages/admin/AdminUserManagement';
import CityUserManagement from 'pages/admin/CityUserManagement';
import Posts from 'pages/admin/Posts';
import Newsletter from 'pages/admin/Newsletter';
import Configuration from 'pages/admin/Configuration';

import CityMain from 'pages/city';
import CityDashboard from 'pages/city/Dashboard';
import UserManagement from 'pages/city/UserManagement';
import Survey from 'pages/city/Survey';
import SurveyGroupPrint from 'pages/city/Survey/print';

import { isAuthenticated, getRole, getCityUserRole } from 'helpers/userAuthUtils';
import { applicationRoles } from 'helpers/statusUtils';
import AdminUserManagementList from 'pages/admin/AdminUserManagement/list';
import AdminUserManagementForm from 'pages/admin/AdminUserManagement/form';
import NewsletterForm from 'pages/admin/Newsletter/form';
import NewsletterList from 'pages/admin/Newsletter/list';
import PostsList from 'pages/admin/Posts/list';
import PostsForm from 'pages/admin/Posts/form';
import CityUserRegistration from 'pages/CityUserRegistration';
import AdminCityDashboard from 'pages/admin/Dashboard/City';
import FinishRegistration from 'pages/admin/FinishRegistration';
import GovNavbar from 'components/GovNavbar';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import LogoFooter from 'components/LogoFooter';
import ScrollToTop from 'components/ScrollToTop';
import AdminSurveyView from 'pages/admin/SurveyView';
import AdminSurveyGroupPrint from 'pages/admin/SurveyView/print';
import Unsubscription from 'pages/public/Unsubscription';
import MyAccount from 'pages/MyAccount';
import CityRecommendationsPrint from 'pages/city/Dashboard/CityRecommendationsPrint';
import AdminRecommendationsPrint from 'pages/admin/Dashboard/City/AdminRecommendationsPrint';
import IndicatorDataLoader from 'pages/admin/IndicatorDataLoader/openFileTest';
import Indicators from 'pages/admin/InsertIndicator';
import IndicatorsList from 'pages/admin/InsertIndicator/list';
import IndicatorsForm from 'pages/admin/InsertIndicator/form';

const checkRole = (roles, subRoles) => {
  if (roles.includes(getRole())) {
    if (subRoles && !subRoles.includes(getCityUserRole())) {
      return false;
    }
    return true;
  }
  return false;
};

const PrivateRoute = ({ target, roles, subRoles, errorTargetPath = '/', ...rest }) => {
  if (isAuthenticated() && checkRole(roles, subRoles)) {
    return target;
  }
  return <Navigate replace to={errorTargetPath} />;
};

// Fazendo desta forma para evitar apresentar estes elementos na impressão
const AppMain = () => {
  return (
    <>
      <GovNavbar />
      <Navbar />
      <Outlet />
      <Footer />
      <LogoFooter />
      <ScrollToTop />
    </>
  );
};

const AppRoutes = () => {
  const location = useLocation();
  useEffect(() => {
    const page = location.pathname + location.search;
    console.log('Analytics called for page ' + page);
    ReactGA.send({ hitType: 'pageview', page, title: 'inteli.gente' });
  }, [location]);

  return (
    <Routes>
      <Route path="/prefeitura/formulario-impressao/:sgcId?" element={<SurveyGroupPrint />} />
      <Route path="/prefeitura/recomendacoes-impressao" element={<CityRecommendationsPrint />} />
      <Route path="/portal/formulario-impressao/:cityFriendlyName/:sgcId" element={<AdminSurveyGroupPrint />} />
      <Route path="/portal/recomendacoes-impressao/:cityFriendlyName" element={<AdminRecommendationsPrint />} />
      <Route path="/" element={<AppMain />}>
        <Route index path="" element={<Home />} /> {/* Homepage */}
        <Route path="municipios" element={<CitySearch />} /> {/* Encontre um município */}
        <Route path="municipios/:cityFriendlyName" element={<CitySearchDetails />} /> {/* Detalhes do município */}
        <Route path="sobre" element={<AboutProgram />} /> {/* Sobre o programa */}
        <Route path="metodologias" element={<Methodologies />} /> {/* Metodologias */}
        <Route path="midia" element={<MediaPosts />} /> {/* Cidades Inteligentes na Mídia */}
        <Route path="contato" element={<ContactUs />} /> {/* Fale Conosco */}
        <Route path="prefeitura/login" element={<CityUserLogin />} /> {/* Login da Área da Prefeitura */}
        <Route path="prefeitura/cadastro" element={<CityUserRegistration />} />
        {/* Cadastro de Prefeituras com gov.br */}
        <Route exact path="ativacao/:token" element={<AccountActivation />} /> {/* Ativação de conta autoaprovada */}
        <Route exact path="ativacao-governanca/:token" element={<ActivationOauth2 />} />
        {/* Ativação de conta admin com gov.br */}
        <Route path="portal/login" element={<AdminLogin />} />
        <Route path="portal/login-master" element={<LoginMaster />} />
        <Route path="portal/finalizar-cadastro" element={<FinishRegistration />} /> {/* Finaliza cadastro admin */}
        <Route path="oauth2-callback/:providerName" element={<OAuth2Callback />} />
        <Route path="oauth2-logout/:providerName" element={<OAuth2Logout />} />
        <Route path="cancelar-inscricao/:uid" element={<Unsubscription />} />
        <Route path="prefeitura" element={<CityMain />}>
          <Route path="conta" element={<MyAccount />} />
          <Route path="dashboard" element={<CityDashboard />} />
          <Route path="formulario" element={<Survey />} />
          <Route path="equipe" element={<UserManagement />} />
        </Route>
        <Route path="portal" element={<AdminMain />}>
          <Route path="conta" element={<MyAccount />} />
          <Route path="dashboard" element={<AdminDashboardSummary />} />
          <Route path="dashboard/:cityFriendlyName" element={<AdminCityDashboard />} />
          <Route path="formulario/:cityFriendlyName" element={<AdminSurveyView />} />
          <Route path="boletins" element={<Newsletter />}>
            <Route index element={<NewsletterList />} />
            <Route path="novo" element={<NewsletterForm />} />
            <Route path=":id/editar" element={<NewsletterForm />} />
          </Route>
          <Route path="posts" element={<Posts />}>
            <Route index element={<PostsList />} />
            <Route path="novo" element={<PostsForm />} />
            <Route path=":id/editar" element={<PostsForm />} />
          </Route>
          <Route path="prefeitos" element={<CityUserManagement />} />
          <Route path="indicadores" element={<IndicatorDataLoader />} />
          <Route path="usuarios-admin" element={<AdminUserManagement />}>
            <Route index element={<AdminUserManagementList />} />
            <Route path="novo" element={<AdminUserManagementForm />} />
            <Route path=":id/editar" element={<AdminUserManagementForm />} />
          </Route>
          <Route path="indicadores" element={<Indicators />}>
            <Route index element={<IndicatorsList />} />
            <Route path=":id/formulario" element={<IndicatorsForm />} />
          </Route>
          <Route
            path="configuracoes"
            element={
              <PrivateRoute
                target={<Configuration />}
                roles={[applicationRoles.GOVERNANCE]}
                errorTargetPath="../dashboard"
              />
            }
          />
        </Route>
      </Route>
    </Routes>
  );
};

export default AppRoutes;
