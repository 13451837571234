import React from 'react';
import { useSelector } from 'react-redux';
import CitySidebar from './Sidebar';
import { Navigate, Outlet } from 'react-router-dom';
import { getRole, getCityUserRole, isAuthenticated } from 'helpers/userAuthUtils';
import { applicationRoles } from 'helpers/statusUtils';
// import { Actions as UserManagementActions } from 'services/userManagement';

/*
  Para rotas privadas, considerar o que está no link:
  https://www.robinwieruch.de/react-router-private-routes/

  TODO: verificar melhor componente para lógica de redirect
*/
const CityMain = () => {
  const cityUserData = useSelector((state) => state.userAuth.userData);
  const authenticated = isAuthenticated();
  const userRole = getRole();
  const cityRole = getCityUserRole();

  const notAllowed = !authenticated || userRole !== 'ROLE_CITY';

  if (notAllowed) {
    return <Navigate to={'/prefeitura/login'} replace />;
  }

  return (
    <div className="d-flex">
      <div className="col-admin-menu">
        <CitySidebar cityUserData={cityUserData} mayor={cityRole === applicationRoles.CITY_MAYOR} />
      </div>
      <div className="col-admin-content">
        <Outlet />
      </div>
    </div>
  );
};

export default CityMain;
