import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useTitle } from 'hooks/useTitle';
import UpdateDataForm from './UpdateDataForm';
import DeleteAccount from './DeleteAccount';
import { isSystemUser } from 'helpers/userAuthUtils';
import ChangePassword from './ChangePassword';

const MyAccount = () => {
  const pageTitle = useTitle('Configurações da conta');
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <>
      <div className="bg-gray-superlight px-5 py-4 w-100">
        {/* <!-- Cabeçalho Página - start --> */}
        <header>
          <h3 className="fw-bolder c-cyan mb-0">{pageTitle}</h3>
          <p className="path">
            <Link to="">Início</Link> &rsaquo; <span className="current">{pageTitle}</span>
          </p>
        </header>
        {/* <!-- Cabeçalho Página - end --> */}
        {!isSystemUser() && (
          <div className="bg-white shadow crud">
            <header>
              <div className="tabs w-100 justify-content-start">
                <div className={`tab w-50 ${selectedTab === 0 ? 'active' : ''}`}>
                  <a href="#" onClick={() => setSelectedTab(0)}>
                    Dados da conta
                  </a>
                </div>
                <div className={`tab w-50 ${selectedTab === 1 ? 'active' : ''}`}>
                  <a href="#" onClick={() => setSelectedTab(1)}>
                    Excluir conta
                  </a>
                </div>
              </div>
            </header>
            {selectedTab === 0 && <UpdateDataForm />}
            {selectedTab === 1 && <DeleteAccount />}
          </div>
        )}
        {isSystemUser() && (
          <div className="bg-white shadow crud">
            <ChangePassword />
          </div>
        )}
      </div>

      <ToastContainer position="bottom-center" />
    </>
  );
};

export default MyAccount;
