import api from 'services/api';

const API_BASE_PATH = '/api/contents';

const DEFAULT_QUANTITY = 10;

const __buildSearchFilters = (filters) => {
  let filterString = ``;

  const filtersObj = {
    dimensions: 'topic',
    states: 'st',
  };

  Object.entries(filters).forEach(([key, value]) => {
    if (filtersObj[key] && value?.length > 0) {
      filterString += `${filtersObj[key]}:${value}|`;
    }
  });

  filterString = filterString.substring(0, filterString.length - 1);

  return filterString;
};

const findAll = async (page, qt = DEFAULT_QUANTITY, q, filters) => {
  const params = {
    page,
    qt,
    q,
    filter: __buildSearchFilters(filters),
  };
  return await api.get(API_BASE_PATH, { params });
};

const findByFriendlyTitle = async (friendlyTitle) => {
  return await api.get(`${API_BASE_PATH}/${friendlyTitle}`);
};

const loadDimensions = async () => {
  return await api.get(`${API_BASE_PATH}/dimensions`);
};

const loadStates = async () => {
  return await api.get(`${API_BASE_PATH}/states`);
};

export default {
  findAll,
  findByFriendlyTitle,
  loadDimensions,
  loadStates,
};
