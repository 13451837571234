import React, { useEffect } from 'react';

const GovNavbar = () => {
  // TODO: Coloocar lógica adequada para detectar se é ou não impressão
  const isPrintLayout = false;

  useEffect(() => {
    if (isPrintLayout === false) {
      const script = document.createElement('script');
      script.defer = 'defer';
      script.async = 'async';
      script.src = '//barra.brasil.gov.br/barra.js';
      document.body.appendChild(script);
    }
  }, [isPrintLayout]);

  return (
    isPrintLayout === false && (
      <div id="barra-brasil">
        <ul id="menu-barra-temp">
          <li>
            <a href="//brasil.gov.br" target="_blank" rel="noopener noreferrer nofollow">
              Portal do Governo Brasileiro
            </a>
          </li>
        </ul>
      </div>
    )
  );
};

export default GovNavbar;
