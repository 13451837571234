import React, { useEffect, useRef, useState } from 'react';
import { useLazyGetIndicatorsQuery } from 'services/query/apiService';
import Pagination from 'components/PaginationIndicators';
import { Link } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { debounce } from 'lodash';

const List = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const [order, setOrder] = useState(['', '']);

  const [searchTerm, setSearchTerm] = useState('');

  const handleOrderChange = (field) => {
    setOrder((current) => [field, current && current[1] === 'DESC' ? 'ASC' : 'DESC']);
  };

  const [triggerIndicatorsQuery, { data: indicators, isLoading }] = useLazyGetIndicatorsQuery();

  const triggerSearch = useRef(
    debounce((value) => triggerIndicatorsQuery({ page: currentPage, order, searchTerm: value }), 500),
  ).current;

  const handleQuerySearch = (evt) => {
    const { value } = evt.target;
    setSearchTerm(value);
    triggerSearch(value);
  };

  useEffect(() => {
    triggerIndicatorsQuery({ page: currentPage, order, searchTerm });
  }, [triggerIndicatorsQuery, currentPage, order, searchTerm]);

  const handlePageChange = (pageDirection) => {
    if (typeof pageDirection === 'number') {
      const pageNumber = pageDirection;
      setCurrentPage(pageNumber);
      return;
    }

    const currentPageHolder = currentPage;
    const changePage = {
      prev: () => {
        const newPage = currentPageHolder - 1;
        setCurrentPage(newPage > 1 ? newPage : 1);
      },
      next: () => {
        const newPage = currentPageHolder + 1;
        setCurrentPage(newPage <= indicators?.totalPages ? newPage : currentPage);
      },
    };

    changePage[pageDirection]();
  };

  return (
    <>
      <div className="bg-white shadow crud">
        <div className="row mt-4">
          <div className="w-100 d-flex align-items-center">
            <div className="col-lg-3 mt-4 w-25 px-3 d-flex align-items-center">
              <div className="has-icon search w-100">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="Buscar por indicador..."
                  name="query"
                  value={searchTerm}
                  onChange={handleQuerySearch}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="p-5">
          <table className="crud mb-5">
            <colgroup>
              <col style={{ width: '10%' }} />
              <col style={{ width: '70%' }} />
              <col style={{ width: '20%' }} />
            </colgroup>
            <thead>
              <tr>
                <th>
                  Cod Indicador{' '}
                  <span onClick={() => handleOrderChange('id')}>
                    <i
                      className={`fa-solid fa-sort${
                        order[0] === 'id'
                          ? order[1] === 'DESC'
                            ? '-down c-aquamarine'
                            : '-up c-aquamarine'
                          : ' c-gray-light'
                      } ms-2`}
                    ></i>
                  </span>
                </th>
                <th>
                  Título{' '}
                  <span onClick={() => handleOrderChange('title')}>
                    <i
                      className={`fa-solid fa-sort${
                        order[0] === 'title'
                          ? order[1] === 'DESC'
                            ? '-down c-aquamarine'
                            : '-up c-aquamarine'
                          : ' c-gray-light'
                      } ms-2`}
                    ></i>
                  </span>
                </th>
                <th className="text-center">Ações</th>
              </tr>
            </thead>
            <tbody>
              {indicators?.content?.map((item) => (
                <tr key={`indicator-${item.id}`}>
                  <td>{item.id}</td>
                  <td>{item.title}</td>
                  <td className="text-center">
                    <Link to={`${item.id}/formulario`} state={{ value: item.title }} className="btn btn-success btn-sm">
                      Atualizar dados
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {isLoading && (
            <Alert variant="info">
              <span className="p-4 fw-semibold">
                Aguarde...&nbsp;<i className="fa-solid fa-spinner fa-spin fa-lg"></i>
              </span>
            </Alert>
          )}
          {indicators?.content?.length > 0 && <Pagination dataObj={indicators} onPageChange={handlePageChange} />}
          {indicators?.content?.length === 0 && <p>Nenhum indicador foi encontrado. </p>}
        </div>
      </div>
    </>
  );
};

export default List;
