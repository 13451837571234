import React from 'react';

export const ShowBullet = ({ isMulti = false, selected = false }) => {
  if (isMulti) {
    return selected ? <i className="fa-regular fa-square-check"></i> : <i className="fa-regular fa-square"></i>;
  } else {
    return selected ? <i className="fa-regular fa-circle-xmark"></i> : <i className="fa-regular fa-circle"></i>;
  }
};

export const ShowOrderBullet = ({ value, showAnswers = false }) => {
  return (
    <input
      type="text"
      value={showAnswers ? value : ''}
      style={{ borderColor: '#000', fontWeight: 'bold', width: '50px', textAlign: 'center' }}
      readOnly
    />
  );
};
