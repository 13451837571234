import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { getRole, getCityUserRole, isRestrictedArea } from 'helpers/userAuthUtils';
import { applicationRoles } from 'helpers/statusUtils';
import { logoutUrl } from 'helpers/govBrUtils';

const LinkItem = ({ active = false, to, children }) => {
  return active ? (
    <Link className="nav-link active" aria-current="page" to={to}>
      {children}
    </Link>
  ) : (
    <Link className="nav-link" to={to}>
      {children}
    </Link>
  );
};

const PublicNavbar = () => {
  return (
    <nav className="navbar navbar-expand-lg main-nav">
      <div className="container-fluid">
        <Link className="navbar-brand mx-5" to="/">
          <img loading="lazy" src="/img/logo_inteli.gente.png" alt="inteli.gente" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Alternar navegação"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mb-2 mb-lg-0 flex-grow-1 justify-content-between">
            <li className="nav-item">
              <LinkItem to="/municipios" active>
                Encontre um município
              </LinkItem>
            </li>
            <li className="nav-item">
              <LinkItem to="/sobre">Sobre a plataforma</LinkItem>
            </li>
            <li className="nav-item">
              <LinkItem to="/metodologias">Metodologias</LinkItem>
            </li>
            <li className="nav-item">
              <LinkItem to="/midia">Cidades Inteligentes Sustentáveis na mídia</LinkItem>
            </li>
            <li className="nav-item">
              <LinkItem to="/contato">Fale conosco</LinkItem>
            </li>
          </ul>
          <div className="mx-5">
            <Link className="btn btn-success" to="/prefeitura/login">
              Área da prefeitura
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

const UserNavItem = ({ name, cityRole }) => {
  if (cityRole === applicationRoles.CITY_MAYOR) {
    return (
      <>
        <i className="fa-solid fa-crown me-2" style={{ color: '#f39c12' }}></i>
        {name}
      </>
    );
  } else {
    return (
      <>
        <i className="fa-solid fa-user me-2 c-aquamarine"></i>
        {name}
      </>
    );
  }
};

const AccountConfigLink = ({ role }) => {
  const destination =
    role === applicationRoles.GOVERNANCE || role === applicationRoles.GOVERNANCE_VIEWER
      ? '/portal/conta'
      : '/prefeitura/conta';
  return (
    <Link to={destination} className="dropdown-item">
      <i className="fa-solid fa-gear c-gray-light me-2"></i> Configurações da conta
    </Link>
  );
};

const AuthNavbar = () => {
  const userData = useSelector((state) => state.userAuth.userData);

  const role = getRole();
  const cityRole = getCityUserRole();

  return (
    <nav className="navbar navbar-expand-lg main-nav">
      <div className="container-fluid">
        <Link className="navbar-brand mx-5" to="/">
          <img loading="lazy" src="/img/logo_inteli.gente.png" alt="inteli.gente" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Alternar navegação"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
          <ul className="navbar-nav mb-2 mb-lg-0 mx-5">
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle larger-2"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <UserNavItem name={userData?.name} cityRole={cityRole} />
              </a>
              <ul className="dropdown-menu dropdown-menu-end shadow" style={{ width: 'unset', right: 0 }}>
                <li>
                  <AccountConfigLink role={role} />
                </li>
                <li>
                  <a className="dropdown-item" href={logoutUrl}>
                    <i className="fa-solid fa-arrow-right-from-bracket c-gray-light me-2"></i> Sair
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

const Navbar = () => {
  const { pathname } = useLocation();
  if (isRestrictedArea(pathname)) {
    return <AuthNavbar />;
  } else {
    return <PublicNavbar />;
  }
};

export default Navbar;
