import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

export const DisapprovalModal = ({ id, show, title, message, onClose, onConfirmation }) => {
  const [reason, setReason] = useState('');
  const origin = 1;

  const handleReason = (evt) => {
    setReason(evt.target.value);
  };

  return (
    <Modal
      className="inteligente-modal"
      show={show}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
        <div className="form-group mb-3">
          <label className="form-label fw-semibold">Motivo da rejeição</label> <br />
          <textarea
            className="form-control"
            id="disapproval"
            name="reason"
            rows={5}
            value={reason}
            placeholder="Informe o motivo da exclusão"
            onChange={handleReason}
          ></textarea>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-danger" onClick={onClose}>
          Não
        </button>
        <button type="button" className="btn btn-success" onClick={() => onConfirmation(id, { origin, reason })}>
          Sim
        </button>
      </Modal.Footer>
    </Modal>
  );
};
