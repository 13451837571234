import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { Actions as AdminUserActions } from 'services/slices/user';
import { showToast, dismissToast } from 'components/Toast';
import { DeletionModal } from './Modals';
import Pagination from 'components/Pagination';

const UserType = ({ type }) => {
  if (type === 1) {
    return <span>Governança</span>;
  } else {
    return <span>Visualização</span>;
  }
};

const UserStatus = ({ status }) => {
  if (status === 1) {
    return <span className="badge bg-green-subtle">Ativado</span>;
  } else if (status === 10 || status === 0) {
    return (
      <span className="badge bg-yellow-subtle">
        Aguardando ativação
        <br />
        do usuário
      </span>
    );
  } else {
    return <></>;
  }
};

const List = () => {
  const usersGovernance = useSelector((state) => state.users);

  const toastId = useRef(null);

  const [page, setPage] = useState(1);
  const [order, setOrder] = useState(['', '']);
  const [modalDeletion, setModalDeletion] = useState();
  const [toastInfo, setToastInfo] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(AdminUserActions.loadGovernanceUsers(page, order));
    return () => {
      AdminUserActions.clearData();
    };
  }, [dispatch, page, order]);

  useEffect(() => {
    if (usersGovernance?.mutateSuccess === true) {
      setToastInfo({ type: 'success', message: usersGovernance?.message });
    } else if (usersGovernance?.mutateSuccess === false) {
      setToastInfo({ type: 'danger', message: usersGovernance?.message });
    }
  }, [usersGovernance?.mutateSuccess, usersGovernance?.message]);

  useEffect(() => {
    const { message, type, autoClose } = toastInfo;
    if (message && type) {
      if (toastId.current !== null) {
        dismissToast(toastId.current);
        toastId.current = null;
      }
      toastId.current = toastId.current === null ? showToast(message, type, autoClose) : toastId.current;
    }
  }, [dispatch, toastInfo]);

  const handleDeleteClick = (id, name, email) => {
    setModalDeletion({
      id,
      title: 'Excluir Usuário',
      message: `Confirmar a exclusão definitiva do usuário ${name} (${email})`,
      onConfirmation: handleDeleteConfirmation,
    });
  };

  const handleDeleteConfirmation = (id, deletionObj) => {
    dispatch(
      AdminUserActions.deleteGovernanceUser(id, deletionObj, () => {
        dispatch(AdminUserActions.loadGovernanceUsers(1, ['', '']));
      }),
    );
    closeDeletionModal(null);
  };

  const handleResendEmailClick = (id) => {
    dispatch(AdminUserActions.resendGovernanceActivationEmail(id));
  };

  const closeDeletionModal = () => {
    setModalDeletion(null);
  };

  const handlePageChange = (pageDirection) => {
    if (typeof pageDirection === 'number') {
      const pageNumber = pageDirection;
      setPage(pageNumber);
      return;
    }

    const currentPage = page;
    const changePage = {
      prev: () => {
        const newPage = currentPage - 1;
        setPage(newPage > 1 ? newPage : 1);
      },
      next: () => {
        const newPage = currentPage + 1;
        setPage(newPage <= usersGovernance?.page?.totalPages ? newPage : currentPage);
      },
    };

    changePage[pageDirection]();
  };

  const handleOrderChange = (field) => {
    setOrder((current) => [field, current && current[1] === 'DESC' ? 'ASC' : 'DESC']);
  };

  return (
    <>
      <div className="bg-white shadow crud">
        {/* <!-- Cabeçalho de Opções - start --> */}
        <header className="justify-content-end">
          <div className="px-4">
            <Link to="novo" className="btn btn-success btn-sm">
              <i className="fa-solid fa-plus"></i> Adicionar usuário
            </Link>
          </div>
        </header>
        {/* <!-- Cabeçalho de Opções - end --> */}

        {/* <!-- Tabela - start --> */}

        <div className="p-5">
          <table className="crud mb-5">
            <colgroup>
              <col style={{ maxWidth: '35%' }} />
              <col style={{ maxWidth: '35%' }} />
              <col style={{ maxWidth: '15%', textAlign: 'center' }} />
              <col style={{ maxWidth: '15%', textAlign: 'center' }} />
              <col style={{ maxWidth: '10%', textAlign: 'right' }} />
            </colgroup>
            <thead>
              <tr>
                <th>
                  Nome{' '}
                  <span onClick={() => handleOrderChange('person.name')}>
                    <i
                      className={`fa-solid fa-sort${
                        order[0] === 'person.name'
                          ? order[1] === 'DESC'
                            ? '-down c-aquamarine'
                            : '-up c-aquamarine'
                          : ' c-gray-light'
                      } ms-2`}
                    ></i>
                  </span>
                </th>
                <th>
                  E-mail{' '}
                  <span onClick={() => handleOrderChange('person.email')}>
                    <i
                      className={`fa-solid fa-sort${
                        order[0] === 'person.email'
                          ? order[1] === 'DESC'
                            ? '-down c-aquamarine'
                            : '-up c-aquamarine'
                          : ' c-gray-light'
                      } ms-2`}
                    ></i>
                  </span>
                </th>
                <th className="text-center">Perfil</th>
                <th className="text-center">Status</th>
                <th className="text-center">Ações</th>
              </tr>
            </thead>
            <tbody>
              {usersGovernance?.data?.map((user) => (
                <tr key={`user-${user.id}`}>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td className="text-center">
                    <UserType type={user.registrationType} />
                  </td>
                  <td className="text-center">
                    <UserStatus status={user.status} />
                  </td>
                  <td className="text-center">
                    <Dropdown>
                      <Dropdown.Toggle variant="outline-secondary" id={`dropdown-actions-${user.id}`} size="sm">
                        <i className="fa-solid fa-ellipsis-vertical"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item as={Link} to={`${user.id}/editar`}>
                          <i className="fa-solid fa-eye fa-fw"></i> Ver detalhes
                        </Dropdown.Item>
                        {(user.status === 10 || user.status === 0) && (
                          <Dropdown.Item onClick={() => handleResendEmailClick(user.id)}>
                            <i className="fa-solid fa-envelope fa-fw"></i> Reenviar e-mail de ativação
                          </Dropdown.Item>
                        )}
                        {usersGovernance?.data?.length > 0 && (
                          <Dropdown.Item onClick={() => handleDeleteClick(user.id, user.name, user.email)}>
                            <i className="fa-solid fa-xmark fa-fw c-danger"></i> Excluir
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {usersGovernance?.data?.length > 0 && (
            <Pagination pageInfo={usersGovernance?.page} onPageChange={handlePageChange} />
          )}
          {usersGovernance?.data?.length === 0 && <p>Nenhum usuário foi encontrado. </p>}
        </div>
        {/* <!-- Tabela - end --> */}
      </div>
      <DeletionModal show={!!modalDeletion} onClose={closeDeletionModal} {...modalDeletion} />
    </>
  );
};

export default List;
