import api from 'services/api';

const API_BASE_PATH = '/api/admin/configurations';

const findAll = async () => {
  return await api.get(`${API_BASE_PATH}`);
};

const editByName = async (data) => {
  return await api.put(`${API_BASE_PATH}/${data.name}`, data);
};

export default { findAll, editByName };
