import React from 'react';
import { Modal } from 'react-bootstrap';
import { handleImageLoadError } from 'helpers/Utils';

const ModalPost = ({ post, show, onClose }) => {
  return (
    <Modal
      className="inteligente-modal"
      show={show}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      centered
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>Cidades Inteligentes Sustentáveis na Mídia</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-12">
            <h3 className="fw-bold">{post?.title}</h3>
            <p>
              {post?.city && post?.stateAbbr && (
                <>
                  {post?.city} - {post?.stateAbbr}
                </>
              )}
              {post?.city && post?.publicationDate && <> | </>}
              {post?.publicationDate && (
                <em>Publicado em {new Date(post?.publicationDate).toLocaleString('pt-BR', { dateStyle: 'short' })}</em>
              )}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-5 col-xl-3 my-2 d-flex justify-content-center">
            <img
              loading="lazy"
              style={{ width: '100%' }}
              src={post?.imageSrc}
              title={post?.imageTitle || post?.friendlyTitle}
              alt={post?.imageTitle || post?.friendlyTitle}
              onError={handleImageLoadError}
            />
          </div>
          <div className="col-12 col-lg-7 col-xl-9 my-2 d-flex justify-content-center">
            <p className="text-justify">{post?.value}</p>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12">
            {post?.topics?.map((topicName, index) => (
              <span key={`topic-${index}`} className="badge text-bg-light m-2 p-2">
                {topicName}
              </span>
            ))}
          </div>
          <div className="col-12 mt-2">
            <p>
              Fonte:{' '}
              <a className="fw-bold" href={post?.sourceUrl} target="_blank" rel="nofollow noopener noreferrer">
                {post?.sourceUrl}
              </a>
            </p>
          </div>
        </div>
        <hr />
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end">
        <button type="button" className="btn btn-secondary" onClick={onClose}>
          Fechar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalPost;
