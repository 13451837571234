export const notEmpty = function (value) {
  if (typeof value === 'string') {
    return value?.trim()?.length > 0;
  }
  return value.length > 0;
};

export const validUrl = function (value) {
  if (!value) return false;
  const regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
  return regex.test(value);
};

export const validIsoDate = function (value) {
  if (!value) return false;
  const regex = /^\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])$/;
  return regex.test(value);
};

export const validName = function (name) {
  // Pelo menos dois nomes (nome e sobrenome), entre 4 e 64 caracteres, e cada nome deve ter, pelo menos, dois caracteres.
  if (name.length === 0) return null;
  const regex = /^((\b[A-zÀ-ú']{1,100})\s*){2,}$/;
  return regex.test(name);
};

export const validEmail = function (email, optional) {
  if (email.length === 0) return optional;
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

export const isPhoneNumber = function (phoneNumber) {
  if (phoneNumber.length === 0) return true;
  // Telefone com DDD, podendo incluir DDI brasileiro (+55).
  return /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/.test(phoneNumber);
};

export const isCity = function (city) {
  if (city.length === 0) return null;
  return /^[A-zÀ-ú- ',]{3,64}$/.test(city);
};

export const isCityId = function (cityId) {
  return !(cityId === '');
};

export const isRole = function (roleCode) {
  if (roleCode.length === 0) return null;
  return roleCode === 'A' || roleCode === 'P';
};

export const isDepartment = function (roleCode, department) {
  if (roleCode === 'P') return true;
  if (roleCode !== 'A') return false;
  if (department.length === 0) return null;
  return /^[A-zÀ-ú -'/]{2,64}$/.test(department);
};

export const termsAccepted = function (checked) {
  return !!checked;
};

export default {
  notEmpty,
  validUrl,
  validIsoDate,
  validName,
  validEmail,
  isPhoneNumber,
  isCity,
  isCityId,
  isDepartment,
  isRole,
  termsAccepted,
};
