import api from 'services/api';
import { userStatus } from 'helpers/statusUtils';
import { filterStatusInAndText } from 'helpers/filterUtils';
import { getUsersPerPage } from 'helpers/paginationUtils';

export const Types = {
  LOADING_MAYORS: 'restrictedArea/LOADING_MAYORS',
  LIST_MAYORS: 'restrictedArea/LIST_MAYORS',
  APPROVE_MAYOR: 'restrictedArea/APPROVE_MAYOR',
  REJECT_MAYOR: 'restrictedArea/REJECT_MAYOR',
  MANAGE_MAYOR_ERROR: 'cityArea/MANAGE_MAYOR_ERROR',
  SEARCH_BY: 'restrictedArea/SEARCH_BY',
  FILTER_MAYORS: 'restrictedArea/FILTER_MAYORS',
  CLEAN_ERROR: 'restrictedArea/CLEAN_ERROR',
  ORDER_BY: 'restrictedArea/ORDER_BY',
  SELECTED_ROLE: 'restrictedArea/SELECTED_ROLE',
};

const INITIAL_STATE = {
  content: [],
  mayorsList: [],
  filtered: [],
  searchBy: userStatus.APPROVED,
  loading: false,
  error: null,
  orderBy: [],
  selectedRole: 'N',
};

export default function restrictedAreaMayors(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_MAYORS:
      return { ...state, content: action.mayors };
    case Types.APPROVE_MAYOR:
      return {
        ...state,
        mayorsList: state.mayorsList.map((mayor) => {
          if (action.mayorId === mayor.id) {
            mayor.status = status.APPROVED;
          }
          return mayor;
        }),
        filtered: state.mayorsList.map((mayor) => {
          if (action.mayorId === mayor.id) {
            mayor.status = userStatus.APPROVED;
          }
          return mayor;
        }),
      };
    case Types.REJECT_MAYOR:
      return {
        ...state,
        mayorsList: state.mayorsList.map((mayor) => {
          if (action.mayorId === mayor.id) {
            mayor.status = userStatus.REJECTED;
          }
          return mayor;
        }),
        filtered: state.mayorsList.map((mayor) => {
          if (action.mayorId === mayor.id) {
            mayor.status = userStatus.REJECTED;
          }
          return mayor;
        }),
      };
    case Types.MANAGE_MAYOR_ERROR:
      return { ...state, error: true };
    case Types.SEARCH_BY:
      return {
        ...state,
        mayorsList: state.content.filter((mayor) =>
          filterStatusInAndText(mayor.status, action, mayor.name, mayor.city.name, mayor.city.state),
        ),
        filtered: state.content.filter((mayor) =>
          filterStatusInAndText(mayor.status, action, mayor.name, mayor.city.name, mayor.city.state),
        ),
      };
    case Types.FILTER_MAYORS:
      return {
        ...state,
        filtered: state.mayorsList.slice(action.perPage * (action.page - 1), action.perPage * action.page),
      };
    case Types.CLEAN_ERROR:
      return { ...state, error: false };
    default:
      return state;
  }
}

export const Creators = {
  // listMayors: (initialStatus, orderBy, role) => {
  //   let orderString = ''
  //   let roleString = ''
  //   if (orderBy.length !== 0) {
  //     orderString = `${orderBy[0]}|${orderBy[1]}`
  //   } else {
  //     orderString = 'name|DESC'
  //   }
  //   switch(role) {
  //     case 'Prefeitos':
  //       roleString = 'P'
  //       break
  //     case 'Assessores':
  //       roleString = 'A'
  //       break
  //     default:
  //       roleString = 'N'
  //       break
  //   }
  //   return dispatch => {
  //     api.get('/api/admin/users/all-roles', { params: { order: orderString, role: roleString } })
  //     .then(response => {
  //         dispatch({
  //           type: Types.LIST_MAYORS,
  //           mayors: response.data.content
  //         })
  //         dispatch({
  //           type: Types.SEARCH_BY,
  //           statusCode: initialStatus,
  //           text: ''
  //         })
  //       })
  //       .catch(err => {
  //         if(err.response && 'status' in err.response) {
  //           if(err.response.status !== 403) {
  //             console.log(err)
  //           }
  //         }
  //       })
  //   }
  // },
  approveMayor: (mayorId, status, filterText) => {
    return (dispatch) => {
      api
        .patch('/api/admin/users/' + mayorId + '/approval')
        .then(() => {
          dispatch({
            type: Types.APPROVE_MAYOR,
            mayorId,
          });
          dispatch({
            type: Types.SEARCH_BY,
            statusCode: status,
            text: filterText,
          });
        })
        .catch((err) => {
          dispatch({
            type: Types.MANAGE_MAYOR_ERROR,
          });
          if (err.response && 'status' in err.response) {
            if (err.response.status !== 403) {
              console.log(err);
            }
          }
        });
    };
  },
  rejectMayor: (mayorId, reason, actionCode, status, filterText) => {
    return (dispatch) => {
      api
        .patch('/api/admin/users/' + mayorId + '/disapproval', { reason, actionCode })
        .then(() => {
          dispatch({
            type: Types.REJECT_MAYOR,
            mayorId,
          });
          dispatch({
            type: Types.SEARCH_BY,
            statusCode: status,
            text: filterText,
          });
        })
        .catch((err) => {
          dispatch({
            type: Types.MANAGE_MAYOR_ERROR,
          });
          if (err.response && 'status' in err.response) {
            if (err.response.status !== 403) {
              console.log(err);
            }
          }
        });
    };
  },
  searchBy: (statusCode, filterText) => {
    return (dispatch) => {
      dispatch({
        type: Types.SEARCH_BY,
        statusCode,
        text: filterText,
      });
      dispatch({
        type: Types.FILTER_MAYORS,
        page: 1,
        perPage: getUsersPerPage(),
      });
    };
  },
  filterMayors: (page, perPage) => {
    return (dispatch) => {
      dispatch({
        type: Types.FILTER_MAYORS,
        page,
        perPage,
      });
    };
  },
  // orderBy: (orderBy) => {
  //   let orderString = ''
  //   if (orderBy.length !== 0) {
  //     orderString = `${orderBy[0]}|${orderBy[1]}`
  //   } else {
  //     orderString = 'name|DESC'
  //   }
  //   console.log(orderString)
  //   return dispatch => {
  //     api.get('/api/admin/users/all-roles', { params: { order: orderString } })
  //     .then(response => {
  //         dispatch({
  //           type: Types.LIST_MAYORS,
  //           mayors: response.data.content
  //         })
  //       })
  //       .catch(err => {
  //         if(err.response && 'status' in err.response) {
  //           if(err.response.status !== 403) {
  //             console.log(err)
  //           }
  //         }
  //       })
  //   }
  // },
  chooseRole: (role) => {
    let roleString = '';
    switch (role) {
      case 'Prefeitos':
        roleString = 'P';
        break;
      case 'Assessores':
        roleString = 'A';
        break;
      default:
        roleString = 'N';
        break;
    }
    return (dispatch) => {
      api
        .get('/api/admin/users/all-roles', { params: { role: roleString } })
        .then((response) => {
          dispatch({
            type: Types.LIST_MAYORS,
            mayors: response.data.content,
          });
        })
        .catch((err) => {
          if (err.response && 'status' in err.response) {
            if (err.response.status !== 403) {
              console.log(err);
            }
          }
        });
    };
  },
  clean: () => {
    return (dispatch) => {
      dispatch({
        type: Types.CLEAN_ERROR,
      });
    };
  },
};
