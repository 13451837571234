import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken } from 'helpers/userAuthUtils';

export const apiService = createApi({
  reducerPath: 'service',
  baseQuery: fetchBaseQuery({
    baseUrl: window.ENV.API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getToken();
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getIndicators: builder.query({
      query: ({ page, qt = 10, order, searchTerm }) => {
        if (order[0] === '' || order[1] === '') {
          order = [];
        }
        return `/indicators?page=${page}&qt=${qt}&order=${order}&searchTerm=${searchTerm}`;
      },
    }),
    getIndicatorById: builder.query({
      query: ({ indicatorId }) => `/indicators/${indicatorId}`,
    }),
    saveIndicator: builder.mutation({
      query: (newIndicator) => {
        const { year, month, ...newData } = newIndicator.data;
        const formData = new FormData();
        formData.append('data', new Blob([JSON.stringify(newData)], { type: 'application/json' }));
        newIndicator.files.forEach((file) => {
          formData.append('files', file);
        });
        const monthVal = +(newIndicator.data?.month ?? 0);
        const monthStr = monthVal < 10 ? '0' + monthVal : '' + monthVal;
        return {
          url:
            monthVal === 0
              ? `/indicators/${newIndicator.data.indicadorId}/${newIndicator.data.year}`
              : `/indicators/${newIndicator.data.indicadorId}/${newIndicator.data.year}-${monthStr}`,
          method: 'PUT',
          body: formData,
          headers: {
            accept: 'application/json',
          },
        };
      },
    }),
  }),
});

export const { useLazyGetIndicatorsQuery, useGetIndicatorByIdQuery, useSaveIndicatorMutation } = apiService;
