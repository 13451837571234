/**
 * Separa o JSON por tab
 */

export const setTabsGroups = (configurations) => {
  const formatterConfigObj = {
    contactUs: [],
    emailServer: [],
    devOptions: [],
    autoapprovalOptions: [],
  };

  formatterConfigObj.contactUs.push(configurations.filter((config) => config.name === 'CONTACT_US_EMAIL_ADDRESS')[0]);
  formatterConfigObj.contactUs.push(configurations.filter((config) => config.name === 'SUPPORT_EMAIL_ADDRESS')[0]);
  formatterConfigObj.emailServer.push(configurations.filter((config) => config.name === 'MAIL_HOST')[0]);
  formatterConfigObj.emailServer.push(configurations.filter((config) => config.name === 'MAIL_PORT')[0]);
  formatterConfigObj.emailServer.push(configurations.filter((config) => config.name === 'MAIL_USERNAME')[0]);
  formatterConfigObj.emailServer.push(configurations.filter((config) => config.name === 'MAIL_PWD')[0]);
  formatterConfigObj.emailServer.push(configurations.filter((config) => config.name === 'MAIL_PROPS')[0]);
  formatterConfigObj.devOptions.push(configurations.filter((config) => config.name === 'DEV_ENABLE_SEND_EMAILS')[0]);
  formatterConfigObj.autoapprovalOptions.push(
    configurations.filter((config) => config.name === 'USER_EMAIL_VALID_SUFFIXES')[0],
  );
  formatterConfigObj.autoapprovalOptions.push(
    configurations.filter((config) => config.name === 'MAIL_AUTOAPPROVAL')[0],
  );

  return formatterConfigObj;
};
