import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TinyMCEditor from 'components/TinyMceEditor';
import FieldSelectInput from 'components/form/FieldSelectInput';
import CrudFieldInput from 'components/CrudFieldInput';
import Validators from 'helpers/Validators';
import { removeTimezoneOffset, currentLocalDateString } from 'helpers/dateTimeUtils';
import { showToast } from 'components/Toast';
import { Actions as NewslettersActions } from 'slices/admin/newsletters';
import CrudFieldInputAndButton from 'components/CrudFieldInputAndButton';

const NewsletterForm = () => {
  const adminNewsletterItem = useSelector((state) => state.adminNewsletterItem);
  const errors = useSelector((state) => state.adminNewsletterItem.fieldErrors);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const toastId = useRef(null);
  const tinyMceEditor = useRef(null);

  const [data, setData] = useState({ status: 3 });
  const [cityObjs, setCityObjs] = useState([]);
  const [validation, setValidation] = useState({});
  const [btnDisabled, setBtnDisabled] = useState(true);

  const [toastInfo, setToastInfo] = useState(null);

  useEffect(() => {
    if (id) {
      dispatch(NewslettersActions.loadNewsletter(id));
    }
    return () => {
      dispatch(NewslettersActions.clearData());
    };
  }, [id, dispatch]);

  useEffect(() => {
    setData((current) => {
      const item = adminNewsletterItem.item;
      return {
        ...current,
        ...item,
      };
    });
    setCityObjs([...adminNewsletterItem.citiesObjArr]);
  }, [adminNewsletterItem.item, adminNewsletterItem.citiesObjArr]);

  useEffect(() => {
    if (adminNewsletterItem?.loading === true) {
      setToastInfo({ type: 'info', message: adminNewsletterItem.message });
    }
  }, [adminNewsletterItem.loading, adminNewsletterItem.message]);

  useEffect(() => {
    if (adminNewsletterItem.success === true) {
      navigate({
        pathname: '/portal/boletins',
        state: {
          success: true,
        },
      });
    }
    if (adminNewsletterItem?.success === false) {
      setToastInfo({ type: 'danger', message: adminNewsletterItem?.message });
    }
  }, [dispatch, navigate, adminNewsletterItem?.success, adminNewsletterItem?.message]);

  useEffect(() => {
    if (toastInfo) {
      const toastIdCurrent = showToast(toastInfo.message, toastInfo.type, toastInfo.autoClose);
      if (toastInfo?.type === 'info') {
        toastId.current = toastIdCurrent;
      }
    }
    return () => {
      dispatch(NewslettersActions.clearData());
    };
  }, [dispatch, toastInfo]);

  const handleInputChange = (evt) => {
    const { type, name, value, checked } = evt.target;

    if (type === 'checkbox') {
      // 2-Liberado para envio, 3-Rascunho
      setData((current) => ({
        ...current,
        [name]: checked ? 2 : 3,
      }));
      setValidation((current) => ({
        ...current,
        sendDate: checked ? validate('sendDate', data?.sendDate) : true,
      }));
    } else {
      setData((current) => ({
        ...current,
        [name]: value,
      }));
      setValidation((current) => ({
        ...current,
        [name]: validate(name, value),
      }));
    }
  };

  const handleEditorChange = (content, editor) => {
    setData((current) => ({
      ...current,
      value: content,
    }));
    setValidation((current) => ({
      ...current,
      value: validate('value', content),
    }));
  };

  const handleSelectCity = (city) => {
    if (data?.status === 2 || data?.status === 3) {
      if (data.citiesIds) {
        setData((current) => ({
          ...current,
          citiesIds: [...current.citiesIds, city.id],
        }));
        setCityObjs((current) => [...current, city]);
      } else {
        setData((current) => ({
          ...current,
          citiesIds: [city.id],
        }));
        setCityObjs([city]);
      }
    }
  };

  const handleRemoveCity = (selectedCity) => {
    if (data?.status === 2 || data?.status === 3) {
      setData((current) => ({
        ...current,
        citiesIds: current.citiesIds.filter((cityId) => selectedCity.id !== cityId),
      }));
      setCityObjs((current) => current.filter((cityObj) => selectedCity.id !== cityObj.id));
    }
  };

  const handleToday = () => {
    setData((current) => ({
      ...current,
      sendDate: currentLocalDateString(),
    }));
  };

  const validate = (name, value) => {
    switch (name) {
      case 'title':
      case 'value':
        return Validators.notEmpty(value);
      case 'sendDate':
        if (data?.status === 2 && value) {
          let now = new Date();
          now.setHours(0, 0, 0, 0);
          now = removeTimezoneOffset(now);
          const dateVal = new Date(value);
          return dateVal.getTime() >= now.getTime();
        }
        return data?.status === 3;
      default:
        return true;
    }
  };

  useEffect(() => {
    const values = Object.values(validation);
    const valid = values.length > 0 && values.every((item) => item === true);
    setBtnDisabled(!valid);
  }, [validation]);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    dispatch(NewslettersActions.saveNewsletter(data));
  };

  return (
    <>
      <div className="bg-white shadow crud p-5">
        <form onSubmit={handleSubmit} noValidate disabled={data?.status === 1 || data?.status === 0}>
          <div className="mb-4">
            <CrudFieldInput
              value={data?.title || ''}
              isError={errors?.title || validation?.title === false}
              errorMsg={errors?.title || 'Título do boletim não preenchido'}
              id="fieldTitle"
              name="title"
              label="Título"
              type="text"
              placeholder="Título do boletim"
              required
              disabled={data?.status === 1 || data?.status === 0}
              onChange={handleInputChange}
            />
          </div>

          <div className="mb-4">
            <label className="form-label fw-semibold" htmlFor="fieldContent">
              Conteúdo do boletim
            </label>
            <TinyMCEditor
              id="fieldContent"
              name="value"
              value={data?.value || ''}
              onInit={(evt, editor) => (tinyMceEditor.current = editor)}
              disabled={data?.status === 1 || data?.status === 0}
              onEditorChange={handleEditorChange}
            />
            {(errors?.value || validation?.value === false) && (
              <p className="mt-2 mb-4 small c-danger">
                <i className="fa-solid fa-xmark"></i> {errors?.value || 'Conteúdo do boletim não preenchido'}
              </p>
            )}
          </div>

          <div className="mb-4">
            <div className="row mb-4">
              <FieldSelectInput
                label="Municípios"
                id="city-autocomplete-newsletter"
                name="city-search"
                placeholder="Preencha as iniciais do município e selecione uma opção apresentada"
                disabled={data?.status === 1 || data?.status === 0}
                onSelectedOption={handleSelectCity}
              />
              {cityObjs?.length > 0 && (
                <>
                  {(data?.status === 2 || data?.status === 3) && (
                    <label className="form-label fw-semibold mt-2">Municípios selecionados. Clique para remover</label>
                  )}
                  {data?.status !== 2 && data?.status !== 3 && (
                    <label className="form-label fw-semibold mt-2">Municípios selecionados.</label>
                  )}
                  <div>
                    {cityObjs.map((cityObj) => (
                      <span
                        className="badge text-bg-light me-2"
                        key={`cityName-${cityObj.id}`}
                        onClick={() => handleRemoveCity(cityObj)}
                      >
                        {cityObj.name ? cityObj.name : cityObj.result} <i className="fa-solid fa-times text-danger"></i>
                      </span>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>

          <CrudFieldInputAndButton
            value={data?.sendDate || ''}
            isError={errors?.sendDate || validation?.sendDate === false}
            errorMsg={errors?.sendDate || 'Data de publicação inválida.'}
            infoMsg={
              data?.status === 2 || data?.status === 3
                ? 'O boletim será enviado aos inscritos na data informada.'
                : null
            }
            name="sendDate"
            label="Data de publicação"
            type="date"
            maxLength={10}
            min={currentLocalDateString()}
            disabled={data?.status === 1 || data?.status === 0}
            onChange={handleInputChange}
            buttonLabel="Hoje"
            onButtonClick={handleToday}
          />

          {(data?.status === 2 || data?.status === 3) && (
            <div className="mb-4">
              <div className="form-check form-switch mb-2">
                <input
                  className="form-check-input"
                  name="status"
                  type="checkbox"
                  role="switch"
                  id="status-send"
                  checked={data?.status === 2}
                  onChange={handleInputChange}
                />
                <label className="form-check-label" htmlFor="status-send">
                  Liberar para envio
                </label>
              </div>
            </div>
          )}
          <div className="mt-5 text-center">
            <Link to="/portal/boletins" className="btn btn-outline-secondary me-3">
              Voltar
            </Link>
            {(data?.status === 2 || data?.status === 3) && (
              <button type="submit" disabled={btnDisabled} className="btn btn-success">
                Confirmar
              </button>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default NewsletterForm;
