import { createSlice } from '@reduxjs/toolkit';

const requestControl = {
  loading: null,
  success: null,
};

const pageControl = {
  totalElements: 1,
  totalPages: 1,
  pageNumber: 1,
  totalInPage: 1,
  pageSize: 1,
  first: true,
  last: true,
};

const initialStateGlobal = {
  ...requestControl,
  ...pageControl,
  message: null,
  data: [],
};

export const adminNewslettersSlice = createSlice({
  name: 'adminNewsletters',
  initialState: initialStateGlobal,
  reducers: {
    loadingStart: (state) => {
      state.loading = true;
      state.success = null;
      state.data = [];
    },
    loadingStop: (state) => {
      state.loading = false;
    },
    success: (state, action) => {
      state.success = true;
      state.data = action.payload?.content;
      state.page = action.payload?.page;
    },
    error: (state, action) => {
      state.success = false;
      state.message = action.payload;
    },
    reset: () => {
      return { ...initialStateGlobal };
    },
  },
});

/* -- -- */

const initialState = {
  global: {
    ...requestControl,
  },
  ...requestControl,
  message: null,
  item: {},
  fieldErrors: {},
  citiesObjArr: [],
};

export const adminNewsletterItemSlice = createSlice({
  name: 'adminNewsletterItem',
  initialState,
  reducers: {
    loadingStart: (state, action) => {
      state.global.loading = true;
      state.global.success = null;
      state.global.message = action.payload || null;
      state.item = {};
      state.fieldErrors = {};
    },
    loadingStop: (state) => {
      state.global.loading = false;
    },
    loadSuccess: (state, action) => {
      state.global.success = true;
      state.item = action.payload.content;
      state.citiesObjArr = action.payload.citiesObjArr;
      state.fieldErrors = {};
    },
    loadError: (state) => {
      state.global.success = false;
      state.item = {};
    },
    success: (state, action) => {
      state.success = true;
      state.message = action.payload;
    },
    error: (state, action) => {
      state.success = false;
      state.message = action.payload.message;
      state.fieldErrors = action.payload.fieldErrors;
    },
    reset: () => {
      return { ...initialState };
    },
  },
});
