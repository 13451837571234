import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import { debounce } from 'lodash';
import SurveyQuestions from './SurveyQuestions';
import { Actions as CitySurveyActions } from 'services/citySurvey';
import { showToast, dismissToast } from 'components/Toast';
import { getLocaleCode } from 'helpers/Utils';
import { formatDateTimeSimple } from 'helpers/dateTimeUtils';
import formSettings from 'components/survey/formSettings';
import { ModalConfirmSave, ModalGroupSaveStatus, getToastInfo } from 'components/survey/ModalToast';
import 'components/survey/Survey.scss';

const SurveyCompletionStatus = ({ finishedStatus, finishedPercent }) => {
  if (!finishedStatus) {
    return (
      <>
        <p>
          <strong>{'' + finishedPercent}%</strong> do preenchimento completo
        </p>
        <div className="progress-bar">
          <div className="completed-bar" style={{ width: '' + finishedPercent + '%' }}></div>
        </div>
      </>
    );
  }
  return <div className="col-12 col-lg-9"></div>;
};

const showAutomaticSavingToast = (timeMS) => {};

// <div className="d-flex justify-content-evenly">
//   <div className="me-4">
//     <a href="#" className="c-gray">
//       <i className="fa-solid fa-chevron-left fa-2x"></i>
//     </a>
//   </div>
//   <div className="text-center fs-3">
//     <i className="fa-solid fa-circle-check c-gray me-3"></i>
//     <i className="fa-solid fa-pen c-green me-3"></i>
//     <a href="#">
//       <i className="fa-solid fa-pen c-gray-light me-3"></i>
//     </a>
//     <a href="#">
//       <i className="fa-solid fa-pen c-gray-light me-3"></i>
//     </a>
//     <a href="#">
//       <i className="fa-solid fa-pen c-gray-light me-3"></i>
//     </a>
//     <a href="#">
//       <i className="fa-solid fa-pen c-gray-light me-3"></i>
//     </a>
//   </div>
//   <div className="ms-4">
//     <a href="#" className="c-gray">
//       <i className="fa-solid fa-chevron-right fa-2x"></i>
//     </a>
//   </div>
// </div>

const TabPagination = ({ steps, currentStep, prevButton, nextButton, mode, tabs }) => {
  return (
    <div className="d-flex justify-content-evenly">
      <div className="me-4">{prevButton()}</div>
      <div className="text-center fs-3">
        {steps.map((stepCode, index) => {
          if (index < currentStep - 1) {
            return (
              <a href="#" key={`tab-${stepCode}`}>
                <i className="fa-solid fa-circle-check c-gray me-3"></i>
              </a>
            );
          } else if (index === currentStep - 1) {
            // ATUAL
            return <i key={`tab-${stepCode}`} className="fa-solid fa-pen c-green me-3"></i>;
          } else {
            return (
              <a href="#" key={`tab-${stepCode}`}>
                <i className="fa-solid fa-pen c-gray-light me-3"></i>
              </a>
            );
          }
        })}
      </div>
      <div className="ms-4">{nextButton()}</div>
    </div>
  );
};

const SurveyGroupQuestions = ({ tabs = [], initialActiveTab }) => {
  const stepCodes = tabs.map((option) => option.code);
  const MAX_STEPS = stepCodes.length;

  const [activeTab, setActiveTab] = useState(initialActiveTab);
  const [currentStep, setCurrentStep] = useState(1);

  const surveyCode = useSelector((state) => state.citySurvey.currentSurveyCode);
  const dispatch = useDispatch();

  const handleChangeTab = (code) => {
    if (surveyCode !== code) {
      const newActiveTab = tabs.filter((option) => option.code === code)[0];
      dispatch(CitySurveyActions.saveAnswers(surveyCode, false));
      setActiveTab(newActiveTab);
      setCurrentStep(stepCodes.indexOf(code) + 1);
    }
  };

  const onTabClick = (evt, code) => {
    evt.preventDefault();
    handleChangeTab(code);
  };

  const _next = (evt, step) => {
    evt.preventDefault();
    const nextStep = step > MAX_STEPS ? MAX_STEPS : step + 1;
    handleChangeTab(tabs[nextStep - 1].code);
  };

  const _prev = (evt, step) => {
    evt.preventDefault();
    const prevStep = step <= 1 ? 1 : step - 1;
    handleChangeTab(tabs[prevStep - 1].code);
  };

  const nextButton = () => {
    if (currentStep < MAX_STEPS) {
      return (
        <a href="#" className="c-gray" onClick={(evt) => _next(evt, currentStep)}>
          <i className="fa-solid fa-chevron-right fa-2x"></i>
        </a>
      );
    }
    if (currentStep === MAX_STEPS) {
      return (
        <a href="#" className="c-gray disabled" onClick={(evt) => false}>
          <i className="fa-solid fa-chevron-right fa-2x"></i>
        </a>
      );
    }
  };

  const prevButton = () => {
    if (currentStep > 1) {
      return (
        <a href="#" className="c-gray" onClick={(evt) => _prev(evt, currentStep)}>
          <i className="fa-solid fa-chevron-left fa-2x"></i>
        </a>
      );
    }
    if (currentStep === 1) {
      return (
        <a href="#" className="c-gray disabled" onClick={(evt) => false}>
          <i className="fa-solid fa-chevron-left fa-2x"></i>
        </a>
      );
    }
  };

  return (
    <div className="bg-white shadow crud">
      <header>
        <div className="tabs auto">
          {tabs.map((option, index) => {
            let tabClassList = 'tab';
            if ((option.code === surveyCode && option.cityStatus === 2) || option.code === activeTab.code) {
              tabClassList += ' active';
            }
            if (stepCodes.indexOf(option.code) < stepCodes.indexOf(activeTab.code) && option.cityStatus === 2) {
              tabClassList += ' complete';
            }
            // if (headers.indexOf(option.label) > headers.indexOf(activeTab.label) && (option.code === surveyCode && option.cityStatus === 2) {
            // }
            return (
              <div key={`survey-tab-${option.code}`} className={tabClassList}>
                <a href={`#${option.code}`} onClick={(evt) => onTabClick(evt, option.code)}>
                  {option.title}
                </a>
              </div>
            );
          })}
        </div>
      </header>
      <div className="p-5">
        {tabs
          .filter((option) => option.code === activeTab.code)
          .map((option) => (
            <SurveyQuestions key={`survey-questions-${option.code}`} surveyCode={option.code} title={option.title} />
          ))}
        {/* <!-- Pagination - start --> */}
        <TabPagination
          steps={stepCodes}
          currentStep={currentStep}
          prevButton={prevButton}
          nextButton={nextButton}
          mode="forms"
          tabs={tabs}
        />
        {/* <!-- Pagination - end --> */}
      </div>
      {/*
      </Tab.Container>
        */}
      {/* <!-- Formulário - end --> */}
    </div>
  );
};

// Fluxo esperado para nova tela de pesquisa
// 1) Carregar informações do SurveyGroup ativo (Surveys, Datas de inicio/termino)
// 2) Ao acessar Survey específico, trazer informações referentes a cidade (caso exista)
// 3) Salvamento somente do Survey específico
// 4) Concluir preenchimento deve fechar todos os formulários

const Survey = () => {
  const allGroupsInfo = useSelector((state) => state.citySurvey.allGroupsInfo);
  const groupInfo = useSelector((state) => state.citySurvey.groupInfo);
  const answers = useSelector((state) => state.citySurvey.groupInfo?.answers);
  const finishedStatus = useSelector((state) => state.citySurvey.groupInfo?.status, shallowEqual) === 2;
  const groupSaveNotification = useSelector((state) => state.citySurvey.groupSaveNotification, shallowEqual) === 1;
  const finishedSurveysGroups = useSelector((state) => state.citySurvey.finishedSurveysGroups);
  const progressSurveyGroup = useSelector((state) => state.citySurvey.progressSurveyGroup);
  const loadStatusValues = useSelector((state) => state.citySurvey.loadStatusValues);
  const answersSaveStatus = useSelector((state) => state.citySurvey.answersSaveStatus);
  const groupSaveStatus = useSelector((state) => state.citySurvey.groupSaveStatus);
  const groupCloneStatus = useSelector((state) => state.citySurvey.groupCloneStatus);
  const dispatch = useDispatch();

  const [tabs, setTabs] = useState(null);
  const [tabsLoaded, setTabsLoaded] = useState(false);
  const [noSurveysAvailable, setNoSurveysAvailable] = useState(false);

  // eslint-disable-next-line
  const [showWarning, setShowWarning] = useState(false);

  const [showModalFinish, setShowModalFinish] = useState(false);
  const [finishedPercent, setFinishedPercent] = useState(0);
  const [selectedFinished, setSelectedFinished] = useState(null);
  const [toastInfo, setToastInfo] = useState({});

  const toastId = useRef(null);

  const printForm = (withAnswers = false) => {
    const id = progressSurveyGroup?.id || selectedFinished?.id;
    let url;
    if (withAnswers === true && id) {
      url = '/prefeitura/formulario-impressao/' + id + '?answers';
    } else {
      url = '/prefeitura/formulario-impressao';
    }
    const result = window.open(url, 'printPreview', 'menubar=0,location=0,width=1024,height=700');
    return result;
  };

  // Carregar todos os grupos ao carregar tela
  useEffect(() => {
    dispatch(CitySurveyActions.findAllGroupsAvailable());
    return () => {
      dispatch(CitySurveyActions.reset());
    };
  }, [dispatch]);

  // Carregar uma pesquisa/grupo com os respectivos dos forms resumidos
  // TODO: Fazer tratamento para 2 ou mais pesquisas ativas simultaneamente
  useEffect(() => {
    if (allGroupsInfo) {
      if (allGroupsInfo.length > 0) {
        const currentCode = allGroupsInfo[0].code;
        setTabs(null);
        dispatch(CitySurveyActions.findGroupByCode(currentCode));
      } else {
        // SEM PESQUISAS PARA PREENCHER
        setTabsLoaded(true);
        setNoSurveysAvailable(true);
      }
    }
  }, [dispatch, allGroupsInfo]);

  // Prepara as abas com os forms que pertencem a pesquisa/grupo
  useEffect(() => {
    if (groupInfo && !tabs) {
      const tabs = Object.values(groupInfo.surveys).map((survey) => ({
        code: survey.code,
        title: survey.title,
        cityStatus: survey.cityStatus,
      }));
      setTabs(tabs);
      setTabsLoaded(true);
      setShowWarning(groupInfo.status === 1);
    }
  }, [groupInfo, tabs]);

  // Apresenta informação do finishedSurveyGroups mais recente no combo box
  useEffect(() => {
    if (finishedSurveysGroups?.length > 0) {
      setSelectedFinished(finishedSurveysGroups[0]);
    }
  }, [finishedSurveysGroups]);

  useEffect(() => {
    const qtSurveys = Object.keys(groupInfo?.surveys || {}).length || 0;
    let finishedPercentLocal = 0;
    if (qtSurveys > 0 && answers) {
      Object.values(answers).forEach((answer) => {
        finishedPercentLocal += answer.finishedPercent;
      });
      finishedPercentLocal = Math.floor(finishedPercentLocal / qtSurveys);
    }
    setFinishedPercent(finishedPercentLocal);
  }, [groupInfo, answers]);

  useEffect(() => {
    const { message, type, autoClose } = toastInfo;
    if (message && type) {
      if (toastId.current !== null) {
        dismissToast(toastId.current);
        toastId.current = null;
      }
      toastId.current = toastId.current === null ? showToast(message, type, autoClose) : toastId.current;
    }
  }, [dispatch, toastInfo]);

  // Aguarda save de cada form para apresentar toast
  useEffect(() => {
    const toastInfo = getToastInfo(
      'survey',
      answersSaveStatus === loadStatusValues.LOADING,
      answersSaveStatus === loadStatusValues.LOADED_ERROR,
      answersSaveStatus === loadStatusValues.LOADED_SUCCESS,
    );
    if (toastInfo) {
      setToastInfo(toastInfo);
    }
  }, [answersSaveStatus, loadStatusValues]);

  useEffect(() => {
    const toastInfo = getToastInfo(
      'group',
      groupSaveStatus === loadStatusValues.LOADING,
      groupSaveStatus === loadStatusValues.LOADED_ERROR,
      groupSaveStatus === loadStatusValues.LOADED_SUCCESS,
    );
    if (toastInfo) {
      setToastInfo(toastInfo);
    }
  }, [groupSaveStatus, loadStatusValues]);

  useEffect(() => {
    const toastInfo = getToastInfo(
      'startUpdate',
      groupCloneStatus === loadStatusValues.LOADING,
      groupCloneStatus === loadStatusValues.LOADED_ERROR,
      groupCloneStatus === loadStatusValues.LOADED_SUCCESS,
    );
    if (toastInfo) {
      setToastInfo(toastInfo);
    }
  }, [groupCloneStatus, loadStatusValues]);

  // Configura timeout para salvamento automático
  useEffect(() => {
    let automaticSaveMessage = null;
    if (!finishedStatus) {
      automaticSaveMessage = setTimeout(() => {
        showAutomaticSavingToast(formSettings.automaticSaveTimeMS);
      }, formSettings.toastAutomaticSaveDuration);
    }
    return () => {
      if (automaticSaveMessage) {
        clearTimeout(automaticSaveMessage);
      }
    };
  }, [finishedStatus]);

  const modalMessage = useMemo(() => {
    if (groupSaveNotification) {
      if (finishedStatus) {
        return '<strong>Obrigado</strong><br>Você finalizou o preenchimento das informações sobre sua cidade.<br>Aguarde por volta de 30 minutos para o cálculo e a publicação do diagnóstico do nível de maturidade.';
      } else {
        return 'Existem erros no preenchimento das informações que impedem a finalização.<br />Por favor, verifique os erros listados abaixo.';
      }
    }
    return null;
  }, [groupSaveNotification, finishedStatus]);

  const btnFinishDisabled = useMemo(() => {
    return !(!finishedStatus && groupSaveStatus !== loadStatusValues.LOADING);
  }, [finishedStatus, groupSaveStatus, loadStatusValues.LOADING]);

  const triggerFinishGroup = useRef(
    debounce(() => {
      setShowModalFinish(false);
      dispatch(CitySurveyActions.saveGroup());
    }, 500),
  ).current;

  const handleFinishGroup = () => {
    triggerFinishGroup();
  };

  const handleModelSaveStatusClose = useCallback(() => {
    dispatch(CitySurveyActions.cleanGroupSaveNotification());
  }, [dispatch]);

  const triggerStartUpdate = useRef(
    debounce(() => {
      dispatch(CitySurveyActions.startUpdate());
    }, 500),
  ).current;

  const handleStartUpdate = () => {
    triggerStartUpdate();
  };

  const handleFinishedSelect = (evt, id) => {
    evt.preventDefault();
    const groupCode = groupInfo?.code;
    if (finishedSurveysGroups && groupCode) {
      dispatch(CitySurveyActions.findGroupById(groupCode, id));
      const filteredData = finishedSurveysGroups.filter((item) => item.id === id);
      setSelectedFinished(filteredData.length > 0 ? filteredData[0] : null);
    }
  };

  if (!groupInfo) {
    return <>Carregando informações do município. Aguarde...</>;
  }

  if (!tabsLoaded) {
    return <>Carregando questões. Aguarde...</>;
  } else {
    if (noSurveysAvailable === true) {
      return <>Não há pesquisas ativas para serem preenchidas neste momento.</>;
    }
  }

  return (
    <>
      <div className="bg-gray-superlight px-5 py-4 w-100">
        {/* <!-- Cabeçalho Página - start --> */}
        <header>
          <h3 className="fw-bolder c-cyan mb-0">{groupInfo.title}</h3>
          <p className="path">
            <a href="#">Início</a> &rsaquo; <span className="current">{groupInfo.title}</span>
          </p>
        </header>
        {/* <!-- Cabeçalho Página - end --> */}

        {/* <!-- Status - start --> */}
        <div className="row mb-4">
          <div className="col-12 col-lg-10">
            {
              /* *** STATUS 1: CIDADE JÁ PREENCHEU E FINALIZOU MAIS DE 1 PESQUISA */
              !progressSurveyGroup && finishedSurveysGroups.length > 1 && (
                <Dropdown as="span" id="dropdown-surveys-finished">
                  <Dropdown.Toggle as="span" className="status bg-white me-2" size="sm">
                    <i className="fa-solid fa-calendar-check"></i> Preenchimento finalizado em{' '}
                    <strong>
                      {selectedFinished ? formatDateTimeSimple(selectedFinished.lastUpdate, getLocaleCode()) : '-'}
                    </strong>
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="start" className="shadow">
                    <Dropdown.Item disabled>Versões anteriores</Dropdown.Item>
                    <Dropdown.Divider />
                    {finishedSurveysGroups.map((item) => {
                      return (
                        <Dropdown.Item
                          key={`finished-survey-${item.id}`}
                          data-sgc-id={item?.id || '-'}
                          active={item.id === selectedFinished?.id}
                          onClick={(evt) => handleFinishedSelect(evt, item.id)}
                        >
                          <i className="fa-solid fa-calendar-check"></i>&nbsp;{formatDateTimeSimple(item.lastUpdate)}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              )
            }
            {
              /* *** STATUS 2: CIDADE JÁ PREENCHEU 1 PESQUISA OU ESTÁ EM PREENCHUMENTO */
              !(!progressSurveyGroup && finishedSurveysGroups.length > 1) && (
                <>
                  {progressSurveyGroup && (
                    <span className="status bg-white me-2">
                      <i className="fa-solid fa-calendar-check"></i> Último preenchimento salvo em{' '}
                      <strong>{formatDateTimeSimple(progressSurveyGroup.lastUpdate, getLocaleCode())}</strong>
                    </span>
                  )}
                  {!progressSurveyGroup && selectedFinished && (
                    <span className="status bg-white me-2">
                      <i className="fa-solid fa-calendar-check"></i> Preenchimento finalizado em{' '}
                      <strong>{formatDateTimeSimple(selectedFinished.lastUpdate, getLocaleCode())}</strong>
                    </span>
                  )}
                  {!progressSurveyGroup && finishedSurveysGroups.length === 0 && (
                    <span className="status me-2">
                      <i className="fa-regular fa-calendar-xmark"></i> Nenhum preenchimento finalizado
                    </span>
                  )}
                </>
              )
            }
            <>
              {groupInfo.status === 2 && (
                <span className="status bg-white me-2">
                  Preenchimento <strong className="text-success fw-bold">finalizado</strong>.
                </span>
              )}
              {groupInfo.status === 1 && (
                <span className="status bg-white me-2">
                  Preenchimento <strong className="text-success fw-bold">em andamento</strong>.
                </span>
              )}
              {groupInfo.status === 0 && (
                <span className="status bg-white me-2">
                  <span className="text-danger fw-bold">Não iniciado.</span> Inicie o preenchimento agora.
                </span>
              )}
            </>
          </div>
          <div className="col-12 col-lg-2 text-end">
            <Dropdown>
              <Dropdown.Toggle
                variant="outline-light"
                className="bg-white rounded-2 c-gray-dark"
                id={`dropdown-print`}
                size="sm"
              >
                <i className="fa-solid fa-print fa-lg"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu align="end" className="shadow">
                <Dropdown.Item className="selectable" onClick={() => printForm()}>
                  Formulário vazio
                </Dropdown.Item>
                <Dropdown.Item className="selectable" onClick={() => printForm(true)}>
                  Respostas preenchidas
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        {/* <!-- Status - end --> */}

        {/* <!-- Progress & Button - start --> */}
        <div className="row mb-4">
          {groupInfo.status === 1 && showWarning && (
            <div className="col-12 offset-lg-1 col-lg-10 mb-4">
              <div className="alert alert-warning fade show" role="alert">
                <div className="d-flex justify-content-center">
                  <h4 className="alert-heading flex-grow-1">Atenção</h4>
                  <button
                    type="button"
                    className="btn-close flex-shrink-1"
                    aria-label="Fechar"
                    onClick={(evt) => setShowWarning(false)}
                  ></button>
                </div>
                <p className="mb-0">
                  Para que o município conheça seu nível de maturidade final é necessário que todas as perguntas estejam
                  respondidas e o preenchimento das informações sobre sua cidade seja finalizado.
                </p>
              </div>
            </div>
          )}
          <div className="col-12 col-lg-8">
            <SurveyCompletionStatus finishedStatus={finishedStatus} finishedPercent={finishedPercent} />
          </div>
          <div className="col-12 col-lg-4 text-end">
            {btnFinishDisabled && !finishedStatus && (
              <button type="button" className="btn btn-success" disabled={btnFinishDisabled}>
                Aguarde...
              </button>
            )}
            {!btnFinishDisabled && !finishedStatus && (
              <button
                type="button"
                className="btn btn-success"
                disabled={finishedPercent < 100}
                onClick={(evt) => setShowModalFinish(true)}
              >
                Finalizar e disponibilizar diagnóstico
              </button>
            )}
            {finishedStatus && (
              <button type="button" className="btn btn-success" onClick={(evt) => handleStartUpdate()}>
                Atualizar informações
              </button>
            )}
          </div>
        </div>
        {/* <!-- Progress & Button - end --> */}

        {/* <!-- Área de CRUD - start --> */}
        {tabs && tabs.length > 0 && <SurveyGroupQuestions tabs={tabs} initialActiveTab={tabs[0]} />}

        <div className="row mt-4 mb-5">
          <div className="col-12 col-lg-8">
            <SurveyCompletionStatus finishedStatus={finishedStatus} finishedPercent={finishedPercent} />
          </div>
          <div className="col-12 col-lg-4 text-end">
            {btnFinishDisabled && !finishedStatus && (
              <button type="button" className="btn btn-success" disabled={btnFinishDisabled}>
                Aguarde... <i className="fa-solid fa-spinner fa-spin"></i>
              </button>
            )}
            {!btnFinishDisabled && !finishedStatus && (
              <button
                type="button"
                className="btn btn-success"
                disabled={finishedPercent < 100}
                onClick={(evt) => setShowModalFinish(true)}
              >
                Finalizar e disponibilizar diagnóstico
              </button>
            )}
            {finishedStatus && (
              <button type="button" className="btn btn-success" onClick={(evt) => handleStartUpdate()}>
                Atualizar informações
              </button>
            )}
          </div>
        </div>
      </div>
      {/* MODAL PARA CONFIRMAÇÃO DE FINALIZAÇÃO DE PREENCHIMENTO */}
      <ModalConfirmSave
        show={showModalFinish}
        onHide={(evt) => setShowModalFinish(false)}
        onConfirmation={(evt) => handleFinishGroup()}
      />
      <ModalGroupSaveStatus
        show={groupSaveNotification}
        isError={!finishedStatus}
        message={modalMessage}
        onHide={(evt) => handleModelSaveStatusClose()}
      />
      <ToastContainer position="bottom-center" />
    </>
  );
};

export default Survey;
