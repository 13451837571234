import api from 'services/api';

const API_BASE_PATH = '/api/admin/users';

const DEFAULT_QUANTITY = 10;

const resendActivationEmail = async (publicId) => {
  return await api.post(`${API_BASE_PATH}/${publicId}/resend-email`);
};

const findAllGovernance = async (page, qt = DEFAULT_QUANTITY, orderArr) => {
  const order = orderArr && orderArr[0].length > 0 ? `${orderArr[0]}|${orderArr[1]}` : ``;
  const params = {
    page,
    qt,
    order,
  };
  return await api.get(`${API_BASE_PATH}/governance`, { params, paramsSerializer: { indexes: null } });
};

const saveUserGovernance = async (userGovernanceObj) => {
  return await api.post(`${API_BASE_PATH}/governance`, userGovernanceObj);
};

const resendGovernanceActivationEmail = async (publicId) => {
  return await api.post(`${API_BASE_PATH}/governance/${publicId}/resend-email`);
};

const disapproveUser = async (publicId, data) => {
  return await api.patch(`${API_BASE_PATH}/${publicId}/disapproval`, data);
};

const approveUser = async (publicId) => {
  return await api.patch(`${API_BASE_PATH}/${publicId}/approval`);
};

const activateUserGovernance = async (userGovernanceObj) => {
  return await api.patch(`${API_BASE_PATH}/governance/activate`, userGovernanceObj);
};

const findAll = async (page, qt = DEFAULT_QUANTITY, orderArr) => {
  const order = orderArr && orderArr[0].length > 0 ? `${orderArr[0]}|${orderArr[1]}` : ``;
  const params = {
    page,
    qt,
    order,
  };
  return await api.get(`${API_BASE_PATH}`, { params, paramsSerializer: { indexes: null } });
};

const countNotifications = async () => {
  return await api.get(`${API_BASE_PATH}/notification-counter`);
};

const countNotificationsCity = async () => {
  return await api.get(`${API_BASE_PATH}/notification-counter-city`);
};

const getUserGovernance = async (publicId) => {
  return await api.get(`${API_BASE_PATH}/governance/${publicId}`);
};

const deleteUserGovernance = async (publicId, deletionObj) => {
  return await api.delete(`${API_BASE_PATH}/governance/${publicId}`, { data: deletionObj });
};

const findMayorByCityId = async (cityId) => {
  return await api.get(`${API_BASE_PATH}/check-mayor`, { params: { cityId } });
};

const findAllRoles = async (page, qt = DEFAULT_QUANTITY, orderArr, status, keyword, role = 'A') => {
  const order = orderArr && orderArr[0].length > 0 ? `${orderArr[0]}|${orderArr[1]}` : ``;
  const params = {
    page,
    qt,
    order,
    status,
    keyword,
    role,
  };
  return await api.get(`${API_BASE_PATH}/all-roles`, { params, paramsSerializer: { indexes: null } });
};

export default {
  resendActivationEmail,
  findAllGovernance,
  saveUserGovernance,
  resendGovernanceActivationEmail,
  disapproveUser,
  approveUser,
  activateUserGovernance,
  findAll,
  countNotifications,
  countNotificationsCity,
  getUserGovernance,
  deleteUserGovernance,
  findMayorByCityId,
  findAllRoles,
};
