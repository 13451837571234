import React, { useEffect, useState } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';

const ScoreChart = ({ score, chartId, maxScore = 7 }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    am4core.options.queue = false;
    const levelColor = {
      1: '#A00000',
      2: '#E23A23',
      3: '#E27400',
      4: '#3E8ED0',
      5: '#1C4F9C',
      6: '#3F9D00',
      7: '#375623',
      default: '#000000',
    };

    const getColorForLevel = (level) => {
      return levelColor[level] || levelColor.default;
    };

    function applyCustomTheme(target) {
      if (target instanceof am4core.ColorSet) {
        target.list = [am4core.color(getColorForLevel(score)), am4core.color('#CCCCCC')];
      }
    }

    function configurePieChart(chart) {
      chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
      chart.data = [{ value: score }, { value: maxScore - score }];
      chart.radius = am4core.percent(100);
      chart.innerRadius = am4core.percent(75);
      chart.startAngle = 135; // "Rotaciona o gráfico - esquerda"
      chart.endAngle = 405; // "Rotaciona o gráfico - direita"
      // chart.logo.disabled = true;
      chart.logo.dx = -40;
      chart.logo.dy = 40;
      chart.dy = -20;
      chart.events.off('hit');
    }

    function createChartSeries(chart) {
      const series = chart.series.push(new am4charts.PieSeries());
      series.dataFields.value = 'value';

      series.hiddenState.properties.startAngle = 90;
      series.hiddenState.properties.endAngle = 90;

      series.ticks.template.hide();
      series.labels.template.hide();
      series.tooltip.disabled = true;

      const slice = series.slices.template;
      slice.states.getKey('hover').properties.scale = 1;
      slice.states.getKey('active').properties.shiftRadius = 0;
      return series;
    }

    function createChartLabel(series, score) {
      const labelScore = series.createChild(am4core.Label);
      labelScore.text = score;
      labelScore.horizontalCenter = 'middle';
      labelScore.verticalCenter = 'middle';
      labelScore.fontSize = 35;
      labelScore.fontWeight = 'bold';
      labelScore.dy = 45;
    }

    am4core.useTheme(am4themesAnimated);
    am4core.useTheme(applyCustomTheme);

    const chart = am4core.create(chartId, am4charts.PieChart);
    chart.events.on('ready', () => {
      configurePieChart(chart);
      const series = createChartSeries(chart);
      createChartLabel(series, score);
      setIsLoading(false);
      am4core.unuseTheme(applyCustomTheme);
    });

    return () => chart?.dispose();
  }, [chartId, score, maxScore]);

  return <div id={chartId} style={{ display: `${isLoading === true ? 'none' : ''}` }}></div>;
};

export default ScoreChart;
