export const getDimensionClassName = (dimensionCodeOrTitle) => {
  switch (dimensionCodeOrTitle) {
    case 'Econômico':
    case 'd2':
      return 'econ';
    case 'Meio Ambiente':
    case 'd3':
      return 'ambi';
    case 'Social':
    case 'd4':
      return 'socio';
    case 'Capacidades Institucionais':
    case 'd6':
    case 'd9':
      return 'capac';
    default:
      return '';
  }
};

export const getDimensionImageName = (code) => {
  switch (code) {
    case 'd2':
      return 'i_econ.png';
    case 'd3':
      return 'i_socio.png';
    case 'd4':
      return 'i_ambi.png';
    case 'd6':
    case 'd9':
      return 'i_capac.png';
  }
};

/**
 * Normaliza e mapeia ícone a ser utilizado.
 *
 * ATENÇÃO: este método visa resolver, temporariamente, o problema de
 * a dimensão "Sociocultural" possuir duas referências ao nome do ícone: soc e socio.
 *
 * Remover e tratar adequadamente este cenário para que apenas um nome de ícone seja utilizado.
 *
 * @param {*} dimensionCode o código da dimensão
 * @param {*} defaultIconName o ícone padrão que vem do backend
 * @returns
 */
export const getDimensionContainerImg = (dimensionCode, defaultIconName) => {
  switch(dimensionCode) {
    // 'd4' é a dimensão "Sociocultural (v1.2.0)"
    case 'd4':
      return 'socio';
    default:
      return defaultIconName;
  }
}
