/**
 * Normaliza o JSON removendo a entrada "dm" cujos valores são idênticos a "m" (níveis de maturidade)
 * @param {*} labels
 */
export const deleteDuplicateLabelKeys = (labels) => {
  delete labels.dm1;
  delete labels.dm2;
  delete labels.dm3;
  delete labels.dm4;
  delete labels.dm5;
  delete labels.dm6;
  delete labels.dm7;
};

/**
 * Cria os filtros dos dados a partir das labels retornadas. <br />
 * Os filtros são: por estado, por zona de influência, por habitantes e por região.
 * @param {*} labels
 * @returns
 */
export const setupFiltersFromLabels = (labels) => {
  const filters = {
    est: [], // Filtro por estado
    arj: [], // Filtro por zona de influência
    pop: [], // Filtro por habitantes
    reg: [], // Filtro por região
  };

  Object.entries(labels).forEach(([k, v]) => {
    const filterToSearch = k.substring(0, 3);
    if (filters[filterToSearch]) {
      filters[filterToSearch] = [...filters[filterToSearch], { label: v.title, value: k }];
      filters[filterToSearch] = filters[filterToSearch].sort((a, b) => {
        if (a.value > b.value) return 1;
        if (a.value < b.value) return -1;
        return 0;
      });
    }
  });

  return filters;
};

/**
 * Formata dimensões, tópicos, indicadores e níveis de maturidade.
 *
 * @param {*} labels
 * @returns
 */
export const setupDataFromLabels = (labels) => {
  deleteDuplicateLabelKeys(labels);
  const dimensions = [];
  const topics = [];
  const indicators = [];
  const maturityLevels = [];

  const data = {
    dimensions,
    topics,
    indicators,
    maturityLevels,
    filters: [],
  };

  Object.entries(labels).forEach(([key, value]) => {
    const mappers = {
      d: 'dimensions',
      t: 'topics',
      i: 'indicators',
      m: 'maturityLevels',
    };

    const keyToSearch = mappers[key.substring(0, 1)];
    if (keyToSearch) {
      const keyName = mappers[keyToSearch.substring(0, 1)];
      data[keyName] = [...data[keyName], { ...value, code: key }];
    }
  });

  return data;
};

export const getFiltersFromLabels = (labelsObj) => {
  const data = {
    est: [],
    reg: [],
    pop: [],
    arj: [],
  };

  if (labelsObj) {
    Object.entries(labelsObj).forEach(([key, value]) => {
      const keyPrefix = key.substring(0, 3);
      if (data[keyPrefix]) {
        data[keyPrefix] = [...data[keyPrefix], { ...value, code: key }];
      }
    });
  }
 return {
   est: data.est.sort((a, b) => (a.title < b.title ? -1 : 1)),
   reg: data.reg.sort((a, b) => (a.title < b.title ? -1 : 1)),
   pop: data.pop.sort((a, b) => (a.value < b.value ? -1 : 1)),
   arj: data.arj.sort((a, b) => (a.value < b.value ? -1 : 1)),
 };
};

export const formatGroupValue = (groupValue) => {
  const objGroupValue = groupValue?.map((gv) => {
    return {
      ...gv,
      value: gv?.value?.trim(),
    };
  });
  return objGroupValue;
};

/**
 * Complementa e formata informações das labels.
 *
 * @param {*} groupValue  O "group-value" recuperado da API
 * @param {*} valuesObj   Os dados recém-montados (filtros, dimensões, tópicos, indicadores e níveis de maturidade)
 * @returns
 */
export const formatFromGroupValueWithValuesObj = (groupValue, valuesObj) => {
  const objGroupValue = formatGroupValue(groupValue)?.filter((gv) => gv.type === 1)[0];
  const formattedValuesObj = { ...valuesObj };

  // Pega os indicadores e adiciona informações extras (ex.: tópicos e dimensões pais)
  formattedValuesObj.indicators.forEach((i) => {
    const filteredIndicator = objGroupValue.indicators.filter((indicator) => indicator.code === i.code);
    if (filteredIndicator.length > 0) {
      i.topicCode = filteredIndicator[0]?.topicCode;
      i.dimensionCode = filteredIndicator[0]?.dimensionCode;
    }
  });

  // Pega todos os tópicos e adiciona o código das dimensões
  formattedValuesObj.topics.forEach((t) => {
    const filteredIndicator = objGroupValue.indicators.filter((indicator) => indicator.topicCode === t.code);
    if (filteredIndicator && filteredIndicator.length > 0) {
      t.dimensionCode = filteredIndicator[0]?.dimensionCode;
    }
  });

  formattedValuesObj.topics = valuesObj.topics.filter((t) => t.dimensionCode);
  formattedValuesObj.dimensions = valuesObj.dimensions
    .filter((d) => d.iconName && formattedValuesObj.topics.some((t) => t.dimensionCode === d.code))
    .sort((a, b) => {
      if (a.code > b.code) return 1;
      if (a.code < b.code) return -1;
      return 0;
    });

  return formattedValuesObj;
};
