const govbrClientId = window.ENV.GOVBR_CLIENT_ID;
const govbrAuthEndpoint = window.ENV.GOVBR_AUTH_ENDPOINT;
const govbrLogoutEndpoint = window.ENV.GOVBR_LOGOUT_ENDPOINT;

const govbrLoginRedirectUri = '' + window.location.origin + '/oauth2-callback/govbr';
const govbrLogoutRedirectUri = '' + window.location.origin + '/oauth2-logout/govbr';

export const loginUrl = `${govbrAuthEndpoint}?client_id=${govbrClientId}&scope=openid email phone profile&response_type=code&redirect_uri=${govbrLoginRedirectUri}`;

export const logoutUrl = `${govbrLogoutEndpoint}?post_logout_redirect_uri=${govbrLogoutRedirectUri}`;
