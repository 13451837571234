import React, { useEffect, useState } from 'react';

const Pagination = ({ pageInfo, onPageChange }) => {
  const [pageArray, setPageArray] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(1);
  const [totalElements, setTotalElements] = useState(1);

  useEffect(() => {
    const MAX_PAGE_ARRAY_LENGTH = totalPages <= 4 ? totalPages : 4;
    const MAX_DIFFERENCE = 2;

    if (pageInfo) {
      setTotalPages(pageInfo?.totalPages);
      setTotalElements(pageInfo?.totalElements);
      setPageSize(pageInfo?.pageSize);
      setPageNumber(pageInfo?.pageNumber);
    }

    if (MAX_PAGE_ARRAY_LENGTH - MAX_DIFFERENCE >= pageNumber) {
      let pageArrayToSet = [];
      const offset = totalPages < MAX_PAGE_ARRAY_LENGTH ? totalPages : MAX_PAGE_ARRAY_LENGTH;
      for (let i = MAX_DIFFERENCE - 1; i <= offset; i++) {
        pageArrayToSet = [...pageArrayToSet, i];
      }
      setPageArray(pageArrayToSet.sort((a, b) => a - b));
      return;
    }

    if (totalPages - MAX_DIFFERENCE <= pageNumber) {
      let pageArrayToSet = [];
      for (let i = totalPages + 1 - MAX_PAGE_ARRAY_LENGTH; i <= totalPages; i++) {
        pageArrayToSet = [...pageArrayToSet, i];
      }
      setPageArray(pageArrayToSet);
      return;
    }

    let pageArrayToSet = [];
    for (let i = pageNumber - 1; i <= pageNumber + MAX_DIFFERENCE; i++) {
      pageArrayToSet = [...pageArrayToSet, i];
    }
    setPageArray(pageArrayToSet.sort((a, b) => a - b));
  }, [pageInfo, totalPages, pageNumber]);

  return (
    <div className="d-flex justify-content-between pt-5 mt-5">
      {totalElements > 0 && (
        <>
          <div>
            Mostrando{' '}
            <strong>
              {(pageNumber - 1) * pageSize + 1} -{' '}
              {pageNumber * pageSize < totalElements ? pageNumber * pageSize : totalElements}
            </strong>{' '}
            de <strong>{totalElements}</strong>
          </div>

          <div className="d-flex justify-content-between">
            <div className="me-4">
              <a href="#" className="c-gray" onClick={() => onPageChange('prev')}>
                <i className="fa-solid fa-chevron-left"></i>
              </a>
            </div>
            <div>
              {pageArray.map((number) =>
                number === pageNumber ? (
                  <span className="fw-bolder mx-2 c-cyan" key={`page-number-${number}`}>
                    {number}
                  </span>
                ) : (
                  <a
                    className="mx-2 c-cyan"
                    key={`page-number-${number}`}
                    href="#"
                    onClick={() => onPageChange(number)}
                  >
                    {number}
                  </a>
                ),
              )}
            </div>
            <div className="ms-4">
              <a href="#" className="c-gray" onClick={() => onPageChange('next')}>
                <i className="fa-solid fa-chevron-right"></i>
              </a>
            </div>
          </div>
        </>
      )}
      {totalElements === 0 && <p>Nenhuma notícia foi encontrada. </p>}
    </div>
  );
};

export default Pagination;
