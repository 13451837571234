import React from 'react';
import formSettings from 'components/survey/formSettings';
import MatrixGroup from './MatrixGroup';
import Options from './Options';

const QuestionsPrint = ({ questions, answers, showAnswers = false, isChild = false, parentPosition }) => {
  const questionsData = Object.entries(questions).map(([code, question], index) => {
    switch (question.type) {
      case formSettings.questionTypes.matrix:
        return (
          <MatrixGroup
            key={code}
            question={question}
            answers={answers}
            position={index + 1}
            isChild={isChild}
            parentPosition={parentPosition}
            showAnswers={showAnswers}
          />
        );
      case formSettings.questionTypes.orderOption:
      case formSettings.questionTypes.multiOption:
      case formSettings.questionTypes.singleOption:
        return (
          <Options
            type={question.type}
            key={code}
            question={question}
            answers={answers}
            position={index + 1}
            isChild={isChild}
            parentPosition={parentPosition}
            showAnswers={showAnswers}
          />
        );
      default:
        return (
          <>
            <p>TODO</p>
            <hr />
          </>
        );
    }
  });
  return questionsData;
};

export default QuestionsPrint;
