import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Actions as SearchCityActions } from 'services/citySearch';
import { Creators as CitiesListActions } from 'services/ducks/citiesList';
import AutoComplete from 'components/AutoComplete';

const InputSearchCity = ({ WrapperComponent = 'div', id, name, className, placeholder }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const citiesSuggestions = useSelector((state) => state.citiesList.searchResult);
  const cityData = useSelector((state) => state.citySearch.cityData);
  const [city, setCity] = useState('');

  useEffect(() => {
    dispatch(CitiesListActions.resetCitiesList());
    return () => dispatch(SearchCityActions.resetSearch());
  }, [dispatch]);

  const handleSubmit = (cityId) => {
    const friendlyName = citiesSuggestions.filter((city) => city.id === cityId)[0].friendlyName;
    dispatch(SearchCityActions.searchCityInformation(friendlyName));
    navigate({ pathname: `/municipios/${friendlyName}`, info: cityData });
  };

  const selectCity = (evt) => {
    const val = evt.target.value;
    let cityIdFilter = {
      id: '',
    };
    if (citiesSuggestions.map((city) => city.result).includes(val)) {
      cityIdFilter = { id: citiesSuggestions.filter((city) => city.result === val)[0].id };
    }

    handleCityChange(evt, cityIdFilter);
    dispatch(CitiesListActions.resetCitiesList());
  };

  const handleCityChange = (evt, cityId) => {
    const { value } = evt.target;
    setCity(value);
    if (cityId && cityId.id !== '') {
      handleSubmit(cityId.id);
    }
  };

  const updateSuggestions = (input) => {
    dispatch(CitiesListActions.searchCity(input));
  };

  return (
    <AutoComplete
      WrapperComponent={WrapperComponent}
      inputClassName="form-control"
      className={className}
      placeholder={placeholder}
      id={id}
      name={name}
      value={city}
      suggestions={citiesSuggestions}
      onChange={selectCity}
      onUpdate={updateSuggestions}
    />
  );
};

export default InputSearchCity;
