import React, { useEffect, useState } from 'react';

const OptionComplement = (props) => {
  const optCode = props.code;
  const [complement, setComplement] = useState('');

  useEffect(() => {
    const newValue = props.value ? props.value : '';
    setComplement(newValue);
  }, [props.value]);

  const handleChange = (evt) => {
    props.onComplementChange(optCode, evt.target.value);
  };

  return (
    props.show && (
      <>
        <input
          type="text"
          id={optCode + '-complement'}
          placeholder={props.label}
          value={props.value ? props.value : ''}
          onChange={handleChange}
          // isError={(complement || complement.length > 0) === false}
        />
        {(complement || complement.length > 0) === false && (
          <p className="text-danger">Você deve preencher este campo.</p>
        )}
      </>
    )
  );
};

export default OptionComplement;
