import React, { useEffect, useState } from 'react';
import CrudFieldInput from 'components/CrudFieldInput';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetIndicatorByIdQuery, useSaveIndicatorMutation } from 'services/query/apiService';
import ModalIndicatorNotes from 'components/ModalIndicatorsNotes';
import { showToast } from 'components/Toast';
import { IndicatorFieldHelper } from 'components/dashboard/IndicatorUtils';

const codificacaoArquivo = ['UTF-8', 'ISO-8859-1', 'UTF-16LE'];

const tipoArquivo = [
  { value: 'text', label: 'Texto' },
  { value: 'number', label: 'Número' },
  { value: 'long', label: 'Número longo' },
  { value: 'boolean', label: 'Sim/Não' },
  { value: 'decimal', label: 'Decimal' },
];

const meses = [
  { value: '0', label: 'Anual' },
  { value: '1', label: 'Janeiro' },
  { value: '2', label: 'Feveiro' },
  { value: '3', label: 'Março' },
  { value: '4', label: 'Abril' },
  { value: '5', label: 'Maio' },
  { value: '6', label: 'Junho' },
  { value: '7', label: 'Julho' },
  { value: '8', label: 'Agosto' },
  { value: '9', label: 'Setembro' },
  { value: '10', label: 'Outubro' },
  { value: '11', label: 'Novembro' },
  { value: '12', label: 'Dezembro' },
];

const optionsSize = 3;

const Form = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  // recebe valores do <Link>
  const { state } = useLocation();

  const { data: indicator } = useGetIndicatorByIdQuery({ indicatorId: id });

  const [saveIndicator, { isSuccess, isError, isLoading }] = useSaveIndicatorMutation();

  const [showModal, setShowModal] = useState(false);
  const [modalInfo, setModalInfo] = useState();

  const [data, setData] = useState({});
  const [fileArrSize, setFileArrSize] = useState(1);
  const [files, setFiles] = useState(Array(fileArrSize).fill(null));

  useEffect(() => {
    if (indicator?.length > 0) {
      setData(indicator[0]);
      const valoresUnicos = new Set();

      indicator[0].fields.forEach((field) => {
        if ('fileIdx' in field) {
          valoresUnicos.add(field.fileIdx);
        }
      });
      setFileArrSize(valoresUnicos.size);
    }
  }, [indicator]);

  const handleFileArrSizeChange = (evt) => {
    if (evt.target.value < files.length) {
      files.splice(-(files.length - evt.target.value));
    }
    setFileArrSize(Number(evt.target.value));
  };

  const handleInputChange = (evt) => {
    const { name, value } = evt.target;
    setData((prevItems) => {
      return { ...prevItems, [name]: value };
    });
  };

  const handleFieldInputChange = (fieldName, evt) => {
    const tempFieldsArray = data?.fields;
    const newValue = tempFieldsArray.map((field) => {
      // console.log(
      //   `handleFieldInputChange: field: ${fieldName}, fieldName: ${evt.target.name}, value: ${evt.target.value}`,
      // );
      if (field.name === fieldName) {
        const newValue = { ...field, [evt.target.name]: evt.target.value };
        // console.log(`newValue: ${JSON.stringify(newValue)}`);
        return newValue;
      }
      return field;
    });
    setData((prevItems) => {
      return { ...prevItems, fields: newValue };
    });
  };

  const handleFileInputChange = (index, file) => {
    setFiles((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = file;
      return newValues;
    });
  };

  const handleShowModal = () => {
    setShowModal((current) => {
      return !current;
    });
  };

  const handleSetModalInfoAndOpenModal = (modalInfo) => {
    setModalInfo(modalInfo);
    handleShowModal();
  };

  const handleSetDataToSend = (evt) => {
    evt.preventDefault();
    const dataToSend = { data, files };
    console.log(dataToSend.data.linesToSkip);
    console.log(typeof dataToSend.data.linesToSkip);
    if (typeof dataToSend.data.linesToSkip === 'string' && dataToSend.data.linesToSkip.includes(',')) {
      let multipleLinesToSkip = dataToSend.data.linesToSkip.split(/\s*,\s*/);
      multipleLinesToSkip = multipleLinesToSkip.map((number) => {
        return parseInt(number);
      });
      dataToSend.data.multipleLinesToSkip = multipleLinesToSkip;
      dataToSend.data.linesToSkip = 0;
    }
    console.log(dataToSend);
    saveIndicator(dataToSend).then((resp) => {
      if (!resp.error) {
        setData({});
      }
    });
  };

  useEffect(() => {
    if (isSuccess) {
      showToast(`Indicador ${id} atualizado com sucesso!`);
      navigate('/portal/indicadores');
    }
  }, [navigate, isSuccess, id]);

  useEffect(() => {
    if (isError) {
      showToast('Erro ao atualizar indicador', 'danger', 5000);
    }
  }, [navigate, isError]);

  return (
    <div className="bg-white shadow crud p-5">
      {showModal && (
        <ModalIndicatorNotes
          handleShowModal={handleShowModal}
          showModal={showModal}
          modalInfo={modalInfo}
          handleChange={handleFieldInputChange}
        />
      )}
      <form>
        <div className="row mb-4">
          <header>
            <h3 className="mb-3">
              <b>Indicador </b>
            </h3>
            <h3 className="fw-bolder c-cyan mb-0" style={{ marginLeft: '15px' }}>
              {id} - {state.value}
            </h3>
          </header>
          <div className="col-lg-6">
            <label className="form-label fw-semibold" htmlFor="fieldYear">
              Ano
            </label>
            <input
              type="text"
              className="form-control"
              id="fieldYear"
              name="year"
              required
              defaultValue={data?.year}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-lg-6">
            <label className="form-label fw-semibold">Mês</label>
            <select className="form-select" name="month" required onChange={handleInputChange} value={data?.month}>
              {meses.map((mes) => (
                <option key={mes.value} value={mes.value}>
                  {mes.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-lg-3">
            <label className="form-label fw-semibold">Codificação arquivo</label>
            <select className="form-select" name="charset" required onChange={handleInputChange} value={data?.charset}>
              {codificacaoArquivo.map((codigo) => (
                <option key={`codigo-${codigo}`} value={codigo}>
                  {codigo}
                </option>
              ))}
            </select>
          </div>
          <div className="col-lg-3">
            <label className="form-label fw-semibold" htmlFor="linesToSkip">
              Linhas para ignorar{' '}
              <IndicatorFieldHelper fieldName="linesToSkip" text="Indicar as linhas que não devem ser importadas" />
            </label>
            <input
              type="text"
              className="form-control"
              id="linesToSkip"
              name="linesToSkip"
              required
              defaultValue={
                data?.linesToSkip || data?.linesToSkip !== 0 ? data?.linesToSkip : data?.multipleLinesToSkip
              }
              onChange={handleInputChange}
            />
          </div>
          <div className="col-lg-3">
            <label className="form-label fw-semibold" htmlFor="lastLine">
              Última linha
              <IndicatorFieldHelper fieldName="lastLine" text="Indicar a última linha a ser utilizada na importação" />
            </label>
            <input
              type="text"
              className="form-control"
              id="lastLine"
              name="lastLine"
              defaultValue={data?.lastLine}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-lg-3">
            <label className="form-label fw-semibold" htmlFor="fieldSeparator">
              Caractere separador{' '}
              <IndicatorFieldHelper
                fieldName="fieldSeparator"
                text="Indicar o caractere usado para separar as informações. Obrigatório para arquivos CSV"
              />
            </label>
            <input
              type="text"
              className="form-control"
              id="fieldSeparator"
              name="fieldSeparator"
              defaultValue={data?.fieldSeparator}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-lg-3">
            <label className="form-label fw-semibold">Quantidade de arquivos</label>
            <select
              className="form-select"
              name="month"
              required
              onChange={handleFileArrSizeChange}
              value={fileArrSize}
            >
              {[...Array(optionsSize)].map((_, index) => (
                <option key={index + 1} value={index + 1}>
                  {index + 1}
                </option>
              ))}
            </select>
          </div>
          <div className="col-lg-9">
            <div className="col mb-4">
              {[...Array(fileArrSize)].map((_, index) => (
                <span key={index}>
                  <label className="form-label fw-semibold">
                    Arquivo {index + 1}
                    <IndicatorFieldHelper
                      fieldName={`file-${index + 1}`}
                      text="Enviar somente arquivos do tipo CSV ou XLSX"
                    />
                  </label>
                  <CrudFieldInput
                    key={index}
                    id="fieldFile"
                    type="file"
                    name="file"
                    placeholder="Arquivo..."
                    required
                    onChange={(evt) => handleFileInputChange(index, evt.target.files[0])}
                    errorMsg={`Insira um arquivo válido. `}
                    accept=".xlsx, .csv"
                  />
                  {data?.previousFileNames?.length > 0 && (
                    <p style={{ fontSize: '15px' }}>
                      Arquivo carregado anteriormente: {data?.previousFileNames[index]}
                    </p>
                  )}
                </span>
              ))}
            </div>
          </div>
        </div>
        <h5>Campos</h5>
        <table className="crud mb-5">
          <colgroup>
            <col style={{ width: '22%' }} />
            <col style={{ width: '16%' }} />
            <col style={{ width: '16%' }} />
            <col style={{ width: '16%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '20%' }} />
          </colgroup>
          <thead>
            <tr>
              <th>
                Nome{' '}
                <IndicatorFieldHelper
                  fieldName="field-name"
                  text="Nome para identificar o dado a ser importado do arquivo"
                />
              </th>
              <th>
                Tipo de dado <IndicatorFieldHelper fieldName="field-data-type" text="Tipo de dado que será importado" />
              </th>
              <th>
                Arquivo <IndicatorFieldHelper fieldName="field-file" text="Arquivo de origem da coluna" />
              </th>
              <th>
                Nome da aba{' '}
                <IndicatorFieldHelper
                  fieldName="field-sheet-name"
                  text="Nome da aba onde o dado está localizado. Obrigatório para arquivos XLSX que tenham mais de uma aba."
                />
              </th>
              <th>
                Posição{' '}
                <IndicatorFieldHelper
                  fieldName="field-file"
                  text="Número da posição (número da coluna) onde a informação está localizada. A contagem deve começar em 0 (zero)"
                />
              </th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {data?.fields?.map((field) => (
              <tr key={`indicator-${field.name}`}>
                <td>
                  <input style={{ width: '90%', height: '30px' }} name="name" disabled defaultValue={field?.name} />
                </td>
                <td>
                  <select
                    style={{ width: '90%', height: '30px' }}
                    name="dataType"
                    disabled
                    required
                    // onChange={(evt) => handleFieldInputChange(field.name, evt)}
                    defaultValue={field?.dataType}
                  >
                    {tipoArquivo.map((tipo) => (
                      <option key={tipo.value} value={tipo.value}>
                        {tipo.label}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <select
                    style={{ width: '90%', height: '30px' }}
                    name="fileIdx"
                    required
                    onChange={(evt) => handleFieldInputChange(field.name, evt)}
                    defaultValue={field?.fileIdx}
                  >
                    {[...Array(optionsSize)].map((_, index) => (
                      <option key={index} value={index}>
                        Arquivo {index + 1}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <input
                    style={{ width: '90%', height: '30px' }}
                    name="sheetName"
                    onChange={(evt) => handleFieldInputChange(field.name, evt)}
                    defaultValue={field?.sheetName}
                  />
                </td>
                <td>
                  <input
                    style={{ width: '90%', height: '30px' }}
                    name="position"
                    defaultValue={field?.position}
                    onChange={(evt) => handleFieldInputChange(field.name, evt)}
                  />
                </td>
                <td>
                  <span className="btn btn-success btn-sm" onClick={() => handleSetModalInfoAndOpenModal(field)}>
                    Adicionar notas
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <hr />
        <div className="text-center mt-5">
          <Link to="/portal/indicadores">
            <button disabled={isLoading} className="btn btn-outline-secondary me-3">
              Voltar
            </button>
          </Link>
          <button disabled={isLoading} className="btn btn-success" onClick={handleSetDataToSend}>
            Enviar
          </button>
          {isLoading && <i className="fa-solid fa-spinner fa-spin fa-lg"></i>}
        </div>
      </form>
    </div>
  );
};

export default Form;
