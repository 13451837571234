import React, { useState, useEffect } from 'react';
import api from 'services/api';

/*
const TermsOfUseText = styled.div`
  height: 350px;
  overflow-y: scroll;
  border: 2px solid ${Colors.LIGHT_GRAY_03};
  border-radius: 12px;
`
*/

const TermsOfUse = ({ showTitle = false, termsOfUse }) => {
  const [localTermsOfUse, setLocalTermsOfUse] = useState(termsOfUse);

  useEffect(() => {
    if (!localTermsOfUse) {
      api.get('/api/public/terms-of-use').then((resp) => {
        setLocalTermsOfUse({ ...resp.data, dateLocale: new Date(resp.data.publicationDate).toLocaleDateString() });
      });
    }
  }, [localTermsOfUse]);

  return (
    <div>
      <div>
        {showTitle && (
          <h1>
            Termos de uso da <strong>Plataforma inteli.gente</strong>
          </h1>
        )}
        <h2>
          Versão: <strong>{localTermsOfUse?.version}</strong>
        </h2>
        <h2>
          Data de publicação:{' '}
          <strong>
            {localTermsOfUse?.dateLocale || new Date(localTermsOfUse?.publicationDate).toLocaleDateString()}
          </strong>
        </h2>
        <div style={{ height: '350px', overflowY: 'scroll', border: '2px solid #8E9199', borderRadius: '27px' }}>
          <div className="p-3" dangerouslySetInnerHTML={{ __html: localTermsOfUse?.value || '' }}></div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfUse;
