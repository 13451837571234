import React, { Fragment } from 'react';
import { replaceLineBreak } from 'helpers/replaceChar';

export const BuildOds = ({ indicatorCode, labels }) => {
  const indicatorLabel = labels[indicatorCode];
  if (indicatorLabel?.ods) {
    return (
      <>
        <p className="mb-1">
          <strong>ODS</strong>
        </p>
        <dl className="inteligente-ods-info">
          {indicatorLabel.ods.map((item) => (
            <Fragment key={`${indicatorCode}-ods${item}-info`}>
              <dt>{item}</dt>
              <dd>{labels[`ods${item}`]?.description || item}</dd>
            </Fragment>
          ))}
        </dl>
      </>
    );
  }
  return <></>;
};

export const BuildIso = ({ indicatorCode, labels }) => {
  const indicatorLabel = labels[indicatorCode];
  if (indicatorLabel?.iso) {
    return (
      <>
        <p className="mb-1">
          <strong>ISO</strong>
        </p>
        <dl className="inteligente-iso-info">
          {indicatorLabel.iso.map((item) => (
            <Fragment key={`${indicatorCode}-iso${item}-info`}>
              <dt>{item}</dt>
              <dd>{labels[`iso${item}`]?.description || item}</dd>
            </Fragment>
          ))}
        </dl>
      </>
    );
  }
  return <></>;
};

export const VariablesDescription = ({ indicatorCode, labels }) => {
  const indicatorLabel = labels[indicatorCode];
  if (indicatorLabel?.variablesDescription) {
    return (
      <>
        <p>
          <strong>Variáveis de composição do indicador</strong>
        </p>
        <p dangerouslySetInnerHTML={{ __html: replaceLineBreak(indicatorLabel.variablesDescription) }}></p>
      </>
    );
  }
  return <></>;
};
