import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import OAuth2LoginButton from 'components/OAuth2LoginButton';

export const ModalInfo = ({ show, onHide }) => {
  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} size="lg" className="inteligente-modal">
      <Modal.Header closeButton closeLabel="Fechar">
        <Modal.Title>Ajuda para acessar a Área da Prefeitura</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          A plataforma inteli.gente foi integrada ao login único Gov.br, seguindo os normativos do Governo Federal e
          trazendo benefícios ao cidadão.
        </p>
        <p>
          Para o acesso, será necessário realizar um novo cadastro, utilizando sua conta Gov.br. Caso ainda não possua
          uma conta Gov.br, será necessário criá-la.
        </p>
        <p>
          É importante ressaltar que as informações sobre cidades preenchidas anteriormente serão mantidas na
          plataforma, podendo ser consultadas a qualquer momento.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-outline-secondary" onClick={onHide}>
          Fechar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

const CityUserLogin = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModalClick = (evt) => {
    evt.preventDefault();
    setShowModal(true);
  };

  useEffect(() => {
    sessionStorage.removeItem('governanceUserData');
    sessionStorage.removeItem('authType');
  }, []);

  return (
    <>
      {/* <!-- Login - start --> */}
      <div className="login login-city">
        {/* <!-- <div className="container"> --> */}
        <div className="row justify-content-evenly">
          {/* <!-- Coluna Título - start --> */}
          <div className="col-12 col-lg-6 col-xxl-4 d-flex align-items-center">
            <div className="py-5 px-3 my-5 flex-grow-1 w-100">
              <div className="mx-5">
                <h1 className="fw-bolder c-cyan mb-2">
                  <span className="c-gray-light">Área da</span>
                  <br />
                  Prefeitura
                </h1>
                <p>É prefeito ou representante de um município?</p>
              </div>
              <OAuth2LoginButton className="mt-5 d-flex" />
              <p className="mt-5 mx-5 smaller-2 c-cyan">
                Possui cadastro antigo?
                <br />
                <a href="#" onClick={handleShowModalClick}>
                  Clique aqui para maiores informações
                </a>
              </p>
            </div>
          </div>
          {/* <!-- Coluna Título - end --> */}

          {/* <!-- Coluna Form - start --> */}
          <div className="col-12 col-lg-6 col-xxl-4 d-flex align-items-center">
            <div className="py-5 px-3 my-5 text-center flex-grow-1 w-100">
              <h3 className="c-white fw-bold">Ainda não é cadastrado?</h3>
              <OAuth2LoginButton
                className="bg-white-70 shadow rounded p-4 d-flex mt-3"
                buttonTextPrefix="Iniciar com sua conta"
              />
            </div>
          </div>
          {/* <!-- Coluna Form - end --> */}
        </div>
        {/* <!-- </div> --> */}
      </div>
      {/* <!-- Login - end --> */}
      <ModalInfo show={showModal} onHide={() => setShowModal(false)} />
    </>
  );
};

export default CityUserLogin;
