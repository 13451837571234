import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showToast, dismissToast } from 'components/Toast';
import CrudFieldInput from 'components/CrudFieldInput';
import PwdValidators from 'helpers/PwdValidators';
import { Actions as ChangePasswordActions } from 'services/changePassword';
import { Actions as AccountManagementActions } from 'services/accountManagement';

const ValidPassword = ({ validCode, children }) => {
  let className = 'list-group-item';
  let iconClassName = 'fa-solid fa-fw';
  if (validCode === 2) {
    className += ' list-group-item-success';
    iconClassName += ' fa-check';
  } else if (validCode === 1) {
    className += ' list-group-item-danger';
    iconClassName += ' fa-xmark';
  } else {
    iconClassName += ' fa-circle';
  }
  return (
    <li className={className}>
      <i className={iconClassName}></i> {children}
    </li>
  );
};

const ChangePassword = () => {
  const toastId = useRef(null);

  const [pwdData, setPwdData] = useState({});
  const [toastInfo, setToastInfo] = useState({});

  const userData = useSelector((state) => state.accountManagement.userData);
  const actionSuccess = useSelector((state) => state.changePassword.success);
  const actionErrorMsg = useSelector((state) => state.changePassword.errorMsg);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(AccountManagementActions.loadData());
    return () => {
      dispatch(AccountManagementActions.clearData());
    };
  }, [dispatch]);

  useEffect(() => {
    const { message, type, autoClose } = toastInfo;
    if (message && type) {
      if (toastId.current !== null) {
        dismissToast(toastId.current);
        toastId.current = null;
      }
      toastId.current = toastId.current === null ? showToast(message, type, autoClose) : toastId.current;
    }
  }, [dispatch, toastInfo]);

  useEffect(() => {
    if (actionSuccess === true) {
      setToastInfo({ type: 'success', message: 'Senha alterada com sucesso.' });
    } else if (actionSuccess === false && actionErrorMsg) {
      setToastInfo({ type: 'danger', message: 'Erro ao alterar senha. Tente novamente mais tarde.' });
    }
    dispatch(ChangePasswordActions.clearData());
    setPwdData({});
  }, [dispatch, actionSuccess, actionErrorMsg]);

  const {
    isPassword,
    isPasswordLength,
    isPasswordLower,
    isPasswordUpper,
    isPasswordNumber,
    isPasswordSpecial,
    isPasswordConfirmed,
  } = PwdValidators;

  const handlePwdInputChange = (evt) => {
    const { name, value } = evt.target;
    setPwdData((current) => ({ ...current, [name]: value }));
  };

  const btnDisabled = () => {
    return !isPassword(pwdData?.newPassword) || !isPasswordConfirmed(pwdData?.newPassword, pwdData?.current);
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    dispatch(ChangePasswordActions.changePassword(pwdData.current, pwdData.newPassword, pwdData.confirmation));
  };

  return (
    <div className="bg-white shadow crud p-5">
      <h4 className="fw-bolder c-cyan mb-4">Alteração de senha</h4>

      {userData && (
        <>
          <h5>Usuário {userData.name}</h5>
          <form onSubmit={handleSubmit}>
            <div className="row mb-4">
              <div className="col-md-8">
                <CrudFieldInput
                  type="password"
                  id="fieldCurrent"
                  name="current"
                  label="Senha atual"
                  value={pwdData?.current}
                  onChange={handlePwdInputChange}
                  required
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-8">
                <div className="mb-4">
                  <CrudFieldInput
                    type="password"
                    id="fieldNewPassword"
                    name="newPassword"
                    label="Senha nova"
                    value={pwdData?.newPassword}
                    onChange={handlePwdInputChange}
                    isError={isPassword(pwdData?.newPassword) === false}
                    errorMsg="A senha não atende os critérios listados ao lado"
                    required
                  />
                </div>
                <div>
                  <CrudFieldInput
                    type="password"
                    id="fieldConfirmation"
                    name="confirmation"
                    label="Confirmar senha nova"
                    value={pwdData?.confirmation}
                    onChange={handlePwdInputChange}
                    isError={isPasswordConfirmed(pwdData?.newPassword, pwdData?.confirmation) === false}
                    errorMsg="A senha nova e a confirmação não são iguais"
                    required
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="card">
                  <div className="card-header">Critérios para nova senha</div>
                  <ul className="list-group list-group-flush">
                    <ValidPassword validCode={isPasswordLength(pwdData?.newPassword)}>
                      Pelo menos 8 caracteres
                    </ValidPassword>
                    <ValidPassword validCode={isPasswordLower(pwdData?.newPassword)}>Uma letra minúscula</ValidPassword>
                    <ValidPassword validCode={isPasswordUpper(pwdData?.newPassword)}>Uma letra maiúscula</ValidPassword>
                    <ValidPassword validCode={isPasswordNumber(pwdData?.newPassword)}>Um número</ValidPassword>
                    <ValidPassword validCode={isPasswordSpecial(pwdData?.newPassword)}>
                      Um caractere especial
                    </ValidPassword>
                  </ul>
                </div>
              </div>
            </div>
            <div className="my-5 text-center">
              <button type="submit" className="btn btn-success" disabled={btnDisabled}>
                Alterar senha
              </button>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default ChangePassword;
