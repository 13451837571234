import React, { useCallback, lazy, Suspense } from 'react';
import { getLocaleCode } from 'helpers/Utils';

// Lazy precisa de import dinamico
const LazyMapChart = lazy(() => import('components/charts/MapChart'));

function retrieveSelectionLabel(currentSelection, labels) {
  if (currentSelection.type === 2) {
    if (currentSelection.value === 'estDF') {
      return labels[currentSelection.value]?.title;
    }
    return 'Estado - ' + labels[currentSelection.value]?.title;
  } else if (currentSelection.type === 3) {
    return 'Região - ' + labels[currentSelection.value]?.title;
  } else if (currentSelection.type === 4) {
    return 'Brasil - Habitantes';
  } else if (currentSelection.type === 5) {
    return 'Brasil - Rede de influência';
  }
  return currentSelection.value;
}

// Ref: https://www12.senado.leg.br/manualdecomunicacao/estilos/artigo-definido
function retrieveFilterLabel(currentSelection, labels, numCities) {
  const rawValue = currentSelection.value.substring(3);
  if (currentSelection.type === 1) {
    return ' brasileiros';
  } else if (currentSelection.type === 2) {
    switch (rawValue) {
      case 'DF':
        return labels[currentSelection.value]?.title;
      case 'AC':
      case 'AM':
      case 'AP':
      case 'CE':
      case 'ES':
      case 'MA':
      case 'PA':
      case 'PR':
      case 'PI':
      case 'RJ':
      case 'RN':
      case 'RS':
      case 'TO':
        return 'do Estado do ' + labels[currentSelection.value]?.title;
      case 'BA':
      case 'PB':
        return 'do Estado da ' + labels[currentSelection.value]?.title;
      case 'AL':
      case 'GO':
      case 'MG':
      case 'MS':
      case 'MT':
      case 'PE':
      case 'RO':
      case 'RR':
      case 'SC':
      case 'SE':
      case 'SP':
        return 'do Estado de ' + labels[currentSelection.value]?.title;
      default:
        return 'do Estado de ' + labels[currentSelection.value]?.title;
    }
  } else if (currentSelection.type === 3) {
    return 'da Região ' + labels[currentSelection.value]?.title;
  } else if (currentSelection.type === 4) {
    switch (rawValue) {
      case 'ND':
        return `${numCities < 2 ? 'brasileiro' : 'brasileiros'} sem população determinada`;
      default:
        return `${numCities < 2 ? 'brasileiro' : 'brasileiros'} com população ${labels[
          currentSelection.value
        ]?.title?.toLowerCase()} habitantes`;
    }
  } else if (currentSelection.type === 5) {
    return `${numCities < 2 ? 'brasileiro' : 'brasileiros'} do grupo ${labels[currentSelection.value]?.title}`;
  }
  return 'do ' + currentSelection.value;
}

const TextMap = ({ currentValues, currentSelection, labels }) => {
  const textPrefix = 'No Mapa você encontra o nível de maturidade estimado nas bases secundárias para';
  const textSuffix =
    'Se você é representante da gestão municipal, pode iniciar o preenchimento do formulário através da Área da Prefeitura.';
  if (currentValues) {
    if (currentSelection.type === 2 && currentSelection.value === 'estDF') {
      return (
        <p>
          {textPrefix} o {retrieveFilterLabel(currentSelection, labels, currentValues.numCities)}. {textSuffix}
        </p>
      );
    } else if (currentValues.numCities < 2) {
      return (
        <p>
          {textPrefix} o município {retrieveFilterLabel(currentSelection, labels, currentValues.numCities)}.{' '}
          {textSuffix}
        </p>
      );
    }
    return (
      <p>
        {textPrefix} os {currentValues.numCities.toLocaleString(getLocaleCode())} municípios{' '}
        {retrieveFilterLabel(currentSelection, labels, currentValues.numCities)}. {textSuffix}
      </p>
    );
  }
  return <></>;
};

const ResultMap = ({ currentValues, currentSelection, labels }) => {
  // const [mapRenderDelay, setMapRenderDelay] = useState(500);

  const handleMapRenderFinish = useCallback(() => {
    return true;
  }, []);

  return (
    <>
      <div className="c-blue-gray fw-bold">
        <small>Mapa selecionado</small>
      </div>
      <h2 className="c-green fw-bold">{retrieveSelectionLabel(currentSelection, labels)}</h2>
      {(currentSelection.type === 4 || currentSelection.type === 5) && <h3>{labels[currentSelection.value]?.title}</h3>}
      <Suspense
        fallback={
          <div className="container d-flex align-items-center justify-content-center">
            <span>
              <i className="fa-solid fa-spinner fa-spin fa-lg"></i> Carregando mapa...
            </span>
          </div>
        }
      >
        <LazyMapChart
          type={currentSelection.type}
          selectionValue={currentSelection.value}
          lineWidth={1}
          markRadius={2}
          legendFontSize={14}
          mapHeight="600px"
          legendHeight="100px"
          className="mapa-area text-center"
          onRenderFinish={handleMapRenderFinish}
        />
      </Suspense>
      <div className="text-center mt-4 larger-1 px-5">
        <TextMap currentValues={currentValues} currentSelection={currentSelection} labels={labels} />
        <p>
          O nível de maturidade é diagnosticado em 4 dimensões, sendo elas: <strong>Meio Ambiente</strong>,{' '}
          <strong>Sociocultural</strong>, <strong>Econômica</strong> e <strong>Capacidades Institucionais</strong> da
          Gestão Pública Municipal.
        </p>
      </div>
    </>
  );
};

export default ResultMap;
