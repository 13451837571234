import React, { useState, useRef, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Actions as SubscribersActions } from 'slices/admin/subscribers';
import { showToast, dismissToast } from 'components/Toast';
import Pagination from 'components/Pagination';
import { DisapprovalModal } from './modal';

const SubscriberList = () => {
  const dispatch = useDispatch();

  const toastId = useRef(null);

  const adminSubscribers = useSelector((state) => state.adminSubscribers);

  const [modalInfo, setModalInfo] = useState();
  const [toastInfo, setToastInfo] = useState({});
  const [page, setPage] = useState(1);
  const [deleteCompleted, setDeleteCompleted] = useState(false);

  useEffect(() => {
    dispatch(SubscribersActions.loadSubscribers(page));
    setDeleteCompleted(false);
  }, [dispatch, page, deleteCompleted]);

  useEffect(() => {
    if (adminSubscribers?.success === true) {
      setToastInfo({ type: 'success', message: adminSubscribers?.message });
    }
    if (adminSubscribers?.success === false) {
      setToastInfo({ type: 'danger', message: adminSubscribers?.message });
    }
  }, [adminSubscribers?.success, adminSubscribers?.message]);

  useEffect(() => {
    const { message, type, autoClose } = toastInfo;
    if (message && type) {
      if (toastId.current !== null) {
        dismissToast(toastId.current);
        toastId.current = null;
      }
      toastId.current = toastId.current === null ? showToast(message, type, autoClose) : toastId.current;
    }
  }, [dispatch, toastInfo]);

  const handleDeleteUserModal = (id, username) => {
    setModalInfo({
      id,
      title: 'Exclusão',
      message: `Deseja remover a assinatura de '${username}'?`,
      onConfirmation: handleDeleteUserSubscription,
    });
  };

  const handleDeleteUserSubscription = (id, disapprovalObj) => {
    dispatch(SubscribersActions.delete(id, disapprovalObj, () => setDeleteCompleted(true)));
    closeModal();
  };

  const closeModal = () => {
    setModalInfo(null);
  };

  const handlePageChange = (pageDirection) => {
    if (typeof pageDirection === 'number') {
      const pageNumber = pageDirection;
      setPage(pageNumber);
      return;
    }

    const currentPage = page;
    const changePage = {
      prev: () => {
        const newPage = currentPage - 1;
        setPage(newPage > 1 ? newPage : 1);
      },
      next: () => {
        const newPage = currentPage + 1;
        setPage(newPage <= adminSubscribers?.page?.totalPages ? newPage : currentPage);
      },
    };

    changePage[pageDirection]();
  };

  return (
    <>
      <div className="bg-white shadow crud">
        <div className="p-5">
          <table className="crud mb-5">
            <thead>
              <tr>
                <th>Nome</th>
                <th>E-mail</th>
                <th className="text-center">Ações</th>
              </tr>
            </thead>
            <tbody>
              {adminSubscribers.data?.map((item) => (
                <tr key={`newsletter-${item.id}`}>
                  <td>{item.name}</td>
                  <td>{item.email}</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle variant="outline-secondary" id={`dropdown-actions-${item.id}`} size="sm">
                        <i className="fa-solid fa-ellipsis-vertical"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleDeleteUserModal(item?.id, item?.name)}>
                          <i className="fa-solid fa-xmark c-danger"></i> Excluir
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {adminSubscribers?.data?.length > 0 && (
            <Pagination pageInfo={adminSubscribers?.page} onPageChange={handlePageChange} />
          )}
          {adminSubscribers?.data?.length === 0 && <p>Nenhum usuário foi encontrado. </p>}
        </div>
        {/* <!-- Tabela - end --> */}
        <DisapprovalModal show={!!modalInfo} onClose={closeModal} {...modalInfo} />
      </div>
    </>
  );
};

export default SubscriberList;
