import axios from 'axios';
import { getToken, logout } from 'helpers/userAuthUtils';

const api = axios.create({ baseURL: window.ENV.API_URL });
export default api;

export const axiosInterceptorsConfig = {
  setupInterceptors: (store) => {
    api.interceptors.request.use(
      async (config) => {
        const token = getToken();
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error),
    );
    api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response) {
          console.log(`axios intercept response ${error.response.status}}`);
          if (error.response.status === 401) {
            logout();
          } else if (error.response.status === 403) {
            logout();
            window.location.reload();
          }
        }
        return Promise.reject(error);
      },
    );
  },
};

