import { createSlice } from '@reduxjs/toolkit';

const requestControl = {
  loading: null,
  success: null,
  mutating: null,
  mutateSuccess: null,
};

const initialState = {
  ...requestControl,
  message: null,
  data: [],
};

export const adminConfigurationsSlice = createSlice({
  name: 'adminConfigurations',
  initialState,
  reducers: {
    loadingStart: (state) => {
      state.loading = true;
      state.success = null;
    },
    loadingStop: (state) => {
      state.loading = false;
    },
    success: (state, action) => {
      state.message = action.payload?.message || null;
      state.success = true;
      state.data = action.payload?.content || state.data;
    },
    error: (state, action) => {
      state.success = false;
      state.message = action.payload || state.message;
    },
    mutateStart: (state) => {
      state.mutating = true;
      state.mutateSuccess = null;
    },
    mutateSuccess: (state, action) => {
      const { message, content } = action.payload;
      state.message = message || null;
      state.data = content || state.data;
      state.mutateSuccess = true;
    },
    mutateError: (state, action) => {
      state.mutateSuccess = false;
      state.message = action.payload?.message || state.message;
    },
    mutateStop: (state) => {
      state.mutating = false;
    },
    mutateClear: (state) => {
      state.mutating = null;
      state.mutateSuccess = null;
    },
    reset: () => {
      return { ...initialState };
    },
  },
});
