import { createSlice } from '@reduxjs/toolkit';
import PublicService from 'services/api/public/publicService';

const initialState = {
  email: process.env.REACT_APP_DEFAULT_SUPPORT_EMAIL,
  loading: null,
  success: null,
  message: null,
};

const supportInfoSlice = createSlice({
  name: 'supportInfo',
  initialState,
  reducers: {
    loadingStart: (state) => {
      state.loading = true;
    },
    loadingStop: (state) => {
      state.loading = false;
    },
    success: (state, action) => {
      state.success = true;
      state.email = action.payload.email;
    },
    error: (state, action) => {
      const { httpStatus, message } = action.payload;
      state.success = false;
      state.message = `${httpStatus ? httpStatus + ' - ' : ''}${message}`;
    },
    reset: () => {
      return { ...initialState };
    },
  },
});

export const { loadingStart, loadingStop, success, error, reset } = supportInfoSlice.actions;

export const Actions = {
  getSupportEmail: () => {
    return (dispatch) => {
      dispatch(loadingStart());
      PublicService.getSupportEmail()
        .then((resp) => {
          dispatch(
            success({
              email: resp.data?.value,
            }),
          );
        })
        .catch((err) => {
          dispatch(
            error({
              errorStatus: err.response.status,
              errorMsg: 'Erro ao carregar e-mail de suporte.',
            }),
          );
        })
        .finally(() => dispatch(loadingStop()));
    };
  },

  reset: () => {
    return (dispatch) => {
      dispatch(reset());
    };
  },
};

export default supportInfoSlice.reducer;
