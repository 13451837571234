import React from 'react';
import formSettings from 'components/survey/formSettings';
import { getBulletFromIndex } from 'components/survey/Questions/Components';
import { ShowBullet } from './ShowBullet';
import OptionsHints from './OptionsHints';

const MatrixLine = ({ isMulti, question, answer, showAnswers }) => {
  const questBullet = question.code.substring(question.code.lastIndexOf('_') + 1);

  return (
    <>
      <th>
        <b>{questBullet}.</b> {question.description}
        {question.withComplement && (
          <div>
            <span>{question.complementLabel || 'Qual?'}</span>&nbsp;
            <span>
              <i>{answer.text || <>&nbsp;</>}</i>
            </span>
          </div>
        )}
      </th>
      {Object.keys(question.options).map((key, index) => {
        const optCode = key;
        // const optBullet = optCode.substring(optCode.lastIndexOf('_') + 1);
        const optBullet = getBulletFromIndex(index);
        return (
          <td key={optCode}>
            <span id={optCode}>
              <ShowBullet isMulti={isMulti} isSelected={showAnswers && answer.options[index].selected} />
              <span>&nbsp;{optBullet}</span>
            </span>
          </td>
        );
      })}
    </>
  );
};

const MatrixGroup = ({ question, answers, showAnswers = false, position }) => {
  const children = Object.entries(question.children).map(([questionCode, question]) => {
    const answerInfo = answers[questionCode];

    switch (question.type) {
      case formSettings.questionTypes.multiOption:
        return (
          <tr key={questionCode}>
            <MatrixLine isMulti question={question} answer={answerInfo} showAnswers={showAnswers} />
          </tr>
        );
      case formSettings.questionTypes.singleOption:
        return (
          <tr key={questionCode}>
            <MatrixLine question={question} answer={answerInfo} showAnswers={showAnswers} />
          </tr>
        );
      default:
        return null;
    }
  });

  // Recupera options da primeira questão.
  const firstQuestion = Object.values(question.children)[0];

  const childrenOptions = Object.entries(firstQuestion.options).map(([optCode, option]) => {
    const bullet = optCode.substring(optCode.lastIndexOf('_') + 1);
    return (
      <th key={optCode}>
        <b>{bullet}.</b> {option.description}
        {!!option.helper && (
          <>
            &nbsp;
            <i className="fa-solid fa-circle-info"></i>
          </>
        )}
      </th>
    );
  });

  const tHeadStyle = {};
  if (question.code === 'SERV01') {
    tHeadStyle.fontSize = '0.7em';
  }

  return (
    <>
      <article>
        <header>
          <h3>
            <span>{position}.</span> {question.description}
          </h3>
          {!!question.helper && (
            <h5>
              <i className="fa-solid fa-circle-info"></i>
              <i>&nbsp;{question.helper}</i>
            </h5>
          )}
        </header>

        <section>
          <table className="table table-striped">
            <thead style={tHeadStyle}>
              <tr>
                <th>&nbsp;</th>
                {childrenOptions}
              </tr>
            </thead>
            <tbody>{children}</tbody>
          </table>
        </section>
        <OptionsHints options={question.options} />
      </article>
      <hr />
    </>
  );
};

export default MatrixGroup;
