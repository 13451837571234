import React, { useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';

const prepareChartData = (data) => {
  return data.sort((a, b) => (a.code < b.code ? -1 : 1));
};

const RadarChart = ({ chartId, chartData }) => {
  useEffect(() => {
    am4core.options.queue = false;
    const levelColor = {
      1: '#A00000',
      2: '#E23A23',
      3: '#E27400',
      4: '#3E8ED0',
      5: '#1C4F9C',
      6: '#3F9D00',
      7: '#375623',
      default: '#000000',
    };

    function applyCustomTheme(target) {
      if (target instanceof am4core.ColorSet) {
        target.list = [
          am4core.color('rgba(255, 255, 255, 1.0)'),
          am4core.color(levelColor[1]),
          am4core.color(levelColor[2]),
          am4core.color(levelColor[3]),
          am4core.color(levelColor[4]),
          am4core.color(levelColor[5]),
          am4core.color(levelColor[6]),
          am4core.color(levelColor[7]),
          am4core.color(levelColor[1]),
          am4core.color(levelColor[2]),
          am4core.color(levelColor[3]),
          am4core.color(levelColor[4]),
          am4core.color(levelColor[5]),
          am4core.color(levelColor[6]),
          am4core.color(levelColor[7]),
        ];
      }
    }

    function createChart() {
      const chart = am4core.create(chartId, am4charts.RadarChart);
      chart.contentWidth = 200;
      chart.chartContainer.paddingLeft = 10;
      chart.chartContainer.paddingRight = 10;
      chart.seriesContainer.zIndex = -1;
      // chart.logo.disabled = true;

      const chartDataToSort = [...chartData];
      chart.data = prepareChartData(chartDataToSort);
      return chart;
    }

    function createXAxis(chart) {
      // Criação do eixo x
      const xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      xAxis.dataFields.category = 'title';
      xAxis.renderer.fontSize = '0.6rem';
      xAxis.renderer.labels.template.location = 0.5;
      xAxis.renderer.labels.template.wrap = true;
      xAxis.renderer.labels.template.maxWidth = 80;
    }

    function createYAxis(chart) {
      // eixo de valores
      const yAxis = chart.yAxes.push(new am4charts.ValueAxis());
      yAxis.min = 0;
      yAxis.max = 7;
      yAxis.strictMinMax = true;
      yAxis.renderer.minGridDistance = 20;
      yAxis.renderer.labels.template.horizontalCenter = 'middle';
      yAxis.renderer.labels.template.scale = 1.25;

      yAxis.renderer.labels.template.adapter.add('text', function (label, target, key) {
        if (target.dataItem && target.dataItem.values.value.value > 0 && target.dataItem.values.value.value < 8) {
          return '[bold]' + label;
        } else {
          return '';
        }
      });
    }

    function createChartSeries(chart) {
      const series = chart.series.push(new am4charts.RadarColumnSeries());
      series.dataFields.valueY = 'level';
      series.dataFields.categoryX = 'title';
      series.name = 'Tópicos';

      // https://www.amcharts.com/docs/v4/tutorials/managing-width-and-spacing-of-column-series/#Setting_column_width
      series.columns.template.width = am4core.percent(60);
      series.columns.template.opacity = 0.7;
      series.columns.template.strokeWidth = 3;
      series.columns.template.strokeOpacity = 0.5;
      __applyAdapters(series);
      return series;
    }

    function __applyAdapters(series) {
      // preenchimento do indicador
      series.columns.template.adapter.add('fill', function (fill, target, key) {
        if (target.dataItem) {
          return chart.colors.getIndex(+target.dataItem.valueY);
        } else {
          return fill;
        }
      });

      // contorno do indicador
      series.columns.template.adapter.add('stroke', function (fill, target, key) {
        if (target.dataItem) {
          return chart.colors.getIndex(+target.dataItem.valueY + 7);
        } else {
          return fill;
        }
      });
    }

    am4core.useTheme(am4themesAnimated);
    am4core.useTheme(applyCustomTheme);

    let chart = null;
    if (chartData && chartData.length > 0) {
      chart = createChart();
      createXAxis(chart);
      createYAxis(chart);
      createChartSeries(chart);
    }

    am4core.unuseTheme(applyCustomTheme);

    return () => chart?.dispose();
  }, [chartData, chartId]);

  return <div id={chartId} style={{ position: 'relative', width: '100%', minHeight: '400px', margin: 'auto' }}></div>;
};

export default RadarChart;
