import React from 'react';

const FieldTextAreaInput = ({
  label,
  className = 'form-control',
  id = 'textarea-id',
  name,
  rows = 5,
  isError,
  infoMsg,
  errorMsg,
  value,
  onChange,
}) => {
  const updatedId = `${name}-${id}`;
  return (
    <>
      <label className="form-label fw-semibold" htmlFor={updatedId}>
        {label}
      </label>
      <textarea name={name} value={value} id={updatedId} className={className} rows={rows} onChange={onChange} />
      {infoMsg && <p className="form-text mt-2">{infoMsg}</p>}
      {isError && (
        <p className="mt-2 mb-4 small c-danger">
          <i className="fa-solid fa-xmark"></i> {errorMsg}
        </p>
      )}
    </>
  );
};

export default FieldTextAreaInput;
