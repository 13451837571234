import React from 'react';
import { Modal } from 'react-bootstrap';
import { BuildIso, BuildOds, VariablesDescription } from './ModalUtils';

const checkLevelValue = (level, levelValue) => {
  if (levelValue.includes('-')) {
    const indexes = levelValue.split('-');
    const minLevel = +indexes[0];
    const maxLevel = +indexes[1];
    return level >= minLevel && level <= maxLevel;
  }
  return levelValue.includes('' + level);
};

const BuildLevelValues = ({ levelValue }) => {
  const resultJsx = [];
  if (levelValue.includes('-')) {
    const indexes = levelValue.split('-');
    const begin = +indexes[0];
    const end = +indexes[1];

    for (let i = begin; i <= end; i++) {
      resultJsx.push(
        <div key={`level-${i}`} className={`level ${'bg-' + i}`}>
          {i}
        </div>,
      );
    }
  } else {
    resultJsx.push(
      <div key={`level-${levelValue}`} className={`level ${'bg-' + levelValue}`}>
        {levelValue}
      </div>,
    );
  }
  return <>{resultJsx.map((item) => item)}</>;
};

const ModalIndicatorInfo = ({ show, onClose, handleChangeIndicator, labels, indicator }) => {
  return (
    indicator && (
      <Modal
        className="inteligente-modal inteligente-modal-indicator"
        show={show && indicator}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex flex-nowrap align-items-center gap-2 me-5" style={{ height: '80px' }}>
            <div className="flex-shrink-1 justify-content-end">
              <a href="#maturity-diagnosis" onClick={() => handleChangeIndicator('prev')}>
                <img src="/img/arrows_left.png" alt="Anterior" />
              </a>
            </div>
            <div className="flex-grow-1">{labels[indicator.code]?.title || indicator.code}</div>
            <div className="flex-shrink-1 justify-content-start">
              <a href="#maturity-diagnosis" onClick={() => handleChangeIndicator('next')}>
                <img src="/img/arrows_right.png" alt="Próximo" />
              </a>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6 inteligente-modal-indicator-info">
              <p>{labels[indicator.code]?.description || 'Sem descrição'}</p>
              <p>
                <strong>Fonte:</strong> {labels[indicator.code]?.source}
              </p>
              <div className="indicator-details">
                <VariablesDescription indicatorCode={indicator.code} labels={labels} />
                <BuildOds indicatorCode={indicator.code} labels={labels} />
                <BuildIso indicatorCode={indicator.code} labels={labels} />
              </div>
            </div>
            <div className="col-lg-6">
              {labels[indicator.code]?.levelsDescriptions && (
                <table className="table inteligente-indicator-score">
                  {labels[indicator.code]?.levelsDescriptions[0]?.score && (
                    <colgroup>
                      <col style={{ width: '80px', textAlign: 'center' }} />
                      <col style={{ width: 'calc(50% - 40px)' }} />
                      <col style={{ width: 'calc(50% - 40px)' }} />
                    </colgroup>
                  )}
                  {!labels[indicator.code]?.levelsDescriptions[0]?.score && (
                    <colgroup>
                      <col style={{ width: '140px', textAlign: 'center' }} />
                      <col />
                    </colgroup>
                  )}
                  <thead>
                    <tr>
                      <th
                        className="table-dark text-center"
                        colSpan={labels[indicator.code]?.levelsDescriptions[0]?.score ? 4 : 3}
                      >
                        Escala do Índice
                      </th>
                    </tr>
                    {labels[indicator.code]?.levelsDescriptions[0]?.score && (
                      <tr className="table-secondary">
                        <th>Nível</th>
                        <th>Pontuação</th>
                        <th>Escala do nível</th>
                      </tr>
                    )}
                    {!labels[indicator.code]?.levelsDescriptions[0]?.score && (
                      <tr className="table-secondary">
                        <th>Nível</th>
                        <th>Escala do nível</th>
                      </tr>
                    )}
                  </thead>
                  <tbody className="table-group-divider table-light">
                    {labels[indicator.code]?.levelsDescriptions.map((item) => (
                      <tr
                        key={`modal-content-${indicator.code}-${item.level}`}
                        className={`${checkLevelValue(indicator.level, item.level) ? 'current' : ''}`}
                      >
                        <td>
                          <BuildLevelValues levelValue={item.level} />
                        </td>
                        {item.score && <td>{item.score}</td>}
                        <td>{item.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-outline-secondary" onClick={onClose}>
            Fechar
          </button>
        </Modal.Footer>
      </Modal>
    )
  );
};

export default ModalIndicatorInfo;
