import { createSlice } from '@reduxjs/toolkit';
import api from 'services/api';

const initialState = {
  value: 'ND',
};

export const backendVersionSlice = createSlice({
  name: 'backendVersion',
  initialState,
  reducers: {
    appVersion: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { appVersion } = backendVersionSlice.actions;

export const Actions = {
  getAppVersion: () => {
    return (dispatch) => {
      api
        .get('/api/version')
        .then((resp) => {
          dispatch(appVersion(resp.data.version));
        })
        .catch((err) => {
          console.error(err);
        });
    };
  },
};

export default backendVersionSlice.reducer;
