import React from 'react';

const SearchFilters = ({
  labels,
  groupValues: groupData,
  selectedOptions = { opt2: 'reset', opt3: 'reset', opt4: 'reset', opt5: 'reset' },
  handleFilterChange,
}) => {
  return (
    <div className="bg-gray-superlight p-4">
      <div className="mb-3">
        <select
          className="form-select"
          onChange={(evt) => handleFilterChange(2, evt.target.value)}
          value={selectedOptions.opt2}
          disabled={!(groupData && labels)}
        >
          <option value="reset">Estado</option>
          {groupData &&
            labels &&
            groupData.t2.map((item) => (
              <option key={`opt2-${item.filterValue}`} value={item.filterValue}>
                {labels[item.filterValue]?.title || item.filterValue}
              </option>
            ))}
        </select>
      </div>
      <div className="mb-3">
        <select
          className="form-select"
          onChange={(evt) => handleFilterChange(3, evt.target.value)}
          value={selectedOptions.opt3}
          disabled={!(groupData && labels)}
        >
          <option value="reset">Região</option>
          {groupData &&
            labels &&
            groupData.t3.map((item) => (
              <option key={`opt3-${item.filterValue}`} value={item.filterValue}>
                {labels[item.filterValue]?.title || item.filterValue}
              </option>
            ))}
        </select>
      </div>
      <div className="mb-3">
        <select
          className="form-select"
          onChange={(evt) => handleFilterChange(4, evt.target.value)}
          value={selectedOptions.opt4}
          disabled={!(groupData && labels)}
        >
          <option value="reset">Habitantes</option>
          {groupData &&
            labels &&
            groupData.t4.map((item) => (
              <option key={`opt4-${item.filterValue}`} value={item.filterValue}>
                {labels[item.filterValue]?.title || item.filterValue}
              </option>
            ))}
        </select>
      </div>
      <div className="mb-3">
        <select
          className="form-select"
          onChange={(evt) => handleFilterChange(5, evt.target.value)}
          value={selectedOptions.opt5}
          disabled={!(groupData && labels)}
        >
          <option value="reset">Rede de influência</option>
          {groupData &&
            labels &&
            groupData.t5.map((item) => (
              <option key={`opt5-${item.filterValue}`} value={item.filterValue}>
                {labels[item.filterValue]?.title || item.filterValue}
              </option>
            ))}
        </select>
      </div>
    </div>
  );
};

export default SearchFilters;
