import { defaultHttpStatusMessages } from './statusUtils';

export function uncheckBlockOthers(questionOptions, answers) {
  answers.forEach((option) => {
    if (questionOptions[option.code].blockOthers) {
      option.selected = false;
    }
  });
}

// Revisar para contexto do survey
export function handleMessage(httpResponse, msg) {
  const complementMsg = msg ? ' ' + msg : '';
  if (httpResponse && 'status' in httpResponse) {
    if (httpResponse.status === 401) {
      const errorCode = httpResponse.data.code;
      if (errorCode === 11) {
        // Necessita ativar conta via e-mail (msg padrão no backend)
        return httpResponse.data.message;
      } else if (errorCode === 12) {
        // Prefeito precisa aprovação (msg padrão no backend)
        return httpResponse.data.message;
      } else if (errorCode === 22) {
        // Assessor precisa aprovação (msg padrão no backend)
        return httpResponse.data.message;
      }
    }
    return defaultHttpStatusMessages[httpResponse.status] + complementMsg;
  }
  return 'Não foi possível completar a operação.' + complementMsg;
}

const buildQuestionAnswer = function (question) {
  let answer = {};
  if (question !== 0) {
    const options = [];
    for (const optionCode in question.options) {
      options.push({
        code: optionCode,
        selected: false,
        position: null,
        complement: null,
      });
    }
    answer = { ...answer, [question.code]: { changed: false, text: null, options } };
  }
  if (question.children) {
    for (const childrenCode in question.children) {
      const childAnswer = buildQuestionAnswer(question.children[childrenCode]);
      answer = { ...answer, ...childAnswer };
    }
  }
  return answer;
};

export function getDefaultAnswers(survey) {
  let answers = {};
  for (const questionCode in survey.questions) {
    const answer = buildQuestionAnswer(survey.questions[questionCode]);
    answers = { ...answers, ...answer };
  }
  return { status: 0, lastUpdate: null, lastResponsible: null, finishedPercent: 0, answers };
}
