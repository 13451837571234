import React from 'react';
import { Link } from 'react-router-dom';

const AboutProgram = () => {
  return (
    <>
      {/*  Título da Página - start  */}
      <section className="page-title">
        <div className="container">
          <div className="title">A Plataforma</div>
        </div>
      </section>
      {/*  Título da Página - end  */}

      {/*  Texto com Ilustração - start  */}
      <section className="py-5 bg-img bg-img-building">
        <div className="container py-5 my-5">
          <div className="row">
            <div className="col-12 col-lg-5 offset-lg-1 text-justify">
              <p>
                Fundamentado no Decreto nº 9.854, de 25 de junho de 2019, que instituiu o Plano Nacional de Internet das
                Coisas, o Ministério da Ciência, Tecnologia e Inovação (MCTI) lançou em 2019 a Câmara das Cidades 4.0,
                em parceria com o Ministério do Desenvolvimento Regional (MDR - atual Ministério das Cidades - MCID). A
                Câmara das Cidades 4.0 foi criada com objetivo de ser um fórum técnico e colaborativo para ampliar e
                discutir a política pública de cidades inteligentes no Brasil e conta com a colaboração da academia, do
                setor privado, de entidades representativas dos Estados e Municípios e da Sociedade Civil Organizada.
              </p>
              <p>
                Um dos trabalhos desenvolvidos no âmbito da Câmara das Cidades 4.0, e que está ancorado nas diretrizes
                da{' '}
                <a
                  href="https://www.gov.br/mdr/pt-br/assuntos/desenvolvimento-regional/projeto-andus/carta-brasileira-para-cidades-inteligentes"
                  target="_blank"
                  rel="nofollow noreferrer noopener"
                >
                  Carta Brasileira para Cidades Inteligentes <i className="fa-solid fa-up-right-from-square"></i>
                </a>
                , foi a plataforma inteli.gente que diagnostica o nível de maturidade em transformação digital e
                desenvolvimento urbano sustentável das cidades brasileiras.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/*  Texto com Ilustração - end  */}

      {/*  Texto Objetivo - start  */}
      <section className="py-5 bg-success">
        <div className="container py-5 my-5">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-9 text-justify">
              <h1 className="mb-4 fw-bolder">Objetivo da Plataforma</h1>
              <p>
                O objetivo da plataforma é realizar um diagnóstico de maturidade para Cidades Inteligentes e
                Sustentáveis e propor diretrizes e eixos de atuação para elaboração da Política Nacional e da Política
                Municipal para Cidades Inteligentes Sustentáveis. A plataforma inteli.gente visa contribuir com os
                gestores públicos federais, estaduais e municipais, auxiliando-os a identificar as condições atuais das
                cidades, as competências e necessidades para avançar em direção a se tornarem cidades inteligentes
                sustentáveis, em curto, médio e longo prazos. Além disso, o diagnóstico realizado possibilitará que
                outros interessados (setor privado, indústria, instituições púbicas e privadas, academia e cidadãos)
                conheçam melhor a realidade das cidades, para encontrar formas de colaborar com a transformação digital
                e o desenvolvimento urbano sustentável local.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/*  Texto Objetivo - end  */}

      {/*  Texto Instituições - start  */}
      <section className="py-5">
        <div className="container py-5 my-5">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-9 text-justify">
              <h1 className="mb-4 fw-bolder c-cyan">Instituições Parceiras</h1>
              <p>
                Para desenvolvimento da plataforma inteli.gente, na primeira versão, foram estabelecidas parcerias com o
                Centro de Tecnologia da Informação - CTI Renato Archer e com a Rede Nacional de Ensino e Pesquisa - RNP.
                Para a segunda versão, o MCTI, enquanto articulador principal, selecionou a equipe científica do
                IARA-Inteligência Artificial Recriando Ambientes da Universidade de São Paulo - USP para a ampliação e
                atualizações da plataforma inteli.gente.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/*  Texto Instituições - end  */}

      {/*  Texto Câmara de Cidades - start  */}
      <section className="py-5 bg-success">
        <div className="container my-5 py-5">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-6 text-justify">
              <h1 className="fw-bolder mb-4">Câmara de Cidades 4.0</h1>
              <p>
                A Câmara das Cidades 4.0 foi lançada em dezembro de 2019 em cooperação com o Ministério de
                Desenvolvimento Regional - MDR (atual MCID) e o Ministério da Ciência, Tecnologia e Inovação (MCTI). A
                Câmara foi criada com objetivo de ser um fórum técnico e colaborativo para ampliar a discussão sobre
                política pública de cidades inteligentes no Brasil e conta com a colaboração da academia, do setor
                privado, de entidades representativas em nível Federal, Estadual e Municipal e da Sociedade Civil
                Organizada.
              </p>
            </div>
            <div className="col-12 col-lg-4 offset-lg-1 wrap-img">
              <img src="/img/div.png" alt="Ilustração" />
            </div>
          </div>
        </div>
      </section>
      {/*  Texto Câmara de Cidades - end  */}

      {/*  Texto Maturidade - start  */}
      <section className="py-5">
        <div className="container py-5 my-5">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-9 text-justify">
              <h1 className="mb-4 fw-bolder c-cyan text-left">A Plataforma inteli.gente</h1>
              <p>
                A plataforma inteli.gente tem como referência o modelo de Maturidade de Cidade Inteligente Sustentável
                da{' '}
                <a
                  href="https://www.itu.int/rec/T-REC-Y.4904-201912-I/en"
                  target="_blank"
                  rel="nofollow noreferrer noopener"
                >
                  União Internacional de Telecomunicações (SSC-MM ITU)
                  <i className="fa-solid fa-up-right-from-square"></i>
                </a>{' '}
                que estabelece critérios de diagnóstico para determinar o nível de maturidade de um país ou cidade nas
                dimensões econômica, ambiental e sociocultural.
              </p>
              <p>
                A plataforma leva em conta a necessidade de construir condições equilibradas de desenvolvimento
                sustentável a partir da melhoria da realidade das cidades em quatro dimensões: Econômica, Sociocultural,
                Meio Ambiente e Capacidades Institucionais da Gestão Pública Municipal. As dimensões da plataforma
                inteli.gente estão organizadas em dois módulos avaliativos. Um módulo para diagnóstico da cidade em suas
                dimensões Econômica, Sociocultural, Meio Ambiente e um segundo módulo de Capacidades Institucionais. O
                conjunto de indicadores são obtidos tanto em bases públicas oficiais (dados secundários), quanto
                coletados por meio de formulários auto declaratório junto aos gestores públicos municipais (dados
                primários).
              </p>
              <p>
                Cada módulo foi construído considerando as especificidades das informações e indicadores que
                conceitualmente compõem cada nível de maturidade das cidades. A cidade pode ser classificada em
                diferentes níveis de maturidade em cada dimensão. O nível final de maturidade da cidade é atribuído pela
                média aritmética alcançada entre as quatro dimensões. A presente metodologia de avaliação não constitui
                um ranqueamento de cidades, mas oferece como resultado uma medição de um nível de maturidade, que
                orienta o processo de planejamento e construção de políticas públicas para cada perfil de cidade,
                possibilitando a proposição de ações mais aderentes ao contexto local e a troca de experiências entre as
                gestões das cidades.
              </p>
              <p>
                A plataforma inteli.gente está ancorada nos Objetivos do Desenvolvimento Sustentável da Organização das
                Nações Unidas - ONU - e nas diretrizes da Carta Brasileira para Cidades Inteligentes, desenvolvida em
                conjunto com o MDR (atual MCID). Sob esta ótica, as pessoas são as principais beneficiárias do
                desenvolvimento urbano e da transformação digital, de modo que as tecnologias sejam utilizadas como um
                meio para melhorar os serviços e as condições de vida nas cidades.
              </p>
              <div className="mt-4 text-center">
                <Link to="/metodologias" className="btn btn-success">
                  Saiba mais
                </Link>
              </div>
              <div className="mt-5 text-center wrap-img">
                <figure>
                  <img
                    src="/img/art_dimensoes.png"
                    alt="Dimensões da plataforma inteli.gente"
                    title="Dimensões da plataforma inteli.gente"
                  />
                  <figcaption>Dimensões da plataforma inteli.gente</figcaption>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*  Texto Maturidade - end  */}
    </>
  );
};

export default AboutProgram;
