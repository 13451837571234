import React, { Fragment, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Actions as CitySurveyActions } from 'services/citySurvey';
import SurveysPrint from './surveys';
import LogoFooterPrint from 'components/LogoFooterPrint';

const SurveyGroupPrint = () => {
  const { sgcId } = useParams();
  const urlParams = new URLSearchParams(window.location.search);

  const allGroupsInfo = useSelector((state) => state.citySurvey.allGroupsInfo);
  const groupInfo = useSelector((state) => state.citySurvey.groupInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(CitySurveyActions.findAllGroupsAvailable());
    return () => {
      dispatch(CitySurveyActions.reset());
    };
  }, [dispatch]);

  // Carregar uma pesquisa/grupo com os respectivos dos forms resumidos
  // TODO: Fazer tratamento para 2 ou mais pesquisas ativas simultaneamente
  useEffect(() => {
    if (allGroupsInfo) {
      if (allGroupsInfo.length > 0) {
        const currentCode = allGroupsInfo[0].code;
        if (sgcId) {
          dispatch(CitySurveyActions.findGroupById(currentCode, sgcId));
        } else {
          dispatch(CitySurveyActions.findGroupByCode(currentCode));
        }
      }
    }
  }, [dispatch, allGroupsInfo, sgcId]);

  // Prepara as abas com os forms que pertencem a pesquisa/grupo
  const tabs = useMemo(() => {
    if (groupInfo) {
      const tabs = Object.values(groupInfo.surveys).map((survey) => ({
        code: survey.code,
        title: survey.title,
        cityStatus: survey.cityStatus,
      }));
      return tabs;
    }
  }, [groupInfo]);

  return (
    <>
      {!tabs && <p>Aguarde...</p>}
      {tabs && tabs.length > 0 && (
        <>
          <div className="position-fixed inteligente-print-buttons" style={{ right: 0, top: 0 }}>
            <button className="btn btn-secondary survey-print" onClick={() => window.close()} title="Fechar">
              <i className="fa-solid fa-times"></i>
            </button>
            <button className="btn btn-success survey-print" onClick={() => window.print()}>
              <i className="fa-solid fa-print"></i>&nbsp;Abrir janela de impressão
            </button>
          </div>
          <div className="container mt-5 inteligente-survey-print">
            <h1 className="mb-4 text-center">{groupInfo?.title}</h1>
            {tabs.map((option) => (
              <Fragment key={`${option.code}-print`}>
                <SurveysPrint surveyCode={option.code} showAnswers={urlParams.has('answers')} />
                <br />
              </Fragment>
            ))}
          </div>
          <LogoFooterPrint />
        </>
      )}
    </>
  );
};

export default SurveyGroupPrint;
