import React from 'react';

const CrudFieldInputAndButton = ({
  type = 'text',
  name,
  id,
  label,
  placeholder,
  value,
  labelClassName = 'form-label fw-semibold',
  className,
  disabled = false,
  required = false,
  readOnly = false,
  isError = false,
  infoMsg,
  errorMsg,
  maxLength,
  min,
  max,
  onChange,
  onBlur,
  buttonType = 'button',
  buttonClassName = 'btn-outline-secondary',
  onButtonClick,
  buttonLabel,
}) => {
  const updatedClassName = `form-control ${className} ${isError ? 'invalid' : ''}`;
  return (
    <>
      <label className={labelClassName} htmlFor={id}>
        {label}
      </label>
      <div className="input-group mb-3">
        <input
          type={type}
          className={updatedClassName}
          id={id}
          name={name}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          required={required}
          readOnly={readOnly}
          maxLength={maxLength}
          min={min}
          max={max}
          onChange={onChange}
          onBlur={onBlur}
        />
        <button
          type={buttonType}
          className={`btn ${buttonClassName}`}
          onClick={onButtonClick}
          disabled={disabled}
          style={{ minWidth: '80px' }}
        >
          {buttonLabel}
        </button>
      </div>
      {infoMsg && <p className="form-text mt-2">{infoMsg}</p>}
      {isError && (
        <p className="mt-2 mb-4 small c-danger">
          <i className="fa-solid fa-xmark"></i> {errorMsg}
        </p>
      )}
    </>
  );
};

export default CrudFieldInputAndButton;
