import React, { useState } from 'react';
import NewsletterList from './newsletterList';
import SubscriberList from './subscribersList';

const List = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <>
      <div className="bg-white shadow crud">
        <header>
          <div className="tabs w-100 justify-content-start">
            <div className={`tab w-33 ${selectedTab === 0 ? 'active' : ''}`}>
              {/*
              <div className="notification">
                <a href="#notification">1</a>
              </div>
              */}
              <a href="#" onClick={() => setSelectedTab(0)}>
                Boletins
              </a>
            </div>
            <div className={`tab w-33 ${selectedTab === 1 ? 'active' : ''}`}>
              <a href="#" onClick={() => setSelectedTab(1)}>
                Usuários
              </a>
            </div>
          </div>
        </header>
        {selectedTab === 0 ? <NewsletterList /> : <SubscriberList />}
      </div>
    </>
  );
};

export default List;
