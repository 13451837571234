import { createSlice } from '@reduxjs/toolkit';
import PublicService from 'services/api/public/publicService';

const initialState = {
  roles: [],
  loading: null,
  success: null,
  message: null,
};

const contactUsSlice = createSlice({
  name: 'contactUs',
  initialState,
  reducers: {
    loadingStart: (state) => {
      state.loading = true;
    },
    loadingStop: (state) => {
      state.loading = false;
    },
    success: (state, action) => {
      state.success = true;
      state.message = action.payload?.message || state.message;
      state.roles = action.payload?.roles || state.roles;
    },
    error: (state, action) => {
      const { httpStatus, message } = action.payload;
      state.success = false;
      state.message = `${httpStatus ? httpStatus + ' - ' : ''}${message}`;
    },
    reset: () => {
      return { ...initialState };
    },
  },
});

export const { loadingStart, loadingStop, success, error, reset } = contactUsSlice.actions;

export const Actions = {
  getRoles: () => {
    return (dispatch) => {
      dispatch(loadingStart());
      PublicService.getRoles()
        .then((resp) => {
          dispatch(
            success({
              roles: resp.data,
            }),
          );
        })
        .catch((err) => {
          dispatch(
            error({
              errorStatus: err.response.status,
              errorMsg: 'Erro ao carregar papeis de usuário. ',
            }),
          );
        })
        .finally(() => dispatch(loadingStop()));
    };
  },
  sendContact: (contactForm, onSuccess, onError) => {
    return (dispatch) => {
      dispatch(loadingStart());
      PublicService.sendContact(contactForm)
        .then(() => {
          dispatch(
            success({
              message: `Obrigado por falar conosco! Sua mensagem é muito importante. `,
            }),
          );
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch((err) => {
          dispatch(
            error({
              httpStatus: err?.response?.status,
              message: 'Ocorreu um erro ao enviar a mensagem. Por favor, tente novamente.',
            }),
          );
          if (onError) {
            onError();
          }
        })
        .finally(() => dispatch(loadingStop()));
    };
  },
  reset: () => {
    return (dispatch) => {
      dispatch(reset());
    };
  },
};

export default contactUsSlice.reducer;
