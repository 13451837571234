const PwdValidators = {
  isPassword: (password = '') => {
    if (password?.length === 0) return null;
    // Ao menos 8 caracteres contendo, no mínimo, uma letra, um número e um caractere especial
    // return /^(?=.*\d(?=.*[A-z-À-ú])(?=.*[!$*&@# ])[0-9a-zA-Z!$*&@# ]{8,}$/).test(password)
    // return /^(?=.*\d(?=.*[A-Z])(?=.*[!$*&@#_,<>:\|\?\/\~\^\'\`\"\=\+\-\(\)\[\]\. ])[0-9a-zA-ZÀ-ú!$*&@#_,<>:\|\?\/\~\^\'\`\"\=\+\-\(\)\[\]\. ]{1,}$/).test(password)
    const minLength = /.{8,}/.test(password);
    const lowerCase = /[a-z]/.test(password);
    const upperCase = /[A-Z]/.test(password);
    const number = /\d/.test(password);
    const specialChar = /[\W_]/.test(password);
    return minLength && lowerCase && upperCase && number && specialChar;
  },

  isPasswordLength: (password = '') => {
    if (password.length === 0) {
      return 3;
    } else if (/.{8,}/.test(password) === true) {
      return 2;
    } else if (/.{8,}/.test(password) === false) {
      return 1;
    }
  },

  isPasswordLower: (password = '') => {
    if (password.length === 0) {
      return 3;
    } else if (/[a-z]/.test(password) === true) {
      return 2;
    } else if (/[a-z]/.test(password) === false) {
      return 1;
    }
  },

  isPasswordUpper: (password = '') => {
    if (password.length === 0) {
      return 3;
    } else if (/[A-Z]/.test(password) === true) {
      return 2;
    } else if (/[A-Z]/.test(password) === false) {
      return 1;
    }
  },

  isPasswordNumber: (password = '') => {
    if (password.length === 0) {
      return 3;
    } else if (/\d/.test(password) === true) {
      return 2;
    } else if (/\d/.test(password) === false) {
      return 1;
    }
  },

  isPasswordSpecial: (password = '') => {
    if (password.length === 0) {
      return 3;
    } else if (/[\W_]/.test(password) === true) {
      return 2;
    } else if (/[\W_]/.test(password) === false) {
      return 1;
    }
  },

  isPasswordConfirmed: (password, confirmation = '') => {
    if (confirmation.length === 0) return null;
    return password === confirmation;
  },
};

export default PwdValidators;
