import { createSlice } from '@reduxjs/toolkit';
import api from 'services/api';
import { applicationRoles, defaultHttpStatusMessages } from 'helpers/statusUtils';
import { Actions as UserAuthActions } from './userAuth';
import { getRole } from 'helpers/userAuthUtils';

export const defaultErrorCode = 100;

const handleError = (errResp, msg) => {
  const complementMsg = msg ? ' ' + msg : '';
  if (errResp && 'status' in errResp) {
    if (errResp.status === 401) {
      const errorCode = errResp.data.code;
      if (errorCode === 5) {
        // Login admin sem cadastro
        return {
          message: errResp.data.message,
          errorCode,
        };
      } else if (errorCode === 11) {
        // Necessita ativar conta via e-mail (msg padrão no backend)
        return {
          message: errResp.data.message,
          errorCode,
        };
      } else if (errorCode === 12) {
        // Prefeito precisa aprovação (msg padrão no backend)
        return {
          message: errResp.data.message,
          errorCode,
        };
      } else if (errorCode === 22) {
        // Assessor precisa aprovação (msg padrão no backend)
        return {
          message: errResp.data.message,
          errorCode,
        };
      }
    }
    return {
      message: defaultHttpStatusMessages[errResp.status] + complementMsg,
      errorCode: defaultErrorCode,
    };
  }
  return {
    message: 'Não foi possível completar a operação.' + complementMsg,
    errorCode: defaultErrorCode,
  };
};

const initialState = {
  logged: false,
  logging: false,
  provider: '',
  success: null,
  message: '',
  errorCode: 0,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginRequest: (state) => {
      state.logging = true;
      state.provider = 'internal';
      state.success = null;
      state.message = '';
      state.errorCode = 0;
    },
    loginSuccess: (state) => {
      state.logging = false;
      state.logged = true;
      state.success = true;
    },
    loginLinkAccountsRequest: (state) => {
      state.logging = true;
      state.success = null;
      state.message = '';
      state.errorCode = 0;
    },
    loginLinkAccounts: (state) => {
      state.logging = false;
      state.logged = false;
      state.success = true;
      state.message = '';
    },
    loginFailure: (state, action) => {
      state.logging = false;
      state.logged = false;
      state.success = false;
      state.message = action.payload.message;
      state.errorCode = action.payload.errorCode;
    },
    logout: (state) => {
      state.logged = false;
      state.provider = '';
      state.success = null;
      state.message = '';
    },
    logoutError: (state, action) => {
      state.logged = false;
      state.logging = false;
      state.success = null;
      state.message = action.payload.message;
      state.errorCode = action.payload.errorCode;
    },
    oauth2LoginRequest: (state, action) => {
      state.logging = true;
      state.provider = action.payload.provider;
      state.success = null;
      state.message = '';
    },
  },
});

export const {
  loginRequest,
  loginSuccess,
  loginLinkAccountsRequest,
  loginLinkAccounts,
  loginFailure,
  logout,
  logoutError,
  oauth2LoginRequest,
} = authSlice.actions;

export const Actions = {
  oauth2Login: (authCode, provider, codeVerifier, governanceUserId, authType, mctiSessionState, navigatePush) => {
    return (dispatch) => {
      dispatch(oauth2LoginRequest(provider));
      api
        .post('/oauth2-callback/' + provider, {
          code: authCode,
          codeVerifier,
          governanceUserId,
          authType,
          sessionState: mctiSessionState,
        })
        .then((response) => {
          // TODO: Ver como diferenciar as seguintes situações:
          // - Usuário com cadastro completo no sistema fazendo login
          // - Usuário com cadastro no MCTI/Gov.br SEM cadastro completo (prefeitos/assessores)
          // - Usuário com cadastro no MCTI/Gov.br SEM cadastro (gestão politicas publicas)
          // - Definir fluxo quando usuário não tem nenhum cadastro
          dispatch(loginSuccess());
          const userResponse = response.data;

          let userData = {
            id: userResponse.id,
            name: userResponse.name,
            systemRoles: userResponse.roles,
            firstAccess: userResponse.firstAccess,
            termsOfUseInfo: { ...userResponse.termsOfUseInfo },
          };

          let role = '';
          if (userResponse.roles?.length > 0) {
            for (let i = 0; i < userResponse.roles.length; i++) {
              role = userResponse.roles[i];
              if (role.startsWith('CITY')) {
                const roleValues = role.split('_');
                userData = {
                  ...userData,
                  city: userResponse.city,
                  cityFriendlyName: userResponse.cityFriendlyName,
                  state: userResponse.state,
                  role: userResponse.role,
                  cityId: roleValues[1],
                };
              }
            }
          }
          dispatch(UserAuthActions.loadData(userResponse.token, userResponse.roles, userData));

          if (userResponse.roles.includes(applicationRoles.CITY)) {
            if (!userResponse.termsOfUseInfo?.valid) {
              navigatePush('/atualizar-termos-uso');
            } else {
              navigatePush('/prefeitura/dashboard');
            }
          } else if (userResponse.roles.includes(applicationRoles.GOVERNANCE)) {
            if (userResponse.firstAccess) {
              navigatePush('/portal/finalizar-cadastro');
            } else if (!userResponse.termsOfUseInfo?.valid) {
              navigatePush('/atualizar-termos-uso');
            } else {
              navigatePush('/portal/prefeitos');
            }
          } else if (userResponse.roles.includes(applicationRoles.GOVERNANCE_VIEWER)) {
            if (userResponse.firstAccess) {
              navigatePush('/portal/finalizar-cadastro');
            } else if (!userResponse.termsOfUseInfo?.valid) {
              navigatePush('/atualizar-termos-uso');
            } else {
              navigatePush('/portal/dashboard');
            }
          }
        })
        .catch((err) => {
          const httpStatus = err.response?.status;
          const errRespData = err.response?.data;

          if (httpStatus === 401) {
            if (errRespData.token) {
              dispatch(loginLinkAccounts());
              navigatePush('/prefeitura/cadastro', { state: { token: errRespData.token } });
              // 1-IDENTIFICOU E-MAIL/CPF INTERNO
              // 2-NÃO IDENTIFICOU E-MAIL/CPF INTERNO
              // 10-IDENTIFICOU PUBLIC_ID
            } else {
              dispatch(loginFailure(handleError(err.response)));
            }
          }
        })
        .finally(() => {
          sessionStorage.removeItem('governanceUserData');
          sessionStorage.removeItem('authType');
          sessionStorage.removeItem('facebookCodeChallenge');
        });
    };
  },

  login: (username, password, navigatePush) => {
    return (dispatch) => {
      dispatch(loginRequest());
      api
        .post('/signin', { username, password })
        .then((response) => {
          dispatch(loginSuccess());
          const userResponse = response.data;
          let userData = {
            id: userResponse.id,
            name: userResponse.name,
            systemRoles: userResponse.roles,
            termsOfUseInfo: { ...userResponse.termsOfUseInfo },
          };
          let role = '';
          if (userResponse.roles?.length > 0) {
            for (let i = 0; i < userResponse.roles.length; i++) {
              role = userResponse.roles[i];
              if (role.startsWith('CITY')) {
                const roleValues = role.split('_');
                userData = {
                  ...userData,
                  city: userResponse.city,
                  cityFriendlyName: userResponse.cityFriendlyName,
                  state: userResponse.state,
                  role: userResponse.role,
                  cityId: roleValues[1],
                };
              }
            }
          }
          dispatch(UserAuthActions.loadData(userResponse.token, userResponse.roles, userData));

          if (userResponse.roles.includes(applicationRoles.CITY)) {
            navigatePush('/prefeitura/dashboard');
          } else if (userResponse.roles.includes(applicationRoles.GOVERNANCE)) {
            navigatePush('/portal/prefeitos');
          } else if (userResponse.roles.includes(applicationRoles.GOVERNANCE_VIEWER)) {
            navigatePush('/portal/dashboard');
          }
        })
        .catch((err) => {
          dispatch(loginFailure(handleError(err.response)));
        });
    };
  },
  logout: (navigatePush, force) => {
    return (dispatch) => {
      if (force === true) {
        api
          .post('/signout', {})
          .then(() => {
            dispatch(logout());
            dispatch(UserAuthActions.logout());
          })
          .catch((err) => {
            dispatch(logoutError(handleError(err.response)));
          });
      }
      try {
        let path;
        const role = getRole();
        switch (role) {
          case applicationRoles.GOVERNANCE:
          case applicationRoles.GOVERNANCE_VIEWER:
            path = '/portal/login';
            break;
          case applicationRoles.CITY:
            path = '/prefeitura/login';
            break;
          default:
            path = '/';
        }
        console.log(`logout path: ${path}`);
        logout();
        navigatePush(path);
        // path && path !== '/home' ? navigatePush(path + '/login') : navigatePush(path);
        // console.clear()
      } catch (err) {
        console.log(err);
        navigatePush('/');
      }
    };
  },

  linkAccounts: (username, password, authToken, navigatePush) => {
    return (dispatch) => {
      dispatch(loginLinkAccountsRequest());
      api
        .post(
          '/api/users/link-accounts',
          { username, password },
          {
            headers: {
              Authorization: 'Bearer ' + authToken,
            },
          },
        )
        .then((resp) => {
          dispatch(loginSuccess());
          const userResponse = resp.data;

          let userData = {
            name: userResponse.name,
            systemRoles: userResponse.roles,
          };
          let role = '';
          if (userResponse.roles?.length > 0) {
            for (let i = 0; i < userResponse.roles.length; i++) {
              role = userResponse.roles[i];
              if (role.startsWith('CITY')) {
                const roleValues = role.split('_');
                userData = {
                  ...userData,
                  city: userResponse.city,
                  cityFriendlyName: userResponse.cityFriendlyName,
                  state: userResponse.state,
                  role: userResponse.role,
                  cityId: roleValues[1],
                };
              }
            }
          }
          dispatch(UserAuthActions.loadData(userResponse.token, userResponse.roles, userData));

          if (userResponse.roles.includes(applicationRoles.CITY)) {
            if (!userResponse.termsOfUseInfo?.valid) {
              navigatePush('/atualizar-termos-uso');
            } else {
              navigatePush('/prefeitura/dashboard');
            }
          } else if (userResponse.roles.includes(applicationRoles.GOVERNANCE)) {
            if (!userResponse.termsOfUseInfo?.valid) {
              navigatePush('/atualizar-termos-uso');
            } else {
              navigatePush('/portal/prefeitos');
            }
          } else if (userResponse.roles.includes(applicationRoles.GOVERNANCE_VIEWER)) {
            if (!userResponse.termsOfUseInfo?.valid) {
              navigatePush('/atualizar-termos-uso');
            } else {
              navigatePush('/portal/dashboard');
            }
          }
        })
        .catch((err) => {
          dispatch(loginFailure(handleError(err.response)));
        });
    };
  },
};

export default authSlice.reducer;
