import api from 'services/api';
import { defaultHttpStatusMessages } from 'helpers/statusUtils';
import { convertErrorsArray } from 'helpers/Utils';

function handleMessage(httpResponse, msg) {
  const complementMsg = msg ? ' ' + msg : '';
  if (httpResponse && 'status' in httpResponse) {
    if (httpResponse.status === 401) {
      const errorCode = httpResponse.data.code;
      if (errorCode === 11) {
        // Necessita ativar conta via e-mail (msg padrão no backend)
        return httpResponse.data.message;
      } else if (errorCode === 12) {
        // Prefeito precisa aprovação (msg padrão no backend)
        return httpResponse.data.message;
      } else if (errorCode === 22) {
        // Assessor precisa aprovação (msg padrão no backend)
        return httpResponse.data.message;
      }
    }
    return defaultHttpStatusMessages[httpResponse.status] + complementMsg;
  }
  return 'Não foi possível completar a operação.' + complementMsg;
}

// Revisar - não tem mais upload de arquivos
function formatDataOutput(formData, token, idCardImg, voteCardImg) {
  const formDataUpload = new FormData();
  formDataUpload.append('token', token);
  formDataUpload.append('user.name', formData.name);
  formDataUpload.append('user.email', formData.email);
  formDataUpload.append('user.telephoneNumber', formData.telephoneNumber);
  formDataUpload.append('user.password', formData.password);
  formDataUpload.append('user.passwordRepeat', formData.passwordConfirmation);
  formDataUpload.append('user.cityId', formData.cityId);
  formDataUpload.append('user.roleCode', formData.role);
  formDataUpload.append('user.roleComplement', formData.department);
  formDataUpload.append('user.cpf', formData.cpfNumber);
  formDataUpload.append('user.identityCard.number', formData.identityCardNumber);
  formDataUpload.append('user.identityCard.emitter', formData.emitter);
  formDataUpload.append('user.identityCard.emissionDate', formData.emissionDate);
  formDataUpload.append('user.voterCard.number', formData.voteCardNumber);
  formDataUpload.append('user.voterCard.zone', formData.zone);
  formDataUpload.append('user.voterCard.section', formData.section);
  formDataUpload.append('user.voterCard.cityId', formData.role === 'P' ? formData.cityId : formData.voteCityId);
  let imgArrIdx = 0;
  if (idCardImg) {
    if (idCardImg.length > 1) {
      formDataUpload.append(`images[${imgArrIdx}].type`, 1);
      formDataUpload.append(`images[${imgArrIdx}].file`, idCardImg[0]);
      imgArrIdx++;
      formDataUpload.append(`images[${imgArrIdx}].type`, 1);
      formDataUpload.append(`images[${imgArrIdx}].file`, idCardImg[1]);
      imgArrIdx++;
    } else {
      formDataUpload.append(`images[${imgArrIdx}].type`, 1);
      formDataUpload.append(`images[${imgArrIdx}].file`, idCardImg);
      imgArrIdx++;
    }
  }
  if (voteCardImg) {
    if (voteCardImg.length > 1) {
      formDataUpload.append(`images[${imgArrIdx}].type`, 2);
      formDataUpload.append(`images[${imgArrIdx}].file`, voteCardImg[0]);
      imgArrIdx++;
      formDataUpload.append(`images[${imgArrIdx}].type`, 2);
      formDataUpload.append(`images[${imgArrIdx}].file`, voteCardImg[1]);
      imgArrIdx++;
    } else {
      formDataUpload.append(`images[${imgArrIdx}].type`, 2);
      formDataUpload.append(`images[${imgArrIdx}].file`, voteCardImg);
      imgArrIdx++;
    }
  }
  return formDataUpload;
}

export const Types = {
  REGISTRATION_REQUEST: 'cityArea/REGISTRATION_REQUEST',
  REGISTRATION_SUCCESS: 'cityArea/REGISTRATION_SUCCESS',
  REGISTRATION_FAILURE: 'cityArea/REGISTRATION_FAILURE',
  RELOAD_FORM: 'cityArea/RELOAD_FORM',
};

const INITIAL_STATE = {
  loading: false,
  error: {
    status: null,
    tries: 1,
    message: null,
    fieldErrors: {},
  },
  successful: null,
  registrationType: 0,
};

export default function cityUserRegistration(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RELOAD_FORM:
      return { ...state, ...INITIAL_STATE };
    case Types.REGISTRATION_REQUEST:
      return { ...state, loading: true };
    case Types.REGISTRATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: { status: false, tries: 1, message: null, fieldErrors: {} },
        successful: true,
        message: action.message,
        registrationType: action.registrationType,
      };
    case Types.REGISTRATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: {
          status: true,
          tries: state.error.tries < 10 ? state.error.tries + 1 : 1,
          message: action.message,
          fieldErrors: action.fieldErrors,
        },
        successful: false,
      };
    default:
      return state;
  }
}

export const Creators = {
  register: (token, formData, idCardImg, voteCardImg) => {
    return (dispatch) => {
      dispatch({
        type: Types.REGISTRATION_REQUEST,
      });
      // formatDataOutput(formData, token, idCardImg, voteCardImg).forEach((elem) => {
      //   console.log(elem)
      // })
      api
        .post('api/cities/users-upload', formatDataOutput(formData, token, idCardImg, voteCardImg))
        .then((response) => {
          dispatch({
            type: Types.REGISTRATION_SUCCESS,
            message: response.data.message,
            registrationType: response.data.type,
          });
        })
        .catch((err) => {
          const errResp = err.response;
          if (errResp && (errResp.status === 400 || errResp.status === 409)) {
            const errMsg = errResp.data.code === 12 ? errResp.data.message : handleMessage(errResp);
            const fieldErrors = convertErrorsArray(errResp.data.fieldErrors, 'field');
            dispatch({
              type: Types.REGISTRATION_FAILURE,
              message: errMsg,
              fieldErrors,
            });
          } else {
            dispatch({
              type: Types.REGISTRATION_FAILURE,
              message: handleMessage(errResp),
              fieldErrors: {},
            });
          }
        });
    };
  },
  registerFromOpenId: (recaptchaToken, authToken, formData /*, idCardImg, voteCardImg */) => {
    return (dispatch) => {
      dispatch({
        type: Types.REGISTRATION_REQUEST,
      });
      // console.log(authToken)
      // console.log(JSON.stringify(formData))
      api
        .post(
          'api/cities/new-user-link-accounts',
          {
            token: recaptchaToken,
            user: {
              name: formData.name,
              email: formData.email,
              telephoneNumber: formData.telephoneNumber,
              cityId: formData.cityId,
              roleCode: formData.role,
              roleComplement: formData.department,
              termsOfUseAcceptance: !!formData.termsOfUseAcceptance,
            },
          },
          {
            headers: {
              Authorization: 'Bearer ' + authToken,
            },
          },
        )
        .then((response) => {
          dispatch({
            type: Types.REGISTRATION_SUCCESS,
            message: response.data.message,
            registrationType: response.data.type,
          });
        })
        .catch((err) => {
          const errResp = err.response;
          if (errResp && (errResp.status === 400 || errResp.status === 409)) {
            const errMsg = errResp.data.code === 12 ? errResp.data.message : handleMessage(errResp);
            const fieldErrors = convertErrorsArray(errResp.data.fieldErrors, 'field');
            dispatch({
              type: Types.REGISTRATION_FAILURE,
              message: errMsg,
              fieldErrors,
            });
          } else {
            dispatch({
              type: Types.REGISTRATION_FAILURE,
              message: handleMessage(errResp),
              fieldErrors: {},
            });
          }
        });
    };
  },
  reloadForm: () => {
    return (dispatch) => {
      dispatch({
        type: Types.RELOAD_FORM,
      });
    };
  },
};
