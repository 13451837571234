import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Questions from 'components/survey/Questions';
import { Actions as AdminSurveyViewActions } from 'services/adminSurveyView';
import { formatDateTimeSimple } from 'helpers/dateTimeUtils';
import { getLocaleCode } from 'helpers/Utils';

const SurveyQuestionsView = ({ surveyCode, title }) => {
  const groupStatus = useSelector((state) => state.adminSurveyView.groupInfo.status);
  const questions = useSelector((state) => state.adminSurveyView.groupInfo.surveys[surveyCode]?.questions);
  const answers = useSelector((state) => state.adminSurveyView.groupInfo.answers[surveyCode]?.answers);
  const lastResponsible = useSelector((state) => state.adminSurveyView.groupInfo.answers[surveyCode]?.lastResponsible);
  const lastUpdate = useSelector((state) => state.adminSurveyView.groupInfo.answers[surveyCode]?.lastUpdate);
  const errors = useSelector((state) => state.adminSurveyView.groupInfo.errors[surveyCode]);
  const loadStatusValues = useSelector((state) => state.adminSurveyView.loadStatusValues);
  const questionsLoadStatus = useSelector((state) => state.adminSurveyView.questionsLoadStatus);
  const answersLoadStatus = useSelector((state) => state.adminSurveyView.answersLoadStatus);
  const dispatch = useDispatch();

  // Dispara carregamento das questões
  useEffect(() => {
    if (surveyCode) {
      dispatch(AdminSurveyViewActions.loadQuestions(surveyCode));
    }
  }, [dispatch, surveyCode]);

  // Dispara carregamento das respostas
  useEffect(() => {
    if (surveyCode && questions) {
      dispatch(AdminSurveyViewActions.loadAnswers(surveyCode));
    }
  }, [dispatch, questions, surveyCode]);

  const formDisabled = true;

  // Verifica se tem questões e respostas para exibir
  const showQuestions = useMemo(() => {
    return (
      questionsLoadStatus === loadStatusValues.LOADED_SUCCESS &&
      questions &&
      Object.keys(questions).length > 0 &&
      answersLoadStatus === loadStatusValues.LOADED_SUCCESS &&
      answers &&
      Object.keys(answers).length > 0
    );
  }, [questionsLoadStatus, questions, answersLoadStatus, answers, loadStatusValues.LOADED_SUCCESS]);

  return (
    <>
      {/* <!-- Validate & Save - start --> */}

      {/* <!-- Questions - start --> */}
      {showQuestions && (
        <fieldset disabled={formDisabled}>
          <div className="px-5 mx-5" id={`survey-${surveyCode}`}>
            <div className="d-flex justify-content-start">
              {groupStatus === 2 ? (
                <i className="fa-solid fa-check fa-3x c-green me-3"></i>
              ) : (
                <i className="fa-solid fa-pen fa-3x c-green me-3"></i>
              )}
              <div id={surveyCode + '-title'}>
                <h3 className="c-green fw-bold mb-0">{title}</h3>
                {lastUpdate && lastResponsible && (
                  <p>
                    Última atualização: <strong>{formatDateTimeSimple(lastUpdate, getLocaleCode())}</strong> feita por{' '}
                    <strong>{lastResponsible}</strong>
                  </p>
                )}
                {!lastUpdate && <p>Preenchimento não iniciado</p>}
              </div>
            </div>

            <hr className="my-4" />
            <Questions
              surveyCode={surveyCode}
              questions={questions}
              answers={answers}
              errors={errors}
              onOptionChange={() => false}
              onOrderChange={() => false}
              onComplementChange={() => false}
              onTextAnswerChange={() => false}
              groupStatus={groupStatus}
            />
          </div>
        </fieldset>
      )}
      {/* <!-- Questions - end --> */}
    </>
  );
};

export default SurveyQuestionsView;
