import React, { useState, useEffect } from 'react';
import { IndicatorSource, IndicatorValue } from './IndicatorUtils';
import ModalCharacterizationInfo from './ModalCharacterizationInfo';

const Characterization = ({ cityData, labels, className }) => {
  const [allIndicators, setAllIndicators] = useState([]);
  const [showModalIndicator, setShowModalIndicator] = useState(false);
  const [selectedIndicator, setSelectedIndicator] = useState(null);

  const handleShowModalIndicador = (indicatorCode) => {
    const selectedIndicatorIndex = allIndicators.findIndex((ind) => ind.code === indicatorCode);
    if (selectedIndicatorIndex >= 0 && allIndicators.length > 0) {
      setSelectedIndicator(allIndicators[selectedIndicatorIndex]);
      setShowModalIndicator(true);
    }
  };

  useEffect(() => {
    const allIndicators =
      Object.keys(cityData)?.length > 0
        ? [...cityData.characterization, ...cityData.characterizationTransDigi, ...cityData.characterizationInst]
        : [];
    setAllIndicators(allIndicators);
  }, [cityData]);

  return (
    <>
      <div className={className}>
        <h6 className="fw-bold mb-3">Caracterização sociodemográfica</h6>
        {cityData.characterization && (
          <table className="topics-indexes character">
            <colgroup>
              <col style={{ width: '60%' }} />
              <col style={{ width: '40%' }} />
            </colgroup>
            <tbody>
              {cityData.characterization.map((char) => (
                <tr key={`caracterizacao-${char.code}`} onClick={() => handleShowModalIndicador(char.code)}>
                  <td data-icode={char.code}>{labels[char.code]?.title || char.code}</td>
                  <td className="text-end">
                    <IndicatorValue
                      code={char.code}
                      value={char.value}
                      digits={char.digits}
                      unit={labels[char.code]?.valueUnit}
                    />
                    <IndicatorSource code={char.code} text={labels[char.code]?.source || 'Não informada'} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {cityData.characterizationTransDigi && (
          <>
            <hr />
            <h6 className="fw-bold mb-3">Caracterização transformação digital</h6>
            <table className="topics-indexes character">
              <colgroup>
                <col style={{ width: '60%' }} />
                <col style={{ width: '40%' }} />
              </colgroup>
              <tbody>
                {cityData.characterizationTransDigi.map((char) => (
                  <tr key={`caracterizacao-${char.code}`} onClick={() => handleShowModalIndicador(char.code)}>
                    <td data-icode={char.code}>{labels[char.code]?.title || char.code}</td>
                    <td className="text-end">
                      <IndicatorValue
                        code={char.code}
                        value={char.value}
                        digits={char.digits}
                        unit={labels[char.code]?.valueUnit}
                      />
                      <IndicatorSource code={char.code} text={labels[char.code]?.source || 'Não informada'} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
        {cityData.characterizationInst && (
          <>
            <hr />
            <h6 className="fw-bold mb-3">Caracterização institucional</h6>
            <table className="topics-indexes character">
              <colgroup>
                <col style={{ width: '60%' }} />
                <col style={{ width: '40%' }} />
              </colgroup>
              <tbody>
                {cityData.characterizationInst.map((char) => (
                  <tr key={`key-${char.code}`} onClick={() => handleShowModalIndicador(char.code)}>
                    <td data-icode={char.code}>{labels[char.code]?.title || char.code}</td>
                    <td className="text-end">
                      <IndicatorValue
                        code={char.code}
                        value={char.value}
                        digits={char.digits}
                        unit={labels[char.code]?.valueUnit}
                      />
                      <IndicatorSource code={char.code} text={labels[char.code]?.source || 'Não informada'} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
        <p className="mt-5 mb-0 text-end" style={{ fontSize: '0.85rem' }}>
          <em>ND - Não disponível</em>
        </p>
      </div>
      <ModalCharacterizationInfo
        show={showModalIndicator}
        indicator={selectedIndicator}
        labels={labels}
        onClose={() => setShowModalIndicator(false)}
      />
    </>
  );
};

export default Characterization;
