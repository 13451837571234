import React from 'react';
import { OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';
import { getLocaleCode } from 'helpers/Utils';

export const LabelHelper = ({ text, placement = 'auto', delay = { show: 250, hide: 400 } }) => (
  <OverlayTrigger
    key="label-helper"
    placement={placement}
    delay={delay}
    overlay={<Tooltip id="label-helper">{text}</Tooltip>}
  >
    <i className="fa-solid fa-circle-info" style={{ marginLeft: '10px' }}></i>
  </OverlayTrigger>
);

const LevelsDescription = () => (
  <Popover id="level-description" style={{ position: 'absolute', boxShadow: '3px 3px 10px 1px #ccc', left: '50%' }}>
    <Popover.Body>
      <p>
        <strong>7</strong> - Otimização
      </p>
      <p>
        <strong>6</strong> - Integração
      </p>
      <p>
        <strong>5</strong> - Desenvolvimento
      </p>
      <p>
        <strong>4</strong> - Alinhamento
      </p>
      <p>
        <strong>3</strong> - Planejamento
      </p>
      <p>
        <strong>2</strong> - Engajamento
      </p>
      <p>
        <strong>1</strong> - Adesão
      </p>
    </Popover.Body>
  </Popover>
);

export const LevelGlossary = ({ placement = 'auto', delay = { show: 250, hide: 400 }, containerRef }) => (
  <OverlayTrigger
    key="level-description"
    trigger={['hover', 'focus', 'click']}
    placement={placement}
    delay={delay}
    container={containerRef}
    overlay={<LevelsDescription />}
  >
    <i className="fa-solid fa-circle-info" style={{ marginLeft: '10px' }}></i>
  </OverlayTrigger>
);

export const IndicatorValue = ({
  code,
  value,
  digits = 0,
  unit,
  outlier = false,
  outlierPlacement = 'auto',
  outlierDelay = { show: 250, hide: 400 },
  notAvailableText = 'ND',
  notAvailableTitle = 'Não disponível',
}) => {
  let strVal = null;

  if (typeof value === 'number') {
    strVal =
      value.toLocaleString(getLocaleCode(), {
        maximumFractionDigits: digits,
        minimumFractionDigits: digits,
      }) + (unit || '');
  } else if (typeof value === 'boolean') {
    strVal =
      value === true ? 'SIM' : value === false ? 'NÃO' : <span title={notAvailableTitle}>{notAvailableText}</span>;
  } else {
    strVal = value;
  }

  if (strVal === null) {
    return <span title={notAvailableTitle}>{notAvailableText}</span>;
  }

  return (
    <>
      {strVal}
      {outlier === true && (
        <OverlayTrigger
          key={`outlier-${code}`}
          placement={outlierPlacement}
          delay={outlierDelay}
          overlay={
            <Tooltip id={`outlier-${code}`}>O valor está fora da faixa definida a partir do desvio padrão</Tooltip>
          }
        >
          <i
            className="fa-solid fa-exclamation-triangle"
            style={{ marginLeft: '5px', color: 'hsl(14, 100%, 53%)' }}
          ></i>
        </OverlayTrigger>
      )}
    </>
  );
};

export const IndicatorSource = ({ code, text, placement = 'auto', delay = { show: 250, hide: 400 } }) => (
  <OverlayTrigger
    key={`source-info-${code}`}
    placement={placement}
    delay={delay}
    overlay={<Tooltip id={`source-info-${code}`}>{text}</Tooltip>}
  >
    <i className="fa-solid fa-circle-info" style={{ marginLeft: '5px' }}></i>
  </OverlayTrigger>
);

export const IndicatorFieldHelper = ({ fieldName, text, placement = 'top', delay = { show: 250, hide: 400 } }) => (
  <OverlayTrigger
    key={`field-helper-${fieldName}`}
    placement={placement}
    delay={delay}
    overlay={<Tooltip id={`field-helper-${fieldName}`}>{text}</Tooltip>}
  >
    <i className="fa-solid fa-circle-info" style={{ marginLeft: '5px' }}></i>
  </OverlayTrigger>
);

