import { createSlice } from '@reduxjs/toolkit';
import { getCityUserData, login, logout, updateLocalStorage } from 'helpers/userAuthUtils';

const initialState = {
  userData: getCityUserData() || { name: '', systemRoles: [] },
};

export const userAuthSlice = createSlice({
  name: 'userAuth',
  initialState,
  reducers: {
    loadData: (state, action) => {
      const userData = action.payload.userData;
      state.userData = userData;
    },
    updateData: (state, action) => {
      state.userData = action.payload.userData;
      // state.userData.name = action.payload.name;
    },
    clearData: (state) => {
      state.userData = { name: '', systemRoles: [] };
    },
  },
});

export const { loadData, updateData, clearData } = userAuthSlice.actions;

export const Actions = {
  loadData: (token, role, userData) => {
    return (dispatch) => {
      dispatch(loadData({ userData }));
      login(token, role, userData);
    };
  },
  updateData: (userData) => {
    return (dispatch) => {
      dispatch(updateData({ userData }));
      updateLocalStorage(userData.name);
    };
  },
  clearData: () => {
    return (dispatch) => {
      dispatch(clearData());
      logout();
    };
  },
};

export default userAuthSlice.reducer;
