import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import api from 'services/api';

const VerifiedValid = () => {
  return (
    <div className="p-5 m-5">
      <h1 className="fw-bold c-green">Sua conta foi ativada.</h1>
      <h3>Agora você pode fazer o login para entrar no sistema.</h3>
      <p className="text-center mt-5">
        <Link to="/prefeitura/login" id="btnRedirectLogin" className="btn btn-success">
          Ir para Login
        </Link>
      </p>
    </div>
  );
};

const VerifiedInvalid = ({ errorMsg }) => {
  return (
    <div className="p-5 m-5">
      <h1 className="fw-bold c-cyan">
        Acesso não autorizado <i className="fa-solid fa-circle-exclamation c-danger"></i>
      </h1>
      <h3>{errorMsg}</h3>
      <p className="text-center mt-5">
        <Link to="/home" id="btnRedirectHome" className="btn btn-success">
          Voltar para tela inicial
        </Link>
      </p>
    </div>
  );
};

const Loading = () => {
  return (
    <div className="p-5 m-5">
      <h1 className="fw-bold c-green">Verificando código</h1>
      <h3>
        Aguarde<span className="dot1">.</span>
        <span className="dot2">.</span>
        <span className="dot3">.</span>
      </h3>
    </div>
  );
};

const AccountActivation = () => {
  const { token } = useParams();

  const [verified, setVerified] = useState(false);
  const [valid, setValid] = useState(false);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (!verified) {
      api
        .patch(
          '/api/users/activate',
          {},
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          },
        )
        .then((resp) => {
          setValid(true);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            setMessage('Este link acessado é inválido ou está expirado.');
          } else {
            setMessage('Erro na validação do código');
          }
        })
        .finally(() => {
          setVerified(true);
        });
    }
  }, [token, verified]);

  return (
    <div className="page bg-cyan">
      <div className="container page">
        <div className="row page justify-content-center">
          <div className="col-12 col-lg-8 bg-white">
            {verified ? valid ? <VerifiedValid /> : <VerifiedInvalid errorMsg={message} /> : <Loading />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountActivation;
