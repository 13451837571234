import React, { useEffect, useState } from 'react';
import Questions from '.';
import OptionsHints from './OptionsHints';
import { OptionTextSimple, getBulletFromIndex } from 'components/survey/Questions/Components';
import formSettings from 'components/survey/formSettings';
import { ShowBullet, ShowOrderBullet } from './ShowBullet';

const Options = ({ type, question, answers, showAnswers = false, position, isChild = false, parentPosition }) => {
  const questionCode = question.code;
  const answer = answers[questionCode].options;
  const optionActivation = question.optionActivation;

  const [questionEnabled, setQuestionEnabled] = useState(false);

  useEffect(() => {
    let enabled = false;
    if (Object.keys(optionActivation).length > 0) {
      for (const actQuestionCode in optionActivation) {
        for (const option of answers[actQuestionCode].options) {
          if (optionActivation[actQuestionCode].includes(option.code) && option.selected === true) {
            enabled = true;
          }
        }
      }
    } else {
      enabled = true;
    }
    setQuestionEnabled(enabled);
  }, [answers, optionActivation]);

  const options = Object.entries(question.options).map(([optCode, option], index) => {
    // const bullet = optCode.substring(optCode.lastIndexOf('_') + 1);
    const bullet = getBulletFromIndex(index);
    return (
      <li key={optCode}>
        <span>
          {type === formSettings.questionTypes.orderOption && (
            <ShowOrderBullet id={optCode} value={answer[index].position} showAnswers={showAnswers} />
          )}
          {type === formSettings.questionTypes.multiOption && (
            <ShowBullet isMulti selected={showAnswers && answer[index].selected} />
          )}
          {type === formSettings.questionTypes.singleOption && (
            <ShowBullet selected={showAnswers && answer[index].selected} />
          )}
          &nbsp;
          <OptionTextSimple bullet={bullet} text={option.description} helper={!!option.helper} />
        </span>
        {option.withComplement && (
          <span className="complement">
            <span>&nbsp;{option.complementLabel}&nbsp;</span>
            <span className="filling-line">
              <i>{showAnswers && answer[index].complement ? answer[index].complement : <>&nbsp;</>}</i>
            </span>
          </span>
        )}
        {option.activateLabel && (
          <span className="activate-label">
            &nbsp;<i className="activate-question">({option.activateLabel})</i>
          </span>
        )}
      </li>
    );
  });

  return (
    <>
      <article className={'col-12' + (isChild ? ' subquestion' : ' question') + (questionEnabled ? '' : ' disabled')}>
        <header>
          <h3 className="mb-3">
            {isChild && (
              <>
                <span>
                  {parentPosition}.{position}.
                </span>{' '}
                {question.description}
              </>
            )}
            {!isChild && (
              <>
                <span>{position}.</span> {question.description}
              </>
            )}
          </h3>
          {!!question.helper && (
            <h5 className="subtitle text-muted">
              <i className="fa-solid fa-circle-info"></i>
              <i>&nbsp;{question.helper}</i>
            </h5>
          )}
        </header>
        <section>
          {type === formSettings.questionTypes.multiOption && (
            <p>
              <i>Múltiplas opções podem ser selecionadas</i>
            </p>
          )}
          {type === formSettings.questionTypes.singleOption && (
            <p>
              <i>Selecionar somente UMA opção</i>
            </p>
          )}
          <ol className="options">{options}</ol>
        </section>
        <OptionsHints options={question.options} />
        {!!question.children && (
          <Questions
            questions={question.children}
            answers={answers}
            isChild={true}
            parentPosition={position}
            parentCode={questionCode}
            showAnswers={showAnswers}
          />
        )}
      </article>
      {!isChild && <hr />}
    </>
  );
};

export default Options;
