import React from 'react';
import { Modal } from 'react-bootstrap';

const ModalIndicatorNotes = ({ handleShowModal, showModal, modalInfo, handleChange }) => {
  return (
    <Modal show={showModal} centered>
      <Modal.Header closeButton onClick={handleShowModal}>
        <Modal.Title>{modalInfo.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <label className="label">Descrição</label>
        <textarea
          className="form-control"
          id="desctiption"
          name="desctiption"
          rows={5}
          defaultValue={modalInfo?.description}
          maxLength={500}
          placeholder="Adiciona aqui a descrição"
          onChange={(evt) => handleChange(modalInfo.name, evt)}
        ></textarea>
        <label className="label">Notas</label>
        <textarea
          className="form-control"
          id="notes"
          name="notes"
          rows={5}
          defaultValue={modalInfo?.notes}
          maxLength={500}
          placeholder="Adicione observações ou informações complementares"
          onChange={(evt) => handleChange(modalInfo.name, evt)}
        ></textarea>
      </Modal.Body>
      <Modal.Footer>
        <span className="btn btn-success btn-sm" onClick={handleShowModal}>
          Fechar
        </span>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalIndicatorNotes;
