const Masks = {
  alphaNumeric: (value) => {
    return value.replace(/[^A-Z0-9]/g, '').replace(/([A-Z0-9]{100})([A-Z0-9]+?)$/, '$1');
  },
  description: (value) => {
    return value
      .replace(/[^A-zÀ-ú0-9 -' -, ()]/g, '')
      .replace(/([A-zÀ-ú0-9 -' -, ()]{1000})([A-zÀ-ú0-9 -' -, ()]+?)$/, '$1');
  },
  name: (value) => {
    return value.replace(/[^A-zÀ-ú -' -]|[\\]/g, '').replace(/([A-zÀ-ú -' -]{64})([A-zÀ-ú -' -]+?)$/, '$1');
  },
  city: (value) => {
    return value.replace(/[^A-zÀ-ú -' -,]|[\\]/g, '').replace(/([A-zÀ-ú -' -,]{64})([A-zÀ-ú -' -]+?)$/, '$1');
  },
  emitter: (value) => {
    return value
      .toUpperCase()
      .replace(/[^A-zÀ-ú \-'/]|[\\]/g, '')
      .replace(/([A-zÀ-ú \-'/]{16})([A-zÀ-ú -'/]+?)$/, '$1');
  },
  zoneNumber: (value) => {
    if (value.length !== 0 && value.length <= 2 && value[0] !== '0') {
      let zeroPad = '';
      for (let i = 0; i < 3 - value.length; i++) {
        zeroPad += '0';
      }
      value = zeroPad + value;
    }
    if (value.length === 4 && value[0] === '0') {
      value = value.substr(1, value.length - 1);
    }
    return value.replace(/[^0-9]/g, '').replace(/([0-9]{3})\d+?$/, '$1');
  },
  sectionNumber: (value) => {
    if (value.length !== 0 && value.length <= 3 && value[0] !== '0') {
      let zeroPad = '';
      for (let i = 0; i < 4 - value.length; i++) {
        zeroPad += '0';
      }
      value = zeroPad + value;
    }
    if (value.length === 5 && value[0] === '0') {
      value = value.substr(1, value.length - 1);
    }
    return value.replace(/[^0-9]/g, '').replace(/([0-9]{4})\d+?$/, '$1');
  },
  cpfNumber: (value) => {
    const cpf = removeMask(value, ['[.]', '[-]']).replace(/\D/g, '');
    if (cpf.length < 3) {
      return cpf;
    } else if (cpf.length < 6) {
      return `${cpf.substr(0, cpf.length - 2)}-${cpf.substr(cpf.length - 2, 2)}`;
    } else if (cpf.length < 9) {
      return `${cpf.substr(0, cpf.length - 5)}.${cpf.substr(cpf.length - 5, 3)}-${cpf.substr(cpf.length - 2, 2)}`;
    }
    return `${cpf.substr(0, cpf.length - 8)}.${cpf.substr(cpf.length - 8, 3)}.${cpf.substr(
      cpf.length - 5,
      3,
    )}-${cpf.substr(cpf.length - 2, 2)}`;
  },
  identityCardNumber: (value) => {
    return value.toUpperCase();
    // REMOVENDO MASCARA POIS O FORMATO PODE VARIAR CONFORME ESTADO
    /* .replace(/[^\dX]/g, '')
      .replace(/(\d{1,2})(\d{3})(\d{3})([\dX])/, '$1.$2.$3-$4')
      .replace(/(-\d|X)(\d|X+?)$/, '$1') */
  },
  voteCardNumber: (value) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{4})(\d)/, '$1 $2')
      .replace(/(\d{4})(\d)/, '$1 $2')
      .replace(/( \d{4})\d+?$/, '$1');
  },
  phoneNumber: (value) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{4,5})(\d{4})/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1');
  },
  date: (value) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\/\d{4})\d+?$/, '$1');
  },
};

function removeCharacter(value, character) {
  return value.replace(RegExp(character, 'g'), '');
}

function removeMask(value, separators) {
  let newValue = value;

  separators.map((separator) => {
    return (newValue = removeCharacter(newValue, separator));
  });

  return newValue;
}

export function setMask(name, value) {
  if (value) {
    switch (name) {
      case 'telephoneNumber':
        value = Masks.phoneNumber(value);
        break;
      case 'cpfNumber':
        value = Masks.cpfNumber(value);
        break;
      case 'identityCardNumber':
        value = Masks.identityCardNumber(value);
        break;
      case 'voteCardNumber':
        value = Masks.voteCardNumber(value);
        break;
      case 'emissionDate':
        value = Masks.date(value);
        break;
      case 'name':
      case 'department':
      case 'city':
      case 'voteCity':
        value = Masks.city(value);
        break;
      case 'emitter':
        value = Masks.emitter(value);
        break;
      case 'zone':
        value = Masks.zoneNumber(value);
        break;
      case 'section':
        value = Masks.sectionNumber(value);
        break;
      case 'code':
        value = Masks.alphaNumeric(value);
        break;
      default:
        break;
    }
  }
  return value;
}

export function unsetMask(name, value) {
  if (value) {
    switch (name) {
      case 'telephoneNumber':
        value = removeMask(value, ['[+]', '[-]', '[ ]', '[(]', '[)]']);
        break;
      case 'cpfNumber':
        value = removeMask(value, ['[.]', '[-]']);
        break;
      /*
      case 'identityCardNumber':
        value = removeMask(value, ['[.]', '[-]'])
        break;
        */
      case 'voteCardNumber':
        value = removeMask(value, ['[ ]']);
        break;
      case 'emissionDate':
        // value = moment(value, 'DD/MM/YYYY', true).format('YYYY-MM-DD')
        break;
      default:
        break;
    }
  }
  return value;
}

export function parseDate(value) {
  // return moment(value, 'YYYY-MM-DD', true).format('DD/MM/YYYY')
}
