import React, { lazy, Suspense } from 'react';

// Lazy precisa de import dinamico
const LazyMapChart = lazy(() => import('components/charts/MapChart'));

const CitiesMap = ({
  type = 1,
  selectionValue,
  lineWidth = 0.5,
  showTooltip = true,
  delayMs = 0,
  mapHeight = '500px',
  legendHeight = '50px',
  onRenderFinish,
}) => {
  return (
    <>
      <div className="bg-white shadow p-5">
        <h6 className="fw-bold c-green">Mapa de Municípios Cadastrados</h6>
        <Suspense
          fallback={
            <div className="container d-flex align-items-center justify-content-center">
              <span>
                <i className="fa-solid fa-spinner fa-spin fa-lg"></i> Carregando mapa...
              </span>
            </div>
          }
        >
          <LazyMapChart
            type={type}
            selectionValue={selectionValue}
            lineWidth={lineWidth}
            showTooltip={showTooltip}
            delayMs={delayMs}
            mapHeight={mapHeight}
            legendHeight={legendHeight}
            onRenderFinish={onRenderFinish}
            className="text-center my-4"
          />
        </Suspense>
      </div>
    </>
  );
};

export default CitiesMap;
