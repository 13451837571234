import React, { useState, createRef, useCallback, useEffect } from 'react';
import { debounce } from 'lodash';

const DEBOUNCE_MS = Number(process.env.REACT_APP_UPDATE_BUTTON_DEBOUNCE_MS);

function createSimpleCustomEvent(name, value) {
  return {
    target: {
      name,
      value,
    },
  };
}

const HighlightTerm = (props) => {
  const result = props.result;
  const userInput = props.userInput;
  // Encontra o termo normalizado (https://stackoverflow.com/a/37511463)
  const input = userInput
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toUpperCase()
    .trim();
  const normalizedResult = result
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toUpperCase();
  const beginPos = normalizedResult.indexOf(input);

  if (beginPos >= 0) {
    const prefix = result.substring(0, beginPos);
    const term = result.substr(beginPos, input.length);
    const suffix = result.substring(beginPos + input.length);
    return (
      <>
        {prefix}
        <strong>{term}</strong>
        {suffix}
      </>
    );
  } else {
    return <>{result}</>;
  }
};

const AutoComplete = (props) => {
  const WrapperComponent = props.WrapperComponent;

  const [activeOption, setActiveOption] = useState(0);
  const [refs, setRefs] = useState({});
  const [userInput, setUserInput] = useState('');
  const [showOptions, setShowOptions] = useState(false);

  useEffect(() => {
    let inputValue = props.value;
    const commaPos = inputValue ? inputValue.indexOf(',') : -1;
    if (commaPos >= 0) {
      inputValue = inputValue.substring(0, commaPos);
    }
    setUserInput(inputValue);
    setRefs(setReferencesToListItems(props.suggestions));
  }, [props]);

  const setReferencesToListItems = (options) => {
    const refs = options.reduce((acc, value) => {
      acc[value.id] = createRef();
      return acc;
    }, {});
    return refs;
  };

  const scrollToListItem = (listItemId) => {
    refs[listItemId].current.scrollIntoView({
      behavior: 'instant',
      block: 'nearest',
      inline: 'center',
    });
  };

  const showList = (evt) => {
    setShowOptions(true);
    setActiveOption(0);
    setUserInput(evt.target.value);
  };

  const clearList = (evt) => {
    setShowOptions(false);
    setActiveOption(0);
    setUserInput(evt.currentTarget.value ? evt.currentTarget.value : evt.currentTarget.innerText);
  };

  const createCustomEvent = (evt) => {
    if (!evt || !evt.currentTarget) return null;
    const eventObject = {
      A: () => {
        const { innerText } = evt.currentTarget;
        return createSimpleCustomEvent(props.name, innerText);
      },
      LI: () => {
        const { innerText } = evt.currentTarget;
        return createSimpleCustomEvent(props.name, innerText);
      },
      INPUT: () => {
        const { value } = evt.currentTarget;
        return createSimpleCustomEvent(props.name, value);
      },
    };
    return eventObject[evt.currentTarget.nodeName]() || null;
  };

  const selectCityData = (cityName) => {
    const index = props.suggestions.map((option) => option.result).indexOf(cityName) || 0;
    setActiveOption(index);
  };

  const handleClick = (evt) => {
    evt.preventDefault();
    handleChange(evt);
    clearList(evt);
  };

  const hasEnoughLength = () => {
    return userInput.length > 2;
  };

  const isListEmpty = () => {
    return props.suggestions.length === 0;
  };

  const updateList = useCallback((newInput) => {
    if (props.suggestions.length > 0) {
      setRefs(setReferencesToListItems(props.suggestions));
      selectCityData(newInput);
    }
    // eslint-disable-next-line
  }, []);

  // TODO: VERIFICAR DEBOUNCE (ATUALMENTE ESTÁ DESABILITADO)
  const setUpdatedValues = useCallback(
    (newInput) => {
      props.onUpdate(newInput);
      updateList(newInput);
      debounce(() => {}, DEBOUNCE_MS);
    },
    [props, updateList],
  );

  const handleChange = (evt) => {
    evt.persist();
    const event = createCustomEvent(evt);
    if (event) {
      const newInput = event.target.value;
      setUserInput(newInput);

      if (newInput !== userInput && newInput.length !== 0) {
        setUpdatedValues(newInput);
      }

      showList(event);
      props.onChange(event);
    }
  };

  const handleKeyDown = (evt) => {
    const { key } = evt;
    const acceptedKeys = {
      ArrowDown: () => {
        const nextActiveOption = activeOption !== props.suggestions.length - 1 ? activeOption + 1 : 0;
        setActiveOption(nextActiveOption);
        scrollToListItem(props.suggestions[nextActiveOption].id);
      },
      ArrowUp: () => {
        const nextActiveOption = activeOption !== 0 ? activeOption - 1 : props.suggestions.length - 1;
        setActiveOption(nextActiveOption);
        scrollToListItem(props.suggestions[nextActiveOption].id);
      },
      Enter: () => {
        evt.target.value = props.suggestions[activeOption].result || '';
        handleChange(evt);
        clearList(evt);
      },
    };
    if (acceptedKeys[key] && showOptions && hasEnoughLength() && !isListEmpty()) {
      acceptedKeys[key]();
    }
  };

  const optionsList = () => {
    if (showOptions && userInput && props.suggestions?.length && userInput.length > 2) {
      /*
        return (
          <ul className="list-group list-group-flush">
            {props.suggestions.map((option, index) => (
              <li
                key={option.id}
                className="list-group-item"
                id={option.friendlyName}
                isSelected={index === activeOption}
                onClick={handleClick}
                ref={refs[option.id]}
              >
                <HighlightTerm result={option.result} userInput={userInput} />
              </li>
            ))}
          </ul>
        );
        */
      return (
        <div className="autocomplete">
          <ul className="list-group list-group-flush">
            {props.suggestions.map((option, index) => (
              <li
                href="#"
                key={option.id}
                className={`list-group-item ${index === activeOption ? 'active' : ''}`}
                id={option.friendlyName}
                onClick={handleClick}
                ref={refs[option.id]}
              >
                <HighlightTerm result={option.result} userInput={userInput} />
              </li>
            ))}
          </ul>
        </div>
      );
    }
  };

  return (
    <WrapperComponent className={`${props.className || ''} has-autocomplete`}>
      <input
        type="text"
        id={props.id}
        name={props.name}
        value={userInput}
        placeholder={props.disabled ? '' : props.placeholder}
        className={props.inputClassName}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        disabled={props.disabled}
        autoComplete="off"
      />
      {/* props.isHomeInput && (
        <span className="icon is-small is-left">
          <i className="fa-solid fa-searh"></i>
        </span>
      ) */}
      {optionsList()}
    </WrapperComponent>
  );
};

export default AutoComplete;
