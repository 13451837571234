import React, { useCallback, useEffect, useRef, useState } from 'react';
import Pagination from './Pagination';
import { useTitle } from 'hooks/useTitle';
import { Actions as PostActions } from 'services/slices/content';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import ModalPost from './Modal';
import { handleImageLoadError } from 'helpers/Utils';

const Filters = ({ filtersList, currentFilter, currentInputValue, onInputChange, onSelectedFilter }) => {
  return (
    <div className="bg-gray-superlight p-4">
      <div className="mb-3">
        <span className="has-icon search">
          <input
            value={currentInputValue}
            onChange={(evt) => onInputChange(evt.target.value)}
            type="text"
            className="form-control"
            placeholder="Buscar..."
          />
        </span>
      </div>
      <hr className="my-3" />

      {filtersList &&
        Object.entries(filtersList)?.map(([filterName, filterValue]) => (
          <div key={`filter-for-${filterName}`} className="mb-3">
            <select
              value={currentFilter[filterName]}
              id={`select-${filterName}`}
              className="form-select"
              onChange={(evt) => onSelectedFilter(filterName, evt.target.value)}
            >
              {filterName === 'dimensions' && (
                <>
                  <option value="">Selecione um tópico</option>
                  {filterValue?.map((opt) => (
                    <optgroup key={`opt-group-${opt.value}`} label={opt?.text}>
                      {opt?.subOptions?.map((subOption) => (
                        <option key={`opt-${opt?.value}-${subOption?.value}`} value={subOption?.value}>
                          {subOption?.text}
                        </option>
                      ))}
                    </optgroup>
                  ))}
                </>
              )}
              {filterName === 'states' && (
                <>
                  <option value="">Selecione um estado</option>
                  {filterValue?.map((opt) => (
                    <option key={`opt-${opt?.value}`} value={opt?.value}>
                      {opt?.text}
                    </option>
                  ))}
                </>
              )}
            </select>
          </div>
        ))}
    </div>
  );
};

const MediaPosts = () => {
  const title = 'Cidades Inteligentes';
  const pageTitle = useTitle(`${title} Sustentáveis na Mídia`);

  const dispatch = useDispatch();

  const posts = useSelector((state) => state.posts);

  const [page, setPage] = useState(1);
  const [queryInputValue, setQueryInputValue] = useState('');
  const [selectedFilter, setSelectedFilter] = useState({});
  const [modalInfo, setModalInfo] = useState();

  const searchedTerm = useRef(null);
  const triggerSearch = useRef(
    debounce((inputValue, currentFilter) => dispatch(PostActions.loadPosts(page, inputValue, currentFilter)), 500),
  ).current;

  useEffect(() => {
    dispatch(PostActions.loadFilters());
    return () => {
      dispatch(PostActions.clearData());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(PostActions.loadPosts(page, searchedTerm?.current, selectedFilter));
  }, [dispatch, page, selectedFilter]);

  useEffect(() => setPage(1), [selectedFilter]);

  const handleQueryInputValue = (newInputValue) => {
    setQueryInputValue(newInputValue);
    triggerSearch(newInputValue, selectedFilter);
    setPage(1);
  };

  useEffect(() => {
    searchedTerm.current = queryInputValue;
  }, [queryInputValue]);

  const handleFilterSelection = useCallback((filterName, filterValue) => {
    setSelectedFilter((current) => {
      return {
        ...current,
        [filterName]: filterValue,
      };
    });
  }, []);

  const handlePageChange = (pageDirection) => {
    if (typeof pageDirection === 'number') {
      const pageNumber = pageDirection;
      setPage(pageNumber);
      return;
    }

    const currentPage = page;
    const changePage = {
      prev: () => {
        const newPage = currentPage - 1;
        setPage(newPage > 1 ? newPage : 1);
      },
      next: () => {
        const newPage = currentPage + 1;
        setPage(newPage <= posts?.page?.totalPages ? newPage : currentPage);
      },
    };

    changePage[pageDirection]();
  };

  const handleOpenPost = (post) => {
    setModalInfo({
      post,
    });
  };

  const closeModal = () => {
    setModalInfo(null);
  };

  return (
    <>
      {/* <!-- Título da Página - start --> */}
      <section className="page-title">
        <div className="container">
          <div className="title">
            {pageTitle.substring(0, pageTitle.indexOf(' ', title.length - 1))}
            <br />
            {pageTitle.substring(pageTitle.indexOf(' ', title.length - 1), pageTitle.length)}
          </div>
        </div>
      </section>
      {/* <!-- Título da Página - end --> */}

      {/* <!-- Conteúdo - start --> */}
      <div className="py-5">
        <div className="container">
          <div className="row">
            {/* <!-- Coluna Lateral - start --> */}
            <div className="col-12 col-lg-4">
              {/* <!-- Box Filtros - start --> */}
              <Filters
                filtersList={{ dimensions: posts?.dimensions, states: posts?.states }}
                currentFilter={selectedFilter}
                currentInputValue={queryInputValue}
                onInputChange={handleQueryInputValue}
                onSelectedFilter={handleFilterSelection}
              />
              {/* <!-- Box Filtros - end --> */}
            </div>
            {/* <!-- Coluna Lateral - end --> */}

            {/* <!-- Coluna Principal - start --> */}
            <div className="col-12 col-lg-8">
              {posts?.data?.map((post) => (
                <div key={`post-n-${post?.id}`} className="post p-4 bg-gray-superlight mb-5">
                  <div className="row">
                    <div className="col-12 col-lg-6 wrap-img">
                      <a className="mt-4" href="#" onClick={() => handleOpenPost(post)}>
                        <img
                          loading="lazy"
                          src={post?.imageSrc}
                          alt={post?.imageTitle || post?.friendlyTitle}
                          className="post-img"
                          onError={handleImageLoadError}
                        />
                      </a>
                    </div>
                    <div className="col-12 col-lg-6">
                      <h3 className="c-cyan fw-bold mb-4">
                        <a href="#" onClick={() => handleOpenPost(post)}>
                          {post?.title}
                        </a>
                      </h3>
                      <p className="text-justify">
                        <a href="#" onClick={() => handleOpenPost(post)}>
                          {post?.value}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              ))}

              {/* <!-- Pagination - start --> */}
              <Pagination pageInfo={posts?.page} onPageChange={handlePageChange} />
              {/* <!-- Pagination - end --> */}
            </div>
            {/* <!-- Coluna Principal - end --> */}
          </div>
        </div>
      </div>
      <ModalPost {...modalInfo} show={!!modalInfo} onClose={closeModal} />
      {/* <!-- Conteúdo - end --> */}
    </>
  );
};

export default MediaPosts;
