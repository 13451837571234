import React, { useEffect, useState } from 'react';
import { getUserData } from 'helpers/userAuthUtils';
import { Modal } from 'react-bootstrap';

export const ConfirmationModal = ({ id, show, title, message, onClose, onConfirmation }) => {
  return (
    <Modal
      className="inteligente-modal"
      show={show}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-outline-secondary" onClick={onClose}>
          Não
        </button>
        <button type="button" className="btn btn-primary" onClick={() => onConfirmation(id)}>
          Sim
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export const DeletionModal = ({ id, show, title, message, onClose, onConfirmation }) => {
  const [reason, setReason] = useState('');
  const [sameUser, setSameUser] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);

  useEffect(() => {
    const userData = getUserData();
    setSameUser(userData?.cityUserData?.id === id);
  }, [id]);

  const handleReason = (evt) => {
    const value = evt.target.value;
    setReason(value);
    setBtnDisabled(!(value?.length > 0));
  };

  return (
    <Modal
      className="inteligente-modal"
      show={show}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="mb-4 fw-bold text-center">{message}</p>
        {sameUser && (
          <div className="form-group mb-3">
            <div className="alert alert-danger" role="alert">
              <h4 className="alert-heading">Autoexclusão de usuário</h4>
              <p>
                <strong>ATENÇÃO</strong>: você está excluindo o seu próprio cadastro. Dessa maneira, você perderá seu
                acesso à plataforma.
                <br />
                Prossiga somente se você tiver certeza, pois esta ação é irreversível.
              </p>
            </div>
          </div>
        )}
        <div className="form-group mb-3">
          <label className="form-label fw-semibold">Motivo da exclusão</label> <br />
          <textarea
            className="form-control"
            id="disapproval"
            name="reason"
            rows={5}
            value={reason}
            maxLength={1024}
            placeholder="Informe o motivo da exclusão"
            onChange={handleReason}
          ></textarea>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-outline-secondary" onClick={onClose}>
          Não
        </button>
        <button
          type="button"
          className="btn btn-danger"
          disabled={btnDisabled}
          onClick={() => onConfirmation(id, { reason, origin: sameUser ? 1 : 2, personType: null })}
        >
          Sim
        </button>
      </Modal.Footer>
    </Modal>
  );
};
