import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import { Actions as AuthActions } from 'services/auth';

const OAuth2Logout = () => {
  const { providerName } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(AuthActions.logout(navigate));
  }, [providerName, dispatch, navigate]);

  return (
    <div className="page bg-cyan">
      <div className="container page">
        <div className="row page justify-content-center">
          <div className="col-12 col-lg-8 bg-white">
            <div className="p-5 m-5">
              <h1 className="fw-bold c-green">Fazendo logout</h1>
              <h3>
                Aguarde<span className="dot1">.</span>
                <span className="dot2">.</span>
                <span className="dot3">.</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

};

export default OAuth2Logout;
