import React, { Fragment } from 'react';
import Questions from '.';
import OptionComplement from './OptionComplement';
import { QuestionHelper, OptionText, OptionHelper, AnswerChanged, getBulletFromIndex } from './Components';

const OrderOption = ({ groupStatus, option, optCode, bullet, answerInfo, handleChangeValue }) => {
  if (groupStatus === 2) {
    if (Number(answerInfo.position)) {
      return (
        // Opção selecionada na visualização (form desabilitado)
        <p>
          <label className="question-order-option">
            <input
              id={optCode}
              type="number"
              className="form-control rounded-1 form-control-short"
              name={optCode}
              value={answerInfo.position ? Number(answerInfo.position) : ''}
              disabled={true}
            />
            <OptionText bullet={bullet} text={option.description} />
            <OptionHelper code={optCode} text={option.helper} />
          </label>
        </p>
      );
    } else {
      return (
        // Opção não selecionada na visualização (form desabilitado)
        <p>
          <label className="question-order-option">
            <input
              type="number"
              id={optCode + '-input'}
              className="form-control rounded-1 form-control-short"
              name={optCode}
              value={answerInfo.position ? Number(answerInfo.position) : ''}
              disabled={true}
            />
            <OptionText bullet={bullet} text={option.description} />
            <OptionHelper code={optCode} text={option.helper} />
          </label>
        </p>
      );
    }
  }
  return (
    <p>
      <label className="question-order-option">
        <input
          id={optCode}
          type="number"
          className="form-control rounded-1 form-control-short"
          name={optCode}
          onChange={handleChangeValue}
          value={answerInfo.position ? Number(answerInfo.position) : ''}
        />
        <OptionText bullet={bullet} text={option.description} />
        <OptionHelper code={optCode} text={option.helper} />
      </label>
    </p>
  );
};

const OrderOptions = (props) => {
  const questionCode = props.question.code;
  const changed = props.answers[questionCode].changed;
  const answers = props.answers[questionCode].options;
  const groupStatus = props.groupStatus;
  const errors = props.errors;

  const handleChange = (evt) => {
    const strVal = evt.target.value;
    const optCode = evt.target.name;
    props.onOrderChange(questionCode, optCode, strVal.replace(/\D/g, ''));
  };
  const handleComplementChange = (optCode, complementValue) =>
    props.onComplementChange(questionCode, optCode, complementValue);

  const handleChildrenOptionChange = (childCode, optCode, checked) =>
    props.onOptionChange(childCode, optCode, checked, questionCode);

  const handleChildrenComplementChange = (childCode, optCode, complementValue) =>
    props.onComplementChange(childCode, optCode, complementValue, questionCode);

  const options = Object.entries(props.question.options).map(([optCode, option], index) => {
    // const bullet = optCode.substring(optCode.lastIndexOf('_') + 1);
    const bullet = getBulletFromIndex(index);
    return (
      <Fragment key={`question-${questionCode}-option-${optCode}`}>
        <OrderOption
          groupStatus={groupStatus}
          option={option}
          optCode={optCode}
          bullet={bullet}
          questionCode={questionCode}
          answerInfo={answers[index]}
          handleChangeValue={handleChange}
        />
        <span>
          <OptionComplement
            code={optCode}
            show={option.withComplement && answers[index].position !== null && answers[index].position !== ''}
            label={option.complementLabel}
            value={answers[index].complement}
            onComplementChange={handleComplementChange}
          />
        </span>
      </Fragment>
    );
  });

  return (
    <>
      <div className={props.isChild ? 'subquestion bg-gray-superlight p-4 mt-3' : 'question'}>
        <h4 className="fw-bold mb-4">
          {props.isChild ? (
            <span className="c-green me-2">
              {props.parentPosition}.{props.position}.
            </span>
          ) : (
            <span className="c-green me-2">{props.position}.</span>
          )}
          {props.question.description}
          <QuestionHelper code={questionCode} text={props.question.helper} />
          <AnswerChanged changed={changed} />
        </h4>
        {errors[questionCode] && errors[questionCode].ROOT && (
          <p className="text-danger">{errors[questionCode].ROOT}</p>
        )}
        <fieldset>{options}</fieldset>
        {!!props.question.children && (
          <Questions
            surveyCode={props.surveyCode}
            questions={props.question.children}
            answers={props.answers}
            isChild={true}
            errors={errors}
            parentPosition={props.position}
            onOptionChange={handleChildrenOptionChange}
            onComplementChange={handleChildrenComplementChange}
            groupStatus={groupStatus}
          />
        )}
      </div>
      {!props.isChild && <hr className="my-5" />}
    </>
  );
};

export default OrderOptions;
