import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Actions as SearchCityActions } from 'services/citySearch';
import { Creators as CitiesListActions } from 'services/ducks/citiesList';
import AutoComplete from 'components/AutoComplete';

const FieldSelectInput = ({
  WrapperComponent = 'div',
  label = '',
  id,
  name,
  className,
  placeholder,
  initialValue,
  disabled = false,
  onSelectedOption,
}) => {
  const dispatch = useDispatch();
  const citiesSuggestions = useSelector((state) => state.citiesList.searchResult);
  // const cityData = useSelector((state) => state.citySearch.cityData);
  const [city, setCity] = useState('');

  useEffect(() => {
    dispatch(CitiesListActions.resetCitiesList());
    return () => dispatch(SearchCityActions.resetSearch());
  }, [dispatch]);

  useEffect(() => {}, [initialValue]);

  const selectCity = (evt) => {
    const val = evt.target.value;
    let cityIdFilter = {
      id: '',
    };
    if (citiesSuggestions.map((city) => city.result).includes(val)) {
      cityIdFilter = { id: citiesSuggestions.filter((city) => city.result === val)[0].id };
    }

    handleCityChange(evt, cityIdFilter);
    dispatch(CitiesListActions.resetCitiesList());
  };

  const handleCityChange = (evt, cityId) => {
    const { value } = evt.target;
    setCity(value);
    if (cityId && cityId.id !== '') {
      const cityDataSelected = citiesSuggestions.filter((city) => city.id === cityId.id)[0];
      // dispatch(SearchCityActions.searchCityInformation(friendlyName));

      onSelectedOption(cityDataSelected);
    }
  };

  const updateSuggestions = (input) => {
    dispatch(CitiesListActions.searchCity(input));
  };

  return (
    <>
      <label className="form-label fw-semibold">{label}</label>
      <AutoComplete
        WrapperComponent={WrapperComponent}
        inputClassName="form-control"
        className={className}
        placeholder={placeholder}
        id={id}
        name={name}
        value={city || initialValue || ''}
        disabled={disabled}
        suggestions={citiesSuggestions}
        onChange={selectCity}
        onUpdate={updateSuggestions}
      />
    </>
  );
};

export default FieldSelectInput;
