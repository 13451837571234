import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Actions as ConfigurationsActions } from 'slices/admin/configuration';
import { ConfigModal } from '../ConfigModal';

const ContactUsTab = ({ data }) => {
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(false);
  const [newData, setNewData] = useState({});
  const [modalValues, setModalValues] = useState();

  const handleShowTab = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    setNewData(data);
  }, [data]);

  const handleUpdateValues = (configurationData) => {
    dispatch(ConfigurationsActions.editConfiguration(configurationData));
  };

  const handleOpenModal = (data) => {
    setModalValues(data);
  };

  const closeModal = () => {
    setModalValues(null);
  };

  return (
    <>
      <div className="accordion-item">
        <h2 className="accordion-header" onClick={handleShowTab}>
          <button
            className={`accordion-button ${isActive ? '' : 'collapsed'}`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapse2"
            aria-expanded="false"
            aria-controls="collapse2"
          >
            Fale conosco e suporte
          </button>
        </h2>
        <div
          id="collapse2"
          className={`accordion-collapse collapse ${isActive ? 'show' : ''}`}
          data-bs-parent="#accordionForm"
        >
          {newData && newData?.length > 0 && (
            <div className="accordion-body">
              <ul className="list-group ps-5 pe-5 pb-5 pt-0">
                {data?.map((d) => (
                  <li
                    key={`item-key-${d.name}`}
                    className="list-group-item p-3 d-flex justify-content-between align-items-center"
                  >
                    <div>
                      <h5 className="fw-bold c-cyan">{d.description}</h5>
                      <div className="c-gray">{d.value}</div>
                    </div>
                    <a href="#">
                      <i className="fa-solid fa-pen larger-4 c-cyan" onClick={() => handleOpenModal(d)}></i>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      <ConfigModal
        modalValues={modalValues}
        show={!!modalValues}
        onClose={closeModal}
        onConfirmation={handleUpdateValues}
      />
    </>
  );
};

export default ContactUsTab;
