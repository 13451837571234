import AdminSubscribersService from 'services/admin/subscribers';

import { adminSubscribersSlice } from 'slices/admin/subscribers/slice';

export const AdminSubscribersSliceActions = adminSubscribersSlice.actions;

export const Actions = {
  loadSubscribers: (page) => {
    return (dispatch) => {
      dispatch(AdminSubscribersSliceActions.loadingStart());
      AdminSubscribersService.findAll(page, 10)
        .then((res) => {
          const responseData = res.data;
          const page = {
            totalElements: responseData.totalElements,
            totalPages: responseData.totalPages,
            totalInPage: responseData.numberOfElements,
            pageNumber: responseData.number + 1,
            pageSize: responseData.size,
            first: responseData.first,
            last: responseData.last,
          };
          dispatch(AdminSubscribersSliceActions.success({ page, content: responseData.content }));
        })
        .catch(() => dispatch(AdminSubscribersSliceActions.error('Ocorreu um erro ao carregar os usuários.')))
        .finally(() => dispatch(AdminSubscribersSliceActions.loadingStop()));
    };
  },
  delete: (id, disapprovalObj, onSuccess) => {
    return (dispatch) => {
      dispatch(AdminSubscribersSliceActions.loadingStart());
      AdminSubscribersService.deleteById(id, disapprovalObj)
        .then(() => {
          dispatch(AdminSubscribersSliceActions.success({ message: 'Usuário excluído com sucesso! ' }));
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch(() => dispatch(AdminSubscribersSliceActions.error('Erro ao excluir usuário. ')))
        .finally(() => dispatch(AdminSubscribersSliceActions.loadingStop()));
    };
  },
  clearData: () => {
    return (dispatch) => {
      dispatch(AdminSubscribersSliceActions.reset());
    };
  },
};

const adminSubscribersReducer = adminSubscribersSlice.reducer;

export default { adminSubscribersReducer };
