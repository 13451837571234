import React from 'react';
import Recommendations from 'components/dashboard/Recommendations';

const CityRecommendations = ({ cityData, labels, descriptions, showCityName = false }) => {
  const print = () => {
    const url = '/prefeitura/recomendacoes-impressao';
    const result = window.open(url, 'printPreview', 'menubar=0,location=0,width=1024,height=700');
    return result;
  };

  return (
    <div className="position-relative">
      <button
        type="button"
        className="btn btn-light bg-white rounded-2 position-absolute"
        style={{ top: '20px', right: '20px', borderColor: '#ccc' }}
        onClick={() => print()}
      >
        <i className="fa-solid fa-print fa-lg"></i>
      </button>
      <Recommendations cityData={cityData} labels={labels} descriptions={descriptions} />
    </div>
  );
};

export default CityRecommendations;
