import api from 'services/api';

const API_BASE_PATH = '/api/admin/contents';

const DEFAULT_QUANTITY = 10;

const findAll = async (page, qt = DEFAULT_QUANTITY, status, q) => {
  const params = {
    page,
    qt,
    status,
    q,
  };
  return await api.get(API_BASE_PATH, { params });
};

const findById = async (id) => {
  return await api.get(`${API_BASE_PATH}/${id}`);
};

const deleteById = async (id) => {
  return await api.delete(`${API_BASE_PATH}/${id}`);
};

const moveToTrash = async (id) => {
  return await api.patch(`${API_BASE_PATH}/${id}/trash`);
};

const restoreFromTrash = async (id) => {
  return await api.patch(`${API_BASE_PATH}/${id}/restore`);
};

const loadDimensions = async () => {
  return await api.get(`${API_BASE_PATH}/dimensions`);
};

const getLatestPresentationOrder = async () => {
  return await api.get(`${API_BASE_PATH}/last-presentation-order`);
};

const savePost = async (post) => {
  const formData = __getFormData(post);
  return await api.post(`${API_BASE_PATH}`, formData);
};

const updatePost = async (id, post) => {
  const formData = __getFormData(post);
  return await api.put(`${API_BASE_PATH}/${id}`, formData);
};

const __getFormData = (post) => {
  const formData = new FormData();
  Object.entries(post).forEach(([key, value]) => {
    if (key === 'image' || key === 'deleteOldImage') {
      formData.append(key, value);
    } else {
      if (value) {
        formData.append('content.' + key, value);
      }
    }
  });
  return formData;
};

export default {
  findAll,
  findById,
  deleteById,
  moveToTrash,
  restoreFromTrash,
  loadDimensions,
  getLatestPresentationOrder,
  savePost,
  updatePost,
};
