import React, { useState, useEffect } from 'react';

const OptionsHints = ({ options }) => {
  const [hasHint, setHasHint] = useState(false);

  useEffect(() => {
    Object.values(options).forEach((option) => {
      if (option.helper) {
        setHasHint(true);
      }
    });
  }, [options]);

  return (
    <>
      {hasHint && (
        <div>
          <h6>Glossário</h6>
          {Object.entries(options).map(([optCode, option], index) => {
            const bullet = optCode.substring(optCode.lastIndexOf('_') + 1);
            if (option.helper) {
              return (
                <div key={index}>
                  <b>{bullet}.</b> <span>{option.helper}</span>
                </div>
              );
            }
            return '';
          })}
        </div>
      )}
    </>
  );
};

export default OptionsHints;
