import api from 'services/api';

const API_BASE_PATH = '/api/admin/public-person';

const DEFAULT_QUANTITY = 10;

const findAll = async (page, qt = DEFAULT_QUANTITY) => {
  const params = {
    page,
    qt,
  };
  return await api.get(API_BASE_PATH, { params });
};

const deleteById = async (id, data) => {
  return await api.delete(`${API_BASE_PATH}/${id}`, { data });
};

export default { findAll, deleteById };
