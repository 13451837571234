export const formatRoleId = (text) => {
  return (
    'opt-' +
    text
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(' ', '-')
  );
};

// Verificar https://stackoverflow.com/a/52112155
export const getLocaleCode = () => {
  return navigator.userLanguage;
};

export const convertErrorsArray = (array, key) => {
  const initialValue = {};
  if (array) {
    const obj = array.reduce((acc, item) => {
      return {
        ...acc,
        [item.field]: item.error,
      };
    }, initialValue);
    return obj;
  }
  return initialValue;
};

// https://medium.com/@webcore1/react-fallback-for-broken-images-strategy-a8dfa9c1be1e
export const handleImageLoadError = (evt) => {
  evt.target.src = '/img/pic_cidades.jpg';
}
