import React from 'react';
import { Link } from 'react-router-dom';
import CitySearchField from './CitySearchField';
import CitySignIn from '../CitySignIn';
import NewsletterSubscription from '../NewsletterSubscription';

const PublicHome = () => (
  <>
    <CitySearchField />
    <section className="one-col-text py-5">
      <div className="container my-5">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-9 text-justify">
            <h1 className="c-cyan text-center fw-bolder mb-4">Objetivo da Plataforma</h1>
            <p>
              O objetivo do Plataforma <strong>inteli.gente</strong> é realizar um diagnóstico de maturidade para
              Cidades Inteligentes e Sustentáveis e propor diretrizes e eixos de atuação para elaboração da Política
              Nacional e da Política Municipal para Cidades Inteligentes Sustentáveis.
            </p>
            <p>
              A plataforma visa contribuir com os gestores públicos federais, estaduais e municipais, auxiliando-os a
              identificar as condições atuais das cidades, as competências e necessidades para avançar em direção a se
              tornarem cidades inteligentes sustentáveis, a médio e longo prazos. Além disso, o diagnóstico realizado
              possibilitará que outros interessados (setor privado, indústria, instituições públicas e privadas,
              academia e cidadãos) conheçam melhor a realidade das cidades, para encontrar formas de colaborar com a
              transformação digital e o desenvolvimento urbano sustentável local.
            </p>
            <div className="text-center mt-4">
              <Link to="/sobre" className="btn btn-success">
                Saiba mais
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="maturity-area py-5 bg-cyan">
      <div className="container my-5 py-5">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6 text-justify">
            <h1 className="fw-bolder mb-4">O Modelo de Maturidade</h1>
            <p>
              A plataforma está assentada no Modelo de Maturidade de Cidade Inteligente Sustentável da União
              Internacional de Telecomunicações, que estabelece critérios de diagnóstico para determinar o nível de
              maturidade de uma cidade nas dimensões econômica, ambiental e sociocultural. As três dimensões estão
              divididas em sete níveis de maturidade, medidos por um conjunto de indicadores-chave.
            </p>
            <div className="mt-4">
              <Link to="/metodologias" className="btn btn-success">
                Saiba mais
              </Link>
            </div>
          </div>
          <div className="col-12 col-lg-auto">
            <img src="img/art_piramide_maturidade.png" alt="Pirâmide - Modelo de Maturidade" />
          </div>
        </div>
      </div>
    </section>

    <CitySignIn />
    <NewsletterSubscription />
  </>
);

export default PublicHome;
