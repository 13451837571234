import React from 'react';

const LogoFooter = () => (
  <footer className="footer-logos p-5 d-flex justify-content-around align-items-center">
    <img src="/img/logo_rnp.png" alt="RNP - Rede Nacional de Ensino e Pesquisa" loading="lazy" height={80} />
    <img
      src="/img/logo_ctira.png"
      alt="CTIRA - Centro de Tecnologia da Informação Renato Archer"
      loading="lazy"
      height={70}
    />
    <img src="/img/logo_iara.png" alt="IARA - Inteligência Artificial Recriando Ambientes" loading="lazy" height={70} />
    <img
      src="/img/logo_mcti.png"
      alt="Ministério da Ciência, Tecnologia e Inovação - Governo Federal"
      loading="lazy"
      height={100}
    />
  </footer>
);

export default LogoFooter;
