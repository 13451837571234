import api from 'services/api';

const API_BASE_PATH = '/api/cities';

const findAllLabels = async () => {
  return await api.get(`${API_BASE_PATH}/score/labels`);
};

const findAllGroupValues = async () => {
  return await api.get(`${API_BASE_PATH}/score/group-values`);
};

const findByCityFriendlyName = async (cityFriendlyName) => {
  return await api.get(`${API_BASE_PATH}/${cityFriendlyName}`);
};

const findAllDescriptions = async () => {
  return await api.get(`${API_BASE_PATH}/score/descriptions`);
};

export default {
  findAllLabels,
  findAllGroupValues,
  findByCityFriendlyName,
  findAllDescriptions,
};
