import React from 'react';
import { Accordion } from 'react-bootstrap';
import { IndicatorSource, IndicatorValue, LabelHelper } from './IndicatorUtils';

export const LevelSummary = ({ summary, handleClick, cityLevel = 0 }) => {
  return (
    <table className="maturidade">
      <tbody>
        <tr>
          <td>Nível de maturidade</td>
          {summary.values.map((item) => {
            const labelValue = item.code.substring(item.code.length - 1);
            return (
              <React.Fragment key={`label-${summary.code}-${item.code}`}>
                <td>
                  <hr />
                </td>
                <td data-modal-level={labelValue}>
                  <div className={`circle-level bg-${labelValue} clickable`} onClick={() => handleClick(+labelValue)}>
                    <span>{labelValue}</span>
                  </div>
                </td>
              </React.Fragment>
            );
          })}
        </tr>
        <tr>
          <td>Nº de Municípios</td>
          {summary.values.map((item) => (
            <React.Fragment key={`qt-${summary.code}-${item.code}`}>
              <td></td>
              <td>{item.quantity}</td>
            </React.Fragment>
          ))}
        </tr>
      </tbody>
    </table>
  );
};

export const ResultDetails = ({ topics, dimensionIdx = 0, labels, handleClick }) => {
  return (
    <Accordion className="mt-4 maturidade">
      <Accordion.Item eventKey={dimensionIdx}>
        <Accordion.Header>Mais informações</Accordion.Header>
        <Accordion.Body>
          <table className="topics-indexes">
            <colgroup>
              <col style={{ width: '30%' }} />
              <col style={{ width: '50%' }} />
              <col style={{ width: '20%' }} />
            </colgroup>
            <thead>
              <tr>
                <th>Tópicos</th>
                <th>Indicadores</th>
                <th>
                  Valor médio
                  <LabelHelper text="Média calculada das bases de dados oficiais do governo." />
                </th>
              </tr>
            </thead>
            <tbody>
              {topics.map((topic) => {
                if (topic.indicators?.length > 0) {
                  return topic.indicators.map((indicator, idx) => (
                    <tr
                      key={`${topic.code}-${indicator.code}`}
                      className={idx === 0 ? 'new-topic' : ''}
                      data-ticode={`${topic.code}-${indicator.code}`}
                      onClick={(evt) => handleClick(indicator)}
                    >
                      {idx === 0 ? <td data-tcode={topic.code}>{topic.title}</td> : <td></td>}
                      <td data-icode={indicator.code}>{indicator.title}</td>
                      <td>
                        <IndicatorValue
                          value={indicator?.avg}
                          digits={indicator?.digits}
                          unit={labels[indicator.code]?.valueUnit}
                          notAvailableTitle="Cálculo da média não disponível"
                        />
                        <IndicatorSource
                          indicatorCode={indicator.code}
                          text={labels[indicator.code]?.source || 'Não informada'}
                        />
                      </td>
                    </tr>
                  ));
                } else {
                  return (
                    <tr key={topic.code}>
                      <td colSpan={3}>Sem dados</td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};
