import React from 'react';
import { useLocation } from 'react-router-dom';
import SidebarLink from 'components/SidebarItem';
import { applicationRoles } from 'helpers/statusUtils';
import api from 'services/api';

const CitySidebar = ({ cityUserData, mayor = false }) => {
  // const qtyPending = useSelector(state => state.cityAreaAdvisors.content.filter(advisor => advisor.status === status.PENDING).length)

  const { pathname } = useLocation();

  const handleDownloadClick = (evt) => {
    evt.preventDefault();
    api.get('/cities/data', { responseType: 'blob' }).then((response) => {
      // nome do header em minusculo
      const fileName = response.headers['content-disposition']?.split('filename=')[1] || 'inteligente-dados.xlsx';
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      setTimeout(() => window.URL.revokeObjectURL(url), 100); // this is important too, otherwise we will be unnecessarily spiking memory!
    });
  };

  const addActiveClassName = (routeToMatch, exact = true) => {
    if (exact === true) {
      return pathname === routeToMatch ? 'active' : '';
    }
    return pathname.startsWith(routeToMatch) ? 'active' : '';
  };

  return (
    <div className="bg-white shadow-right position-relative z-1 w-100">
      {cityUserData && (
        <header>
          <h4>{cityUserData.city}</h4>
          <h6>{cityUserData.state}</h6>
        </header>
      )}
      <ul className="list-group list-group-flush">
        <SidebarLink
          to="dashboard"
          className={`list-group-item list-group-item-action ${addActiveClassName('/prefeitura/dashboard')}`}
          requiredRoles={[applicationRoles.CITY]}
        >
          Níveis de maturidade
        </SidebarLink>
        <SidebarLink
          to="formulario"
          className={`list-group-item list-group-item-action ${addActiveClassName('/prefeitura/formulario')}`}
          requiredRoles={[applicationRoles.CITY]}
        >
          Preencher informações da sua cidade
        </SidebarLink>
        {mayor && (
          <SidebarLink
            to="equipe"
            className={`list-group-item list-group-item-action ${addActiveClassName('/prefeitura/equipe')}`}
            requiredRoles={[applicationRoles.CITY]}
          >
            Gerenciamento da Equipe de Assessores
          </SidebarLink>
        )}
        <li className="list-group-item list-group-item-action">
          <a onClick={handleDownloadClick} style={{ cursor: 'pointer' }}>
            Download dos dados <i className="fa-solid fa-file-arrow-down"></i>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default CitySidebar;
