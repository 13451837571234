import React from 'react';
import InputSearchCity from 'components/InputSearchCity';

const CitySearchField = () => {
  return (
    <section className="home-feat py-5 bg-blue-deep bg-img bg-img-home-feat">
      <div className="container my-lg-5 py-lg-5">
        <div className="row">
          <div className="col-12 col-lg-8">
            <h1>Qual município deseja encontrar?</h1>
            <div className="row mt-lg-4">
              <InputSearchCity
                className="col-8 d-flex align-items-center"
                id="fieldSearchCity"
                name="citySearch"
                placeholder="Digite o município..."
              />
              {/*
              <div className="col-2 d-flex align-items-center">
                <button className="btn btn-warning">Buscar</button>
              </div>
              */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CitySearchField;
