import React from 'react';

const FieldFileUpload = ({
  name,
  id,
  label,
  placeholder,
  value,
  labelClassName = 'form-label fw-semibold',
  className,
  disabled = false,
  required = false,
  readOnly = false,
  isError = false,
  infoMsg,
  errorMsg,
  onChange,
  accept,
}) => {
  const updatedClassName = `form-control ${className} ${isError ? 'invalid' : ''}`;
  return (
    <>
      <label className={labelClassName} htmlFor={id}>
        {label}
      </label>
      <input
        type="file"
        className={updatedClassName}
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        required={required}
        readOnly={readOnly}
        onChange={onChange}
        accept={accept}
      />
      {infoMsg && <p className="form-text mt-2">{infoMsg}</p>}
      {isError && (
        <p className="mt-2 mb-4 small c-danger">
          <i className="fa-solid fa-xmark"></i> {errorMsg}
        </p>
      )}
    </>
  );
};

export default FieldFileUpload;
