// https://javascript.plainenglish.io/object-freeze-vs-object-seal-vs-object-preventextensions-e78ef3a24201
export const userStatus = Object.freeze({
  PENDING: Number(process.env.REACT_APP_USER_STATUS_PENDING),
  APPROVED: Number(process.env.REACT_APP_USER_STATUS_APPROVED),
  REJECTED: Number(process.env.REACT_APP_USER_STATUS_REJECTED),
  WAITING_AUTOAPPROVAL: Number(process.env.REACT_APP_USER_STATUS_WAITING_AUTOAPPROVAL),
});

function createPublicRole(code, text) {
  return Object.freeze({ code: Number(code), text });
}

export const AUTH_TYPE_GOVERNANCE = process.env.REACT_APP_AUTH_TYPE_GOVERNANCE;

export const publicUserRoles = Object.freeze({
  ALL: createPublicRole(process.env.REACT_APP_PUBLIC_ROLE_ALL, process.env.REACT_APP_PUBLIC_ROLE_ALL_TEXT),
  RESEARCHER: createPublicRole(
    process.env.REACT_APP_PUBLIC_ROLE_RESEARCHER,
    process.env.REACT_APP_PUBLIC_ROLE_RESEARCHER_TEXT,
  ),
  PROFESSOR: createPublicRole(
    process.env.REACT_APP_PUBLIC_ROLE_PROFESSOR,
    process.env.REACT_APP_PUBLIC_ROLE_PROFESSOR_TEXT,
  ),
  STUDENT: createPublicRole(process.env.REACT_APP_PUBLIC_ROLE_STUDENT, process.env.REACT_APP_PUBLIC_ROLE_STUDENT_TEXT),
  ENTREPRENEUR: createPublicRole(
    process.env.REACT_APP_PUBLIC_ROLE_ENTREPRENEUR,
    process.env.REACT_APP_PUBLIC_ROLE_ENTREPRENEUR_TEXT,
  ),
  CONSULTANT: createPublicRole(
    process.env.REACT_APP_PUBLIC_ROLE_CONSULTANT,
    process.env.REACT_APP_PUBLIC_ROLE_CONSULTANT_TEXT,
  ),
  CITY_EMPLOYEE: createPublicRole(
    process.env.REACT_APP_PUBLIC_ROLE_CITY_EMPLOYEE,
    process.env.REACT_APP_PUBLIC_ROLE_CITY_EMPLOYEE_TEXT,
  ),
  STATE_EMPLOYEE: createPublicRole(
    process.env.REACT_APP_PUBLIC_ROLE_STATE_EMPLOYEE,
    process.env.REACT_APP_PUBLIC_ROLE_STATE_EMPLOYEE_TEXT,
  ),
  FEDERAL_EMPLOYEE: createPublicRole(
    process.env.REACT_APP_PUBLIC_ROLE_FEDERAL_EMPLOYEE,
    process.env.REACT_APP_PUBLIC_ROLE_FEDERAL_EMPLOYEE_TEXT,
  ),
  OTHERS: createPublicRole(process.env.REACT_APP_PUBLIC_ROLE_OTHERS, process.env.REACT_APP_PUBLIC_ROLE_OTHERS_TEXT),
});

export const applicationRoles = Object.freeze({
  GOVERNANCE: process.env.REACT_APP_ROLE_CODES_GOVERNANCE,
  GOVERNANCE_VIEWER: process.env.REACT_APP_ROLE_CODES_GOVERNANCE_VIEWER,
  CITY_MAYOR: process.env.REACT_APP_ROLE_CODES_CITY_MAYOR,
  CITY_ADVISOR: process.env.REACT_APP_ROLE_CODES_CITY_ADVISOR,
  CITY: process.env.REACT_APP_ROLE_CODES_CITY,
  SYSTEM_USER: process.env.REACT_APP_ROLE_CODES_SYSTEM_USER,
});

export const postStatus = Object.freeze({
  PUBLISHED: String(process.env.REACT_APP_POST_STATUS_PUBLISHED),
  HIDDEN: String(process.env.REACT_APP_POST_STATUS_HIDDEN),
  TRASH: String(process.env.REACT_APP_POST_STATUS_TRASH),
  ALL: String(process.env.REACT_APP_POST_STATUS_ALL),
});

export const reportStatus = Object.freeze({
  SENT: String(process.env.REACT_APP_REPORT_STATUS_SENT),
  DRAFTS: String(process.env.REACT_APP_REPORT_STATUS_DRAFTS),
  TRASH: String(process.env.REACT_APP_REPORT_STATUS_TRASH),
});

export const defaultHttpStatusMessages = Object.freeze({
  // SUCCESS
  200: 'Operação realizada com sucesso.',
  201: 'Envio realizado com sucesso.',
  // ERRORS
  400: 'Verifique todos os campos e tente novamente.',
  401: 'Acesso não autorizado.',
  403: 'Autenticação expirada ou você não tem permissão para acessar este conteúdo.',
  404: 'Recurso não encontrado.',
  409: 'Recurso possui conflito com existente no servidor.',
  500: 'Erro ao processar solicitação. Tente novamente mais tarde.',
});
