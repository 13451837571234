import { createSlice } from '@reduxjs/toolkit';
import api from 'services/api';

const initialState = {
  loading: false,
  success: null,
  labels: null,
  errorMsg: null,
};

export const labelsSlice = createSlice({
  name: 'labels',
  initialState,
  reducers: {
    request: (state) => {
      state.loading = true;
      state.success = null;
    },
    loadSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.labels = action.payload.labels;
      state.errorMsg = null;
    },
    loadError: (state, action) => {
      state.loading = false;
      state.success = false;
      state.labels = null;
      state.errorMsg = action.payload.errorMsg;
    },
    reset: (state) => {
      state.loading = false;
      state.success = null;
      state.labels = null;
      state.errorMsg = null;
    },
  },
});

export const { request, loadSuccess, loadError, reset } = labelsSlice.actions;

export const Actions = {
  loadLabels: () => {
    return (dispatch, getState) => {
      const currentState = getState();
      if (currentState.labels.success !== true) {
        dispatch(request());
        api
          .get('/api/cities/score/labels')
          .then((resp) => {
            dispatch(
              loadSuccess({
                labels: resp.data,
              }),
            );
          })
          .catch((err) => {
            console.error(err.response);
            dispatch(
              loadError({
                errorStatus: err?.response?.status || '',
                errorMsg: 'Erro ao carregar labels',
              }),
            );
          });
      }
    };
  },
};

export default labelsSlice.reducer;
