import AdminConfigurationService from 'services/admin/configuration';

import { adminConfigurationsSlice } from './slice';
import { setTabsGroups } from 'helpers/adminConfiguration';

export const AdminConfigurationsSliceActions = adminConfigurationsSlice.actions;

export const Actions = {
  loadConfigurations: () => {
    return (dispatch) => {
      dispatch(AdminConfigurationsSliceActions.loadingStart());
      AdminConfigurationService.findAll()
        .then((res) => {
          const data = setTabsGroups(res.data);
          dispatch(AdminConfigurationsSliceActions.success({ content: data }));
        })
        .catch(() => dispatch(AdminConfigurationsSliceActions.error('Ocorreu um erro ao carregar as configurações.')))
        .finally(() => dispatch(AdminConfigurationsSliceActions.loadingStop()));
    };
  },
  editConfiguration: (data) => {
    return (dispatch) => {
      dispatch(AdminConfigurationsSliceActions.mutateStart());
      AdminConfigurationService.editByName(data)
        .then(() => {
          dispatch(AdminConfigurationsSliceActions.mutateSuccess({ message: 'Configuração atualizada. ' }));
        })
        .catch(() =>
          dispatch(AdminConfigurationsSliceActions.mutateError({ message: 'Erro ao atualizar configuração. ' })),
        )
        .finally(() => dispatch(AdminConfigurationsSliceActions.mutateStop()));
    };
  },
  mutateClear: () => {
    return (dispatch) => {
      dispatch(AdminConfigurationsSliceActions.mutateClear());
    };
  },
};

const adminConfigurationsReducer = adminConfigurationsSlice.reducer;

export default { adminConfigurationsReducer };
