import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Actions as ContactUsActions } from 'services/slices/contactUs';
import ContactForm from './ContactForm';
import { Link } from 'react-router-dom';

const ContactUs = () => {
  const dispatch = useDispatch();
  const contactUs = useSelector((state) => state.contactUs);

  useEffect(() => {
    dispatch(ContactUsActions.getRoles());
    return () => {
      dispatch(ContactUsActions.reset());
    };
  }, [dispatch]);

  const tryAgain = () => {
    dispatch(ContactUsActions.reset());
  };

  return (
    <>
      {contactUs?.message === null ? (
        <ContactForm />
      ) : (
        <div className="page bg-cyan">
          <div className="container h-100">
            <div className="row h-100 justify-content-center">
              <div className="col-12 col-lg-8 bg-white">
                <div className="p-5 m-5">
                  {contactUs?.success === true && (
                    <>
                      <h1 className="fw-bold">
                        Mensagem enviada <i className="fa-solid fa-circle-check c-green"></i>
                      </h1>
                      <h3>Sua mensagem foi enviada com sucesso!</h3>
                      <div className="m-5 text-center">
                        <i className="fa-solid fa-envelope fa-10x c-gray-light"></i>
                      </div>
                      <p className="text-center mb-5">
                        <Link to="/municipios" className="btn btn-success">
                          Encontre um município
                        </Link>
                      </p>
                    </>
                  )}
                  {contactUs?.success === false && (
                    <>
                      <h1 className="fw-bold">
                        Mensagem não enviada <i className="fa-solid fa-circle-exclamation c-danger"></i>
                      </h1>
                      <h3>Sua mensagem não pôde ser enviada.</h3>
                      <div className="m-5 text-center">
                        <i className="fa-solid fa-envelope fa-10x c-gray-light"></i>
                      </div>
                      <p className="text-center mb-5">
                        <a href="#" onClick={tryAgain} className="btn btn-success">
                          Tente novamente
                        </a>
                      </p>
                    </>
                  )}
                  <p>{contactUs?.message}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ContactUs;
