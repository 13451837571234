import { createSlice } from '@reduxjs/toolkit';
import { defaultHttpStatusMessages } from 'helpers/statusUtils';
import { Actions as UserAuthActions } from './userAuth';
import api from 'services/api';

function handleErrorMesssage(response, msg) {
  const complementMsg = msg ? ' ' + msg : '';
  if (response && 'status' in response) {
    return defaultHttpStatusMessages[response.status] + complementMsg;
  }
  return 'Não foi possível completar a operação.' + complementMsg;
}

const initialState = {
  userData: {},
  loading: false,
  loadSuccess: null,
  updateSuccess: null,
  deleteSuccess: null,
  errorMsg: null,
};

export const accountManagementSlice = createSlice({
  name: 'accountManagement',
  initialState,
  reducers: {
    loadDataRequest: (state) => {
      state.userData = {};
      state.loading = true;
      state.loadSuccess = null;
      state.updateSuccess = null;
      state.deleteSuccess = null;
      state.errorMsg = null;
    },
    loadDataSuccess: (state, action) => {
      state.loading = false;
      state.userData = action.payload.userData;
      state.loadSuccess = true;
    },
    loadDataError: (state, action) => {
      state.loading = false;
      state.loadSuccess = false;
      state.errorMsg = action.payload.message;
    },
    updateDataSuccess: (state, action) => {
      state.loading = false;
      state.userData = action.payload.userData;
      state.updateSuccess = true;
    },
    updateDataError: (state, action) => {
      state.loading = false;
      state.updateSuccess = false;
      state.errorMsg = action.payload.message;
    },
    deleteSuccess: (state) => {
      state.loading = false;
      state.deleteSuccess = true;
    },
    deleteError: (state, action) => {
      state.loading = false;
      state.deleteSuccess = false;
      state.errorMsg = action.payload.message;
    },
    clearData: (state) => {
      state = initialState;
    },
  },
});

export const {
  loadDataRequest,
  loadDataSuccess,
  loadDataError,
  updateDataSuccess,
  updateDataError,
  deleteSuccess,
  deleteError,
  clearData,
} = accountManagementSlice.actions;

export const Actions = {
  loadData: () => {
    return (dispatch) => {
      dispatch(loadDataRequest());
      api
        .get('api/me')
        .then((response) => {
          dispatch(loadDataSuccess({ userData: response.data }));
        })
        .catch((error) => {
          dispatch(loadDataError({ message: handleErrorMesssage(error.response) }));
          if (!(error.response?.status === 403)) {
            console.log(error);
          }
        });
    };
  },

  updateGovernanceFirstAccessData: (updatedUserData) => {
    return (dispatch) => {
      dispatch(loadDataRequest());
      api
        .put('api/me/governance-first-access', updatedUserData)
        .then((response) => {
          dispatch(updateDataSuccess({ userData: response.data }));
          dispatch(UserAuthActions.updateData(response.data));
        })
        .catch((error) => {
          dispatch(updateDataError({ message: handleErrorMesssage(error.response) }));
          if (error.response && 'status' in error.response) {
            if (!(error.response?.status === 403)) {
              console.log(error);
            }
          }
        });
    };
  },

  acceptTermsOfUse: (userData) => {
    return (dispatch) => {
      dispatch(loadDataRequest());
      api
        .put('api/me/terms-of-use', userData)
        .then((response) => {
          dispatch(updateDataSuccess({ userData: response.data }));
          dispatch(UserAuthActions.updateData(response.data));
        })
        .catch((error) => {
          dispatch(updateDataError({ message: handleErrorMesssage(error.response) }));
          if (error.response && 'status' in error.response) {
            if (!(error.response?.status === 403)) {
              console.log(error);
            }
          }
        });
    };
  },

  updateData: (userData) => {
    return (dispatch) => {
      dispatch(loadDataRequest());
      api
        .put('api/me', userData)
        .then((response) => {
          dispatch(updateDataSuccess({ userData: response.data }));
          dispatch(UserAuthActions.updateData(response.data));
        })
        .catch((error) => {
          dispatch(updateDataError({ message: handleErrorMesssage(error.response) }));
          if (error.response && 'status' in error.response) {
            if (!(error.response?.status === 403)) {
              console.log(error);
            }
          }
        });
    };
  },

  delete: (reason) => {
    return (dispatch) => {
      dispatch(loadDataRequest());
      api
        .delete('/api/me', { data: { origin: 1, reason } })
        .then((resp) => {
          dispatch(deleteSuccess());
        })
        .catch((err) => {
          dispatch(deleteError({ message: handleErrorMesssage(err.response) }));
        });
    };
  },

  clearData: () => {
    return (dispatch) => {
      dispatch(clearData());
    };
  },
};

export default accountManagementSlice.reducer;
