import React from 'react';
import { Link } from 'react-router-dom';

const CitySignIn = () => {
  return (
    <>
      <section className="cta-signin-join py-5 bg-img bg-img-cta-signin-join">
        <div className="container my-5 py-5">
          <div className="row">
            <div className="col-12 col-lg-5 offset-lg-6">
              <div className="bg-white-90 p-5 shadow">
                <div className="m-lg-4">
                  <h2 className="c-cyan fw-bolder mb-4">É prefeito ou assessor de prefeito?</h2>
                  <p>
                    Cadastre-se para inserir sua cidade no programa. Assim, você poderá ter acesso a métricas de
                    diagnóstico do nível de maturidade e desenvolvimento tecnológico de seu município.
                  </p>
                  <div className="mt-4">
                    <Link to="/prefeitura/login" className="btn btn-success w-100">
                      Entrar ou fazer novo cadastro
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CitySignIn;
