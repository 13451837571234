import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Validators from 'helpers/Validators';
import { Creators as CitiesListActions } from 'services/ducks/citiesList';
import { applicationRoles } from 'helpers/statusUtils';
import AutoComplete from 'components/AutoComplete';
import { setMask } from 'helpers/inputMasks';

const FieldInput = ({
  type = 'text',
  name,
  id,
  label,
  placeholder,
  value,
  labelClassName = 'form-label',
  className,
  isError = false,
  errorMsg,
  onChange,
}) => {
  const updatedClassName = `form-control ${className} ${isError ? 'invalid' : ''}`;
  return (
    <>
      <label className={labelClassName} htmlFor={id}>
        {label}
      </label>
      <input
        type={type}
        className={updatedClassName}
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      {isError && (
        <p className="mt-2 mb-4 small c-danger">
          <i className="fa-solid fa-xmark"></i> {errorMsg}
        </p>
      )}
    </>
  );
};

const FieldInputAutoComplete = ({
  name,
  id,
  label,
  placeholder,
  value,
  labelClassName = 'form-label',
  className,
  isError = false,
  errorMsg = 'Preenchimento errado',
  onChange,
}) => {
  const dispatch = useDispatch();
  const citiesSuggestions = useSelector((state) => state.citiesList.searchResult);

  useEffect(() => {
    dispatch(CitiesListActions.resetCitiesList());
  }, [dispatch]);

  const selectCity = (evt) => {
    const val = evt.target.value;
    let cityId = {
      id: '',
    };
    if (citiesSuggestions.map((city) => city.result).includes(val)) {
      cityId = { id: citiesSuggestions.filter((city) => city.result === val)[0].id };
    }
    onChange(evt, cityId);
    dispatch(CitiesListActions.resetCitiesList());
  };

  const updateSuggestions = (input) => {
    dispatch(CitiesListActions.searchCity(input));
  };

  const updatedClassName = `form-control ${className || ''} ${isError ? 'invalid' : ''}`;

  return (
    <>
      <label className={labelClassName} htmlFor={id}>
        {label}
      </label>
      <AutoComplete
        WrapperComponent="span"
        inputClassName={updatedClassName}
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        suggestions={citiesSuggestions}
        onChange={selectCity}
        onUpdate={updateSuggestions}
      />
      {isError && (
        <p className="mt-2 mb-4 small c-danger">
          <i className="fa-solid fa-xmark"></i> {errorMsg}
        </p>
      )}
    </>
  );
};

const Step1 = (props) => {
  const { role, department, city, cityId, telephoneNumber, email } = props.data;
  const { isDepartment, isCity, isCityId, isPhoneNumber, validEmail } = Validators;

  return (
    <div className="px-5">
      <div className="mb-4">
        <h5 className="fw-bold c-blue-deep">Cargo</h5>
        <label className="form-label d-block ps-3" htmlFor="optRoleMayor">
          <input
            type="radio"
            id="optRoleMayor"
            name="role"
            value={applicationRoles.CITY_MAYOR}
            onChange={props.handleChange}
            checked={role === applicationRoles.CITY_MAYOR}
          />
          Prefeito
        </label>
        <label className="form-label d-block ps-3" htmlFor="optRoleAdvisor">
          <input
            type="radio"
            id="optRoleAdvisor"
            name="role"
            value={applicationRoles.CITY_ADVISOR}
            onChange={props.handleChange}
            checked={role === applicationRoles.CITY_ADVISOR}
          />
          Assessor
        </label>
      </div>
      {role === applicationRoles.CITY_ADVISOR && (
        <div className="mb-3">
          <FieldInput
            id="fieldDepartment"
            label="Departamento"
            name="department"
            placeholder="Digite uma área"
            value={department}
            onChange={props.handleChange}
            isError={isDepartment(role, department) === false}
            errorMsg="Digite uma área."
          />
        </div>
      )}
      <div className="mb-3">
        <FieldInputAutoComplete
          id="fieldCitySearch"
          label="Município"
          name="city"
          placeholder="Digite o nome do município"
          value={city}
          onChange={props.handleChange}
          isError={isCity(city) === false || (isCity(city) === true && isCityId(cityId) === false)}
          errorMsg="Selecione um município"
        />
      </div>
      <div className="mb-3">
        <FieldInput
          type="email"
          id="fieldEmail"
          label="E-mail"
          name="email"
          placeholder="Digite seu e-mail institucional"
          value={email}
          onChange={props.handleChange}
          isError={validEmail(email, true) === false}
          errorMsg="Digite um e-mail válido."
        />
      </div>
      <div className="mb-5">
        <FieldInput
          id="fieldTelephoneNumber"
          label="Telefone"
          name="telephoneNumber"
          placeholder="Digite o número do seu telefone institucional"
          value={setMask('telephoneNumber', telephoneNumber)}
          onChange={props.handleChange}
          isError={isPhoneNumber(telephoneNumber) === false}
          errorMsg="Digite um telefone válido."
        />
      </div>
      {/*
      <div className="text-center">
        <a href="Portal_Cadastro.html" className="btn btn-outline-success me-3">
          Voltar
        </a>
        <a href="Portal_Cadastro_3.html" className="btn btn-success">
          Continuar
        </a>
      </div>
      */}
    </div>
  );
};

export default Step1;
