import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Actions as DashboardActions } from 'slices/admin/dashboard';
import Filter from './Filter';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import GroupInfo from './GroupInfo';
import CitiesMap from './CitiesMap';
import { useTitle } from 'hooks/useTitle';
import { Actions as LabelActions } from 'services/labels';
import { getFiltersFromLabels } from 'helpers/dashboard';
import SurveyChart from './SurveyChart';

const AdminDashboardSummary = () => {
  const pageTitle = useTitle('Níveis de Maturidade');
  const dispatch = useDispatch();

  const groupValues = useSelector((state) => state.adminDashboard.groupValues);
  const labels = useSelector((state) => state.labels.labels);

  const [valueSelected, setValueSelected] = useState('');
  const [groupSelected, setGroupSelected] = useState({});

  useEffect(() => {
    dispatch(LabelActions.loadLabels());
    dispatch(DashboardActions.loadData());
  }, [dispatch]);

  const localGroupValues = useMemo(() => {
    if (groupValues && labels) {
      console.time('groupValues');
      const options = {
        t1: [], // Pais
        t2: [], // Estado
        t3: [], // Regiao
        t4: [], // Cluster populacional (classe)
        t5: [], // Rede de influência (nivel hierarquia)
      };

      for (const data of groupValues) {
        const topics = {};
        // let indicators = []
        for (const indicator of data.indicators) {
          if (!topics[indicator.topicCode]) {
            topics[indicator.topicCode] = {
              indicators: [],
              code: indicator.topicCode,
              dimensionCode: indicator.dimensionCode,
              title: labels[indicator.topicCode]?.title || indicator.topicCode,
            };
          }
          // indicators.push({ ...indicator, title: labels[indicator.code].title })
          topics[indicator.topicCode].indicators.push({
            ...indicator,
            title: labels[indicator.code]?.title || indicator.code,
            unit: labels[indicator.code]?.valueUnit,
            source: labels[indicator.code]?.source || 'Não definido',
          });
        }
        options['t' + data.type].push({ ...data, /* indicators: indicators, */ topics });
      }
      const retVal = {
        t1: options.t1.sort((a, b) => (a.value < b.value ? -1 : 1)),
        t2: options.t2.sort((a, b) => (a.value < b.value ? -1 : 1)),
        t3: options.t3.sort((a, b) => (a.value < b.value ? -1 : 1)),
        t4: options.t4.sort((a, b) => (a.value < b.value ? -1 : 1)),
        t5: options.t5.sort((a, b) => (a.value < b.value ? -1 : 1)),
      };
      console.timeEnd('groupValues');
      return retVal;
    }
    return {};
  }, [groupValues, labels]);

  const handleGroupValue = useCallback(
    (newSelected) => {
      if (localGroupValues && Object.keys(localGroupValues).length > 0) {
        if (newSelected.startsWith('est')) {
          setGroupSelected(localGroupValues.t2.filter((opt) => opt.filterValue === newSelected)[0] || {});
        } else if (newSelected.startsWith('reg')) {
          setGroupSelected(localGroupValues.t3.filter((opt) => opt.filterValue === newSelected)[0] || {});
        } else if (newSelected.startsWith('pop')) {
          setGroupSelected(localGroupValues.t4.filter((opt) => opt.filterValue === newSelected)[0] || {});
        } else if (newSelected.startsWith('arj')) {
          setGroupSelected(localGroupValues.t5.filter((opt) => opt.filterValue === newSelected)[0] || {});
        } else {
          setGroupSelected(localGroupValues.t1[0] || {});
        }
      }
    },
    [localGroupValues],
  );

  useEffect(() => handleGroupValue(''), [handleGroupValue]);

  const onSelectedOption = (newSelected) => {
    if (valueSelected !== newSelected) {
      setValueSelected(newSelected);
      handleGroupValue(newSelected);
    }
  };

  const handleMapRenderFinish = useCallback(() => console.log('Mapa carregado. '), []);

  return (
    <div className="bg-gray-superlight px-5 py-4 w-100">
      {/* <!-- Cabeçalho Página - start --> */}
      <header>
        <h3 className="fw-bolder c-cyan mb-0">{pageTitle}</h3>
        <p className="path">
          <Link to="">Início</Link> &rsaquo; <span className="current">{pageTitle}</span>
        </p>
      </header>
      {/* <!-- Cabeçalho Página - end --> */}

      <Filter
        valueSelected={valueSelected}
        filters={getFiltersFromLabels(labels)}
        onSelectedOption={onSelectedOption}
      />

      <div className="row d-flex align-items-stretch ms-0 gx-5">
        <div className="col-12 col-lg-8 bg-white shadow p-5">
          <GroupInfo groupSelected={groupSelected} labels={labels} />
        </div>
        <div className="col-12 col-lg-4">
          <SurveyChart groupSelected={groupSelected} chartId="score-chart-data" />
          <CitiesMap
            type={groupSelected?.type}
            selectionValue={groupSelected?.value}
            mapHeight="600px"
            legendHeight="200px"
            onRenderFinish={handleMapRenderFinish}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardSummary;
