import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { showToast, dismissToast } from 'components/Toast';
import { setMask, unsetMask } from 'helpers/inputMasks';
import CrudFieldInput from 'components/CrudFieldInput';
import { useTitle } from 'hooks/useTitle';
import { notEmpty, validEmail, validName } from 'helpers/Validators';
import { Actions as AdminUserActions } from 'services/slices/user';

const Form = () => {
  const { id } = useParams();

  const navigate = useNavigate(); // Mudança do router v6 em relação ao v5

  const dispatch = useDispatch();

  const userGovernance = useSelector((state) => state.users);

  useTitle(`Usuários administrativos > ${id ? 'Editar' : 'Incluir novo'}`);

  const toastId = useRef(null);

  const [data, setData] = useState({});
  const [dataValidation, setDataValidation] = useState({});
  const [toastInfo, setToastInfo] = useState({});
  const [btnDisabled, setBtnDisabled] = useState(true);

  const validate = (name, value) => {
    switch (name) {
      case 'name':
        return !!validName(value);
      case 'email':
        return !!validEmail(value);
      case 'registrationType':
      case 'telephoneNumber':
        return !!notEmpty(value);
      default:
        return true;
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(AdminUserActions.getGovernanceUserById(id));
    }
    return () => {
      dispatch(AdminUserActions.clearData());
    };
  }, [dispatch, id]);

  useEffect(() => {
    setData(userGovernance?.item);
  }, [userGovernance?.item]);

  useEffect(() => {
    if (userGovernance?.mutateSuccess === true) {
      setToastInfo({ type: 'success', message: userGovernance?.message });
    } else if (userGovernance?.mutateSuccess === false) {
      setToastInfo({ type: 'danger', message: userGovernance?.message });
    }
  }, [userGovernance?.mutateSuccess, userGovernance?.message]);

  useEffect(() => {
    const { message, type, autoClose } = toastInfo;
    if (message && type) {
      if (toastId.current !== null) {
        dismissToast(toastId.current);
        toastId.current = null;
      }
      toastId.current = toastId.current === null ? showToast(message, type, autoClose) : toastId.current;
    }
  }, [dispatch, toastInfo]);

  useEffect(() => {
    setBtnDisabled(
      !(
        dataValidation.name &&
        dataValidation.email &&
        dataValidation.registrationType &&
        dataValidation.telephoneNumber
      ),
    );
  }, [dataValidation]);

  const handleInputChange = (evt) => {
    const { name, value } = evt.target;
    let newValue;
    if (name === 'telephoneNumber') {
      newValue = unsetMask(name, value);
    } else {
      newValue = value;
    }
    setData((current) => ({ ...current, [name]: newValue }));
    setDataValidation((current) => ({ ...current, [name]: validate(name, value) }));
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    dispatch(
      AdminUserActions.saveGovernanceUser(data, () => {
        navigate('/portal/usuarios-admin');
      }),
    );
  };

  const showError = (name) => {
    return name in dataValidation && !dataValidation[name] === true;
  };

  return (
    <>
      <div className="bg-white shadow crud p-5">
        {data && (
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <CrudFieldInput
                id="fieldName"
                name="name"
                label="Nome"
                placeholder="Nome completo"
                required
                value={data?.name || ''}
                disabled={id}
                onChange={handleInputChange}
                isError={showError('name')}
                errorMsg={`Digite um nome válido. `}
              />
            </div>
            <div className="mb-4">
              <label className="form-label fw-semibold">Perfil de acesso</label>
              <select
                name="registrationType"
                className="form-select"
                required
                disabled={id}
                onChange={handleInputChange}
                value={data?.registrationType || ''}
              >
                <option value={''} disabled>
                  Selecione uma opção
                </option>
                <option value={1}>Governança</option>
                <option value={2}>Visualização</option>
              </select>
              {showError('registrationType') && (
                <p className="mt-2 mb-4 small c-danger">
                  <i className="fa-solid fa-xmark"></i> Selecione um perfil de acesso.
                </p>
              )}
            </div>
            <div className="row mb-4">
              <div className="col-lg-6">
                <CrudFieldInput
                  label="E-mail institucional"
                  type="email"
                  name="email"
                  id="fieldEmail"
                  placeholder="E-mail institucional"
                  required
                  value={data?.email || ''}
                  disabled={id}
                  onChange={handleInputChange}
                  isError={showError('email')}
                  errorMsg={`Digite um e-mail válido. `}
                />
              </div>
              <div className="col-lg-6">
                <CrudFieldInput
                  label="Telefone"
                  name="telephoneNumber"
                  id="fieldTelephoneNumber"
                  placeholder="Número de telefone com DDD"
                  value={setMask('telephoneNumber', data?.telephoneNumber || '')}
                  disabled={id}
                  onChange={handleInputChange}
                  isError={showError('telephoneNumber')}
                  errorMsg={`Digite um número de telefone válido. `}
                />
              </div>
            </div>

            <div className="mt-5 text-center">
              {!id && (
                <>
                  <Link to={'..'} className="btn btn-secondary me-3">
                    Cancelar
                  </Link>
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={btnDisabled || userGovernance?.loading === true}
                  >
                    {!id && userGovernance?.loading === true ? 'Aguarde...' : 'Salvar'}
                  </button>
                </>
              )}
              {id && (
                <Link to={'..'} className="btn btn-secondary">
                  <i className="fa-solid fa-arrow-left"></i> Voltar
                </Link>
              )}
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default Form;
