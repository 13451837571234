import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Actions as DashboardCityActions } from 'slices/city/dashboard';
import { Actions as LabelActions } from 'services/labels';
import { getUserData } from 'helpers/userAuthUtils';
import Dimensions from 'components/dashboard/Dimensions';
import MaturityLevel from 'components/dashboard/MaturityLevel';
import CityRecommendations from './CityRecommendations';

const tabOptions = [
  {
    code: 'maturidade',
    label: 'Níveis de Maturidade',
  },
  {
    code: 'dimensoes',
    label: 'Detalhes das Dimensões',
  },
  {
    code: 'recomendacoes',
    label: 'Recomendações e Diretrizes',
  },
];

const Tabs = ({ filters, valueSelected, onSelectedOption }) => (
  <div className="bg-white shadow">
    <header className="bg-white admin">
      <div className="tabs w-100 h-100 justify-content-between">
        {filters?.map((f) => (
          <div key={f.code} className={`tab w-33 ${f.code === valueSelected ? 'active' : ''}`}>
            <a href={`#${f.code}`} onClick={() => onSelectedOption(f.code)}>
              {f.label}
            </a>
          </div>
        ))}
      </div>
    </header>
  </div>
);

const AdminCityDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = useState(tabOptions[0]);

  const labels = useSelector((state) => state.labels.labels);
  const descriptions = useSelector((state) => state.cityDashboard.descriptions);
  const cityData = useSelector((state) => state.cityDashboard.item);

  useEffect(() => {
    const cityFriendlyName = getUserData().cityUserData?.cityFriendlyName;
    if (!cityFriendlyName) {
      navigate('/portal/prefeitura');
    } else {
      dispatch(LabelActions.loadLabels());
      dispatch(DashboardCityActions.loadCityItem(cityFriendlyName));
    }
  }, [dispatch, navigate]);

  const handleSelectTab = (tabCode) => {
    if (tabCode !== selectedTab) {
      setSelectedTab(() => tabOptions.filter((t) => t.code === tabCode)[0]);
    }
  };

  return (
    <>
      {/* Coluna Corpo - start  */}
      <div className="bg-gray-superlight px-5 py-4 w-100">
        {/* Cabeçalho Página - start */}
        <header>
          <h3 className="fw-bolder c-cyan mb-0">{selectedTab.label}</h3>
          <p className="path">
            <Link to="/portal/dashboard">Início</Link> &rsaquo; <span className="current">{selectedTab.label}</span>
          </p>
        </header>
        {/* Cabeçalho Página - end */}

        <Tabs valueSelected={selectedTab.code} onSelectedOption={handleSelectTab} filters={tabOptions} />

        {/* Conteúdo apresentado após selecionar as tabs - início */}
        {selectedTab.code === tabOptions[0].code && (
          <MaturityLevel cityData={cityData} labels={labels} descriptions={descriptions} />
        )}
        {selectedTab.code === tabOptions[1].code && <Dimensions cityData={cityData} labels={labels} />}
        {selectedTab.code === tabOptions[2].code && (
          <CityRecommendations cityData={cityData} labels={labels} descriptions={descriptions} />
        )}
        {/* Conteúdo apresentado após selecionar as tabs - fim */}
      </div>

      {/* Coluna Corpo - end  */}
    </>
  );
};

export default AdminCityDashboard;
