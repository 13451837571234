import { createSlice } from '@reduxjs/toolkit';
import PublicService from 'services/api/public/publicService';

const initialState = {
  loading: null,
  success: null,
  roles: null,
  errorMsg: null,
};

const rolesDataSlice = createSlice({
  name: 'rolesData',
  initialState,
  reducers: {
    loadingStart: (state) => {
      state.loading = true;
      state.success = null;
    },
    loadingStop: (state) => {
      state.loading = false;
    },
    success: (state, action) => {
      state.success = true;
      state.roles = action.payload?.roles;
      state.errorMsg = null;
    },
    error: (state, action) => {
      state.success = false;
      state.roles = null;
      state.errorMsg = action.payload?.errorMsg;
    },
    reset: () => {
      return { ...initialState };
    },
  },
});

export const { loadingStart, loadingStop, success, error, reset } = rolesDataSlice.actions;

export const Actions = {
  loadRoles: () => {
    return (dispatch, getState) => {
      const currentState = getState();
      if (currentState.rolesData?.success !== true) {
        dispatch(loadingStart());
        PublicService.getRoles()
          .then((resp) => {
            dispatch(
              success({
                roles: resp.data,
              }),
            );
          })
          .catch((err) => {
            dispatch(
              error({
                errorStatus: err.response?.status ?? -1,
                errorMsg: 'Erro ao carregar papeis de usuário. ',
              }),
            );
          })
          .finally(() => dispatch(loadingStop()));
      }
    };
  },
  reset: () => {
    return (dispatch) => {
      dispatch(reset());
    };
  },
};

export default rolesDataSlice.reducer;
