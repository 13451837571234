import CityDashboardService from 'services/city/dashboard';

import { cityInfoSlice } from 'slices/city/dashboard/slice';

export const cityInfoSliceActions = cityInfoSlice.actions;

export const Actions = {
  loadCityItem: (cityFriendlyName) => {
    return (dispatch) => {
      dispatch(cityInfoSliceActions.loadingStart());
      Promise.allSettled([
        CityDashboardService.findByCityFriendlyName(cityFriendlyName),
        CityDashboardService.findAllDescriptions(),
      ])
        .then(([cityItemPromise, descriptionsPromise]) => {
          dispatch(
            cityInfoSliceActions.success({
              item: cityItemPromise.value?.data,
              descriptions: descriptionsPromise.value?.data,
            }),
          );
        })
        .catch(() => dispatch(cityInfoSliceActions.error('Ocorreu um erro ao carregar os dados do município. ')))
        .finally(() => dispatch(cityInfoSliceActions.loadingStop()));
    };
  },
  clearData: () => {
    return (dispatch) => {
      dispatch(cityInfoSliceActions.reset());
    };
  },
};

const cityInfoReducer = cityInfoSlice.reducer;
export default { cityInfoReducer };
