import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { showToast, dismissToast } from 'components/Toast';
import { Actions as AccountManagementActions } from 'services/accountManagement';
import { logoutUrl } from 'helpers/govBrUtils';
/*
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { showToast, dismissToast } from 'components/Toast';
import { setMask, unsetMask } from 'helpers/inputMasks';
import CrudFieldInput from 'components/CrudFieldInput';
import { useTitle } from 'hooks/useTitle';
import { notEmpty, validEmail, validName } from 'helpers/Validators';
import { Actions as AdminUserActions } from 'services/slices/user';
*/

export const ConfirmationModal = ({ show, title, message, onClose, onConfirmation }) => {
  return (
    <Modal className="inteligente-modal" show={show} onHide={onClose} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-outline-secondary" onClick={onClose}>
          Não
        </button>
        <button type="button" className="btn btn-danger" onClick={onConfirmation}>
          Sim
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export const DoneModal = ({ show, onClose }) => {
  return (
    <Modal className="inteligente-modal" show={show} onHide={onClose} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton>
        <Modal.Title>Conta excluída</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Sua conta foi excluída.</p>
        <p>Clique em OK para voltar à tela inicial da Plataforma inteli.gente</p>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-primary" onClick={onClose}>
          OK
        </button>
      </Modal.Footer>
    </Modal>
  );
};

const DeleteAccount = () => {
  const toastId = useRef(null);

  const [reason, setReason] = useState('');
  const [toastInfo, setToastInfo] = useState({});
  const [modalInfo, setModalInfo] = useState();
  const [showDoneModal, setShowDoneModal] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);

  // eslint-disable-next-line
  const deleteLoading = useSelector((state) => state.accountManagement.loading);

  const deleteSuccess = useSelector((state) => state.accountManagement.deleteSuccess);

  const dispatch = useDispatch();

  const handleReason = (evt) => {
    const value = evt.target.value;
    setReason(value);
    setBtnDisabled(!(value?.length > 0));
  };

  const closeConfirmationModal = () => {
    setModalInfo(null);
  };

  useEffect(() => {
    const { message, type, autoClose } = toastInfo;
    if (message && type) {
      if (toastId.current !== null) {
        dismissToast(toastId.current);
        toastId.current = null;
      }
      toastId.current = toastId.current === null ? showToast(message, type, autoClose) : toastId.current;
    }
  }, [dispatch, toastInfo]);

  useEffect(() => {
    if (deleteSuccess === true) {
      setShowDoneModal(true);
    } else if (deleteSuccess === false) {
      setToastInfo({ type: 'danger', message: 'Erro ao excluir a conta. Tente novamente mais tarde.' });
    }
    dispatch(AccountManagementActions.clearData());
  }, [dispatch, deleteSuccess]);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setModalInfo({
      title: 'Excluir conta',
      message: `Confirma a exclusão definitiva de sua conta?`,
      onConfirmation: handleDeleteConfirmation,
    });
  };

  const handleDeleteConfirmation = (id, deletionObj) => {
    dispatch(AccountManagementActions.delete(reason));
    closeConfirmationModal();
  };

  const handleCloseDoneModal = () => {
    // FAZ LOGOUT VIA GOV.BR
    window.location.href = logoutUrl;
  };

  return (
    <>
      <div className="bg-white shadow crud p-5">
        <form onSubmit={handleSubmit}>
          <div className="form-group mb-3">
            <div className="alert alert-danger" role="alert">
              <p>
                <strong>ATENÇÃO</strong>: você está excluindo o seu próprio cadastro. Dessa maneira, você perderá seu
                acesso à plataforma.
                <br />
                Prossiga somente se você tiver certeza, pois esta ação é irreversível.
              </p>
            </div>
          </div>

          <div className="form-group mb-4">
            <label className="form-label fw-semibold">Motivo da exclusão</label> <br />
            <textarea
              className="form-control"
              id="disapproval"
              name="reason"
              rows={5}
              value={reason}
              maxLength={1024}
              placeholder="Informe o motivo da exclusão"
              onChange={handleReason}
            ></textarea>
          </div>
          <div className="mt-5 text-center">
            <button type="submit" className="btn btn-danger" disabled={btnDisabled}>
              Excluir
            </button>
          </div>
        </form>
      </div>
      <ConfirmationModal show={!!modalInfo} onClose={closeConfirmationModal} {...modalInfo} />
      <DoneModal show={showDoneModal} onClose={handleCloseDoneModal} />
    </>
  );
};

export default DeleteAccount;
