import React from 'react';
import AdminSidebar from './Sidebar';
import { Navigate, Outlet } from 'react-router-dom';
import { getRole, isAuthenticated } from 'helpers/userAuthUtils';

/*
  Para rotas privadas, considerar o que está no link:
  https://www.robinwieruch.de/react-router-private-routes/

  TODO: verificar melhor componente para lógica de redirect
*/
const AdminMain = () => {
  const authenticated = isAuthenticated();
  const userRole = getRole();

  const notAllowed = !authenticated || (userRole !== 'ROLE_GOVERNANCE' && userRole !== 'ROLE_GOVERNANCE_VIEWER');

  if (notAllowed) {
    return <Navigate to={'/portal/login'} replace />;
  }

  return (
    <div className="d-flex">
      <div className="col-admin-menu">
        <AdminSidebar />
      </div>
      <div className="col-admin-content">
        <Outlet />
      </div>
    </div>
  );
};

export default AdminMain;
