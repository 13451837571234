import crypto from 'crypto-js';
import { applicationRoles } from './statusUtils';

const encrypt = (data) => {
  return crypto.AES.encrypt(data, process.env.REACT_APP_ENCRYPTION_KEY).toString();
};

const decrypt = (encData) => {
  return crypto.AES.decrypt(encData, process.env.REACT_APP_ENCRYPTION_KEY).toString(crypto.enc.Utf8);
};

const APP_INFO_KEY = '@app-info';

export const isAuthenticated = () => {
  return localStorage.getItem(APP_INFO_KEY) !== null;
};

export const getUserData = () => {
  const storageData = localStorage.getItem(APP_INFO_KEY);
  if (storageData) {
    try {
      return JSON.parse(decrypt(storageData));
    } catch (err) {
      console.error('Error retriving data from localstorage - removing item');
      localStorage.removeItem(APP_INFO_KEY);
    }
  }
  return null;
};

export const getToken = () => {
  if (!isAuthenticated()) {
    return null;
  }
  return getUserData()?.token;
};

// export const verifyTokenExpiration = () => {
//   const token = getToken();
//   if (token) {
//     const decoded = jwt.decode(token);
//     const tokenExp = +decoded.exp;
//     const currentTime = Math.ceil(new Date().getTime()/1000);

//     //const tokenDateStr = new Date(new Date(tokenExp * 1000) - (new Date().getTimezoneOffset() * 60 * 1000)).toISOString();
//     //const currentDateStr = new Date(new Date(currentTime * 1000) - (new Date().getTimezoneOffset() * 60 * 1000)).toISOString();
//     const currentDateStr = new Date(currentTime * 1000).toLocaleString('pt-BR', { timezone: Intl.DateTimeFormat().resolvedOptions().timeZone })
//     const tokenDateStr = new Date(tokenExp * 1000).toLocaleString('pt-BR', { timezone: Intl.DateTimeFormat().resolvedOptions().timeZone })

//     console.log(`Current time: ${currentDateStr} - Token exp: ${tokenDateStr}${currentTime > tokenExp ? ' - Token expired' : ''}`);
//   }
// }

export const getRoleInfo = () => {
  return getUserData()?.role;
};

export const getRole = () => {
  return getUserData()?.role[0] || null;
};

export const getCityId = () => {
  const cityUserInfo = getUserData()?.role?.[1];
  if (cityUserInfo && cityUserInfo.startsWith('CITY_')) {
    const cityUserId = cityUserInfo.split('_')[1];
    try {
      return parseInt(cityUserId);
    } catch (err) {
      console.log('Invalid cityUserId: ' + cityUserId);
      return null;
    }
  }
  return null;
};

export const getCityUserRole = () => {
  const cityUserInfo = getUserData()?.role?.[1];
  if (cityUserInfo) {
    return cityUserInfo.split('_')[2];
  }
  return null;
};

export const isSystemUser = () => {
  return getUserData()?.role?.includes(applicationRoles.SYSTEM_USER) || false;
};

export const getCityUserData = () => {
  return getUserData()?.cityUserData;
};

export const updateLocalStorage = (name) => {
  try {
    const cityUserData = getCityUserData();
    const data = { token: getToken(), role: getRoleInfo(), cityUserData: { ...cityUserData, name } };
    const encData = encrypt(JSON.stringify(data));
    localStorage.setItem(APP_INFO_KEY, encData);
  } catch (err) {
    console.error('Ocorreu um erro interno ao obter informações de login. Detalhe: ' + err);
    logout();
  }
};

export const login = (token, role, cityUserData) => {
  try {
    const data = { token, role, cityUserData };
    const encData = encrypt(JSON.stringify(data));
    localStorage.setItem(APP_INFO_KEY, encData);
  } catch (err) {
    console.error('Ocorreu um erro interno ao obter informações de login. Detalhe: ' + err);
    logout();
  }
};

export const logout = () => {
  localStorage.removeItem(APP_INFO_KEY);
};

export function authHeader() {
  const user = getToken();
  if (user && user.token) {
    return { Authorization: 'Bearer ' + user.token };
  } else {
    return {};
  }
}

export const isRestrictedArea = (pathname) => {
  let isRestricted = false;
  let isUnrestricted = false;
  const restrictedPaths = ['/prefeitura', '/portal', '/politicas'];
  const unrestrictedPaths = ['/login', '/cadastro'];

  for (const restrictedPath of restrictedPaths) {
    if (pathname.includes(restrictedPath)) {
      isRestricted = true;
      break;
    }
  }

  for (const unrestrictedPath of unrestrictedPaths) {
    if (pathname.includes(unrestrictedPath)) {
      isUnrestricted = true;
      break;
    }
  }

  return isAuthenticated() && isRestricted && !isUnrestricted;
};
