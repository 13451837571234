import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validEmail, validName } from 'helpers/Validators';
import { Actions as ContactUsActions } from 'services/slices/contactUs';
import { formatRoleId, getLocaleCode } from 'helpers/Utils';

import { submitRecaptcha } from 'components/reCaptcha';

const ContactForm = () => {
  const MESSAGE_MAX_CHARACTERS = 1024;

  const INITIAL_CONTACT_DATA = {
    name: '',
    email: '',
    roleCode: '',
    message: '',
  };

  const dispatch = useDispatch();
  const contactUs = useSelector((state) => state.contactUs);

  const [contactData, setContactData] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    setButtonDisabled(
      !(
        validName(contactData?.name || '') &&
        validEmail(contactData?.email || '') &&
        contactData?.roleCode?.length > 0 &&
        isMessageValid(contactData?.message || '')
      ),
    );
  }, [contactData]);

  const handleInputChangeData = (evt) => {
    setContactData((currentData) => {
      return { ...currentData, [evt.target.name]: evt.target.value };
    });
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setButtonDisabled(true);
    submitRecaptcha('contact')
      .then((token) => {
        dispatch(
          ContactUsActions.sendContact({ ...contactData, token }, () => {
            setButtonDisabled(false);
            setContactData({ ...INITIAL_CONTACT_DATA });
          }),
        );
      })
      .catch(() => {
        alert(`O desafio ReCaptcha falhou. Por favor, tente enviar sua mensagem novamente.`);
      });
  };

  const isMessageValid = (message) => {
    return message?.length > 0 && message?.length <= MESSAGE_MAX_CHARACTERS;
  };

  return (
    <>
      {/* <!-- Content - start --> */}
      <div className="bg-cyan">
        <div className="container">
          <div className="row">
            {/* <!-- Coluna Título - start --> */}
            <div className="col-12 col-lg-3">
              <div className="py-5">
                <h1 className="fw-bolder line my-5">
                  Fale
                  <br />
                  Conosco
                </h1>
              </div>
            </div>
            {/* <!-- Coluna Título - end --> */}

            {/* <!-- Coluna Form - start --> */}
            <div className="col-12 col-lg-9 bg-white">
              <div className="p-5">
                <div className="p-5 m-5 c-gray-dark fw-semibold">
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="fieldName">
                        Nome
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="fieldName"
                        name="name"
                        value={contactData?.name || ''}
                        onChange={handleInputChangeData}
                        placeholder="Digite seu nome completo"
                        maxLength="100"
                      />
                      {validName(contactData?.name || '') === false && (
                        <p className="mt-2 mb-4 small c-danger">
                          <i className="fa-solid fa-xmark"></i> Digite seu nome completo.
                        </p>
                      )}
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="fieldEmail">
                        E-mail
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="fieldEmail"
                        name="email"
                        value={contactData?.email || ''}
                        onChange={handleInputChangeData}
                        placeholder="Digite seu e-mail"
                        maxLength="100"
                      />
                      {validEmail(contactData?.email || '') === false && (
                        <p className="mt-2 mb-4 small c-danger">
                          <i className="fa-solid fa-xmark"></i> Digite um e-mail válido.
                        </p>
                      )}
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Perfil</label>
                      <select
                        className="form-select"
                        name="roleCode"
                        value={contactData?.roleCode || ''}
                        onChange={handleInputChangeData}
                      >
                        <option value="">Selecione um perfil</option>
                        {contactUs?.roles?.map((option) => (
                          <option id={formatRoleId(option.text)} key={`role${option.value}`} value={'' + option.value}>
                            {option.text}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-4">
                      <label className="form-label" htmlFor="fieldMessage">
                        Mensagem (Caracteres restantes:{' '}
                        {MESSAGE_MAX_CHARACTERS - contactData?.message?.length >= 0
                          ? (MESSAGE_MAX_CHARACTERS - contactData?.message?.length).toLocaleString(getLocaleCode())
                          : 0}
                        )
                      </label>
                      <textarea
                        maxLength={MESSAGE_MAX_CHARACTERS}
                        className="form-control"
                        id="fieldMessage"
                        name="message"
                        placeholder="Digite sua mensagem (Máximo: 1.024 caracteres)"
                        rows="5"
                        value={contactData?.message || ''}
                        onChange={handleInputChangeData}
                      ></textarea>
                      {contactData?.message?.length > MESSAGE_MAX_CHARACTERS && (
                        <p className="mt-2 mb-4 small c-danger">
                          <i className="fa-solid fa-xmark"></i> A mensagem não deve conter mais que{' '}
                          {MESSAGE_MAX_CHARACTERS} caracteres.
                        </p>
                      )}
                    </div>
                    <div>
                      <button disabled={buttonDisabled} className="btn btn-warning">
                        {contactData?.message && contactUs?.loading === true ? 'Enviando...' : 'Enviar mensagem'}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* <!-- Coluna Form - end --> */}
          </div>
        </div>
      </div>
      {/* <!-- Content - end --> */}
    </>
  );
};

export default ContactForm;
