import { createSlice } from '@reduxjs/toolkit';
import api from 'services/api';
import { handleMessage, uncheckBlockOthers, getDefaultAnswers } from 'helpers/surveyUtils';

const BASE_URL = '/api/cities/survey-groups';

const loadStatusValues = Object.freeze({
  UNDEFINED: -1,
  LOADING: 0,
  LOADED_SUCCESS: 1,
  LOADED_ERROR: 2,
});

const initialState = {
  loadStatusValues,
  allGroupsInfo: null,
  finishedSurveysGroups: [],
  progressSurveyGroup: null,
  allGroupLoadStatus: loadStatusValues.UNDEFINED,
  groupInfo: null,
  currentSurveyCode: null,
  groupLoadStatus: loadStatusValues.UNDEFINED,
  groupSaveStatus: loadStatusValues.UNDEFINED,
  groupCloneStatus: loadStatusValues.UNDEFINED,
  questionsLoadStatus: loadStatusValues.UNDEFINED,
  answersLoadStatus: loadStatusValues.UNDEFINED,
  answersSaveStatus: loadStatusValues.UNDEFINED,
  groupSaveNotification: 0,
  errorMsg: null,
  errors: {},
};

const cleanQuestionError = function (state, surveyCode, questionCode) {
  const errors = { ...state.groupInfo.errors[surveyCode] };
  if (errors && errors[questionCode]) {
    delete errors[questionCode];
  }
  return errors;
};

export const citySurveySlice = createSlice({
  name: 'citySurvey',
  initialState,
  reducers: {
    allGroupsLoading: (state) => {
      state.allGroupLoadStatus = loadStatusValues.LOADING;
    },
    allGroupsLoadedSuccess: (state, action) => {
      const availableGroups = [];
      for (const surveyGroup of action.payload) {
        availableGroups.push({
          id: surveyGroup.id,
          code: surveyGroup.code,
          title: surveyGroup.title,
          startDate: surveyGroup.startDate,
          endDate: surveyGroup.endDate,
          status: surveyGroup.status,
        });
      }
      state.allGroupsInfo = availableGroups;
      state.allGroupLoadStatus = loadStatusValues.LOADED_SUCCESS;
      state.groupInfo = null;
      state.errorMsg = null;
      state.errors = {};
    },
    allGroupsLoadedError: (state, action) => {
      state.allGroupLoadStatus = loadStatusValues.LOADED_ERROR;
      state.loadingAllGroups = false;
      state.errorMsg = action.payload.msg;
    },
    groupLoading: (state) => {
      state.groupLoadStatus = loadStatusValues.LOADING;
    },
    groupLoadedSuccess: (state, action) => {
      const actionData = action.payload.data;

      let initialErrors = {};
      let initialValid = {};
      let initialNotifications = {};
      let initialSurveys = {};
      Object.keys(actionData.surveys).forEach((surveyCode) => {
        initialErrors = {
          ...initialErrors,
          [surveyCode]: {},
        };
        initialValid = {
          ...initialValid,
          [surveyCode]: -1,
        };
        initialNotifications = {
          ...initialNotifications,
          [surveyCode]: 0,
        };
        // Mantém questões do survey caso eles já estejam carregados
        const currentSurvey = state.groupInfo?.surveys[surveyCode] || actionData.surveys[surveyCode];
        initialSurveys = {
          ...initialSurveys,
          [surveyCode]: currentSurvey,
        };
      });
      state.groupInfo = {
        id: actionData.id,
        code: actionData.code,
        title: actionData.title,
        status: actionData.status,
        startDate: actionData.startDate,
        endDate: actionData.endDate,
        surveys: initialSurveys,
        answers: actionData.answers,
        valid: initialValid,
        errors: initialErrors,
        notifications: initialNotifications,
      };
      state.progressSurveyGroup = action.payload.progressSurveyGroup || state.progressSurveyGroup;
      state.finishedSurveysGroups = action.payload.finishedSurveysGroups || state.finishedSurveysGroups;
      state.groupLoadStatus = loadStatusValues.LOADED_SUCCESS;
      state.errorMsg = null;
      state.errors = {};
    },
    groupLoadedError: (state, action) => {
      state.groupLoadStatus = loadStatusValues.LOADED_ERROR;
      state.errorMsg = action.payload.msg;
    },
    groupSaving: (state) => {
      state.groupSaveStatus = loadStatusValues.LOADING;
    },
    groupSaveSuccess: (state, action) => {
      const actionData = action.payload;
      let updatedSurveys = {};
      let updatedAnswers = {};
      let updatedValid = {};
      let updatedErrors = {};
      let updatedNotifications = {};

      Object.keys(state.groupInfo.surveys).forEach((surveyCode) => {
        updatedSurveys = {
          ...updatedSurveys,
          [surveyCode]: {
            ...state.groupInfo.surveys[surveyCode],
            cityStatus: actionData.status,
          },
        };
        updatedAnswers = {
          ...updatedAnswers,
          [surveyCode]: {
            ...state.groupInfo.answers[surveyCode],
            status: actionData.status,
            lastUpdate: actionData.lastUpdate,
            lastResponsible: actionData.lastResponsible,
            finishedPercent: actionData.finishedPercent,
          },
        };
        updatedValid = {
          ...updatedValid,
          [surveyCode]:
            actionData.errors && actionData.errors[surveyCode] && Object.keys(actionData.errors[surveyCode].length > 0)
              ? 0
              : 1,
        };
        let errors = {};
        if (actionData.errors && actionData.errors[surveyCode]) {
          errors = actionData.errors[surveyCode];
        }
        updatedErrors = {
          ...updatedErrors,
          [surveyCode]: errors,
        };
        updatedNotifications = {
          ...updatedNotifications,
          [surveyCode]: 0, // Coloca inicialmente em 0 para apresentar resultado geral. Deve ser colocado em 1 posteriormente.
        };
      });
      state.groupInfo = {
        ...state.groupInfo,
        status: actionData.status,
        surveys: updatedSurveys,
        answers: updatedAnswers,
        valid: updatedValid,
        errors: updatedErrors,
        notifications: updatedNotifications,
        progressSurveyGroup: null,
      };
      state.progressSurveyGroup = null;
      state.finishedSurveysGroups = actionData.finishedSurveysGroups || state.finishedSurveysGroups;
      state.groupSaveStatus = loadStatusValues.LOADED_SUCCESS;
      state.groupSaveNotification = 1;
    },
    groupSaveErrorValidation: (state, action) => {
      const actionData = action.payload;
      let updatedSurveys = {};
      let updatedAnswers = {};
      let updatedValid = {};
      let updatedErrors = {};
      let updatedNotifications = {};
      Object.keys(state.groupInfo.surveys).forEach((surveyCode) => {
        updatedSurveys = {
          ...updatedSurveys,
          [surveyCode]: {
            ...state.groupInfo.surveys[surveyCode],
            cityStatus: actionData.status,
          },
        };
        updatedAnswers = {
          ...updatedAnswers,
          [surveyCode]: {
            ...state.groupInfo.answers[surveyCode],
            status: actionData.status,
            lastUpdate: actionData.lastUpdate,
            lastResponsible: actionData.lastResponsible,
            finishedPercent: actionData.allFinishedPercent[surveyCode] || 0,
          },
        };
        updatedValid = {
          ...updatedValid,
          [surveyCode]:
            actionData.errors && actionData.errors[surveyCode] && Object.keys(actionData.errors[surveyCode].length > 0)
              ? 0
              : 1,
        };
        let errors = {};
        if (actionData.errors && actionData.errors[surveyCode]) {
          errors = actionData.errors[surveyCode];
        }
        updatedErrors = {
          ...updatedErrors,
          [surveyCode]: errors,
        };
        updatedNotifications = {
          ...updatedNotifications,
          [surveyCode]: 0, // Coloca inicialmente em 0 para apresentar resultado geral. Deve ser colocado em 1 posteriormente.
        };
      });
      state.groupInfo = {
        ...state.groupInfo,
        status: actionData.status,
        surveys: updatedSurveys,
        answers: updatedAnswers,
        valid: updatedValid,
        errors: updatedErrors,
        notifications: updatedNotifications,
      };
      state.groupSaveStatus = loadStatusValues.LOADED_SUCCESS;
      state.groupSaveNotification = 1;
    },
    groupCloneSaving: (state) => {
      state.groupCloneStatus = loadStatusValues.LOADING;
    },
    groupCloneSaveSuccess: (state, action) => {
      const actionData = action.payload;
      let updatedSurveys = {};
      let updatedAnswers = {};
      let updatedValid = {};
      let updatedErrors = {};
      let updatedNotifications = {};

      Object.keys(state.groupInfo.surveys).forEach((surveyCode) => {
        updatedSurveys = {
          ...updatedSurveys,
          [surveyCode]: {
            ...state.groupInfo.surveys[surveyCode],
            cityStatus: actionData.status,
          },
        };
        updatedAnswers = {
          ...updatedAnswers,
          [surveyCode]: {
            ...state.groupInfo.answers[surveyCode],
            status: actionData.status,
            lastUpdate: actionData.lastUpdate,
            lastResponsible: actionData.lastResponsible,
            finishedPercent: actionData.allFinishedPercent[surveyCode] || 0,
          },
        };
        updatedValid = {
          ...updatedValid,
          [surveyCode]:
            actionData.errors && actionData.errors[surveyCode] && Object.keys(actionData.errors[surveyCode].length > 0)
              ? 0
              : 1,
        };
        let errors = {};
        if (actionData.errors && actionData.errors[surveyCode]) {
          errors = actionData.errors[surveyCode];
        }
        updatedErrors = {
          ...updatedErrors,
          [surveyCode]: errors,
        };
        updatedNotifications = {
          ...updatedNotifications,
          [surveyCode]: 0, // Coloca inicialmente em 0 para apresentar resultado geral. Deve ser colocado em 1 posteriormente.
        };
      });
      state.groupInfo = {
        ...state.groupInfo,
        status: actionData.status,
        surveys: updatedSurveys,
        answers: updatedAnswers,
        valid: updatedValid,
        errors: updatedErrors,
        notifications: updatedNotifications,
      };
      state.progressSurveyGroup = actionData.progressSurveyGroup;
      state.groupCloneStatus = loadStatusValues.LOADED_SUCCESS;
    },
    groupSaveError: (state) => {
      state.groupCloneStatus = loadStatusValues.LOADED_ERROR;
    },
    groupSaveCleanNotification: (state) => {
      let updatedNotifications = {};
      Object.keys(state.groupInfo.surveys).forEach((surveyCode) => {
        const notificationValue =
          state.groupInfo.errors[surveyCode] && Object.keys(state.groupInfo.errors[surveyCode]).length > 0 ? 1 : 0;
        updatedNotifications = {
          ...updatedNotifications,
          [surveyCode]: notificationValue,
        };
      });
      state.groupInfo.notifications = updatedNotifications;
      state.groupSaveNotification = 0;
    },
    questionsLoading: (state, action) => {
      const surveyCode = action.payload.surveyCode;
      state.currentSurveyCode = surveyCode;
      state.questionsLoadStatus = loadStatusValues.LOADING;
    },
    questionsLoadedSuccess: (state, action) => {
      const surveyCode = action.payload.surveyCode;
      state.questionsLoadStatus = loadStatusValues.LOADED_SUCCESS;
      state.groupInfo.surveys[surveyCode].questions = action.payload.surveyData.questions;
    },
    questionsLoadedKeep: (state, action) => {
      const surveyCode = action.payload.surveyCode;
      state.currentSurveyCode = surveyCode;
      state.questionsLoadStatus = loadStatusValues.LOADED_SUCCESS;
    },
    questionsLoadedError: (state) => {
      state.questionsLoadStatus = loadStatusValues.LOADED_ERROR;
    },
    answersLoading: (state) => {
      state.answersLoadStatus = loadStatusValues.LOADING;
    },
    answersLoadedKeep: (state) => {
      state.answersLoadStatus = loadStatusValues.LOADED_SUCCESS;
    },
    answersLoadedSuccess: (state, action) => {
      const actionData = action.payload;

      let currentErrors;
      if (state.groupInfo.errors[actionData.surveyCode]) {
        currentErrors = { ...state.groupInfo.errors[actionData.surveyCode] };
      } else {
        currentErrors = {};
      }
      state.groupInfo.answers[actionData.surveyCode] = { ...actionData.answersData };
      state.groupInfo.errors[actionData.surveyCode] = currentErrors;
      state.answersLoadStatus = loadStatusValues.LOADED_SUCCESS;
    },
    answersLoadedError: (state) => {
      state.answersLoadStatus = loadStatusValues.LOADED_ERROR;
    },
    answersSaving: (state) => {
      state.answersSaveStatus = loadStatusValues.LOADING;
    },
    answersSaveSuccess: (state, action) => {
      const actionData = action.payload;
      Object.values(state.groupInfo.answers[actionData.surveyCode].answers).forEach((item) => {
        item.changed = false;
      });
      state.groupInfo.answers[actionData.surveyCode] = {
        ...state.groupInfo.answers[actionData.surveyCode],
        status: actionData.status,
        lastUpdate: actionData.lastUpdate,
        lastResponsible: actionData.lastResponsible,
        finishedPercent: actionData.finishedPercent || 0,
        answers: state.groupInfo.answers[actionData.surveyCode].answers,
      };
      state.groupInfo.valid[actionData.surveyCode] = actionData.validated
        ? actionData.errors && Object.keys(actionData.errors).length > 0
          ? 0
          : 1
        : -1;
      state.groupInfo.errors[actionData.surveyCode] = actionData.errors || {};
      state.groupInfo.notifications[actionData.surveyCode] = actionData.validated ? 1 : 0;
      state.progressSurveyGroup = actionData.progressSurveyGroup;
      state.answersSaveStatus = loadStatusValues.LOADED_SUCCESS;
    },
    answersSaveError: (state, action) => {
      const actionData = action.payload;
      state.groupInfo.errors[actionData.surveyCode] = actionData.errors || {};
    },
    answersSaveCleanNotification: (state, action) => {
      const actionData = action.payload;
      state.groupInfo.notifications[actionData.surveyCode] = 0;
    },
    answerChangeCheckbox: (state, action) => {
      const { surveyCode, questionCode, selectedAnswerCode, isChecked } = action.payload;
      const optionsCheck = state.groupInfo.answers[surveyCode].answers[questionCode].options;
      const questionsCheck = state.groupInfo.surveys[surveyCode].questions;
      const isChildCheck = questionCode.split('_').length > 1;

      let questionsOptionsCheck = {};
      if (isChildCheck) {
        questionsOptionsCheck = questionsCheck[questionCode.split('_')[0]].children[questionCode].options;
      } else {
        questionsOptionsCheck = questionsCheck[questionCode].options;
      }

      const shouldBlockOthersCheck = questionsOptionsCheck[selectedAnswerCode].blockOthers;
      optionsCheck.forEach((option) => {
        if (option.code === selectedAnswerCode) {
          option.selected = isChecked;
        } else {
          if (shouldBlockOthersCheck) {
            option.selected = false;
            option.complement = null;
            option.position = null;
          }
        }
      });
      if (!shouldBlockOthersCheck) {
        uncheckBlockOthers(questionsOptionsCheck, optionsCheck);
      }
      if (!isChildCheck) {
        // cleanChildren(questionsCheck, action, state, optionsCheck)
        const parentQuestion = questionsCheck[questionCode];
        const parentQuestionCode = questionCode;
        const parentOptions = optionsCheck;
        Object.values(parentQuestion.children).forEach((childQuestion) => {
          const optActivationCodes = childQuestion.optionActivation[parentQuestionCode];
          const isActivationCodeChecked = parentOptions
            .filter((opt) => optActivationCodes.includes(opt.code))
            .some((opt) => opt.selected);
          if (!isActivationCodeChecked) {
            const childrenOptions = state.groupInfo.answers[surveyCode].answers[childQuestion.code].options;
            const someSelected = childrenOptions.some((opt) => opt.selected);
            if (someSelected) {
              childrenOptions.forEach((childOption) => {
                childOption.selected = false;
                childOption.complement = null;
                childOption.position = null;
              });
              state.groupInfo.answers[surveyCode].answers[childQuestion.code].changed = true;
            }
          }
        });
      }
      state.groupInfo.answers[surveyCode].answers[questionCode].changed = true;
      state.groupInfo.answers[surveyCode].answers[questionCode].options = [...optionsCheck];
      state.groupInfo.errors[surveyCode] = cleanQuestionError(state, surveyCode, questionCode);
    },
    anwserChangeRadiobutton: (state, action) => {
      const { surveyCode, questionCode, selectedAnswerCode, isChecked } = action.payload;
      const optionsRadio = state.groupInfo.answers[surveyCode].answers[questionCode].options;
      const questionsRadio = state.groupInfo.surveys[surveyCode].questions;
      const isChildRadio = questionCode.split('_').length > 1;

      let questionsOptionsRadio = {};
      if (isChildRadio) {
        questionsOptionsRadio = questionsRadio[questionCode.split('_')[0]].children[questionCode].options;
      } else {
        questionsOptionsRadio = questionsRadio[questionCode].options;
      }

      // REVER PARA OTIMIZAR: COMPORTAMENTO PADRAO DO RADIO É BLOQUEAR OUTRAS RESPOSTAS
      // ENTAO ESSA FLAG NÃO É NECESSARIA
      const shouldBlockOthersRadio = questionsOptionsRadio[selectedAnswerCode].blockOthers;
      optionsRadio.forEach((option) => {
        if (option.code === selectedAnswerCode) {
          option.selected = isChecked;
        } else {
          if (shouldBlockOthersRadio) {
            option.selected = false;
            option.complement = null;
            option.position = null;
          }
        }
      });
      if (!shouldBlockOthersRadio) {
        uncheckBlockOthers(questionsOptionsRadio, optionsRadio);
      }
      if (!isChildRadio) {
        // cleanChildren(questionsRadio, action, state, optionsRadio)
        const parentQuestion = questionsRadio[questionCode];
        const parentQuestionCode = questionCode;
        const parentOptions = optionsRadio;
        Object.values(parentQuestion.children).forEach((childQuestion) => {
          const optActivationCodes = childQuestion.optionActivation[parentQuestionCode];
          const isActivationCodeChecked = parentOptions
            .filter((opt) => optActivationCodes.includes(opt.code))
            .some((opt) => opt.selected);
          if (!isActivationCodeChecked) {
            const childrenOptions = state.groupInfo.answers[surveyCode].answers[childQuestion.code].options;
            const someSelected = childrenOptions.some((opt) => opt.selected);
            if (someSelected) {
              childrenOptions.forEach((childOption) => {
                childOption.selected = false;
                childOption.complement = null;
                childOption.position = null;
              });
              state.groupInfo.answers[surveyCode].answers[childQuestion.code].changed = true;
            }
          }
        });
      }
      state.groupInfo.answers[surveyCode].answers[questionCode].changed = true;
      state.groupInfo.answers[surveyCode].answers[questionCode].options = [...optionsRadio];
      state.groupInfo.errors[surveyCode] = cleanQuestionError(state, surveyCode, questionCode);
    },
    answerChangeOrder: (state, action) => {
      const { surveyCode, questionCode, selectedAnswerCode, orderValue } = action.payload;
      const optionsOrder = state.groupInfo.answers[surveyCode].answers[questionCode].options;
      optionsOrder.forEach((option) => {
        if (option.code === selectedAnswerCode) {
          if (orderValue) {
            option.selected = true;
            option.position = orderValue;
          } else {
            option.selected = false;
            option.position = null;
          }
        } else {
          if (option.position === orderValue) {
            option.selected = false;
            option.position = null;
          }
        }
      });
      state.groupInfo.answers[surveyCode].answers[questionCode].changed = true;
      state.groupInfo.answers[surveyCode].answers[questionCode].options = [...optionsOrder];
      state.groupInfo.errors[surveyCode] = cleanQuestionError(state, surveyCode, questionCode);
    },
    answerChangeComplement: (state, action) => {
      const { surveyCode, questionCode, selectedAnswerCode, complementValue } = action.payload;
      const optionsComplement = state.groupInfo.answers[surveyCode].answers[questionCode].options;
      optionsComplement.forEach((option) => {
        option.complement = option.code === selectedAnswerCode ? complementValue : option.complement;
      });
      state.groupInfo.answers[surveyCode].answers[questionCode].changed = true;
      state.groupInfo.answers[surveyCode].answers[questionCode].options = [...optionsComplement];
      state.groupInfo.errors[surveyCode] = cleanQuestionError(state, surveyCode, questionCode);
    },
    answerChangeText: (state, action) => {
      const { surveyCode, questionCode, textValue } = action.payload;
      state.groupInfo.answers[surveyCode].answers[questionCode].changed = true;
      state.groupInfo.answers[surveyCode].answers[questionCode].text = textValue;
      state.groupInfo.errors[surveyCode] = cleanQuestionError(state, surveyCode, questionCode);
    },
    reset: (state) => {
      state = initialState;
    },
  },
});

export const {
  allGroupsLoading,
  allGroupsLoadedSuccess,
  allGroupsLoadedError,
  groupLoading,
  groupLoadedSuccess,
  groupLoadedError,
  groupSaving,
  groupSaveSuccess,
  groupSaveErrorValidation,
  groupCloneSaving,
  groupCloneSaveSuccess,
  groupSaveError,
  groupSaveCleanNotification,
  questionsLoading,
  questionsLoadedSuccess,
  questionsLoadedKeep,
  questionsLoadedError,
  answersLoading,
  answersLoadedKeep,
  answersLoadedSuccess,
  answersLoadedError,
  answersSaving,
  answersSaveSuccess,
  answersSaveError,
  answersSaveCleanNotification,
  answerChangeCheckbox,
  anwserChangeRadiobutton,
  answerChangeOrder,
  answerChangeComplement,
  answerChangeText,
  reset,
} = citySurveySlice.actions;

export const Actions = {
  findAllGroupsAvailable: () => {
    return (dispatch) => {
      dispatch(allGroupsLoading());
      api
        .get(BASE_URL)
        .then((resp) => {
          if (resp.data) {
            dispatch(allGroupsLoadedSuccess(resp.data));
          }
        })
        .catch((err) => {
          dispatch(
            allGroupsLoadedError({
              msg:
                handleMessage(err.response, 'Erro desconhecido LOADED_ALL_GROUPS') ||
                `Erro desconhecido LOADING_ALL - ${err.response.status}`,
            }),
          );
        });
    };
  },

  findGroupByCode: (groupCode) => {
    return (dispatch) => {
      dispatch(groupLoading());
      api
        .get(`${BASE_URL}/${groupCode}`)
        .then((resp) => {
          if (resp.data) {
            const surveysData = resp.data;
            let progressSurveyGroup = null;
            let finishedSurveysGroupsArr = [];

            api
              .get(`${BASE_URL}/${groupCode}/finished`)
              .then((resp2) => {
                const answeredSurveysGroupsArr = resp2.data;
                if (answeredSurveysGroupsArr.length > 0) {
                  // Somente considera surveys em andamento quando o último não estiver finalizado (array com mais recente primeiro)
                  if (answeredSurveysGroupsArr[0].status !== 2) {
                    const progressSurveyGroupArr = answeredSurveysGroupsArr.filter((item) => item.status === 1);
                    if (progressSurveyGroupArr.length > 0) {
                      progressSurveyGroup = progressSurveyGroupArr[0];
                    }
                  }
                  finishedSurveysGroupsArr = answeredSurveysGroupsArr.filter((item) => item.status === 2);
                }
                dispatch(
                  groupLoadedSuccess({
                    data: surveysData,
                    progressSurveyGroup,
                    finishedSurveysGroups: finishedSurveysGroupsArr,
                  }),
                );
              })
              .catch((err) => {
                console.log(JSON.stringify(err));
                dispatch(groupLoadedSuccess({ data: surveysData, msg: err?.response || 'Erro' }));
              });
          }
        })
        .catch((err) => {
          dispatch(
            groupLoadedError({
              msg:
                handleMessage(err.response, 'Erro desconhecido LOADED_GROUP ' + groupCode) ||
                `Erro desconhecido LOADED_GROUP - ${groupCode} (${err.response.status})`,
            }),
          );
        });
    };
  },

  findGroupById: (groupCode, sgcId) => {
    return (dispatch) => {
      dispatch(groupLoading());
      api
        .get(`${BASE_URL}/${groupCode}/answers/${sgcId}`)
        .then((resp) => {
          if (resp.data) {
            const surveysData = resp.data;
            dispatch(groupLoadedSuccess({ data: surveysData }));
          }
        })
        .catch((err) => {
          dispatch(
            groupLoadedError({
              msg:
                handleMessage(err.response, 'Erro desconhecido LOADED_GROUP ' + groupCode + ', ID ' + sgcId) ||
                `Erro desconhecido LOADED_GROUP - ${groupCode} (${err.response.status})`,
            }),
          );
        });
    };
  },

  saveGroup: () => {
    return (dispatch, getState) => {
      const currentState = getState();
      if (currentState.citySurvey.groupInfo.status !== 2) {
        dispatch(groupSaving());
        const groupCode = currentState.citySurvey.groupInfo.code;
        const requestMsg = {};
        for (const surveyCode in currentState.citySurvey.groupInfo.answers) {
          requestMsg[surveyCode] = { ...currentState.citySurvey.groupInfo.answers[surveyCode] };
        }
        // console.log('FINISHING GROUP ' + groupCode + ': ' + JSON.stringify(requestMsg))
        if (currentState.citySurvey.progressSurveyGroup) {
          api
            .put(`${BASE_URL}/${groupCode}/${currentState.citySurvey.progressSurveyGroup?.id}`, requestMsg)
            .then((resp) => {
              // console.log('SAVE SUCCESS: ' + JSON.stringify(resp))
              const groupResp = resp.data;
              let progressSurveyGroup = null;
              let finishedSurveysGroupsArr = [];
              api
                .get(`${BASE_URL}/${groupCode}/finished`)
                .then((resp2) => {
                  const answeredSurveysGroupsArr = resp2.data;
                  if (answeredSurveysGroupsArr.length > 0) {
                    const progressSurveyGroupArr = answeredSurveysGroupsArr.filter((item) => item.status === 1);
                    if (progressSurveyGroupArr.length > 0) {
                      progressSurveyGroup = progressSurveyGroupArr[0];
                    }
                    finishedSurveysGroupsArr = answeredSurveysGroupsArr.filter((item) => item.status === 2);
                  }
                  dispatch(
                    groupSaveSuccess({
                      status: groupResp.status,
                      lastUpdate: groupResp.lastUpdate,
                      lastResponsible: groupResp.lastResponsible,
                      progressSurveyGroup,
                      finishedSurveysGroups: finishedSurveysGroupsArr,
                    }),
                  );
                })
                .catch((err) => {
                  dispatch(
                    groupSaveSuccess({
                      status: groupResp.status,
                      lastUpdate: groupResp.lastUpdate,
                      lastResponsible: groupResp.lastResponsible,
                      error: err.response.status,
                    }),
                  );
                });
            })
            .catch((err) => {
              let errorMsg = 'Erro desconhecido';
              if (err.response && 'status' in err.response) {
                if (err.response.status === 400) {
                  const groupResp = err.response.data;
                  // console.log(JSON.stringify(errors))
                  dispatch(
                    groupSaveErrorValidation({
                      status: groupResp.status,
                      lastUpdate: groupResp.lastUpdate,
                      lastResponsible: groupResp.lastResponsible,
                      allFinishedPercent: groupResp.allFinishedPercent,
                      errors: groupResp.errors,
                    }),
                  );
                  return;
                } else if (err.response.status !== 403) {
                  console.error(err);
                  errorMsg = handleMessage(err.response);
                }
              }
              dispatch(
                groupSaveError({
                  msg: errorMsg,
                  errors: {},
                }),
              );
            });
        } else {
          api
            .post(`${BASE_URL}/${groupCode}`, requestMsg)
            .then((resp) => {
              // console.log('SAVE SUCCESS: ' + JSON.stringify(resp))
              const groupResp = resp.data;
              let progressSurveyGroup = null;
              let finishedSurveysGroupsArr = [];
              api
                .get(`${BASE_URL}/${groupCode}/finished`)
                .then((resp2) => {
                  const answeredSurveysGroupsArr = resp2.data;
                  if (answeredSurveysGroupsArr.length > 0) {
                    const progressSurveyGroupArr = answeredSurveysGroupsArr.filter((item) => item.status === 1);
                    if (progressSurveyGroupArr.length > 0) {
                      progressSurveyGroup = progressSurveyGroupArr[0];
                    }
                    finishedSurveysGroupsArr = answeredSurveysGroupsArr.filter((item) => item.status === 2);
                  }
                  dispatch(
                    groupSaveSuccess({
                      status: groupResp.status,
                      lastUpdate: groupResp.lastUpdate,
                      lastResponsible: groupResp.lastResponsible,
                      progressSurveyGroup,
                      finishedSurveysGroups: finishedSurveysGroupsArr,
                    }),
                  );
                })
                .catch((err) => {
                  dispatch(
                    groupSaveSuccess({
                      status: groupResp.status,
                      lastUpdate: groupResp.lastUpdate,
                      lastResponsible: groupResp.lastResponsible,
                      error: err.response.status,
                    }),
                  );
                });
            })
            .catch((err) => {
              console.log('ERROR: ' + JSON.stringify(err));
              let errorMsg = 'Erro desconhecido';
              if (err.response && 'status' in err.response) {
                if (err.response.status === 400) {
                  const groupResp = err.response.data;
                  // console.log(JSON.stringify(errors))
                  dispatch(
                    groupSaveErrorValidation({
                      status: groupResp.status,
                      lastUpdate: groupResp.lastUpdate,
                      lastResponsible: groupResp.lastResponsible,
                      allFinishedPercent: groupResp.allFinishedPercent,
                      errors: groupResp.errors,
                    }),
                  );
                  return;
                } else if (err.response.status !== 403) {
                  console.error(err);
                  errorMsg = handleMessage(err.response);
                }
              }
              dispatch(
                groupSaveError({
                  msg: errorMsg,
                  errors: {},
                }),
              );
            });
        }
      }
    };
  },

  cleanGroupSaveNotification: () => {
    return (dispatch) => {
      dispatch(groupSaveCleanNotification());
    };
  },

  loadQuestions: (surveyCode) => {
    return (dispatch, getState) => {
      const groupCode = getState().citySurvey.groupInfo.code;
      const survey = getState().citySurvey.groupInfo.surveys[surveyCode];
      if (survey) {
        if (!survey.questions || Object.keys(survey.questions).length === 0) {
          // Questões não carregadas -> buscar no backend
          dispatch(questionsLoading({ surveyCode }));
          api
            .get(`${BASE_URL}/${groupCode}/${surveyCode}`)
            .then((resp) => {
              // console.log(`***** QUESTOES ${surveyCode}: ${JSON.stringify(resp.data, null, '\t')}`)
              dispatch(questionsLoadedSuccess({ surveyCode, surveyData: resp.data }));
            })
            .catch((err) => {
              dispatch(
                questionsLoadedError({
                  surveyCode,
                  msg:
                    handleMessage(err.response, 'Erro QUESTIONS_LOADED_ERROR ' + groupCode + '/' + surveyCode) ||
                    `Erro desconhecido QUESTIONS_LOADED_ERROR - ${err.response.status}`,
                }),
              );
            });
        } else {
          dispatch(questionsLoadedKeep({ surveyCode }));
        }
      }
    };
  },

  startUpdate: () => {
    return (dispatch, getState) => {
      // dispatch({
      //   type: ActionTypes.GROUP_START_UPDATE
      // })
      const currentState = getState();
      const groupCode = currentState.citySurvey.groupInfo.code;

      dispatch(groupCloneSaving());

      // console.log(`***** CLONING ALL ANSWERS FROM ${groupCode}`);
      const requestMsg = {};
      for (const surveyCode in currentState.citySurvey.groupInfo.answers) {
        requestMsg[surveyCode] = { ...currentState.citySurvey.groupInfo.answers[surveyCode] };
      }
      api
        .put(`${BASE_URL}/${groupCode}/clone-save`, requestMsg)
        .then((resp) => {
          // console.log('SAVE SUCCESS: ' + JSON.stringify(resp))
          dispatch(
            groupCloneSaveSuccess({
              status: resp.data.status,
              validated: true,
              progressSurveyGroup: {
                id: resp.data.progressSurveyGroupId,
                status: resp.data.status,
                lastUpdate: resp.data.lastUpdate,
              },
              lastUpdate: resp.data.lastUpdate,
              lastResponsible: resp.data.lastResponsible,
              allFinishedPercent: resp.data.allFinishedPercent,
              errors: resp.data.errors || {},
            }),
          );
        })
        .catch((err) => {
          // console.log('ERROR: ' + JSON.stringify(err))
          let errorMsg = 'Erro desconhecido';
          if (err.response && 'status' in err.response) {
            if (err.response.status === 400) {
              // console.log(JSON.stringify(errors))
              dispatch(
                groupSaveErrorValidation({
                  status: err.response.data.status,
                  lastUpdate: err.response.data.lastUpdate,
                  lastResponsible: err.response.data.lastResponsible,
                  errors: err.response.data.errors,
                }),
              );
              return;
            } else if (err.response.status !== 403) {
              console.error(err);
              errorMsg = handleMessage(err.response);
            }
          }
          dispatch(groupSaveError({ msg: errorMsg, errors: {} }));
        });
    };
  },

  loadAnswers: (surveyCode) => {
    return (dispatch, getState) => {
      const currentState = getState();
      const groupCode = currentState.citySurvey.groupInfo.code;
      const surveyAnswer = currentState.citySurvey.groupInfo.answers[surveyCode];

      // Se estiver em preenchimento, carrega cada vez que acessa tab para atualizar
      // respostas que podem estar sendo preenchidas por mais de um usuario simultaneamente
      if (!surveyAnswer || surveyAnswer.status === 1) {
        dispatch(answersLoading({ surveyCode }));
        api
          .get(`${BASE_URL}/${groupCode}/${surveyCode}/answers`)
          .then((resp) => {
            dispatch(answersLoadedSuccess({ surveyCode, answersData: resp.data }));
          })
          .catch((err) => {
            if (err.response && err.response.status === 404) {
              const initialAnswers = getDefaultAnswers(currentState.citySurvey.groupInfo.surveys[surveyCode]);
              dispatch(
                answersLoadedSuccess({
                  surveyCode,
                  answersData: initialAnswers,
                }),
              );
            } else {
              dispatch(
                answersLoadedError({
                  surveyCode,
                  msg:
                    handleMessage(
                      err.response,
                      'Erro desconhecido ANSWERS_LOADED_ERROR ' + groupCode + '/' + surveyCode,
                    ) || `Erro desconhecido QUESTIONS_LOADED_ERROR - ${err.response.status}`,
                }),
              );
            }
          });
      } else if (surveyAnswer.status !== 1) {
        dispatch(answersLoadedKeep({ surveyCode }));
      }
    };
  },

  saveAnswers: (surveyCode, validate = false) => {
    return (dispatch, getState) => {
      const currentState = getState();

      const groupCode = currentState.citySurvey.groupInfo.code;
      const surveyAnswers = currentState.citySurvey.groupInfo.answers[surveyCode];
      const someChanged = Object.values(surveyAnswers.answers).some((ans) => ans.changed === true);

      if (validate || someChanged) {
        dispatch(answersSaving({ surveyCode }));
        const requestMsg = {
          status: 1,
          answers: surveyAnswers.answers,
        };
        if (currentState.citySurvey.progressSurveyGroup?.id) {
          // console.log(`***** UPDATING FORM ${surveyCode}`)
          api
            .put(
              `${BASE_URL}/${groupCode}/${surveyCode}/answers/${currentState.citySurvey.progressSurveyGroup?.id}${
                validate === true ? '?validate=1' : ''
              }`,
              requestMsg,
            )
            .then((resp) => {
              dispatch(
                answersSaveSuccess({
                  surveyCode,
                  validated: validate,
                  progressSurveyGroup: {
                    id: resp.data.progressSurveyGroupId,
                    status: resp.data.status,
                    lastUpdate: resp.data.lastUpdate,
                  },
                  status: resp.data.status,
                  lastUpdate: resp.data.lastUpdate,
                  lastResponsible: resp.data.lastResponsible,
                  finishedPercent: resp.data.finishedPercent,
                  errors: resp.data.errors || {},
                }),
              );
            })
            .catch((err) => {
              let errors = {};
              let errorMsg = 'Erro desconhecido';
              if (err.response && 'status' in err.response) {
                if (err.response.status === 400) {
                  // console.log(JSON.stringify(errors))
                  errorMsg = 'Respostas inválidas';
                  errors = err.response.data;
                } else if (err.response.status !== 403) {
                  // console.error(err)
                  errorMsg = handleMessage(err.response);
                } else {
                  errorMsg = errorMsg + ' - ' + (err.response?.status || 'Status não definido');
                }
              }
              dispatch(
                answersSaveError({
                  surveyCode,
                  msg: errorMsg,
                  errors,
                }),
              );
            });
        } else {
          // console.log(`***** SAVING NEW FORM ${surveyCode}`)
          api
            .post(`${BASE_URL}/${groupCode}/${surveyCode}/answers${validate === true ? '?validate=1' : ''}`, requestMsg)
            .then((resp) => {
              dispatch(
                answersSaveSuccess({
                  surveyCode,
                  validated: validate,
                  progressSurveyGroup: {
                    id: resp.data.progressSurveyGroupId,
                    status: resp.data.status,
                    lastUpdate: resp.data.lastUpdate,
                  },
                  status: resp.data.status,
                  lastUpdate: resp.data.lastUpdate,
                  lastResponsible: resp.data.lastResponsible,
                  finishedPercent: resp.data.finishedPercent,
                  errors: resp.data.errors || {},
                }),
              );
            })
            .catch((err) => {
              let errors = {};
              let errorMsg = 'Erro desconhecido';
              if (err.response && 'status' in err.response) {
                if (err.response.status === 400) {
                  // console.log(JSON.stringify(errors))
                  errorMsg = 'Respostas inválidas';
                  errors = err.response.data;
                } else if (err.response.status !== 403) {
                  // console.error(err)
                  errorMsg = handleMessage(err.response);
                } else {
                  errorMsg = errorMsg + ' - ' + (err.response?.status || 'Status não definido');
                }
              }
              dispatch(
                answersSaveError({
                  msg: errorMsg,
                  errors,
                }),
              );
            });
        }
      }
    };
  },

  checkOption: (surveyCode, questionCode, selectedAnswerCode, isRadioButton, isChecked) => {
    return (dispatch) => {
      if (isRadioButton) {
        dispatch(
          anwserChangeRadiobutton({
            surveyCode,
            questionCode,
            selectedAnswerCode,
            isChecked,
          }),
        );
      } else {
        dispatch(
          answerChangeCheckbox({
            surveyCode,
            questionCode,
            selectedAnswerCode,
            isChecked,
          }),
        );
      }
    };
  },

  orderOption: (surveyCode, questionCode, selectedAnswerCode, orderValue) => {
    return (dispatch) => {
      dispatch(
        answerChangeOrder({
          surveyCode,
          questionCode,
          selectedAnswerCode,
          orderValue,
        }),
      );
    };
  },

  complementOption: (surveyCode, questionCode, selectedAnswerCode, complementValue) => {
    return (dispatch) => {
      dispatch(
        answerChangeComplement({
          surveyCode,
          questionCode,
          selectedAnswerCode,
          complementValue,
        }),
      );
    };
  },

  textOption: (surveyCode, questionCode, textValue) => {
    return (dispatch) => {
      dispatch(
        answerChangeText({
          surveyCode,
          questionCode,
          textValue,
        }),
      );
    };
  },

  cleanNotification: (surveyCode) => {
    return (dispatch) => {
      dispatch(answersSaveCleanNotification({ surveyCode }));
    };
  },

  reset: () => {
    return (dispatch) => {
      dispatch(reset());
    };
  },
};

export default citySurveySlice.reducer;
