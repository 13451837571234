import React, { useState } from 'react';
import { applicationRoles } from 'helpers/statusUtils';
import { getRoleInfo } from 'helpers/userAuthUtils';
import { Link } from 'react-router-dom';

const CityLevel = ({ cityData, labels, descriptions }) => {
  const [showMaturityDescription, setShowMaturityDescription] = useState(false);

  const handleShowMaturityDescription = () => {
    setShowMaturityDescription(!showMaturityDescription);
  };

  const roles = getRoleInfo();
  const isAdmin = roles.includes(applicationRoles.GOVERNANCE) || roles.includes(applicationRoles.GOVERNANCE_VIEWER);
  const formRoute = isAdmin ? '/portal/formulario/' + cityData.friendlyName : '/prefeitura/formulario';

  return (
    <div className="bg-white shadow p-5 mb-4">
      <h6 id="description" className="fw-bold mb-5">
        Nível de maturidade da cidade
      </h6>
      <div className="display-level">
        <div className={`circle level-${cityData.level}`}>
          <div>
            <h1>{cityData.level}</h1>
            <h4>{labels && cityData && labels[`m${cityData.level}`]?.title}</h4>
            <p>
              <a href="#description" onClick={handleShowMaturityDescription}>
                + Saiba mais
              </a>
            </p>
          </div>
        </div>
      </div>

      {showMaturityDescription && (
        <>
          <h5>{labels && cityData && labels[`m${cityData.level}`]?.title}</h5>
          <p>{descriptions && cityData && descriptions.general[`m${cityData.level}`]}</p>
        </>
      )}

      <hr className="my-5" />

      {cityData?.evaluated ? (
        <p className="text-center my-4">
          <Link to={formRoute}>Visualizar informações preenchidas &rsaquo;</Link>
        </p>
      ) : (
        <div className="alert alert-secondary p-4 fw-semibold rounded text-center fs-5" role="alert">
          <h5>
            <strong>Este nível é estimado nas bases secundárias.</strong>
          </h5>
          {!isAdmin && (
            <p>
              Se você é representante da gestão municipal,{' '}
              <Link to={formRoute}>preencha as informações sobre sua cidade</Link>.
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default CityLevel;
