import { convertErrorsArray } from 'helpers/Utils';
import AdminNewsletterService from 'services/admin/newsletter';

import { adminNewslettersSlice, adminNewsletterItemSlice } from 'slices/admin/newsletters/slice';

export const AdminNewslettersSliceActions = adminNewslettersSlice.actions;
export const AdminNewsletterItemSliceActions = adminNewsletterItemSlice.actions;

export const Actions = {
  loadNewsletters: (page, status, query) => {
    return (dispatch) => {
      dispatch(AdminNewslettersSliceActions.loadingStart());
      AdminNewsletterService.findAll(page, 10, status, query)
        .then((res) => {
          const responseData = res.data;
          const page = {
            totalElements: responseData.totalElements,
            totalPages: responseData.totalPages,
            totalInPage: responseData.numberOfElements,
            pageNumber: responseData.number + 1,
            pageSize: responseData.size,
            first: responseData.first,
            last: responseData.last,
          };
          dispatch(AdminNewslettersSliceActions.success({ page, content: responseData.content }));
        })
        .catch(() => dispatch(AdminNewslettersSliceActions.error('Ocorreu um erro ao carregar os boletins.')))
        .finally(() => dispatch(AdminNewslettersSliceActions.loadingStop()));
    };
  },
  loadNewsletter: (id) => {
    return (dispatch) => {
      dispatch(AdminNewsletterItemSliceActions.loadingStart());
      AdminNewsletterService.findById(id)
        .then((res) => {
          const content = res.data;
          let citiesObjArr = [];
          if (content.citiesIds?.length > 0) {
            AdminNewsletterService.findCitiesById(content.citiesIds).then((res2) => {
              citiesObjArr = res2.data;
              dispatch(AdminNewsletterItemSliceActions.loadSuccess({ content, citiesObjArr }));
            });
          } else {
            dispatch(AdminNewsletterItemSliceActions.loadSuccess({ content, citiesObjArr }));
          }
        })
        .catch(() => dispatch(AdminNewsletterItemSliceActions.loadError('Erro ao carregar boletim. ')))
        .finally(() => dispatch(AdminNewsletterItemSliceActions.loadingStop()));
    };
  },
  delete: (id) => {
    return (dispatch) => {
      dispatch(AdminNewsletterItemSliceActions.loadingStart());
      AdminNewsletterService.deleteById(id)
        .then(() => dispatch(AdminNewsletterItemSliceActions.success('Boletim excluído com sucesso! ')))
        .catch(() => dispatch(AdminNewsletterItemSliceActions.error('Erro ao excluir boletim. ')))
        .finally(() => dispatch(AdminNewsletterItemSliceActions.loadingStop()));
    };
  },
  moveNewsletterToTrash: (id) => {
    return (dispatch) => {
      dispatch(AdminNewsletterItemSliceActions.loadingStart());
      AdminNewsletterService.moveToTrash(id)
        .then(() => dispatch(AdminNewsletterItemSliceActions.success('Boletim foi movido para a lixeira. ')))
        .catch(() => dispatch(AdminNewsletterItemSliceActions.error('Erro ao mover boletim para a lixeira')))
        .finally(() => dispatch(AdminNewsletterItemSliceActions.loadingStop()));
    };
  },
  restoreFromTrash: (id) => {
    return (dispatch) => {
      dispatch(AdminNewsletterItemSliceActions.loadingStart());
      AdminNewsletterService.restoreFromTrash(id)
        .then(() => dispatch(AdminNewsletterItemSliceActions.success('Boletim foi restaurado da lixeira. ')))
        .catch(() => dispatch(AdminNewsletterItemSliceActions.error('Erro ao restaurar boletim da lixeira. ')))
        .finally(() => dispatch(AdminNewsletterItemSliceActions.loadingStop()));
    };
  },
  saveNewsletter: (data) => {
    return (dispatch) => {
      dispatch(AdminNewsletterItemSliceActions.loadingStart(`Aguarde...`));
      const request = data?.id
        ? AdminNewsletterService.updateNewsletter(data?.id, data)
        : AdminNewsletterService.saveNewsletter(data);
      request
        .then(() => {
          dispatch(
            AdminNewsletterItemSliceActions.success(`Boletim ${data?.id ? 'atualizado' : 'criado'} com sucesso. `),
          );
        })
        .catch((err) => {
          let fieldErrors = {};
          if (err.response.status === 400) {
            fieldErrors = convertErrorsArray(err.response.data.fieldErrors, 'field');
            // console.log(JSON.stringify(fieldErrors));
          }
          dispatch(
            AdminNewsletterItemSliceActions.error({
              message: `Erro ao ${data?.id ? 'atualizar' : 'criar'} boletim. `,
              fieldErrors,
            }),
          );
        })
        .finally(() => dispatch(AdminNewsletterItemSliceActions.loadingStop()));
    };
  },
  clearData: () => {
    return (dispatch) => {
      dispatch(AdminNewslettersSliceActions.reset());
      dispatch(AdminNewsletterItemSliceActions.reset());
    };
  },
};

const adminNewslettersReducer = adminNewslettersSlice.reducer;
const adminNewsletterItemReducer = adminNewsletterItemSlice.reducer;
export default { adminNewslettersReducer, adminNewsletterItemReducer };
