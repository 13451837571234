import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Actions as DashboardCityActions } from 'slices/admin/dashboard';
import { Actions as LabelActions } from 'services/labels';
import Recommendations from 'components/dashboard/Recommendations';
import LogoFooterPrint from 'components/LogoFooterPrint';

const AdminRecommendationsPrint = () => {
  const { cityFriendlyName } = useParams();

  const dispatch = useDispatch();
  const labels = useSelector((state) => state.labels.labels);
  const descriptions = useSelector((state) => state.adminDashboard.descriptions);
  const cityData = useSelector((state) => state.adminDashboardCity.item);

  useEffect(() => {
    dispatch(LabelActions.loadLabels());
    dispatch(DashboardCityActions.loadData());
  }, [dispatch]);

  useEffect(() => {
    dispatch(DashboardCityActions.loadCityItem(cityFriendlyName));
  }, [dispatch, cityFriendlyName]);

  return (
    <>
      <div className="position-fixed inteligente-print-buttons" style={{ right: 0, top: 0 }}>
        <button className="btn btn-secondary survey-print" onClick={() => window.close()} title="Fechar">
          <i className="fa-solid fa-times"></i>
        </button>
        <button className="btn btn-success survey-print" onClick={() => window.print()}>
          <i className="fa-solid fa-print"></i>&nbsp;Abrir janela de impressão
        </button>
      </div>
      <Recommendations cityData={cityData} labels={labels} descriptions={descriptions} showCityName />
      <LogoFooterPrint />
    </>
  );
};

export default AdminRecommendationsPrint;
