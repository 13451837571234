import React from 'react';
import { Modal } from 'react-bootstrap';
import { BuildIso, BuildOds, VariablesDescription } from './ModalUtils';

const ModalCharacterizationInfo = ({ show, onClose, labels, indicator }) => {
  return (
    indicator && (
      <Modal
        className="inteligente-modal inteligente-modal-indicator"
        show={show && indicator}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex flex-nowrap align-items-center gap-2 me-5" style={{ height: '80px' }}>
            <div className="flex-grow-1">{labels[indicator.code]?.title || indicator.code}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{labels[indicator.code]?.description || 'Sem descrição'}</p>
          <p>
            <strong>Fonte:</strong> {labels[indicator.code]?.source}
          </p>
          <div className="indicator-details">
            <VariablesDescription indicatorCode={indicator.code} labels={labels} />
            <BuildOds indicatorCode={indicator.code} labels={labels} />
            <BuildIso indicatorCode={indicator.code} labels={labels} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-outline-secondary" onClick={onClose}>
            Fechar
          </button>
        </Modal.Footer>
      </Modal>
    )
  );
};

export default ModalCharacterizationInfo;
