import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import ContactUsTab from './Accordion/contactUsTab';
import EmailServerTab from './Accordion/emailServerTab';
import DevOptionsTab from './Accordion/devOptionsTab';
import AutoapprovalOptionsTab from './Accordion/autoapprovalOptionsTab';
import { showToast, dismissToast } from 'components/Toast';
import { useDispatch, useSelector } from 'react-redux';
import { Actions as ConfigurationsActions } from 'slices/admin/configuration';
import { ToastContainer } from 'react-toastify';

const Configuration = () => {
  const dispatch = useDispatch();

  const toastId = useRef(null);

  const adminConfiguration = useSelector((state) => state.adminConfiguration);

  const [updateCompleted, setUpdateCompleted] = useState(false);
  const [toastInfo, setToastInfo] = useState({});

  useEffect(() => {
    dispatch(ConfigurationsActions.loadConfigurations());
    setUpdateCompleted(false);
  }, [dispatch, updateCompleted]);

  useEffect(() => {
    if (adminConfiguration?.mutateSuccess === true) {
      setToastInfo({ type: 'success', message: adminConfiguration?.message });
      setUpdateCompleted(true);
    } else if (adminConfiguration?.mutateSuccess === false) {
      setToastInfo({ type: 'danger', message: adminConfiguration?.message });
    }
    dispatch(ConfigurationsActions.mutateClear());
  }, [dispatch, adminConfiguration?.mutateSuccess, adminConfiguration?.message]);

  useEffect(() => {
    const { message, type, autoClose } = toastInfo;
    if (message && type) {
      if (toastId.current !== null) {
        dismissToast(toastId.current);
        toastId.current = null;
      }
      toastId.current = toastId.current === null ? showToast(message, type, autoClose) : toastId.current;
    }
  }, [dispatch, toastInfo]);

  return (
    <div className="bg-gray-superlight px-5 py-4 w-100">
      <header>
        <h3 className="fw-bolder c-cyan mb-0">Configurações do portal</h3>
        <p className="path">
          <Link to="">Início</Link> &rsaquo; <span className="current">Configurações do portal</span>
        </p>
      </header>

      <div className="bg-white shadow">
        <div className="accordion accordion-flush config" id="accordionForm">
          <ContactUsTab data={adminConfiguration?.data?.contactUs} />
          <EmailServerTab data={adminConfiguration?.data?.emailServer} />
          <AutoapprovalOptionsTab data={adminConfiguration?.data?.autoapprovalOptions} />
          <DevOptionsTab data={adminConfiguration?.data?.devOptions} />
        </div>
      </div>
      <ToastContainer position="bottom-center" />
    </div>
  );
};

export default Configuration;
