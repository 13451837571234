import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Actions as ConfigurationsActions } from 'slices/admin/configuration';
import { ConfigModal } from '../ConfigModal';

const AutoapprovalOptions = ({ data }) => {
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(false);
  const [isChecked, setIsChecked] = useState(null);
  const [newData, setNewData] = useState({});
  const [modalValues, setModalValues] = useState();

  useEffect(() => {
    if (data?.[1].value !== undefined && isChecked === null) {
      if (data?.[1].value === '1') {
        setIsChecked(true);
      } else {
        setIsChecked(false);
      }
    }
    setNewData(data);
  }, [data, isChecked]);

  const handleShowTab = () => {
    setIsActive(!isActive);
  };

  const handleAutoapprove = (configurationData) => {
    if (isChecked) {
      configurationData = { ...configurationData, value: '0' };
      setIsChecked(false);
    } else {
      configurationData = { ...configurationData, value: '1' };
      setIsChecked(true);
    }
    handleUpdateValues(configurationData);
  };

  const handleUpdateValues = (configurationData) => {
    dispatch(ConfigurationsActions.editConfiguration(configurationData));
  };

  const handleOpenModal = (data) => {
    setModalValues(data);
  };

  const closeModal = () => {
    setModalValues(null);
  };

  return (
    <>
      <div className="accordion-item">
        <h2 className="accordion-header" onClick={handleShowTab}>
          <button
            className={`accordion-button ${isActive ? '' : 'collapsed'}`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapse4"
            aria-expanded="false"
            aria-controls="collapse4"
          >
            Opções de autoaprovação
          </button>
        </h2>
        <div
          id="collapse4"
          className={`accordion-collapse collapse ${isActive ? 'show' : ''}`}
          data-bs-parent="#accordionForm"
        >
          {newData && newData?.length > 0 && (
            <div className="accordion-body">
              <ul className="list-group ps-5 pe-5 pb-5 pt-0">
                {
                  <>
                    <li className="list-group-item p-3 d-flex justify-content-between align-items-center">
                      <div>
                        <h5 className="fw-bold c-cyan">{newData?.[1].description}</h5>
                      </div>
                      <a href="#">
                        <div className="form-check form-switch mb-2">
                          <input
                            className="form-check-input"
                            name="status"
                            type="checkbox"
                            role="switch"
                            id="status-publish"
                            checked={isChecked}
                            onChange={() => handleAutoapprove(newData?.[1])}
                          />
                        </div>
                      </a>
                    </li>
                    <li className="list-group-item p-3 d-flex justify-content-between align-items-center">
                      <div>
                        <h5 className="fw-bold c-cyan">{newData?.[0].description}</h5>
                        <div className="c-gray">{newData?.[0].value}</div>
                      </div>
                      <a href="#">
                        <i
                          className="fa-solid fa-pen larger-4 c-cyan"
                          onClick={() => handleOpenModal(newData?.[0])}
                        ></i>
                      </a>
                    </li>
                  </>
                }
              </ul>
            </div>
          )}
        </div>
      </div>
      <ConfigModal
        modalValues={modalValues}
        show={!!modalValues}
        onClose={closeModal}
        onConfirmation={handleUpdateValues}
      />
    </>
  );
};

export default AutoapprovalOptions;
