import { useTitle } from 'hooks/useTitle';
import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

const AdminUserManagement = () => {
  const pageTitle = useTitle('Usuários Administrativos');

  return (
    <>
      <div className="bg-gray-superlight px-5 py-4 w-100">
        {/* <!-- Cabeçalho Página - start --> */}
        <header>
          <h3 className="fw-bolder c-cyan mb-0">{pageTitle}</h3>
          <p className="path">
            <Link to={''}>Início</Link> &rsaquo; <span className="current">{pageTitle}</span>
          </p>
        </header>
        {/* <!-- Cabeçalho Página - end --> */}

        {/* <!-- Conteúdos - start --> */}
        <Outlet />
        {/* <!-- Conteúdos - end --> */}
      </div>
      <ToastContainer position="bottom-center" />
    </>
  );
};

export default AdminUserManagement;
