import { createSlice } from '@reduxjs/toolkit';

const requestControl = {
  loading: null,
  success: null,
};

const pageControl = {
  totalElements: 1,
  totalPages: 1,
  pageNumber: 1,
  totalInPage: 1,
  pageSize: 1,
  first: true,
  last: true,
};

const initialStateGlobal = {
  ...requestControl,
  ...pageControl,
  message: null,
  data: [],
};

export const adminSubscribersSlice = createSlice({
  name: 'adminSubscribersSlice',
  initialState: initialStateGlobal,
  reducers: {
    loadingStart: (state) => {
      state.loading = true;
      state.success = null;
    },
    loadingStop: (state) => {
      state.loading = false;
    },
    success: (state, action) => {
      state.message = action.payload?.message || null;
      state.success = true;
      state.data = action.payload?.content || state.data;
      state.page = action.payload?.page || state.page;
    },
    error: (state, action) => {
      state.success = false;
      state.message = action.payload || state.message;
    },
    reset: () => {
      return { ...initialStateGlobal };
    },
  },
});
