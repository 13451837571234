import React from 'react';
import TermsOfUse from 'components/TermsOfUse';

const Step2 = ({ data, handleChange }) => {
  return (
    <div className="px-5">
      <TermsOfUse />
      <div className="mb-4">
        <label className="form-label d-block ps-3" htmlFor="fieldTermsOfUseAcceptance">
          <input
            type="checkbox"
            id="fieldTermsOfUseAcceptance"
            name="termsOfUseAcceptance"
            onChange={handleChange}
            checked={data.termsOfUseAcceptance === true}
            value={data.termsOfUseAcceptance}
          />
          <strong>Li e concordo com os Termos de Uso e Aviso de Privacidade.</strong>
        </label>
      </div>

      {/*
      <div className="text-center">
        <a href="Portal_Cadastro.html" className="btn btn-outline-success me-3">
          Voltar
        </a>
        <a href="Portal_Cadastro_3.html" className="btn btn-success">
          Continuar
        </a>
      </div>
  */}
    </div>
  );
};

export default Step2;
