import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { debounce } from 'lodash';
import { setMask, unsetMask } from 'helpers/inputMasks';
import { validEmail, isPhoneNumber } from 'helpers/Validators';
import { applicationRoles } from 'helpers/statusUtils';
import { getRoleInfo } from 'helpers/userAuthUtils';
import CrudFieldInput from 'components/CrudFieldInput';
import TermsOfUse from 'components/TermsOfUse';

import { Actions as AccountManagementActions } from 'services/accountManagement';
import api from 'services/api';

const ModalTermsOfUse = ({ show, onHide, termsOfUse }) => {
  return (
    <Modal
      className="inteligente-modal"
      show={show}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
    >
      <Modal.Body>
        <TermsOfUse termsOfUse={termsOfUse} showTitle />
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-primary" onClick={onHide}>
          OK
        </button>
      </Modal.Footer>
    </Modal>
  );
};

const FinishRegistration = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const userData = useSelector((state) => state.accountManagement.userData);
  const loadSuccess = useSelector((state) => state.accountManagement.loadSuccess);
  const updateSuccess = useSelector((state) => state.accountManagement.updateSuccess);
  const [termsOfUse, setTermsOfUse] = useState();

  const [modalShow, setModalShow] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    telephoneNumber: '',
    termsOfUseAcceptance: false,
  });

  const roles = getRoleInfo();

  useEffect(() => {
    api.get('/api/public/terms-of-use').then((resp) => {
      setTermsOfUse({ ...resp.data, dateLocale: new Date(resp.data.publicationDate).toLocaleDateString() });
    });
  }, []);

  useEffect(() => {
    // TODO: incluir ducks para gerenciar o termo de uso
    // - Carregar informações do atual
    // - Versão aceita pelo usuário
    dispatch(AccountManagementActions.loadData());
    return () => dispatch(AccountManagementActions.clearData());
  }, [dispatch]);

  useEffect(() => {
    if (loadSuccess) {
      setFormData((data) => {
        return {
          ...data,
          name: userData.name,
          email: userData.email,
          telephoneNumber: userData.telephoneNumber,
          termsOfUseAcceptance: false,
        };
      });
    }
  }, [loadSuccess, userData]);

  useEffect(() => {
    if (updateSuccess === true) {
      if (roles.includes(applicationRoles.GOVERNANCE)) {
        navigate('/portal/prefeitos');
      } else if (roles.includes(applicationRoles.GOVERNANCE_VIEWER)) {
        navigate('/portal/dashboard');
      } else {
        navigate('/prefeitura/dashboard');
      }
    }
  }, [updateSuccess, roles, navigate]);

  const triggerSubmit = useRef(
    debounce((userInfo) => {
      dispatch(AccountManagementActions.updateGovernanceFirstAccessData(userInfo));
    }, 500),
  ).current;

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const userInfo = {
      id: userData.id,
      name: userData.name,
      email: formData.email,
      telephoneNumber: formData.telephoneNumber,
      termsOfUseAcceptance: formData.termsOfUseAcceptance,
      termsOfUseVersion: formData.termsOfUseVersion,
      termsOfUseType: 1,
    };
    triggerSubmit(userInfo);
  };

  const isButtonDisabled = () => {
    return !validEmail(formData.email) || !isPhoneNumber(formData.telephoneNumber) || !formData.termsOfUseAcceptance;
  };

  const handleInputChange = (evt) => {
    const { name, value, checked } = evt.target;

    if (name === 'termsOfUseAcceptance') {
      setFormData({ ...formData, termsOfUseAcceptance: checked, termsOfUseVersion: termsOfUse.version });
      return;
    }
    if (name === 'telephoneNumber') {
      setFormData({ ...formData, telephoneNumber: unsetMask('telephoneNumber', value) });
      return;
    }
    setFormData({ ...formData, [name]: value });
  };

  const showModalTermsOfUse = () => {
    setModalShow(true);
  };

  return (
    <>
      <div className="page bg-cyan">
        <div className="container page">
          <div className="row page justify-content-center">
            <div className="col-12 col-lg-8 bg-white">
              {/* FORMULARIO + TERMO DE USO - INICIO */}
              {formData && (
                <div className="p-5 m-5">
                  <h1 className="fw-bold c-green">Olá {formData.name}</h1>
                  <h3>
                    Por favor, confirme os dados abaixo, leia e aceite os termos de uso para continuar acessando a
                    plataforma.
                  </h3>

                  <form noValidate onSubmit={handleSubmit}>
                    <div className="mb-4">
                      <CrudFieldInput
                        id="fieldName"
                        label="Nome"
                        name="name"
                        placeholder="Nome completo"
                        value={formData.name}
                        readOnly
                      />
                    </div>
                    <div className="row mb-4">
                      <div className="col-lg-6">
                        <CrudFieldInput
                          type="email"
                          id="fieldEmail"
                          label="E-mail institucional"
                          name="email"
                          placeholder="E-mail institucional"
                          required
                          value={formData.email}
                          onChange={handleInputChange}
                          isError={validEmail(formData.email) === false}
                          errorMsg="Digite um e-mail válido"
                        />
                      </div>
                      <div className="col-lg-6">
                        <CrudFieldInput
                          id="fieldTelephoneNumber"
                          label="Telefone"
                          name="telephoneNumber"
                          placeholder="Número de telefone com DDD"
                          value={setMask('telephoneNumber', formData.telephoneNumber)}
                          onChange={handleInputChange}
                          isError={isPhoneNumber(formData.telephoneNumber) === false}
                          errorMsg="Digite um telefone válido"
                        />
                      </div>
                    </div>
                    <div className="mb-4 form-check">
                      <p>
                        Para continuar, você deve ler e concordar com os{' '}
                        <button type="button" className="btn btn-link" onClick={showModalTermsOfUse}>
                          <strong>Termos de Uso</strong>
                        </button>
                        .
                      </p>
                      <label className="form-label d-block ps-3" htmlFor="fieldTermsOfUseAcceptance">
                        <input
                          type="checkbox"
                          id="fieldTermsOfUseAcceptance"
                          name="termsOfUseAcceptance"
                          onChange={handleInputChange}
                          checked={formData.termsOfUseAcceptance}
                        />
                        <strong>Li e concordo com os Termos de Uso e Aviso de Privacidade.</strong>
                      </label>
                    </div>
                    <div className="mt-5 text-center">
                      <Link to="/home" className="btn btn-secondary me-3">
                        Voltar para tela inicial
                      </Link>
                      <button type="submit" className="btn btn-success" disabled={isButtonDisabled()}>
                        Salvar
                      </button>
                    </div>
                  </form>
                </div>
              )}
              {/* FORMULARIO + TERMO DE USO - FIM */}
            </div>
          </div>
        </div>
      </div>
      <ModalTermsOfUse show={modalShow} onHide={() => setModalShow(false)} termsOfUse={termsOfUse} />
    </>
  );
};

export default FinishRegistration;
