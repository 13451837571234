import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Alert, Button, Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Actions as PostsActions } from 'services/slices/content';
import { showToast, dismissToast } from 'components/Toast';
import { ConfirmationModal } from 'components/Modal';
import Pagination from 'components/Pagination';
import debounce from 'lodash.debounce';

const postFilterStatus = [
  { value: -1, label: 'Todas' },
  { value: 1, label: 'Publicadas' },
  { value: 2, label: 'Ocultas' },
  { value: 0, label: 'Lixeira' },
];

const FilterGroup = ({ statusList, current, onSelected }) => {
  return (
    <div className="btn-group" role="group" aria-label="Basic outlined example">
      {statusList.map((filter) => (
        <button
          key={`btn-filter-${filter.value}`}
          onClick={() => onSelected(filter.value)}
          type="button"
          className={`btn btn-sm ${current === filter.value ? 'btn-success' : 'btn-outline-gray-light fw-normal'}`}
        >
          {filter.label}
        </button>
      ))}
    </div>
  );
};

const PostStatus = ({ status }) => {
  switch (status) {
    case 0:
      return <span className="badge text-bg-danger">Lixeira</span>;
    case 1:
      return <span className="badge bg-green-subtle">Publicado</span>;
    case 2:
      return <span className="badge bg-cyan-subtle">Oculto</span>;
    default:
      return <></>;
  }
};

const PostsList = () => {
  const dispatch = useDispatch();

  const toastId = useRef(null);

  const triggerSearch = useRef(
    debounce(
      (newPage, newStatus, inputValue) => dispatch(PostsActions.loadPostsAdmin(newPage, newStatus, inputValue)),
      500,
    ),
  ).current;

  const searchedTerm = useRef('');

  const posts = useSelector((state) => state.posts);

  const [modalInfo, setModalInfo] = useState();
  const [toastInfo, setToastInfo] = useState({});
  const [deleteCompleted, setDeleteCompleted] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState(-1);
  const [page, setPage] = useState(1);

  const [queryInputValue, setQueryInputValue] = useState('');

  useEffect(() => {
    dispatch(PostsActions.loadPostsAdmin(page, selectedStatus, searchedTerm?.current));
    setDeleteCompleted(false);
  }, [dispatch, page, selectedStatus, deleteCompleted]);

  useEffect(() => setPage(1), [selectedStatus]);

  useEffect(() => {
    if (posts?.success === true) {
      setToastInfo({ type: 'success', message: posts?.message });
    }
    if (posts?.success === false) {
      setToastInfo({ type: 'danger', message: posts?.message });
    }
  }, [posts?.success, posts?.message]);

  useEffect(() => {
    searchedTerm.current = queryInputValue;
  }, [queryInputValue]);

  useEffect(() => {
    const { message, type, autoClose } = toastInfo;
    if (message && type) {
      if (toastId.current !== null) {
        dismissToast(toastId.current);
        toastId.current = null;
      }
      toastId.current = toastId.current === null ? showToast(message, type, autoClose) : toastId.current;
    }
  }, [dispatch, toastInfo]);

  const handleStatusChange = (newSelected) => {
    if (newSelected !== selectedStatus) {
      setSelectedStatus(newSelected);
    }
  };

  const handleMoveClick = (id, itemTitle) => {
    if (selectedStatus === 0) {
      setModalInfo({
        id,
        title: 'Restauração',
        message: `Deseja restaurar o item '${itemTitle}' da lixeira?`,
        onConfirmation: () => handleRestoreFromTrashConfirmation(id),
      });
    } else {
      setModalInfo({
        id,
        title: 'Lixeira',
        message: `Deseja mover o item '${itemTitle}' para a lixeira?`,
        confirmationClassName: 'btn-danger',
        onConfirmation: () => handleMoveToTrashConfirmation(id),
      });
    }
  };

  const handleDeleteClick = (id, itemTitle) => {
    setModalInfo({
      id,
      title: 'Exclusão',
      message: `Deseja excluir permanentemente o item '${itemTitle}'?`,
      confirmationClassName: 'btn-danger',
      onConfirmation: () => handleDeleteConfirmation(id),
    });
  };

  const handleMoveToTrashConfirmation = (id) => {
    dispatch(PostsActions.moveToTrash(id, () => setSelectedStatus(0)));
    closeModal();
  };

  const handleRestoreFromTrashConfirmation = (id) => {
    dispatch(PostsActions.restoreFromTrash(id, () => setSelectedStatus(2)));
    closeModal();
  };

  const handleDeleteConfirmation = (id) => {
    dispatch(PostsActions.delete(id, () => setDeleteCompleted(true)));
    closeModal();
  };

  const closeModal = () => {
    setModalInfo(null);
  };

  const handlePageChange = (pageDirection) => {
    if (typeof pageDirection === 'number') {
      const pageNumber = pageDirection;
      setPage(pageNumber);
      return;
    }

    const currentPage = page;
    const changePage = {
      prev: () => {
        const newPage = currentPage - 1;
        setPage(newPage > 1 ? newPage : 1);
      },
      next: () => {
        const newPage = currentPage + 1;
        setPage(newPage <= posts?.page?.totalPages ? newPage : currentPage);
      },
    };

    changePage[pageDirection]();
  };

  const handleQuerySearch = (evt) => {
    const { value } = evt.target;
    setQueryInputValue(value);
    triggerSearch(page, selectedStatus, value);
  };

  const clearSearch = () => {
    setQueryInputValue('');
    triggerSearch(page, selectedStatus, '');
  };

  return (
    <div className="bg-white shadow crud">
      {/* <!-- Cabeçalho de Opções - start --> */}
      <header className="justify-content-between">
        <div className="px-4 w-33">
          <span className="has-icon search w-100">
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="Buscar por título ou conteúdo..."
              name="query"
              value={queryInputValue}
              onChange={handleQuerySearch}
            />
          </span>
        </div>
        <div className="px-4">
          {posts?.loading && (
            <span className="p-4">
              <i className="fa-solid fa-spinner fa-spin fa-lg"></i>
            </span>
          )}
          <FilterGroup statusList={postFilterStatus} current={selectedStatus} onSelected={handleStatusChange} />
          <Link to="novo" className="btn btn-success btn-sm ms-3">
            <i className="fa-solid fa-plus"></i> Adicionar notícia
          </Link>
        </div>
      </header>
      {/* <!-- Cabeçalho de Opções - end --> */}

      {searchedTerm?.current && (
        <Alert variant="info mx-4">
          <div className="row">
            <div className="col-9">
              <p className="mt-3 float-start">
                Resultados da busca por <strong>{searchedTerm?.current}</strong>
              </p>
            </div>
            <div className="col-3">
              <Button variant="outline-success float-end" onClick={clearSearch}>
                Limpar pesquisa
              </Button>
            </div>
          </div>
        </Alert>
      )}

      {/* <!-- Tabela - start --> */}

      <div className="p-5">
        <table className="crud mb-5">
          <colgroup>
            <col style={{ maxWidth: '3%', textAlign: 'center' }} />
            <col style={{ maxWidth: '40%' }} />
            <col style={{ maxWidth: '10%', textAlign: 'center' }} />
            <col style={{ maxWidth: '20%', textAlign: 'center' }} />
            <col style={{ maxWidth: '20%' }} />
            <col style={{ maxWidth: '7%', textAlign: 'right' }} />
          </colgroup>
          <thead>
            <tr>
              <th>Ordem</th>
              <th>Título</th>
              <th className="text-center">Status</th>
              <th className="text-center">Município</th>
              <th>Tópicos</th>
              <th className="text-center">Ações</th>
            </tr>
          </thead>
          <tbody>
            {posts?.data?.map((item) => (
              <tr key={`post-${item.id}`}>
                <td className="text-center">{item.presentationOrder || `-`}</td>
                <td>{item.title}</td>
                <td className="text-center">
                  <PostStatus status={item.status} />
                </td>
                <td className="text-center">
                  {item.city} - {item.stateAbbr}
                </td>
                <td>
                  {item.topics.map((topic, indexTopic) => (
                    <Fragment key={`badge-${item.id}-n-${indexTopic}`}>
                      <span className="badge bg-gray-superlight">{topic}</span>{' '}
                    </Fragment>
                  ))}
                </td>
                <td className="text-center">
                  <Dropdown>
                    <Dropdown.Toggle variant="outline-secondary" id={`dropdown-actions-${item.id}`} size="sm">
                      <i className="fa-solid fa-ellipsis-vertical"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item as={Link} to={`${item.id}/editar`}>
                        {item.status !== 0 && (
                          <>
                            <i className="fa-solid fa-pencil"></i> Editar
                          </>
                        )}
                        {item.status === 0 && (
                          <>
                            <i className="fa-solid fa-eye"></i> Ver detalhes
                          </>
                        )}
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => handleMoveClick(item.id, item.title)}>
                        {item.status === 0 && (
                          <>
                            <i className="fa-solid fa-trash-arrow-up"></i> Restaurar
                          </>
                        )}
                        {item.status !== 0 && (
                          <>
                            <i className="fa-solid fa-trash c-danger"></i> Mover para a lixeira
                          </>
                        )}
                      </Dropdown.Item>
                      {item.status === 0 && (
                        <Dropdown.Item onClick={() => handleDeleteClick(item.id, item.title)}>
                          <i className="fa-solid fa-xmark c-danger"></i> Excluir
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {posts?.data?.length > 0 && <Pagination pageInfo={posts?.page} onPageChange={handlePageChange} />}
        {posts?.data?.length === 0 && <p>Nenhuma notícia foi encontrada. </p>}
      </div>
      {/* <!-- Tabela - end --> */}
      <ConfirmationModal show={!!modalInfo} onClose={closeModal} {...modalInfo} />
    </div>
  );
};

export default PostsList;
