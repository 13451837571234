import { createSlice } from '@reduxjs/toolkit';
import { defaultHttpStatusMessages } from 'helpers/statusUtils';
import api from 'services/api';

function handleErrorMesssage(response, msg) {
  const complementMsg = msg ? ' ' + msg : '';
  if (response && 'status' in response) {
    return defaultHttpStatusMessages[response.status] + complementMsg;
  }
  return 'Não foi possível completar a operação.' + complementMsg;
}

const initialState = {
  loading: null,
  success: null,
  errorMsg: null,
};

export const changePasswordSlice = createSlice({
  name: 'changePassword',
  initialState,
  reducers: {
    startRequest: (state) => {
      state.loading = true;
      state.success = null;
      state.errorMsg = null;
    },
    success: (state) => {
      state.loading = false;

      state.success = true;
    },
    error: (state, action) => {
      state.loading = false;
      state.success = false;
      state.errorMsg = action.payload.message;
    },
    clearData: (state) => {
      state = initialState;
    },
  },
});

export const { startRequest, success, error, clearData } = changePasswordSlice.actions;

export const Actions = {
  changePassword: (currentPassword, password, passwordRepeat) => {
    return (dispatch) => {
      dispatch(startRequest());
      api
        .post('api/me/change-password', { currentPassword, password, passwordRepeat })
        .then((response) => {
          dispatch(success());
        })
        .catch((err) => {
          dispatch(error({ message: handleErrorMesssage(err.response) }));
        });
    };
  },
  clearData: () => {
    return (dispatch) => {
      dispatch(clearData());
    };
  },
};

export default changePasswordSlice.reducer;
