const questionTypes = Object.freeze({
  matrix: Number(process.env.REACT_APP_QUESTION_TYPES_MATRIX),
  singleOption: Number(process.env.REACT_APP_QUESTION_TYPES_SINGLEOPTION),
  multiOption: Number(process.env.REACT_APP_QUESTION_TYPES_MULTIOPTION),
  orderOption: Number(process.env.REACT_APP_QUESTION_TYPES_ORDEROPTION),
});

const answerStatus = Object.freeze({
  0: process.env.REACT_APP_ANSWERS_STATUS_NOTINITIALIZED,
  1: process.env.REACT_APP_ANSWERS_STATUS_FULFILLING,
  2: process.env.REACT_APP_ANSWERS_STATUS_FILLED,
});

const automaticSaveTimeMS = Number(process.env.REACT_APP_AUTOMATIC_SAVE_INTERVAL_MS);

const toastsDurationMS = Number(process.env.REACT_APP_TOASTS_DURATION_MS);

const toastAutomaticSaveDuration = Number(process.env.REACT_APP_TOAST_AUTOMATIC_SAVE_DURATION_MS);

const updateButtonDebounce = Number(process.env.REACT_APP_UPDATE_BUTTON_DEBOUNCE_MS);

export default {
  questionTypes,
  automaticSaveTimeMS,
  toastsDurationMS,
  toastAutomaticSaveDuration,
  answerStatus,
  updateButtonDebounce,
};
