import { createSlice } from '@reduxjs/toolkit';
// import UsersService from 'services/api/users/usersService'; --usar na configuração de usuário
import UsersAdminService from 'services/api/users/usersAdminService';

const requestControl = {
  loading: null,
  success: null,
  mutating: null,
  mutateSuccess: null,
};

const pageControl = {
  totalElements: 1,
  totalPages: 1,
  totalInPage: 1,
  pageNumber: 1,
  pageSize: 1,
  first: true,
  last: true,
};

const dataControl = {
  data: [],
  item: {},
};

const usersInitialState = {
  ...requestControl,
  ...pageControl,
  ...dataControl,
  message: null,
  notificationCount: 0,
  mayorFound: [],
};

export const usersSlice = createSlice({
  name: 'users',
  initialState: usersInitialState,
  reducers: {
    loadingStart: (state, action) => {
      state.loading = true;
      state.message = action.payload?.message || state.message;
      state.success = null;
    },
    loadingStop: (state) => {
      state.loading = false;
    },
    success: (state, action) => {
      const { data, item, page, message, mayorFound } = action.payload;
      state.success = true;
      state.data = data || state.data;
      state.page = page || state.page;
      state.item = item || state.item;
      state.message = message || state.message;
      state.mayorFound = mayorFound || state.mayorFound;
    },
    error: (state, action) => {
      const { httpStatus, message } = action.payload;
      state.success = false;
      state.message = `${httpStatus ? httpStatus + ' - ' : ''}${message}`;
    },
    updateNotificationCounter: (state, action) => {
      state.notificationCount = action.payload;
    },
    mutateStart: (state, action) => {
      state.mutating = true;
      state.message = action.payload?.message || state.message;
      state.mutateSuccess = null;
    },
    mutateStop: (state) => {
      state.mutating = false;
    },
    mutateClear: (state) => {
      state.mutating = null;
      state.mutateSuccess = null;
    },
    mutateSuccess: (state, action) => {
      const { data, item, page, message, mayorFound } = action.payload;
      state.mutateSuccess = true;
      state.data = data || state.data;
      state.page = page || state.page;
      state.item = item || state.item;
      state.message = message || state.message;
      state.mayorFound = mayorFound || state.mayorFound;
    },
    mutateError: (state, action) => {
      const { httpStatus, message } = action.payload;
      state.mutateSuccess = false;
      state.message = `${httpStatus ? httpStatus + ' - ' : ''}${message}`;
    },
    reset: () => {
      return { ...usersInitialState };
    },
  },
});

export const UsersSliceActions = usersSlice.actions;

export const Actions = {
  loadUsersAllRoles: (page, orderObj, status, keyword, role) => {
    return (dispatch) => {
      dispatch(UsersSliceActions.loadingStart());
      Promise.all([
        UsersAdminService.countNotifications(),
        UsersAdminService.findAllRoles(page, 10, orderObj, status, keyword, role),
      ])
        .then(([counterValue, usersValue]) => {
          const responseData = usersValue?.data;
          const page = {
            totalElements: responseData.totalElements,
            totalPages: responseData.totalPages,
            totalInPage: responseData.numberOfElements,
            pageNumber: responseData.number + 1,
            pageSize: responseData.size,
            first: responseData.first,
            last: responseData.last,
          };
          dispatch(UsersSliceActions.success({ page, data: responseData.content }));
          dispatch(UsersSliceActions.updateNotificationCounter(counterValue.data));
        })
        .catch((err) =>
          dispatch(
            UsersSliceActions.error({
              httpStatus: err?.response?.status,
              message: 'Ocorreu um erro ao carregar os usuários. ',
            }),
          ),
        )
        .finally(() => dispatch(UsersSliceActions.loadingStop()));
    };
  },
  loadAdvisorUsers: (page, orderObj, status, keyword) => {
    return (dispatch) => {
      dispatch(UsersSliceActions.loadingStart());
      Promise.all([
        UsersAdminService.countNotificationsCity(),
        UsersAdminService.findAllRoles(page, 10, orderObj, status, keyword),
      ])
        .then(([counterValue, usersValue]) => {
          const responseData = usersValue?.data;
          const page = {
            totalElements: responseData.totalElements,
            totalPages: responseData.totalPages,
            totalInPage: responseData.numberOfElements,
            pageNumber: responseData.number + 1,
            pageSize: responseData.size,
            first: responseData.first,
            last: responseData.last,
          };
          dispatch(UsersSliceActions.success({ page, data: responseData.content }));
          dispatch(UsersSliceActions.updateNotificationCounter(counterValue?.data));
        })
        .catch((err) =>
          dispatch(
            UsersSliceActions.error({
              httpStatus: err?.response?.status,
              message: 'Ocorreu um erro ao carregar os assessores.',
            }),
          ),
        )
        .finally(() => dispatch(UsersSliceActions.loadingStop()));
    };
  },
  loadGovernanceUsers: (page, order) => {
    return (dispatch) => {
      dispatch(UsersSliceActions.loadingStart());
      UsersAdminService.findAllGovernance(page, 10, order)
        .then((res) => {
          const responseData = res?.data;
          const page = {
            totalElements: responseData.totalElements,
            totalPages: responseData.totalPages,
            totalInPage: responseData.numberOfElements,
            pageNumber: responseData.number + 1,
            pageSize: responseData.size,
            first: responseData.first,
            last: responseData.last,
          };
          dispatch(UsersSliceActions.success({ page, data: responseData.content }));
        })
        .catch((err) =>
          dispatch(
            UsersSliceActions.error({
              httpStatus: err?.response?.status,
              message: 'Ocorreu um erro ao carregar os usuários administrativos.',
            }),
          ),
        )
        .finally(() => dispatch(UsersSliceActions.loadingStop()));
    };
  },
  approveUser: (publicId, onSuccess) => {
    return (dispatch) => {
      dispatch(UsersSliceActions.mutateStart({ message: 'Aguarde...' }));
      UsersAdminService.approveUser(publicId)
        .then(() => {
          dispatch(UsersSliceActions.mutateSuccess({ message: 'Assessor aprovado com sucesso. ' }));
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch((err) =>
          dispatch(
            UsersSliceActions.mutateError({
              httpStatus: err?.response?.status,
              message: 'Erro ao aprovar assessor. ',
            }),
          ),
        )
        .finally(() => dispatch(UsersSliceActions.mutateStop()));
    };
  },
  disapproveUser: (publicId, data, onSuccess) => {
    return (dispatch) => {
      dispatch(UsersSliceActions.mutateStart({ message: 'Aguarde...' }));
      UsersAdminService.disapproveUser(publicId, data)
        .then(() => {
          dispatch(UsersSliceActions.mutateSuccess({ message: 'Assessor recusado com sucesso. ' }));
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch((err) =>
          dispatch(
            UsersSliceActions.mutateError({
              httpStatus: err?.response?.status,
              message: 'Erro ao recusar assessor. ',
            }),
          ),
        )
        .finally(() => dispatch(UsersSliceActions.mutateStop()));
    };
  },
  checkMayorFromCityId: (cityId) => {
    return (dispatch) => {
      dispatch(UsersSliceActions.loadingStart({ message: 'Checando informações de prefeito...' }));
      UsersAdminService.findMayorByCityId(cityId)
        .then((res) => {
          const mayorFound = res.data;
          dispatch(UsersSliceActions.success({ mayorFound }));
        })
        .catch((err) =>
          dispatch(
            UsersSliceActions.error({
              httpStatus: err?.response?.status,
              message: 'Erro ao confirmar informações de prefeito. ',
            }),
          ),
        )
        .finally(() => dispatch(UsersSliceActions.loadingStop()));
    };
  },
  resendActivationEmail: (userId, onSuccess) => {
    return (dispatch) => {
      dispatch(UsersSliceActions.mutateStart({ message: 'Aguarde...' }));
      UsersAdminService.resendActivationEmail(userId)
        .then(() => {
          dispatch(UsersSliceActions.mutateSuccess({ message: 'E-mail de ativação enviado com sucesso. ' }));
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch((err) =>
          dispatch(
            UsersSliceActions.mutateError({
              httpStatus: err?.response?.status,
              message: 'Erro ao enviar e-mail de ativação. ',
            }),
          ),
        )
        .finally(() => dispatch(UsersSliceActions.mutateStop()));
    };
  },
  resendGovernanceActivationEmail: (userId) => {
    return (dispatch) => {
      dispatch(UsersSliceActions.mutateStart({ message: 'Aguarde...' }));
      UsersAdminService.resendGovernanceActivationEmail(userId)
        .then(() => dispatch(UsersSliceActions.mutateSuccess({ message: 'E-mail de ativação enviado com sucesso. ' })))
        .catch((err) =>
          dispatch(
            UsersSliceActions.mutateError({
              httpStatus: err?.response?.status,
              message: 'Erro ao enviar e-mail de ativação. ',
            }),
          ),
        )
        .finally(() => dispatch(UsersSliceActions.mutateStop()));
    };
  },
  deleteGovernanceUser: (userId, deletionObj, onSuccess) => {
    return (dispatch) => {
      dispatch(UsersSliceActions.mutateStart({ message: 'Aguarde...' }));
      UsersAdminService.deleteUserGovernance(userId, deletionObj)
        .then(() => {
          dispatch(UsersSliceActions.mutateSuccess({ message: 'Usuário administrativo excluído com sucesso. ' }));
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch((err) =>
          dispatch(
            UsersSliceActions.mutateError({
              httpStatus: err?.response?.status,
              message: 'Erro ao excluir usuário administrativo. ',
            }),
          ),
        )
        .finally(() => dispatch(UsersSliceActions.mutateStop()));
    };
  },
  getGovernanceUserById: (userId) => {
    return (dispatch) => {
      dispatch(UsersSliceActions.loadingStart());
      UsersAdminService.getUserGovernance(userId)
        .then((res) => {
          const item = res?.data;
          dispatch(UsersSliceActions.success({ item }));
        })
        .catch((err) =>
          dispatch(
            UsersSliceActions.error({
              httpStatus: err?.response?.status,
              message: 'Erro ao obter informações de usuário administrativo. ',
            }),
          ),
        )
        .finally(() => dispatch(UsersSliceActions.loadingStop()));
    };
  },
  saveGovernanceUser: (userData, onSuccess) => {
    return (dispatch) => {
      dispatch(UsersSliceActions.mutateStart());
      UsersAdminService.saveUserGovernance(userData)
        .then(() => {
          dispatch(UsersSliceActions.mutateSuccess({ message: 'Usuário cadastrado com sucesso. ' }));
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch((err) => {
          dispatch(
            UsersSliceActions.mutateError({
              httpStatus: err?.response?.status,
              message: 'Erro ao cadastrar usuário administrativo. ',
            }),
          );
        })
        .finally(() => dispatch(UsersSliceActions.mutateStop()));
    };
  },
  clearData: () => {
    return (dispatch) => {
      dispatch(UsersSliceActions.reset());
    };
  },
};

const usersReducer = usersSlice.reducer;
export default usersReducer;
