import React from 'react';
import { useLocation } from 'react-router-dom';
import SidebarLink from 'components/SidebarItem';

import { applicationRoles } from 'helpers/statusUtils';

const AdminSidebar = () => {
  const arr = window.ENV.API_URL.split('/');
  const domain = arr[0] + '//' + arr[2];
  const downloadUrl = domain + '/images/inteligente-dados.xlsx';

  const { pathname } = useLocation();

  const addActiveClassName = (routeToMatch, exact = true) => {
    if (exact === true) {
      return pathname === routeToMatch ? 'active' : '';
    }
    return pathname.startsWith(routeToMatch) ? 'active' : '';
  };

  return (
    <div className="bg-white shadow-right position-relative z-1 w-100">
      <ul className="list-group list-group-flush">
        <SidebarLink
          to="dashboard"
          className={`list-group-item list-group-item-action ${addActiveClassName('/portal/dashboard')}`}
          requiredRoles={[applicationRoles.GOVERNANCE, applicationRoles.GOVERNANCE_VIEWER]}
        >
          Níveis de maturidade
        </SidebarLink>
        <SidebarLink
          to="prefeitos"
          className={`list-group-item list-group-item-action ${addActiveClassName('/portal/prefeitos')}`}
          requiredRoles={[applicationRoles.GOVERNANCE]}
        >
          Gerenciamento de prefeituras
        </SidebarLink>
        <SidebarLink
          to="posts"
          className={`list-group-item list-group-item-action ${addActiveClassName('/portal/posts', false)}`}
          requiredRoles={[applicationRoles.GOVERNANCE]}
        >
          Cidades Inteligentes Sustentáveis na mídia
        </SidebarLink>
        <SidebarLink
          to="boletins"
          className={`list-group-item list-group-item-action ${addActiveClassName('/portal/boletins')}`}
          requiredRoles={[applicationRoles.GOVERNANCE]}
        >
          Boletins informativos
        </SidebarLink>
        <SidebarLink
          to="usuarios-admin"
          className={`list-group-item list-group-item-action ${addActiveClassName('/portal/usuarios-admin', false)}`}
          requiredRoles={[applicationRoles.GOVERNANCE]}
        >
          Usuários administrativos
        </SidebarLink>
        <SidebarLink
          to="indicadores"
          className={`list-group-item list-group-item-action ${addActiveClassName('/portal/indicadores')}`}
          requiredRoles={[applicationRoles.GOVERNANCE]}
        >
          Atualização de indicadores
        </SidebarLink>
        <SidebarLink
          to="configuracoes"
          className={`list-group-item list-group-item-action ${addActiveClassName('/portal/configuracoes')}`}
          requiredRoles={[applicationRoles.GOVERNANCE]}
        >
          Configurações do portal
        </SidebarLink>
        <li className="list-group-item list-group-item-action">
          <a href={downloadUrl} download>
            Download dos dados <i className="fa-solid fa-file-arrow-down"></i>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default AdminSidebar;
