import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const AnswerChanged = ({ changed, text = 'ALTERADO' }) =>
  changed && (
    <span className="badge rounded-pill text-bg-warning ms-3" style={{ fontSize: '0.5em' }}>
      {text}
    </span>
  );

export const OptionText = ({ bullet, text }) => {
  return (
    <span>
      <strong className="me-1">{bullet}.</strong>
      {text}
    </span>
  );
};

export const OptionTextSimple = ({ bullet, text, helper = false }) => {
  return (
    <>
      <b>{bullet}.</b> {text}
      {helper && (
        <>
          &nbsp;<i className="fa-solid fa-circle-info"></i>
        </>
      )}
    </>
  );
};

export const OptionHelper = ({ code, text }) =>
  !!text && (
    <OverlayTrigger
      key={`option-helper-${code}`}
      placement="auto"
      overlay={<Tooltip id={`option-helper-${code}`}>{text}</Tooltip>}
    >
      <i className="fa-solid fa-circle-info" style={{ marginLeft: '10px' }}></i>
    </OverlayTrigger>
  );

export const QuestionHelper = ({ code, text }) =>
  !!text && (
    <OverlayTrigger
      key={`question-helper-${code}`}
      placement="auto"
      overlay={<Tooltip id={`question-helper-${code}`}>{text}</Tooltip>}
    >
      <i className="fa-solid fa-circle-info c-gray-light" style={{ marginLeft: '10px' }}></i>
    </OverlayTrigger>
  );

// 65 - cod ASCII do "A"
export const getBulletFromIndex = (index) => String.fromCharCode(65 + index);

export const getBulletFromPosition = (position) => getBulletFromIndex(position - 1);
