import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import Sidebar from './Sidebar';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

import Validators from 'helpers/Validators';
import { submitRecaptcha } from 'components/reCaptcha';
import { Creators as CityUserRegistrationActions } from 'services/ducks/cityUser.registration';
import api from 'services/api';
import { unsetMask } from 'helpers/inputMasks';
import { debounce } from 'lodash';

const retrieveFieldLabel = {
  name: 'Nome',
  email: 'E-mail',
  password: 'Senha',
  passwordRepeat: 'Repetição da senha',
  roleCode: 'Cargo',
  roleComplement: 'Departamento',
  cityId: 'Cidade',
  'identityCard.number': 'Número do RG',
  'identityCard.emitter': 'Órgão emissor do RG',
  'identityCard.emissionDate': 'Data de emissão do RG',
  'voterCard.number': 'Número do título de eleitor',
  'voterCard.cityId': 'Domicílio eleitoral',
  'voterCard.zone': 'Zona eleitoral',
  'voterCard.section': 'Seção eleitoral',
};

const ModalRegistration = (props) => {
  const navigate = useNavigate();

  const localOnHide = () => {
    props.onHide();
    if (props.shouldRedirect) {
      navigate(props.pathToRedirect);
    }
  };

  const errorList = (props.fieldErrors && Object.entries(props.fieldErrors)) || [];

  return (
    <Modal
      className="inteligente-modal"
      show={props.show}
      onHide={localOnHide}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {props.isError ? 'Erro ao finalizar preenchimento' : 'Formulário preenchido com sucesso'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p dangerouslySetInnerHTML={{ __html: props.message || '' }}></p>
        {errorList?.length > 0 && (
          <ul>
            {errorList.map(([key, msg]) => (
              <li key={key}>{retrieveFieldLabel[key] + ': ' + msg}</li>
            ))}
          </ul>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-primary" onClick={localOnHide}>
          OK
        </button>
      </Modal.Footer>
    </Modal>
  );
};

const validateStep1 = (role, department, cityId, phoneNumber, email) => {
  const { isRole, isDepartment, isCityId, isPhoneNumber, validEmail } = Validators;
  return (
    isRole(role) &&
    isDepartment(role, department) &&
    isCityId(cityId) &&
    isPhoneNumber(phoneNumber) &&
    validEmail(email, true)
  );
};

const validateStep2 = (termOfUseAccept) => {
  const { termsAccepted } = Validators;
  return termsAccepted(termOfUseAccept);
};

const RegistrationForm = ({ currentStep, setCurrentStep, authToken, headers, cpf, name = '', emailFromHome = '' }) => {
  const MAX_STEPS = headers.length;

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    name,
    email: emailFromHome,
    telephoneNumber: '',
    role: '',
    department: '',
    city: '',
    cityId: '',
    cpfNumber: '',
    termsOfUseAcceptance: false,
  });

  const [modalErrorShow, setModalErrorShow] = useState(false);
  const formErrorMessage = useSelector((state) => state.cityUserRegistration.error.message);
  const fieldErrors = useSelector((state) => state.cityUserRegistration.error.fieldErrors);
  const hasFormError = useSelector((state) => state.cityUserRegistration.error.status);
  const isFormSuccess = useSelector((state) => state.cityUserRegistration.successful);
  const registrationType = useSelector((state) => state.cityUserRegistration.registrationType);
  const tries = useSelector((state) => state.cityUserRegistration.error.tries);

  const validatorObject = useMemo(
    () => ({
      step1: validateStep1(
        formData.role,
        formData.department,
        formData.cityId,
        formData.telephoneNumber,
        formData.email,
      ),
      step2: validateStep2(formData.termsOfUseAcceptance),
    }),
    [formData],
  );

  useEffect(() => setModalErrorShow(hasFormError), [hasFormError, tries]);
  useEffect(() => {
    return () => dispatch(CityUserRegistrationActions.reloadForm());
  }, [dispatch]);

  const handleChange = (evt, city = null) => {
    const { name, value, checked } = evt.target;

    if (name === 'dataConfirmation' || name === 'termsOfUseAcceptance') {
      setFormData((current) => ({ ...current, [name]: checked }));
      return;
    }

    if (city) {
      setFormData((current) => ({ ...current, [name]: value, [name + 'Id']: city.id }));
    } else {
      setFormData((current) => ({ ...current, [name]: unsetMask(name, value) }));
    }
  };

  const triggerSubmit = useRef(
    debounce((data) => {
      submitRecaptcha('new_user_link_accounts').then((token) => {
        dispatch(CityUserRegistrationActions.registerFromOpenId(token, authToken, data));
      });
    }, 500),
  ).current;

  const handleSubmit = (evt) => {
    evt.preventDefault();
    triggerSubmit(formData);
  };

  const _next = useCallback(
    (evt, step) => {
      evt.preventDefault();
      return validatorObject['step' + currentStep]
        ? setCurrentStep(step >= MAX_STEPS ? MAX_STEPS : (step = step + 1))
        : false;
    },
    [currentStep, setCurrentStep, validatorObject, MAX_STEPS],
  );

  const _prev = useCallback(
    (evt, step) => {
      evt.preventDefault();
      setCurrentStep(step <= 1 ? 1 : (step = step - 1));
    },
    [setCurrentStep],
  );

  const nextButton = () => {
    const buttonLabel = currentStep === 1 ? 'Continuar' : currentStep === 2 ? 'Finalizar' : 'Voltar para tela inicial';
    if (currentStep === 2) {
      if (validatorObject.step1 && validatorObject.step2) {
        return (
          <a
            href="#"
            className={`c-gray ${!validatorObject['step' + currentStep] ? 'op-40 disabled' : ''}`}
            onClick={handleSubmit}
          >
            {buttonLabel} <i className="fa-solid fa-chevron-right fa-2x"></i>
          </a>
        );
      } else {
        return (
          <a href="#" className={`c-gray ${!validatorObject['step' + currentStep] ? 'op-40 disabled' : ''}`} disabled>
            {buttonLabel} <i className="fa-solid fa-chevron-right fa-2x"></i>
          </a>
        );
      }
    }
    if (currentStep < MAX_STEPS - 1) {
      return (
        <a
          href="#"
          className={`c-gray ${!validatorObject['step' + currentStep] ? 'op-40 disabled' : ''}`}
          onClick={(evt) => _next(evt, currentStep)}
        >
          {buttonLabel} <i className="fa-solid fa-chevron-right fa-2x"></i>
        </a>
      );
    }
  };

  const prevButton = () => {
    if (currentStep > 1 && currentStep !== MAX_STEPS) {
      return (
        <a href="#" className="c-gray" onClick={(evt) => _prev(evt, currentStep)}>
          <i className="fa-solid fa-chevron-left fa-2x"></i>
        </a>
      );
    }
    if (currentStep === 1) {
      return (
        <a href="#" className="c-gray disabled">
          <i className="fa-solid fa-chevron-left fa-2x"></i>
        </a>
      );
    }
  };

  useEffect(() => {
    if (isFormSuccess === true) {
      setCurrentStep(MAX_STEPS);
    }
  }, [isFormSuccess, MAX_STEPS, setCurrentStep]);

  return (
    <>
      <div className="p-5 c-gray-dark fw-semibold">
        <h4 className="fw-bold c-green">{headers[currentStep - 1]}</h4>

        <hr className="mt-4 mb-5"></hr>

        {/* <!-- Formulário - start --> */}

        {currentStep === 1 && <Step1 data={formData} handleChange={handleChange} />}
        {currentStep === 2 && <Step2 data={formData} handleChange={handleChange} />}
        {isFormSuccess && <Step3 data={formData} registrationType={registrationType} />}

        {/* <!-- Formulário - end --> */}

        <hr className="mt-5 mb-4"></hr>

        {/* <!-- Pagination - start --> */}
        <div className="d-flex justify-content-evenly align-items-center">
          <div className="me-4">{prevButton()}</div>
          <div className="text-center d-flex">
            {headers.map((header, index) => {
              const key = `registration-progress-${index + 1}`;
              if (index < currentStep - 1) {
                return (
                  <span key={key} className="circle-text circle-text-gray me-4">
                    {index + 1}
                  </span>
                );
              } else if (index === currentStep - 1) {
                return (
                  <span key={key} className="circle-text circle-text-blue-deep me-4">
                    {index + 1}
                  </span>
                );
              }
              return (
                <span
                  key={key}
                  className={`circle-text circle-text-gray-light ${currentStep !== MAX_STEPS ? 'me-4' : ''}`}
                >
                  {index + 1}
                </span>
              );
            })}
          </div>
          <div className="ms-4">{nextButton()}</div>
        </div>
        {/* <!-- Pagination - end --> */}
      </div>
      <ModalRegistration
        isError
        message={formErrorMessage}
        show={modalErrorShow}
        onHide={() => setModalErrorShow(false)}
        fieldErrors={fieldErrors}
      />
    </>
  );
};

const CityUserRegistration = (props) => {
  // JWT de autenticação pelo redirect após Auth
  const { state } = useLocation();
  const token = state?.token;

  const [userData, setUserData] = useState();
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    if (!verified) {
      api
        .get('/api/users/link-accounts', {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        })
        .then((resp) => {
          console.log(JSON.stringify(resp.data));
          const data = {
            name: resp.data.name,
            email: resp.data.email,
            status: resp.data.status,
            token,
          };
          setUserData(data);
        })
        .catch((err) => {
          console.error(`Link accounts error: ${JSON.stringify(err.response)}`);
        })
        .finally(() => {
          setVerified(true);
        });
    }
    // eslint-disable-next-line
  }, [token]);

  const [currentStep, setCurrentStep] = useState(1);
  const headers = ['Dados institucionais', 'Termos de uso', 'Aprovação de cadastro'];

  return (
    <div className="bg-cyan">
      <div className="container">
        <div className="row">
          {/* <!-- Coluna Título - start --> */}
          {(userData?.status === 1 || userData?.status === 2) && (
            <>
              <div className="col-12 col-lg-3">
                <Sidebar headers={headers} currentStep={currentStep} name={userData?.name} />
              </div>

              {/* <!-- Coluna Form - start --> */}
              <div className="col-12 col-lg-9 bg-white">
                <RegistrationForm
                  headers={headers}
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  authToken={token}
                  name={userData?.name}
                />
              </div>
            </>
          )}
          {!userData && (
            <div className="col-12 bg-white">
              <p>Aguarde</p>
            </div>
          )}
          {/* <!-- Coluna Form - end --> */}
        </div>
      </div>
    </div>
  );
};

export default CityUserRegistration;
