import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import { debounce } from 'lodash';
import Pagination from 'components/Pagination';
import { dismissToast, showToast } from 'components/Toast';
import { Actions as CityUserActions } from 'services/slices/user';
import { ApprovalModal, DisapprovalModal } from './Modals';
import { ConfirmationModal } from 'components/Modal';
import { useTitle } from 'hooks/useTitle';
import { setMask } from 'helpers/inputMasks';

const tabsList = [
  {
    value: 1,
    label: 'Aprovados',
  },
  {
    value: 0,
    label: 'Pendentes',
  },
  {
    value: 2,
    label: 'Recusados',
  },
];

const rolesList = [
  {
    value: '',
    label: 'Todos os cargos',
  },
  {
    value: 'P',
    label: 'Prefeitos',
  },
  {
    value: 'A',
    label: 'Assessores',
  },
];

const TabGroup = ({ tabsList, current, notificationCount = 0, onSelected }) => (
  <div className="tabs w-50">
    {tabsList?.map((filter) => (
      <div
        key={`city-user-filter-status-${filter.value}`}
        className={`tab w-33 ${filter.value === current ? 'active' : ''}`}
      >
        <a href="#" onClick={() => onSelected(filter.value)}>
          {filter.label}
        </a>
        {filter.value === 0 && notificationCount > 0 && (
          <div className="notification">
            <a href="#notification">{notificationCount}</a>
          </div>
        )}
      </div>
    ))}
  </div>
);

const CityUserManagement = () => {
  const pageTitle = useTitle('Gerenciamento de Prefeituras');

  const cityUser = useSelector((state) => state.users);

  const [toastInfo, setToastInfo] = useState({});

  const [modalInfo, setModalInfo] = useState();
  const [modalDisapprovalInfo, setModalDisapprovalInfo] = useState();
  const [modalApprovalInfo, setModalApprovalInfo] = useState();

  const dispatch = useDispatch();

  const toastId = useRef(null);

  const searchedTerm = useRef(null);

  const triggerSearch = useRef(
    debounce(
      (inputValue, currentStatus, currentRole) =>
        dispatch(CityUserActions.loadUsersAllRoles(page, order, currentStatus, inputValue, currentRole)),
      500,
    ),
  ).current;

  const [selectedStatus, setSelectedStatus] = useState(0);
  const [selectedRole, setSelectedRole] = useState('');
  const [order, setOrder] = useState(['', '']);
  const [userIdSelected, setUserIdSelected] = useState('');
  const [queryInputValue, setQueryInputValue] = useState('');
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(CityUserActions.loadUsersAllRoles(page, order, selectedStatus, searchedTerm?.current, selectedRole));
  }, [dispatch, page, selectedStatus, order, selectedRole]);

  useEffect(() => setPage(1), [selectedStatus]);

  useEffect(() => {
    if (cityUser?.mutateSuccess === true) {
      setToastInfo({ type: 'success', message: cityUser?.message });
    } else if (cityUser?.mutateSuccess === false) {
      setToastInfo({ type: 'danger', message: cityUser?.message });
    }
  }, [cityUser?.mutateSuccess, cityUser?.message]);

  useEffect(() => {
    searchedTerm.current = queryInputValue;
  }, [queryInputValue]);

  useEffect(() => {
    const { message, type, autoClose } = toastInfo;
    if (message && type) {
      if (toastId.current !== null) {
        dismissToast(toastId.current);
        toastId.current = null;
      }
      toastId.current = toastId.current === null ? showToast(message, type, autoClose) : toastId.current;
    }
  }, [dispatch, toastInfo]);

  const handleStatusChange = (newSelected) => {
    if (newSelected !== selectedStatus) {
      setSelectedStatus(newSelected);
    }
  };

  const handleRoleChange = (newSelected) => {
    if (newSelected !== selectedRole) {
      setSelectedRole(newSelected);
    }
  };

  const handleOrderChange = (field) => {
    setOrder((current) => [field, current && current[1] === 'DESC' ? 'ASC' : 'DESC']);
  };

  const handlePageChange = (pageDirection) => {
    if (typeof pageDirection === 'number') {
      const pageNumber = pageDirection;
      setPage(pageNumber);
      return;
    }

    const currentPage = page;
    const changePage = {
      prev: () => {
        const newPage = currentPage - 1;
        setPage(newPage > 1 ? newPage : 1);
      },
      next: () => {
        const newPage = currentPage + 1;
        setPage(newPage <= cityUser?.page?.totalPages ? newPage : currentPage);
      },
    };

    changePage[pageDirection]();
  };

  const handleUserMoreInfo = (userId) => {
    if (userIdSelected === userId) {
      setUserIdSelected('');
    } else {
      setUserIdSelected(userId);
    }
  };

  const handleQuerySearch = (evt) => {
    const { value } = evt.target;
    setQueryInputValue(value);
    triggerSearch(value, selectedStatus, selectedRole);
  };

  const handleApproval = (id, personName, roleCode, cityId) => {
    setModalApprovalInfo({
      id,
      roleCode,
      cityId,
      title: 'Aprovar cadastro',
      message: `Deseja aprovar o cadastro de "${personName}"?`,
      onConfirmation: approveUser,
    });
  };

  const handleDisapproval = (id, personName) => {
    setModalDisapprovalInfo({
      id,
      title: 'Recusar cadastro',
      message: `Deseja recusar o cadastro de "${personName}"?`,
      onConfirmation: disapproveUser,
    });
  };

  const handleResendEmail = (id, personName, personEmail) => {
    setModalInfo({
      id,
      title: 'Reenviar e-mail de ativação',
      message: `Deseja reenviar o e-mail de ativação de "${personName} ${personEmail ? `(${personEmail})` : ''}"?`,
      onConfirmation: resendEmail,
    });
  };

  const disapproveUser = (id, disapprovalObj) => {
    dispatch(
      CityUserActions.disapproveUser(id, disapprovalObj, () => {
        setSelectedStatus(2);
      }),
    );
    closeDisapprovalModal();
    setUserIdSelected('');
  };

  const approveUser = (id) => {
    dispatch(
      CityUserActions.approveUser(id, () => {
        setSelectedStatus(1);
      }),
    );
    closeApprovalModal();
    setUserIdSelected('');
  };

  const resendEmail = (id) => {
    dispatch(CityUserActions.resendActivationEmail(id));
    closeConfirmationModal();
    setUserIdSelected('');
  };

  const closeConfirmationModal = () => {
    setModalInfo(null);
  };

  const closeDisapprovalModal = () => {
    setModalDisapprovalInfo(null);
  };

  const closeApprovalModal = () => {
    setModalApprovalInfo(null);
  };

  return (
    <>
      <div className="bg-gray-superlight px-5 py-4 w-100">
        {/* <!-- Cabeçalho Página - start --> */}
        <header>
          <h3 className="fw-bolder c-cyan mb-0">{pageTitle}</h3>
          <p className="path">
            <Link to="">Início</Link> &rsaquo; <span className="current">{pageTitle}</span>
          </p>
        </header>
        {/* <!-- Cabeçalho Página - end --> */}

        {/* <!-- Conteúdos - start --> */}
        {/* <!-- Área de CRUD - start --> */}
        <div className="bg-white shadow crud">
          {/* <!-- Cabeçalho de Opções - start --> */}
          <header>
            {/* <!-- Tabs --> */}
            <TabGroup
              tabsList={tabsList}
              current={selectedStatus}
              notificationCount={cityUser?.notificationCount}
              onSelected={handleStatusChange}
            />

            {/* <!-- Filtros --> */}
            <div className="w-50 d-flex align-items-stretch">
              <div className={`w-50 px-3 d-flex align-items-center`}>
                <select className="form-select form-select-sm" onChange={(evt) => handleRoleChange(evt.target.value)}>
                  {rolesList.map((role) => (
                    <option key={`role-${role.value}`} value={role.value}>
                      {role.label}
                    </option>
                  ))}
                </select>
              </div>

              <div className="w-50 px-3 d-flex align-items-center">
                <span className="has-icon search w-100">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="Buscar por município..."
                    name="query"
                    value={queryInputValue}
                    onChange={handleQuerySearch}
                  />
                </span>
              </div>
            </div>
          </header>
          {/* <!-- Cabeçalho de Opções - end --> */}

          {/* <!-- Tabela - start --> */}
          <div className="p-5">
            <table className="crud mb-5">
              <colgroup>
                <col style={{ maxWidth: '5%', textAlign: 'center' }} />
                <col style={{ maxWidth: '50%' }} />
                <col style={{ maxWidth: '20%', textAlign: 'center' }} />
                <col style={{ maxWidth: '5%', textAlign: 'center' }} />
                <col style={{ maxWidth: '20%', textAlign: 'center' }} />
              </colgroup>
              <thead>
                <tr>
                  <th>
                    Cargo{' '}
                    <span onClick={() => handleOrderChange('role')}>
                      <i
                        className={`fa-solid fa-sort${
                          order[0] === 'role'
                            ? order[1] === 'DESC'
                              ? '-down c-aquamarine'
                              : '-up c-aquamarine'
                            : ' c-gray-light'
                        } ms-2`}
                      ></i>
                    </span>
                  </th>
                  <th>
                    Nome{' '}
                    <span onClick={() => handleOrderChange('name')}>
                      <i
                        className={`fa-solid fa-sort${
                          order[0] === 'name'
                            ? order[1] === 'DESC'
                              ? '-down c-aquamarine'
                              : '-up c-aquamarine'
                            : ' c-gray-light'
                        } ms-2`}
                      ></i>
                    </span>
                  </th>
                  <th className="text-center">
                    Município{' '}
                    <span onClick={() => handleOrderChange('city')}>
                      <i
                        className={`fa-solid fa-sort${
                          order[0] === 'city'
                            ? order[1] === 'DESC'
                              ? '-down c-aquamarine'
                              : '-up c-aquamarine'
                            : ' c-gray-light'
                        } ms-2`}
                      ></i>
                    </span>
                  </th>
                  <th className="text-center">Status</th>
                  <th className="text-center">Ações</th>
                </tr>
              </thead>
              <tbody>
                {cityUser?.data?.length > 0 &&
                  cityUser.data.map((item) => (
                    <Fragment key={`user-${item.id}`}>
                      <tr>
                        <td>
                          {item.roleCode === 'P' && (
                            <>
                              <i className="fa-solid fa-crown c-gray-light me-1"></i> Prefeito
                            </>
                          )}
                          {item.roleCode === 'A' && (
                            <>
                              <i className="fa-solid fa-user-tie c-gray-light me-1"></i> Assessor
                            </>
                          )}
                        </td>
                        <td>{item.name}</td>
                        <td className="text-center">{`${item.city?.name} - ${item.city?.state}`}</td>

                        {item.status === 0 && (
                          <td className="text-center">
                            <i className="fa-solid fa-lg fa-triangle-exclamation text-warning"></i>
                          </td>
                        )}
                        {item.status === 1 && (
                          <td className="text-center">
                            <i className="fa-solid fa-lg fa-check c-green"></i>
                          </td>
                        )}
                        {item.status === 2 && (
                          <td className="text-center">
                            <i className="fa-solid fa-lg fa-xmark text-danger"></i>
                          </td>
                        )}
                        {item.status === 10 && (
                          <td className="text-center">
                            <i className="fa-solid fa-lg fa-clock text-info"></i>
                          </td>
                        )}

                        <td className="text-center">
                          <a href="#" onClick={() => handleUserMoreInfo(item.id)}>
                            {userIdSelected === item.id ? <strong>Recolher</strong> : 'Mais informações'}
                          </a>
                        </td>
                      </tr>
                      {userIdSelected === item.id && (
                        <tr>
                          <td colSpan={5}>
                            <div className="container py-4">
                              <div className="row">
                                <div className="col-sm-9">
                                  <div className="row">
                                    <div className="col-md-12 align-self-center">
                                      <strong>CPF:</strong> {setMask('cpfNumber', item.cpf) || 'Não informado'}
                                    </div>
                                    <div className="col-md-6 align-self-center">
                                      <strong>E-mail:</strong> {item.email || 'Não informado'}
                                    </div>
                                    <div className="col-md-6 align-self-center">
                                      <strong>Telefone:</strong>{' '}
                                      {setMask('telephoneNumber', item.telephoneNumber) || 'Não informado'}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-3">
                                  <div className="d-grid gap-2">
                                    {item.status === 2 && <></>}
                                    {item.status !== 1 && item.status !== 10 && (
                                      <button
                                        className="btn btn-sm btn-success"
                                        onClick={() => handleApproval(item.id, item.name, item.roleCode, item.city?.id)}
                                      >
                                        Aprovar cadastro
                                      </button>
                                    )}
                                    {item.status !== 1 && item.status === 10 && (
                                      <button
                                        className="btn btn-sm btn-warning"
                                        onClick={() => handleResendEmail(item.id, item.name, item.email)}
                                      >
                                        Enviar e-mail de ativação
                                      </button>
                                    )}
                                    {item.status !== 2 && (
                                      <button
                                        className="btn btn-sm btn-danger"
                                        onClick={() => handleDisapproval(item.id, item.name)}
                                      >
                                        Recusar cadastro
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  ))}
              </tbody>
            </table>

            {cityUser?.data?.length > 0 && !cityUser?.loading && !cityUser?.loading && (
              <Pagination pageInfo={cityUser?.page} onPageChange={handlePageChange} />
            )}
            {cityUser?.loading === true && (
              <Alert variant="info">
                <span className="p-4 fw-semibold">
                  Aguarde...&nbsp;<i className="fa-solid fa-spinner fa-spin fa-lg"></i>
                </span>
              </Alert>
            )}
            {cityUser?.data?.length === 0 && !cityUser?.loading && <p>Nenhum usuário foi encontrado. </p>}
          </div>
          {/* <!-- Tabela - end --> */}
          <ConfirmationModal show={!!modalInfo} onClose={closeConfirmationModal} {...modalInfo} />
          <ApprovalModal show={!!modalApprovalInfo} onClose={closeApprovalModal} {...modalApprovalInfo} />
          <DisapprovalModal show={!!modalDisapprovalInfo} onClose={closeDisapprovalModal} {...modalDisapprovalInfo} />
        </div>
        {/* <!-- Área de CRUD - end --> */}
        {/* <!-- Conteúdos - end --> */}
      </div>
      <ToastContainer position="bottom-center" />
    </>
  );
};

export default CityUserManagement;
