import { load } from 'recaptcha-v3';

const reCaptcha = (dispatch, dispatchAction, action, ...props) => {
  load(process.env.REACT_APP_PUBLIC_CAPTCHA_KEY, {
    autoHideBadge: true,
    explicitRenderParameters: { badge: 'bottomleft' },
  }).then((recaptcha) => {
    recaptcha.execute(action).then((token) => {
      dispatch(dispatchAction(token, ...props));
    });
  });
};

export const submitRecaptcha = async (actionName) => {
  const recaptcha = await load(process.env.REACT_APP_PUBLIC_CAPTCHA_KEY, {
    autoHideBadge: true,
    explicitRenderParameters: { badge: 'bottomleft' },
  });
  const token = await recaptcha.execute(actionName);
  return token;
};

export default reCaptcha;
