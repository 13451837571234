import React from 'react';
import { logoutUrl } from 'helpers/govBrUtils';

const Sidebar = ({ currentStep, headers, name, cpf, email }) => {
  return (
    <div className="py-5">
      <h1 className="fw-bolder line my-3">{name}</h1>
      <h3 className="fw-bold c-blue-deep">Complete seu cadastro</h3>
      <p>Preencha os campos ao lado para se cadastrar na plataforma!</p>

      <div className="my-4">
        {headers.map((header, index) => {
          const keyValue = `registration-menu-${index + 1}`;
          if (index < currentStep - 1) {
            // Passo concluido
            return (
              <div key={keyValue} className="d-flex align-items-center mb-3 done">
                <div className="circle-text circle-text-blue-deep me-3">{index + 1}</div>
                <div className="fw-bold larger-3 c-blue-deep">{header}</div>
              </div>
            );
          } else if (index === currentStep - 1) {
            // Passo atual
            return (
              <div key={keyValue} className="d-flex align-items-center mb-3 current">
                <div className="circle-text circle-text-white me-3">{index + 1}</div>
                <div className="fw-bold larger-3">{header}</div>
              </div>
            );
          }
          return (
            <div key={keyValue} className="d-flex align-items-center mb-3">
              <div className="circle-text circle-text-blue-deep op-40 me-3">{index + 1}</div>
              <div className="fw-bold larger-3 c-blue-deep op-40">{header}</div>
            </div>
          );
        })}
      </div>
      {currentStep < 3 && (
        <p>
          <a href={logoutUrl} className="btn btn-secondary btn-sm">
            Cancelar cadastro
          </a>
        </p>
      )}
    </div>
  );
};

export default Sidebar;
