import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showToast, dismissToast } from 'components/Toast';
import { setMask, unsetMask } from 'helpers/inputMasks';
import CrudFieldInput from 'components/CrudFieldInput';
import { notEmpty, validEmail } from 'helpers/Validators';
import { Actions as AccountManagementActions } from 'services/accountManagement';
import { debounce } from 'lodash';

const UpdateDataForm = () => {
  const dispatch = useDispatch();

  const toastId = useRef(null);

  const [data, setData] = useState();
  const [toastInfo, setToastInfo] = useState({});
  const [btnDisabled, setBtnDisabled] = useState(true);

  const userData = useSelector((state) => state.accountManagement.userData);
  const loadSuccess = useSelector((state) => state.accountManagement.loadSuccess);
  const updateSuccess = useSelector((state) => state.accountManagement.updateSuccess);
  const errorMsg = useSelector((state) => state.accountManagement.errorMsg);

  useEffect(() => {
    dispatch(AccountManagementActions.loadData());
    return () => {
      dispatch(AccountManagementActions.clearData());
    };
  }, [dispatch]);

  useEffect(() => {
    setData((current) => ({
      ...current,
      name: userData.name,
      email: userData.email,
      telephoneNumber: userData.telephoneNumber,
    }));
  }, [userData]);

  useEffect(() => {
    const { message, type, autoClose } = toastInfo;
    if (message && type) {
      if (toastId.current !== null) {
        dismissToast(toastId.current);
        toastId.current = null;
      }
      toastId.current = toastId.current === null ? showToast(message, type, autoClose) : toastId.current;
    }
  }, [dispatch, toastInfo]);

  useEffect(() => {
    if (updateSuccess === true) {
      setToastInfo({ type: 'success', message: 'Alterações salvas com sucesso.' });
    } else if (updateSuccess === false && errorMsg) {
      setToastInfo({ type: 'danger', message: 'Erro ao salvar as alterações. Tente novamente mais tarde.' });
    }
    dispatch(AccountManagementActions.clearData());
    dispatch(AccountManagementActions.loadData());
  }, [dispatch, updateSuccess, errorMsg]);

  useEffect(() => {
    setBtnDisabled(!(validEmail(data?.email || '') && notEmpty(data?.telephoneNumber || '')));
  }, [data]);

  const handleInputChange = (evt) => {
    const { name, value } = evt.target;
    let newValue;
    if (name === 'telephoneNumber') {
      newValue = unsetMask(name, value);
    } else {
      newValue = value;
    }
    setData((current) => ({ ...current, [name]: newValue }));
  };

  const triggerUpdate = useRef(
    debounce((data) => {
      setToastInfo({ type: 'info', message: 'Salvando alterações...' });
      dispatch(
        AccountManagementActions.updateData({
          name: data.name,
          email: data.email,
          telephoneNumber: data.telephoneNumber,
        }),
      );
    }, 300),
  ).current;

  const handleSubmit = (evt) => {
    evt.preventDefault();
    triggerUpdate(data);
  };

  return (
    <>
      <div className="bg-white shadow crud p-5">
        {data && loadSuccess && (
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <CrudFieldInput
                id="fieldName"
                name="name"
                label="Nome"
                placeholder="Nome completo"
                value={data?.name}
                required
                disabled
                readOnly
              />
            </div>
            <div className="row mb-4">
              <div className="col-lg-6">
                <CrudFieldInput
                  label="E-mail institucional"
                  type="email"
                  name="email"
                  id="fieldEmail"
                  placeholder="E-mail institucional"
                  required
                  value={data?.email || ''}
                  onChange={handleInputChange}
                  isError={!validEmail(data?.email || '')}
                  errorMsg={`Digite um e-mail válido. `}
                />
              </div>
              <div className="col-lg-6">
                <CrudFieldInput
                  label="Telefone"
                  name="telephoneNumber"
                  id="fieldTelephoneNumber"
                  placeholder="Número de telefone com DDD"
                  value={setMask('telephoneNumber', data?.telephoneNumber || '')}
                  onChange={handleInputChange}
                  isError={!notEmpty(data?.telephoneNumber || '')}
                  errorMsg={`Digite um número de telefone válido. `}
                />
              </div>
            </div>

            <div className="my-5 text-center">
              <button type="submit" className="btn btn-success" disabled={btnDisabled}>
                Salvar
              </button>
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default UpdateDataForm;
