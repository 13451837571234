import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import CrudFieldInput from 'components/CrudFieldInput';

export const ConfigModal = ({ modalValues, show, onClose, onConfirmation }) => {
  const [configValue, setConfigValue] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChangeConfigValue = (evt) => {
    setConfigValue(evt.target.value);
  };

  const handleClose = () => {
    setConfigValue('');
    onClose();
  };

  const handleSave = () => {
    const objToUpdate = {
      ...modalValues,
      value: configValue,
    };
    onConfirmation(objToUpdate);
    handleClose();
  };

  useEffect(() => {
    setConfigValue(modalValues?.value || '');
  }, [modalValues]);

  return (
    <Modal
      className="inteligente-modal"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Editar configuração: {modalValues?.description}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span className="list-group-item d-flex justify-content-between align-items-center">
          {modalValues?.name === 'MAIL_PWD' ? (
            <CrudFieldInput
              onChange={handleChangeConfigValue}
              value={configValue}
              type={showPassword ? 'text' : 'password'}
            />
          ) : (
            <CrudFieldInput onChange={handleChangeConfigValue} value={configValue} />
          )}
          {modalValues?.name === 'MAIL_PWD' && (
            <a href="#" onClick={handleShowPassword}>
              <i className={`fa-solid larger-4 c-cyan p-3 ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
            </a>
          )}
        </span>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-outline-secondary" onClick={handleClose}>
          Cancelar
        </button>
        <button type="button" className="btn btn-success" onClick={handleSave}>
          Atualizar
        </button>
      </Modal.Footer>
    </Modal>
  );
};
