import React from 'react';
import { LevelSummary, ResultDetails } from 'components/dashboard/GroupResultDetails';
import { getDimensionImageName } from 'helpers/DimensionUtils';
import { getLocaleCode } from 'helpers/Utils';


const ResultSummary = ({ currentValues = {}, currentSelection = {}, onLevelClick, labels }) => {
  const localeCode = getLocaleCode();
  return (
    Object.keys(currentValues).length > 0 &&
    Object.keys(currentSelection).length > 0 &&
    labels && (
      <>
        <p className="mb-5">
          <strong>{labels[currentSelection.value]?.title || 'Brasil'}</strong>: Total de municípios:{' '}
          <strong>{currentValues.numCities?.toLocaleString(localeCode) || '5.570'}</strong> / Avaliados:{' '}
          <strong>{currentValues.numEvaluated?.toLocaleString(localeCode) || '0'}</strong>
        </p>
        {Object.values(currentValues.summary)
          .sort((a, b) => (a.code < b.code ? -1 : 1))
          .map((summary, idx) => {
            const dimensionLabel = labels[summary.code]?.title || summary.code;
            return (
              <div className="mb-5 pb-5" key={`summary-${summary.code}`}>
                <div className="d-flex align-items-center">
                  <img src={`/img/${getDimensionImageName(summary.code)}`} alt={dimensionLabel} />
                  <h4 className="ms-3">{dimensionLabel}</h4>
                </div>
                <LevelSummary summary={summary} handleClick={onLevelClick} />
                <ResultDetails
                  topics={Object.values(currentValues.topics).filter((item) => item.dimensionCode === summary.code)}
                  dimensionIdx={idx}
                  labels={labels}
                  handleClick={() => true}
                />
              </div>
            );
          })}
      </>
    )
  );
};

export default ResultSummary;
