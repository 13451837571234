import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import SurveyQuestionsView from './SurveyQuestionsView';
import { Actions as AdminSurveyViewActions } from 'services/adminSurveyView';
import { Actions as AdminDashboardActions } from 'slices/admin/dashboard';
import { getLocaleCode } from 'helpers/Utils';
import { formatDateTimeSimple } from 'helpers/dateTimeUtils';
import 'components/survey/Survey.scss';

const TabPagination = ({ steps, currentStep, prevButton, nextButton, mode, tabs }) => {
  return (
    <div className="d-flex justify-content-evenly">
      <div className="me-4">{prevButton()}</div>
      <div className="text-center fs-3">
        {steps.map((stepCode, index) => {
          if (index < currentStep - 1) {
            return (
              <a href="#" key={`tab-${stepCode}`}>
                <i className="fa-solid fa-circle-check c-gray me-3"></i>
              </a>
            );
          } else if (index === currentStep - 1) {
            // ATUAL
            return <i key={`tab-${stepCode}`} className="fa-solid fa-pen c-green me-3"></i>;
          } else {
            return (
              <a href="#" key={`tab-${stepCode}`}>
                <i className="fa-solid fa-pen c-gray-light me-3"></i>
              </a>
            );
          }
        })}
      </div>
      <div className="ms-4">{nextButton()}</div>
    </div>
  );
};

const SurveyGroupQuestions = ({ tabs = [], initialActiveTab, cityData }) => {
  const stepCodes = tabs.map((option) => option.code);
  const MAX_STEPS = stepCodes.length;

  const [activeTab, setActiveTab] = useState(initialActiveTab);
  const [currentStep, setCurrentStep] = useState(1);

  const surveyCode = useSelector((state) => state.adminSurveyView.currentSurveyCode);

  const handleChangeTab = (code) => {
    if (surveyCode !== code) {
      const newActiveTab = tabs.filter((option) => option.code === code)[0];
      setActiveTab(newActiveTab);
      setCurrentStep(stepCodes.indexOf(code) + 1);
    }
  };

  const onTabClick = (evt, code) => {
    evt.preventDefault();
    handleChangeTab(code);
  };

  const _next = (evt, step) => {
    evt.preventDefault();
    const nextStep = step > MAX_STEPS ? MAX_STEPS : step + 1;
    handleChangeTab(tabs[nextStep - 1].code);
  };

  const _prev = (evt, step) => {
    evt.preventDefault();
    const prevStep = step <= 1 ? 1 : step - 1;
    handleChangeTab(tabs[prevStep - 1].code);
  };

  const nextButton = () => {
    if (currentStep < MAX_STEPS) {
      return (
        <a href="#" className="c-gray" onClick={(evt) => _next(evt, currentStep)}>
          <i className="fa-solid fa-chevron-right fa-2x"></i>
        </a>
      );
    }
    if (currentStep === MAX_STEPS) {
      return (
        <a href="#" className="c-gray disabled" onClick={(evt) => false}>
          <i className="fa-solid fa-chevron-right fa-2x"></i>
        </a>
      );
    }
  };

  const prevButton = () => {
    if (currentStep > 1) {
      return (
        <a href="#" className="c-gray" onClick={(evt) => _prev(evt, currentStep)}>
          <i className="fa-solid fa-chevron-left fa-2x"></i>
        </a>
      );
    }
    if (currentStep === 1) {
      return (
        <a href="#" className="c-gray disabled" onClick={(evt) => false}>
          <i className="fa-solid fa-chevron-left fa-2x"></i>
        </a>
      );
    }
  };

  return (
    <div className="bg-white shadow crud">
      {cityData && (
        <h2 className="p-4 fw-bold">
          {cityData?.name || 'Nome do Município'}/{cityData?.stateAbbr || 'Estado'}
        </h2>
      )}
      <header>
        <div className="tabs auto">
          {tabs.map((option, index) => {
            let tabClassList = 'tab';
            if ((option.code === surveyCode && option.cityStatus === 2) || option.code === activeTab.code) {
              tabClassList += ' active';
            }
            if (stepCodes.indexOf(option.code) < stepCodes.indexOf(activeTab.code) && option.cityStatus === 2) {
              tabClassList += ' complete';
            }
            // if (headers.indexOf(option.label) > headers.indexOf(activeTab.label) && (option.code === surveyCode && option.cityStatus === 2) {
            // }
            return (
              <div key={`survey-tab-${option.code}`} className={tabClassList}>
                <a href={`#${option.code}`} onClick={(evt) => onTabClick(evt, option.code)}>
                  {option.title}
                </a>
              </div>
            );
          })}
        </div>
      </header>
      <div className="p-5">
        {tabs
          .filter((option) => option.code === activeTab.code)
          .map((option) => (
            <SurveyQuestionsView
              key={`survey-questions-${option.code}`}
              surveyCode={option.code}
              title={option.title}
            />
          ))}
        {/* <!-- Pagination - start --> */}
        <TabPagination
          steps={stepCodes}
          currentStep={currentStep}
          prevButton={prevButton}
          nextButton={nextButton}
          mode="forms"
          tabs={tabs}
        />
        {/* <!-- Pagination - end --> */}
      </div>
      {/* <!-- Formulário - end --> */}
    </div>
  );
};

// Fluxo esperado para nova tela de pesquisa
// 1) Carregar informações do SurveyGroup ativo (Surveys, Datas de inicio/termino)
// 2) Ao acessar Survey específico, trazer informações referentes a cidade (caso exista)

const AdminSurveyView = () => {
  const { cityFriendlyName } = useParams();

  const cityData = useSelector((state) => state.adminDashboardCity.item);

  const allGroupsInfo = useSelector((state) => state.adminSurveyView.allGroupsInfo);
  const groupInfo = useSelector((state) => state.adminSurveyView.groupInfo);
  const finishedSurveysGroups = useSelector((state) => state.adminSurveyView.finishedSurveysGroups);
  const progressSurveyGroup = useSelector((state) => state.adminSurveyView.progressSurveyGroup);
  const dispatch = useDispatch();

  const [tabs, setTabs] = useState(null);
  const [tabsLoaded, setTabsLoaded] = useState(false);
  const [noSurveysAvailable, setNoSurveysAvailable] = useState(false);
  const [selectedFinished, setSelectedFinished] = useState(null);

  const printForm = (withAnswers = false) => {
    const url =
      '/portal/formulario-impressao/' + cityFriendlyName + '/' + selectedFinished?.id + (withAnswers ? '?answers' : '');
    const result = window.open(url, 'printPreview', 'menubar=0,location=0,width=1024,height=700');
    return result;
  };

  // Carregar todos os grupos ao carregar tela
  useEffect(() => {
    dispatch(AdminSurveyViewActions.findAllGroupsAvailable());
    return () => {
      dispatch(AdminSurveyViewActions.reset());
    };
  }, [dispatch]);

  useEffect(() => {
    if (cityFriendlyName) {
      dispatch(AdminDashboardActions.loadCityItem(cityFriendlyName));
    }
    return () => dispatch(AdminDashboardActions.clearData());
  }, [dispatch, cityFriendlyName]);

  // Carregar uma pesquisa/grupo com os respectivos dos forms resumidos
  // TODO: Fazer tratamento para 2 ou mais pesquisas ativas simultaneamente
  useEffect(() => {
    if (allGroupsInfo) {
      if (allGroupsInfo.length > 0) {
        const currentCode = allGroupsInfo[0].code;
        setTabs(null);
        dispatch(AdminSurveyViewActions.findGroupByCode(currentCode, cityFriendlyName));
      } else {
        // SEM PESQUISAS PARA PREENCHER
        setTabsLoaded(true);
        setNoSurveysAvailable(true);
      }
    }
  }, [dispatch, allGroupsInfo, cityFriendlyName]);

  // Prepara as abas com os forms que pertencem a pesquisa/grupo
  useEffect(() => {
    if (groupInfo && !tabs) {
      const tabs = Object.values(groupInfo.surveys).map((survey) => ({
        code: survey.code,
        title: survey.title,
        cityStatus: survey.cityStatus,
      }));
      setTabs(tabs);
      setTabsLoaded(true);
    }
  }, [groupInfo, tabs]);

  // Apresenta informação do finishedSurveyGroups mais recente no combo box
  useEffect(() => {
    // console.log(JSON.stringify(finishedSurveysGroups));
    if (finishedSurveysGroups?.length > 0) {
      setSelectedFinished(finishedSurveysGroups[0]);
    }
  }, [finishedSurveysGroups]);

  const handleFinishedSelect = (evt, id) => {
    evt.preventDefault();
    const groupCode = groupInfo?.code;
    if (finishedSurveysGroups && groupCode) {
      dispatch(AdminSurveyViewActions.findGroupById(groupCode, cityFriendlyName, id));
      const filteredData = finishedSurveysGroups.filter((item) => item.id === id);
      setSelectedFinished(filteredData.length > 0 ? filteredData[0] : null);
    }
  };

  if (!groupInfo) {
    return <>Carregando informações do município. Aguarde...</>;
  }

  if (!tabsLoaded) {
    return <>Carregando questões. Aguarde...</>;
  } else {
    if (noSurveysAvailable === true) {
      return <>Não há pesquisas ativas para serem preenchidas neste momento.</>;
    }
  }

  return (
    <>
      <div className="bg-gray-superlight px-5 py-4 w-100">
        {/* <!-- Cabeçalho Página - start --> */}
        <header>
          <h3 className="fw-bolder c-cyan mb-0">{groupInfo?.title}</h3>
          <p className="path">
            <a href="#">Início</a> &rsaquo; <span className="current">{groupInfo?.title}</span>
          </p>
        </header>
        {/* <!-- Cabeçalho Página - end --> */}

        {/* <!-- Status - start --> */}
        <div className="row mb-4">
          <div className="col-12 col-lg-10">
            {
              /* *** STATUS 1: CIDADE JÁ PREENCHEU E FINALIZOU MAIS DE 1 PESQUISA */
              !progressSurveyGroup && finishedSurveysGroups.length > 1 && (
                <Dropdown as="span" id="dropdown-surveys-finished">
                  <Dropdown.Toggle as="span" className="status bg-white me-2" size="sm">
                    <i className="fa-solid fa-calendar-check"></i> Preenchimento finalizado em{' '}
                    <strong>
                      {selectedFinished ? formatDateTimeSimple(selectedFinished.lastUpdate, getLocaleCode()) : '-'}
                    </strong>
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="start" className="shadow">
                    <Dropdown.Item disabled>Versões anteriores</Dropdown.Item>
                    <Dropdown.Divider />
                    {finishedSurveysGroups.map((item) => {
                      return (
                        <Dropdown.Item
                          key={`finished-survey-${item.id}`}
                          data-sgc-id={item?.id || '-'}
                          active={item.id === selectedFinished?.id}
                          onClick={(evt) => handleFinishedSelect(evt, item.id)}
                        >
                          <i className="fa-solid fa-calendar-check"></i>&nbsp;{formatDateTimeSimple(item.lastUpdate)}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              )
            }
            {
              /* *** STATUS 2: CIDADE JÁ PREENCHEU 1 PESQUISA OU ESTÁ EM PREENCHUMENTO */
              !(!progressSurveyGroup && finishedSurveysGroups.length > 1) && (
                <>
                  {progressSurveyGroup && (
                    <span className="status bg-white me-2">
                      <i className="fa-solid fa-calendar-check"></i> Último preenchimento salvo em{' '}
                      <strong>{formatDateTimeSimple(progressSurveyGroup.lastUpdate, getLocaleCode())}</strong>
                    </span>
                  )}
                  {!progressSurveyGroup && selectedFinished && (
                    <span className="status bg-white me-2">
                      <i className="fa-solid fa-calendar-check"></i> Preenchimento finalizado em{' '}
                      <strong>{formatDateTimeSimple(selectedFinished.lastUpdate, getLocaleCode())}</strong>
                    </span>
                  )}
                  {!progressSurveyGroup && finishedSurveysGroups.length === 0 && (
                    <span className="status me-2">
                      <i className="fa-regular fa-calendar-xmark"></i> Nenhum preenchimento finalizado
                    </span>
                  )}
                </>
              )
            }
          </div>
          <div className="col-12 col-lg-2 text-end">
            <Dropdown>
              <Dropdown.Toggle
                variant="outline-light"
                className="bg-white rounded-2 c-gray-dark"
                id={`dropdown-print`}
                size="sm"
              >
                <i className="fa-solid fa-print fa-lg"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu align="end" className="shadow">
                <Dropdown.Item className="selectable" onClick={() => printForm()}>
                  Formulário vazio
                </Dropdown.Item>
                <Dropdown.Item className="selectable" onClick={() => printForm(true)}>
                  Respostas preenchidas
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        {/* <!-- Status - end --> */}

        {tabs && tabs.length > 0 && <SurveyGroupQuestions tabs={tabs} initialActiveTab={tabs[0]} cityData={cityData} />}
      </div>
    </>
  );
};

export default AdminSurveyView;
