import CrudFieldInput from 'components/CrudFieldInput';
import FieldFileUpload from 'components/form/FieldFileUpload';
import FieldTextAreaInput from 'components/form/FieldTextAreaInput';
import Validators from 'helpers/Validators';
import { currentLocalDateString, tomorrow } from 'helpers/dateTimeUtils';
import { generateFriendlyTitle } from 'helpers/replaceChar';
import { Actions as PostsActions } from 'services/slices/content';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FieldSelectInput from 'components/form/FieldSelectInput';
import { dismissToast, showToast } from 'components/Toast';
import { useNavigate, useParams } from 'react-router-dom';
import CrudFieldInputGroup from 'components/form/CrudFieldInputGroup';
import CrudFieldInputAndButton from 'components/CrudFieldInputAndButton';

const PostsForm = () => {
  const posts = useSelector((state) => state.posts);
  const errors = useSelector((state) => state.posts.fieldErrors);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const toastId = useRef(null);

  const [data, setData] = useState({ status: 2 });
  const [validation, setValidation] = useState({});
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [toastInfo, setToastInfo] = useState({});

  useEffect(() => dispatch(PostsActions.loadLovs()), [dispatch]);

  useEffect(() => {
    dispatch(PostsActions.clearData());
    if (id) {
      dispatch(PostsActions.loadPostById(id));
    }
    return () => {
      dispatch(PostsActions.clearData());
    };
  }, [id, dispatch]);

  useEffect(() => {
    setData((current) => {
      const item = posts?.item;
      return {
        ...current,
        ...item,
      };
    });
  }, [posts?.item]);

  useEffect(() => {
    setData((current) => ({
      ...current,
      presentationOrder: posts.presentationOrder || '',
    }));
  }, [posts?.presentationOrder]);

  useEffect(() => {
    if (posts?.loading === true) {
      setToastInfo({ type: 'info', message: posts.message });
    }
  }, [posts?.loading, posts?.message]);

  useEffect(() => {
    if (posts?.success === true) {
      setToastInfo({ type: 'success', message: posts?.message });
    }
    if (posts?.success === false) {
      setToastInfo({ type: 'danger', message: posts?.message });
    }
  }, [posts?.success, posts?.message]);

  useEffect(() => {
    const { message, type, autoClose } = toastInfo;
    if (message && type) {
      if (toastId.current !== null) {
        dismissToast(toastId.current);
        toastId.current = null;
      }
      toastId.current = toastId.current === null ? showToast(message, type, autoClose) : toastId.current;
    }
  }, [dispatch, toastInfo]);

  useEffect(() => {
    const values = Object.values(validation);
    const valid = values.length > 0 && values.every((item) => item === true);
    setBtnDisabled(!valid);
  }, [validation]);

  const validate = (name, value) => {
    switch (name) {
      case 'title':
      case 'value':
        return Validators.notEmpty(value);
      case 'sourceUrl':
        return Validators.validUrl(value);
      case 'publicationDate':
        return Validators.validIsoDate(value);
      default:
        return true;
    }
  };

  const getSanitizedValue = (name, value) => {
    const sanitizationEvent = {
      friendlyTitle: () => {
        return value?.trim();
      },
      _default: () => value,
    };
    return sanitizationEvent[name] ? sanitizationEvent[name]() : sanitizationEvent._default();
  };

  const afterInputChange = (name, value) => {
    const afterInputChangeEvent = {
      title: () => {
        setData((current) => ({
          ...current,
          friendlyTitle: generateFriendlyTitle(value),
        }));
      },
    };
    if (afterInputChangeEvent[name]) {
      afterInputChangeEvent[name]();
    }
  };

  const handleAdjustSlug = (evt) => {
    const friendlyTitle = generateFriendlyTitle(data.friendlyTitle || '');
    setData((current) => ({
      ...current,
      friendlyTitle,
    }));
  };

  const handleInputChange = (evt) => {
    const { name, value } = evt.target;
    const sanitizedValue = getSanitizedValue(name, value);
    setData((current) => ({
      ...current,
      [name]: sanitizedValue,
    }));
    setValidation((current) => ({
      ...current,
      [name]: validate(name, value),
    }));
    afterInputChange(name, sanitizedValue);
  };

  const handleGetLatestPresentationOrder = () => {
    dispatch(PostsActions.getLatestPresentationOrder());
  };

  const handleCheckTopic = (evt) => {
    const { name, value, checked } = evt.target;
    if (checked) {
      setData((current) => {
        const allTopics = current[name] ? current[name] : [];
        const topics = [...allTopics, Number(value)];
        return {
          ...current,
          [name]: topics,
        };
      });
    } else {
      setData((current) => {
        const topicsFiltered = current[name]?.filter((topicValue) => topicValue !== Number(value));
        return {
          ...current,
          [name]: topicsFiltered,
        };
      });
    }
  };

  const handleCheckStatus = (evt) => {
    const { name, checked } = evt.target;
    setData((current) => ({
      ...current,
      [name]: checked ? 1 : 2,
    }));
  };

  const handleImageUpload = (evt) => {
    setData((current) => ({
      ...current,
      [evt.target.name]: evt.target.files[0],
    }));
  };

  const handleSelectCity = (cityData) => {
    setData((current) => ({
      ...current,
      cityId: cityData.id,
    }));
  };

  const handleToday = () => {
    setData((current) => ({
      ...current,
      publicationDate: currentLocalDateString(),
    }));
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    dispatch(PostsActions.savePost(data, () => navigate('/portal/posts')));
  };

  return (
    <div className="bg-white shadow crud p-5">
      <div className="mb-4">
        <CrudFieldInput
          value={data?.title || ''}
          isError={errors?.title || validation?.title === false}
          errorMsg={errors?.title || 'Digite um título válido. '}
          name="title"
          label="Título da notícia"
          type="text"
          placeholder="Título da notícia (ex: 'Notícia da cidade de Ceilândia')"
          maxLength={255}
          onChange={handleInputChange}
          disabled={data?.status === 0}
        />
      </div>

      <div className="mb-4">
        <CrudFieldInput
          value={data?.friendlyTitle || ''}
          isError={errors?.friendlyTitle}
          errorMsg={errors?.friendlyTitle}
          className={validation?.friendlyTitle === true ? `valid` : ``}
          infoMsg={`Slug é o valor que poderá usado para acessar essa notícia na área pública (ex: "https://inteligente.mcti.gov.br/midia/${
            data.friendlyTitle ? data.friendlyTitle : 'slug-da-noticia'
          }")`}
          name="friendlyTitle"
          label="URL amigável (Slug)"
          type="text"
          placeholder="Letras minúsculas, sem acentos e palavras separadas com hífen (ex: 'noticia-da-cidade-de-ceilandia')"
          maxLength={255}
          onChange={handleInputChange}
          onBlur={handleAdjustSlug}
          disabled={data?.status === 0}
        />
      </div>

      <div className="mb-4">
        <FieldTextAreaInput
          value={data?.value || ''}
          isError={errors?.value || validation?.value === false}
          errorMsg={errors?.value || 'Conteúdo da notícia não pode estar vazio. '}
          rows={6}
          name="value"
          label="Conteúdo"
          placeholder="Digite o texto a ser apresentado"
          maxLength={25500}
          onChange={handleInputChange}
          disabled={data?.status === 0}
        />
      </div>

      <div className="mb-4">
        <CrudFieldInput
          className={validation?.sourceUrl === true ? `valid` : ``}
          value={data?.sourceUrl || ''}
          isError={errors?.sourceUrl || validation?.sourceUrl === false}
          errorMsg={errors?.sourceUrl || 'Digite ou cole uma URL válida. '}
          infoMsg="Ex.: 'https://site-da-noticia.com.br/noticia/titulo-da-noticia'"
          name="sourceUrl"
          label="Link"
          type="text"
          placeholder="Fonte da notícia original"
          maxLength={255}
          onChange={handleInputChange}
          disabled={data?.status === 0}
        />
      </div>

      <div className="mb-4 row">
        <div className="col col-sm-12 col-md-12 col-lg-6">
          <CrudFieldInputAndButton
            value={data?.publicationDate || ''}
            isError={validation?.publicationDate === false}
            errorMsg="Data de publicação inválida. "
            infoMsg="A notícia só ficará visível na área pública APÓS a data informada."
            name="publicationDate"
            label="Data de publicação"
            type="date"
            maxLength={10}
            disabled={data?.status === 0}
            onChange={handleInputChange}
            buttonLabel="Hoje"
            onButtonClick={handleToday}
          />
        </div>
        <div className="col col-sm-12 col-md-12 col-lg-6">
          <CrudFieldInput
            value={data?.expirationDate || ''}
            name="expirationDate"
            label="Data de expiração"
            type="date"
            maxLength={10}
            min={tomorrow(data?.publicationDate)}
            disabled={data?.status === 0}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="mb-4">
        <CrudFieldInputGroup
          value={data?.presentationOrder || ''}
          isError={validation?.presentationOrder === false}
          errorMsg="Digite ou cole uma URL válida. "
          infoMsg="ATENÇÃO: caso não seja informada, será usada a data de publicação como critério. "
          name="presentationOrder"
          label="Ordem de apresentação da notícia"
          buttonLabel="Buscar próxima posição"
          type="number"
          min={1}
          placeholder="Ordem de apresentação da notícia"
          onChange={handleInputChange}
          onButtonClick={handleGetLatestPresentationOrder}
          disabled={data?.status === 0}
        />
      </div>

      <div className="mb-4 row">
        <label className="form-label fw-semibold">Tópicos</label>
        {posts &&
          posts?.dimensions?.map((dimension) => {
            return (
              <div className="col" key={`form-group-dim-${dimension.value}`}>
                <fieldset>
                  <p className="form-label">{dimension.text}</p>
                  {dimension?.subOptions?.map((option) => (
                    <div key={`form-group-dim-${dimension.value}-option-${option.value}`} className="row">
                      <div className="col-12">
                        <div className="form-check form-check-inline mb-4" key={`check-opt-${option.value}`}>
                          <input
                            name="topicsIds"
                            className="form-check-input"
                            type="checkbox"
                            id={`topic-opt-${option.value}`}
                            value={option.value || ''}
                            checked={data?.topicsIds?.includes(option?.value) || false}
                            onChange={handleCheckTopic}
                            disabled={data?.status === 0}
                          />
                          <label className="form-check-label mt-2" htmlFor={`topic-opt-${option.value}`}>
                            {option.text}
                          </label>
                        </div>
                      </div>
                    </div>
                  ))}
                </fieldset>
              </div>
            );
          })}
      </div>

      <div className="mb-4">
        <FieldFileUpload
          label="Upload da imagem"
          name="image"
          isError={errors?.image || validation?.image === false}
          errorMsg={errors?.image || 'Arquivo inválido - não é uma imagem'}
          onChange={handleImageUpload}
          accept="image/*"
          disabled={data?.status === 0}
        />
      </div>
      <div className="mb-4">
        <CrudFieldInput
          value={data?.imageTitle || ''}
          isError={errors?.imageTitle || validation?.imageTitle === false}
          errorMsg="Digite um título válido. "
          name="imageTitle"
          label="Título da imagem"
          type="text"
          placeholder="Preencha o título da imagem"
          maxLength={4000}
          onChange={handleInputChange}
          disabled={data?.status === 0}
        />
      </div>

      {data?.id && data?.imageSrc && (
        <div className="mb-4">
          <label className="form-label fw-semibold">Imagem atual</label>
          <div>
            <img src={data.imageSrc} alt="Imagem atual" style={{ maxWidth: '570px' }} />
          </div>
        </div>
      )}

      <div className="mb-4">
        <label className="form-label fw-semibold" htmlFor="city-autocomplete-post">
          Município
        </label>
        <FieldSelectInput
          id="city-autocomplete-post"
          name="cityId"
          initialValue={data?.city}
          placeholder="Digite o município"
          onSelectedOption={handleSelectCity}
          disabled={data?.status === 0}
        />
      </div>

      <div className="mb-4">
        <div className="fw-semibold mb-2">Publicação</div>
        <div className="form-check form-switch mb-2">
          <input
            className="form-check-input"
            name="status"
            type="checkbox"
            role="switch"
            id="status-publish"
            checked={data?.status === 1}
            onChange={handleCheckStatus}
            disabled={data?.status === 0}
          />
          <label className="form-check-label" htmlFor="status-publish">
            Liberar visualização na área pública
          </label>
        </div>
      </div>

      <div className="mt-5 text-center">
        <button className="btn btn-outline-secondary me-3" onClick={() => navigate('/portal/posts')}>
          Voltar
        </button>
        <button disabled={btnDisabled} className="btn btn-success" onClick={handleSubmit}>
          Confirmar
        </button>
      </div>
    </div>
  );
};

export default PostsForm;
