import React, { useEffect, useState } from 'react';
import leaflet from 'leaflet';
import { MapContainer, Marker, Popup /*, TileLayer */, GeoJSON, useMap } from 'react-leaflet';
import api from 'services/api';
import * as topojson from 'topojson-client';
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';

const LeafleatMapData = ({ cityData }) => {
  const map = useMap();

  const [cityCoordinates, setCityCoordinates] = useState(null);
  const [geoJsonData, setGeoJsonData] = useState(null);

  useEffect(() => {
    if (cityData != null) {
      api
        .get(`https://servicodados.ibge.gov.br/api/v3/malhas/municipios/${cityData.code}?formato=application/json`)
        .then((resp) => {
          const geoData = topojson.feature(resp.data, `MU${cityData.code}MU`);
          setGeoJsonData(geoData);
          const feature = geoData.features[0];

          let minLat = 99999;
          let maxLat = -99999;
          let minLgt = 99999;
          let maxLgt = -99999;

          if (feature.geometry.type === 'Polygon') {
            for (const polygon of feature.geometry.coordinates) {
              for (const coord of polygon) {
                if (coord[1] < minLat) {
                  minLat = coord[1];
                }
                if (coord[1] > maxLat) {
                  maxLat = coord[1];
                }
                if (coord[0] < minLgt) {
                  minLgt = coord[0];
                }
                if (coord[0] > maxLgt) {
                  maxLgt = coord[0];
                }
              }
            }
          } else if (feature.geometry.type === 'MultiPolygon') {
            for (const coordinate of feature.geometry.coordinates) {
              for (const polygon of coordinate) {
                for (const coord of polygon) {
                  if (coord[1] < minLat) {
                    minLat = coord[1];
                  }
                  if (coord[1] > maxLat) {
                    maxLat = coord[1];
                  }
                  if (coord[0] < minLgt) {
                    minLgt = coord[0];
                  }
                  if (coord[0] > maxLgt) {
                    maxLgt = coord[0];
                  }
                }
              }
            }
          }
          // console.log(`minLat: ${minLat}, maxLat: ${maxLat}\nminLong: ${minLgt}, maxLong: ${maxLgt}`)

          // setCityCoordinates([-23.5489, -46.6388]) // SAO PAULO
          setCityCoordinates([cityData.latitude, cityData.longitude]);
          if (minLat !== 99999 && maxLat !== -99999 && minLgt !== 99999 && maxLgt !== -99999) {
            const bounds = [
              [maxLat, minLgt],
              [minLat, maxLgt],
            ];
            map.fitBounds(bounds);
          }
        });
    }

    // Exibição de marcador
    const DefaultIcon = leaflet.icon({
      iconUrl: icon,
      iconSize: [20, 35],
      iconAnchor: [10, 35],
    });
    leaflet.Marker.prototype.options.icon = DefaultIcon;
  }, [cityData, map]);

  return (
    <>
      { /*
      <TileLayer
        attribution='&copy; <a href="https://stadiamaps.com/" target="_blank">Stadia Maps</a> <a href="https://stamen.com/" target="_blank">&copy; Stamen Design</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/about" target="_blank">OpenStreetMap</a> contributors'
        url="https://tiles.stadiamaps.com/tiles/stamen_toner_lite/{z}/{x}/{y}{r}.png"
      /> */ }
      {cityCoordinates && (
        <Marker position={cityCoordinates}>
          <Popup>
            Localização geográfica de{' '}
            <strong>
              {cityData.name} - {cityData.stateAbbr}
            </strong>
          </Popup>
        </Marker>
      )}
      {geoJsonData && <GeoJSON data={geoJsonData} key={JSON.stringify(geoJsonData)} color={'#16A9B8'} />}
    </>
  );
};

const MapCity = ({ cityData, className }) => {
  const initialMapBounds = [
    [2.81972, -72.89583],
    [-33.69111, -34.80861],
  ];

  // const [mapCenter, setMapCenter] = useState([-15.7801, -47.9292]) // BRASILIA

  return (
    <div className={className}>
      <MapContainer bounds={initialMapBounds} scrollWheelZoom={false} style={{ width: '100%', height: '550px' }}>
        <LeafleatMapData cityData={cityData} />
      </MapContainer>
    </div>
  );
};

export default MapCity;
