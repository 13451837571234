import React, { Fragment } from 'react';

const MatrixType = ({ isMulti, question, answer, onOptionChange, onTextAnswerChange }) => {
  const handleOptionChange = (evt) => {
    const value = evt.target.value;
    const checked = evt.target.checked;
    onOptionChange(question.code, value, checked, true);
  };

  const options = Object.keys(question.options).map((key, idx) => {
    const optCode = key;
    return (
      <td key={optCode} className="has-text-centered">
        <label className={isMulti ? 'checkbox' : 'radio'}>
          <input
            id={optCode + '-input'}
            className="me-2"
            type={isMulti ? 'checkbox' : 'radio'}
            name={question.code}
            value={optCode}
            checked={answer.options[idx].selected}
            onChange={handleOptionChange}
          />
        </label>
      </td>
    );
  });

  const bullet = question.code.substring(question.code.lastIndexOf('_') + 1);
  return (
    <>
      <th>
        <b>{bullet}.</b> {question.description}
        {Object.keys(answer.options).map((key, index) => {
          const show = question.withComplement && answer.options[key].selected;
          if (show) {
            return (
              <input
                key={key}
                type="text"
                id={question.code + '-text'}
                placeholder={question.complementLabel}
                value={answer.text || ''}
                onChange={(evt) => onTextAnswerChange(question.code, evt.target.value)}
              />
            );
          }
          return <Fragment key={key}></Fragment>;
        })}
      </th>
      {options}
    </>
  );
};

export default MatrixType;
