import { createSlice } from '@reduxjs/toolkit';
import ContentService from 'services/api/content/contentService';
import ContentAdminService from 'services/api/content/contentAdminService';
import { convertErrorsArray } from 'helpers/Utils';

const requestControl = {
  loading: null,
  success: null,
};

const pageControl = {
  totalElements: 1,
  totalPages: 1,
  totalInPage: 1,
  pageNumber: 1,
  pageSize: 1,
  first: true,
  last: true,
};

const dataControl = {
  data: [],
  item: {},
};

const postsInitialState = {
  ...requestControl,
  ...pageControl,
  ...dataControl,
  dimensions: [],
  states: [],
  presentationOrder: null,
  message: null,
  fieldErrors: {},
};

export const postsSlice = createSlice({
  name: 'posts',
  initialState: postsInitialState,
  reducers: {
    loadingStart: (state, action) => {
      state.loading = true;
      state.message = action.payload?.message || null;
      state.success = null;
    },
    loadingStop: (state) => {
      state.loading = false;
    },
    success: (state, action) => {
      const { data, item, page, message, dimensions, states } = action.payload;
      state.success = true;
      state.data = data || state.data;
      state.page = page || state.page;
      state.dimensions = dimensions || state.dimensions;
      state.states = states || state.states;
      state.item = item || state.item;
      state.message = message || state.message;
    },
    error: (state, action) => {
      const { httpStatus, message, fieldErrors } = action.payload;
      state.success = false;
      state.message = `${httpStatus ? httpStatus + ' - ' : ''}${message}`;
      state.fieldErrors = fieldErrors || null;
    },
    updateLatestPresentationOrder: (state, action) => {
      state.presentationOrder = action.payload?.presentationOrder || state.presentationOrder;
    },
    reset: () => {
      return { ...postsInitialState };
    },
  },
});

export const PostsSliceActions = postsSlice.actions;

export const Actions = {
  loadFilters: () => {
    return (dispatch) => {
      dispatch(PostsSliceActions.loadingStart());
      Promise.all([ContentService.loadDimensions(), ContentService.loadStates()])
        .then(([dimensionsPromiseValue, statesPromiseValue]) => {
          dispatch(
            PostsSliceActions.success({
              dimensions: dimensionsPromiseValue.data,
              states: statesPromiseValue.data,
            }),
          );
        })
        .catch((err) =>
          dispatch(
            PostsSliceActions.error({
              httpStatus: err?.response?.status,
              message: 'Ocorreu um erro ao carregar os filtros de pesquisa.',
            }),
          ),
        )
        .finally(() => dispatch(PostsSliceActions.loadingStop()));
    };
  },
  loadPosts: (page, query, filters) => {
    return (dispatch) => {
      dispatch(PostsSliceActions.loadingStart());
      ContentService.findAll(page, 10, query, filters)
        .then((res) => {
          const responseData = res.data;
          const page = {
            totalElements: responseData.totalElements,
            totalPages: responseData.totalPages,
            totalInPage: responseData.numberOfElements,
            pageNumber: responseData.number + 1,
            pageSize: responseData.size,
            first: responseData.first,
            last: responseData.last,
          };
          dispatch(PostsSliceActions.success({ page, data: responseData.content }));
        })
        .catch((err) =>
          dispatch(
            PostsSliceActions.error({
              httpStatus: err?.response?.status,
              message: 'Ocorreu um erro ao carregar as notícias.',
            }),
          ),
        )
        .finally(() => dispatch(PostsSliceActions.loadingStop()));
    };
  },
  loadPost: (friendlyTitle) => {
    return (dispatch) => {
      dispatch(PostsSliceActions.loadingStart());
      ContentService.findByFriendlyTitle(friendlyTitle)
        .then((res) => {
          const content = res.data;
          dispatch(PostsSliceActions.success({ item: content }));
        })
        .catch((err) =>
          dispatch(
            PostsSliceActions.error({
              httpStatus: err?.response?.status,
              message: 'Ocorreu um erro ao carregar a notícia.',
            }),
          ),
        )
        .finally(() => dispatch(PostsSliceActions.loadingStop()));
    };
  },
  loadPostsAdmin: (page, status, query) => {
    return (dispatch) => {
      dispatch(PostsSliceActions.loadingStart());
      ContentAdminService.findAll(page, 10, status, query)
        .then((res) => {
          const responseData = res.data;
          const page = {
            totalElements: responseData.totalElements,
            totalPages: responseData.totalPages,
            totalInPage: responseData.numberOfElements,
            pageNumber: responseData.number + 1,
            pageSize: responseData.size,
            first: responseData.first,
            last: responseData.last,
          };
          dispatch(PostsSliceActions.success({ page, data: responseData.content }));
        })
        .catch((err) =>
          dispatch(
            PostsSliceActions.error({
              httpStatus: err?.response?.status,
              message: 'Ocorreu um erro ao carregar as notícias.',
            }),
          ),
        )
        .finally(() => dispatch(PostsSliceActions.loadingStop()));
    };
  },
  loadPostById: (id) => {
    return (dispatch) => {
      dispatch(PostsSliceActions.loadingStart());
      ContentAdminService.findById(id)
        .then((res) => {
          const content = res.data;
          dispatch(PostsSliceActions.success({ item: content }));
        })
        .catch((err) =>
          dispatch(
            PostsSliceActions.error({
              httpStatus: err?.response?.status,
              message: 'Ocorreu um erro ao carregar a notícia.',
            }),
          ),
        )
        .finally(() => dispatch(PostsSliceActions.loadingStop()));
    };
  },
  delete: (id, onSuccess) => {
    return (dispatch) => {
      dispatch(PostsSliceActions.loadingStart());
      ContentAdminService.deleteById(id)
        .then(() => {
          dispatch(
            PostsSliceActions.success({
              message: 'Notícia excluída com sucesso! ',
            }),
          );
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch((err) => {
          dispatch(
            PostsSliceActions.error({
              httpStatus: err?.response?.status,
              message: 'Ocorreu um erro ao excluir a notícia.',
            }),
          );
        })
        .finally(() => dispatch(PostsSliceActions.loadingStop()));
    };
  },
  moveToTrash: (id, onSuccess) => {
    return (dispatch) => {
      dispatch(PostsSliceActions.loadingStart());
      ContentAdminService.moveToTrash(id)
        .then(() => {
          dispatch(
            PostsSliceActions.success({
              message: 'Notícia foi movida para a lixeira. ',
            }),
          );
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch((err) =>
          dispatch(
            PostsSliceActions.error({
              httpStatus: err?.response?.status,
              message: 'Ocorreu um erro ao mover a notícia para a lixeira.',
            }),
          ),
        )
        .finally(() => dispatch(PostsSliceActions.loadingStop()));
    };
  },
  restoreFromTrash: (id, onSuccess) => {
    return (dispatch) => {
      dispatch(PostsSliceActions.loadingStart());
      ContentAdminService.restoreFromTrash(id)
        .then(() => {
          dispatch(
            PostsSliceActions.success({
              message: 'Notícia foi restaurada da lixeira. ',
            }),
          );
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch((err) =>
          dispatch(
            PostsSliceActions.error({
              httpStatus: err?.response?.status,
              message: 'Ocorreu um erro ao restaurar a notícia da lixeira.',
            }),
          ),
        )
        .finally(() => dispatch(PostsSliceActions.loadingStop()));
    };
  },
  loadLovs: () => {
    return (dispatch) => {
      dispatch(PostsSliceActions.loadingStart());
      ContentAdminService.loadDimensions()
        .then((res) => {
          dispatch(PostsSliceActions.success({ dimensions: res.data }));
        })
        .catch((err) =>
          dispatch(
            PostsSliceActions.error({
              httpStatus: err?.response?.status,
              message: 'Ocorreu um erro ao carregar os dados do formulário.',
            }),
          ),
        )
        .finally(() => dispatch(PostsSliceActions.loadingStop()));
    };
  },
  getLatestPresentationOrder: () => {
    return (dispatch) => {
      ContentAdminService.getLatestPresentationOrder()
        .then((res) => {
          const { value } = res.data;
          dispatch(PostsSliceActions.updateLatestPresentationOrder({ presentationOrder: value }));
        })
        .catch((err) =>
          dispatch(
            PostsSliceActions.error({
              httpStatus: err?.response?.status,
              message: 'Ocorreu um erro ao obter a última posição.',
            }),
          ),
        );
    };
  },
  savePost: (data, onSuccess) => {
    return (dispatch) => {
      dispatch(PostsSliceActions.loadingStart(`Aguarde...`));
      const request = data?.id ? ContentAdminService.updatePost(data?.id, data) : ContentAdminService.savePost(data);
      request
        .then(() => {
          dispatch(
            PostsSliceActions.success({
              message: `Notícia ${data?.id ? 'atualizada' : 'criada'} com sucesso. `,
            }),
          );
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch((err) => {
          let fieldErrors = {};
          if (err.response.status === 400) {
            fieldErrors = convertErrorsArray(err.response.data.fieldErrors, 'field');
            // console.log(JSON.stringify(fieldErrors));
          }
          dispatch(
            PostsSliceActions.error({
              httpStatus: err?.response?.status,
              message: `Ocorreu um erro ao ${data?.id ? 'atualizar' : 'criar'} notícia.`,
              fieldErrors,
            }),
          );
        })
        .finally(() => dispatch(PostsSliceActions.loadingStop()));
    };
  },
  clearData: () => {
    return (dispatch) => {
      dispatch(PostsSliceActions.reset());
    };
  },
};

const postsReducer = postsSlice.reducer;
export default postsReducer;
