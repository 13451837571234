import React, { Fragment, useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import formSettings from './formSettings';
import { getBulletFromPosition } from './Questions/Components';

export const ModalConfirmSave = ({ show, onHide, onConfirmation }) => {
  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} centered className="inteligente-modal">
      <Modal.Header closeButton>
        <Modal.Title>Confirmar finalização</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Você confirma o envio?</h5>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-outline-secondary" onClick={onHide}>
          Não
        </button>
        <button type="button" className="btn btn-primary" onClick={onConfirmation}>
          Sim
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export const ModalGroupSaveStatus = (props) => {
  const navigate = useNavigate();

  const groupStatus = useSelector((state) => state.citySurvey.groupInfo?.status, shallowEqual) === 2;
  const surveys = useSelector((state) => state.citySurvey.groupInfo.surveys);
  const errors = useSelector((state) => state.citySurvey.groupInfo?.errors);

  const onHide = () => {
    props.onHide();
    if (props.shouldRedirect) {
      navigate(props.pathToRedirect);
    }
  };

  const surveyPosition = useMemo(() => {
    let sDict = {};
    for (const [surveyCode, survey] of Object.entries(surveys)) {
      let dict = {};
      if (Object.keys(survey?.questions).length > 0) {
        let qIdx = 0;
        for (const [qCode, question] of Object.entries(survey?.questions)) {
          let options = {};
          let optIdx = 0;
          for (const optCode of Object.keys(question.options)) {
            options = { ...options, [optCode]: ++optIdx };
          }
          dict = { ...dict, [qCode]: { position: ++qIdx, options } };
        }
        sDict = { ...sDict, [surveyCode]: { questions: dict } };
      }
    }
    return sDict;
  }, [surveys]);

  return (
    <Modal
      show={props.show}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
      className="inteligente-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {props.isError ? 'Erro ao finalizar o preenchimento' : 'Preenchimento finalizado com sucesso'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p dangerouslySetInnerHTML={{ __html: props.message || '' }}></p>
        {!groupStatus &&
          errors &&
          Object.keys(errors).length > 0 &&
          Object.entries(errors).map(([surveyCode, errorsSurveys]) => (
            <Fragment key={`errors-${surveyCode}`}>
              &nbsp;
              <h3 style={{ color: '#000', fontWeight: 'bold' }}>{surveys[surveyCode].title}</h3>
              {Object.keys(errorsSurveys).length > 0 && (
                <div
                  style={{ width: '100%', borderRadius: '5px', backgroundColor: 'hsl(348, 86%, 61%)', color: 'white' }}
                >
                  <ul>
                    {surveyPosition &&
                      Object.keys(surveyPosition).length > 0 &&
                      Object.entries(surveyPosition[surveyCode]?.questions)
                        .filter(([qCode, positionInfo]) => Object.keys(errorsSurveys).includes(qCode))
                        .map(([qCode, positionInfo]) => {
                          const qError = errorsSurveys[qCode];
                          return Object.entries(qError).map(([optCode, msg]) => (
                            <li key={`error-${qCode}`}>
                              Questão {positionInfo.position}
                              {optCode !== 'ROOT'
                                ? ` - Opção ${getBulletFromPosition(positionInfo.options[optCode])}: ${msg}`
                                : `: ${msg}`}
                            </li>
                          ));
                        })}
                    {/* Object.entries(errorsSurveys).map(([questionCode, error]) => {
                      return Object.entries(error).map(([optionCode, msg]) => (
                        <li key={optionCode} style={{ padding: '5px' }}>
                          {`Questão ${questionCode
                            .slice(surveyCode.length, questionCode.length)
                            .replace('_', '.')
                            .split('.')
                            .map((str) => str.replace(/^0+/, ''))
                            .join('.')}`}
                          {optionCode !== 'ROOT'
                            ? ` - Opção ${optionCode.charAt(optionCode.length - 1)}: ${msg}`
                            : `: ${msg}`}
                        </li>
                      ));
                    }) */}
                  </ul>
                </div>
              )}
              {Object.keys(errorsSurveys).length === 0 && <p>Formulário preenchido corretamente</p>}
            </Fragment>
          ))}
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-primary" onClick={onHide}>
          OK
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export const ModalSurveyMessage = (props) => {
  const navigate = useNavigate();
  // const surveyCode = props.surveyCode;
  const questionsPosition = props.questionsPosition;

  const onHide = () => {
    props.onHide();
    if (props.shouldRedirect) {
      navigate(props.pathToRedirect);
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
      className="inteligente-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {props.isError ? 'Respostas gravadas com erros de validação' : 'Respostas gravadas com sucesso'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p dangerouslySetInnerHTML={{ __html: props.message || '' }}></p>
        {props.errorsList && Object.keys(props.errorsList).length > 0 && (
          <>
            &nbsp;
            <div style={{ width: '100%', borderRadius: '5px', backgroundColor: 'hsl(348, 86%, 61%)', color: 'white' }}>
              <ul>
                {/*
                Object.entries(props.errorsList).map(([questionCode, error], index) => {
                  return Object.entries(error).map(([optionCode, msg]) => (
                    <li key={optionCode}>
                      {`Questão ${questionCode
                        .slice(surveyCode.length, questionCode.length)
                        .replace('_', '.')
                        .split('.')
                        .map((str) => str.replace(/^0+/, ''))
                        .join('.')}`}
                      {optionCode !== 'ROOT'
                        ? ` - Opção ${optionCode.charAt(optionCode.length - 1)}: ${msg}`
                        : `: ${msg}`}
                    </li>
                  ));
                }) */}
                {Object.entries(questionsPosition)
                  .filter(([qCode, positionInfo]) => Object.keys(props.errorsList).includes(qCode))
                  .map(([qCode, positionInfo]) => {
                    const qError = props.errorsList[qCode];
                    return Object.entries(qError).map(([optCode, msg]) => (
                      <li key={`error-${qCode}`}>
                        Questão {positionInfo.position}
                        {optCode !== 'ROOT'
                          ? ` - Opção ${getBulletFromPosition(questionsPosition[qCode].options[optCode])}: ${msg}`
                          : `: ${msg}`}
                      </li>
                    ));
                  })}
                {/*
                  Object.entries(props.errorsList).map(([qCode, error], index) => {
                  return Object.entries(error).map(([optCode, msg]) => (
                    <li key={`error-${qCode}`}>
                      Questão {questionsPosition[qCode]}{' '}
                      {optCode !== 'ROOT'
                        ? ` - Opção ${questionsPosition[qCode].options[optCode]}: ${msg}`
                        : `: ${msg}`}
                    </li>
                  ));
                }) */}
              </ul>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-primary" onClick={onHide}>
          OK
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export const ModalUncheckConfirmation = ({
  show,
  eventType,
  eventValue,
  disableSubquestion,
  handleConfirmation,
  handleClose,
}) => {
  const localConfirmation = (uncheck) => {
    const shouldUncheck = eventType ? uncheck : !uncheck;
    handleConfirmation(eventValue, shouldUncheck, eventType);
    handleClose();
  };
  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered className="inteligente-modal">
      <Modal.Header closeButton>
        <Modal.Title>Confirmar ação</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {disableSubquestion
          ? 'Se desmarcar esta opção, as subquestões relacionadas a ela serão desabilitadas e suas opções desmarcadas. '
          : 'Se você marcar esta opção, irá desmarcar as outras. '}
        <br />
        Deseja continuar?
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-outline-secondary" onClick={handleClose}>
          Não
        </button>
        <button type="button" className="btn btn-danger" onClick={() => localConfirmation(!disableSubquestion)}>
          Sim
        </button>
      </Modal.Footer>
    </Modal>
  );
};

const toastMessages = {
  survey: {
    loading: 'Gravando respostas deste formulário',
    error: 'Ocorreu um erro ao gravar as respostas. Tente novamentes mais tarde.',
    success: 'Respostas gravadas com sucesso.',
  },
  group: {
    loading: 'Validando e gravando as respostas de todos os formulários',
    error: 'Ocorreu um erro ao gravar as respostas. Tente novamentes mais tarde.',
    success: 'Respostas gravadas com sucesso.',
  },
  startUpdate: {
    // survey group clone
    loading: 'Copiando respostas para atualização das informações',
    error: 'Ocorreu um erro ao gravar as respostas. Tente novamentes mais tarde.',
    success: 'O preenchimento está liberado para atualização.',
  },
};

const buildToast = (loading, error, success, message) => {
  if (loading === true) {
    toast.info(message.loading, { autoClose: formSettings.toastsDurationMS });
  } else {
    if (error === true) {
      toast.error(message.error, { autoClose: formSettings.toastsDurationMS });
    }
    if (success === true) {
      toast.success(message.success, { autoClose: formSettings.toastsDurationMS });
    }
  }
};

export const showPageToasts = (page, loading, error, success) => {
  if (toastMessages[page]) {
    return buildToast(loading, error, success, toastMessages[page]);
  }
};

export const getToastInfo = (page, loading, error, success) => {
  if (toastMessages[page]) {
    if (loading === true) {
      return { type: 'info', message: toastMessages[page].loading };
    } else {
      if (error === true) {
        return { type: 'danger', message: toastMessages[page].error };
      }
      if (success === true) {
        return { type: 'success', message: toastMessages[page].success };
      }
    }
  }
};
