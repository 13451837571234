import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import readXlsxFile, { readSheetNames } from 'read-excel-file';
import { useTitle } from 'hooks/useTitle';

const SPREADSHEET_EXT = ['xlsx'];

const CSV_EXT = ['csv', 'txt'];

const csvSeparator = ';';

const calculateColumnLetter = (idx) => {
  const codeOffset = 65; // https://stackoverflow.com/a/13202079
  const remainder = idx % 26;
  const decimal = Math.floor(idx / 26);
  const hundred = Math.floor(decimal / 26);

  const firstLetter = hundred > 0 ? String.fromCharCode(codeOffset + hundred) : '';
  const secondLetter = decimal > 0 ? String.fromCharCode(codeOffset + decimal) : '';
  const thirdLetter = String.fromCharCode(codeOffset + remainder);

  return firstLetter + secondLetter + thirdLetter;
};

const writeCell = (cell) => {
  try {
    if (cell instanceof Date) {
      return cell.toISOString();
    }
  } catch (err) {
    console.error('Error to show value ' + cell);
  }
  return cell;
};

const readCsvFile = (file, csvSeparator) => {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject(new Error('File is null'));
    }
    const reader = new FileReader();
    reader.addEventListener('load', function (evtFr) {
      const content = evtFr.target.result;
      if (content) {
        const data = content
          .trim()
          .split('\n')
          .map((line) => line.split(csvSeparator));
        resolve(data);
      }
    });
    reader.readAsText(file);
  });
};

const SpreadsheetSample = ({ sample }) => {
  return (
    <table className="table table-striped" style={{ overflowX: 'visible' }}>
      <thead>
        <tr className="table-secondary">
          <td>&nbsp;</td>
          {sample.length > 0 &&
            sample[0].map((cell, idx) => <th key={`header-${idx}`}>{calculateColumnLetter(idx)}</th>)}
        </tr>
      </thead>
      <tbody>
        {sample.length > 0 &&
          sample.map((row, rIdx) => (
            <tr key={`row-${rIdx}`}>
              <th className="table-secondary">{rIdx + 1}</th>
              {row.map((cell, cIdx) => (
                <td key={`cell-${rIdx}-${cIdx}`}>{writeCell(cell)}</td>
              ))}
            </tr>
          ))}
      </tbody>
    </table>
  );
};

const CsvSample = ({ sample }) => {
  return (
    <table className="table table-striped" style={{ overflowX: 'visible' }}>
      <thead>
        <tr className="table-secondary">
          {sample.length > 0 && sample[0].map((val, idx) => <th key={`header-${idx}`}>{idx}</th>)}
        </tr>
      </thead>
      <tbody>
        {sample.length > 0 &&
          sample.map((row, rIdx) => (
            <tr key={`row-${rIdx}`}>
              {row.map((val, vIdx) => (
                <td key={`cell-${rIdx}-${vIdx}`}>{val}</td>
              ))}
            </tr>
          ))}
      </tbody>
    </table>
  );
};

const IndicatorDataLoader = () => {
  const pageTitle = useTitle('Importar dados de indicador');

  const [file, setFile] = useState('');
  const [fileExt, setFileExt] = useState('');
  const [sample, setSample] = useState([]);
  const [sheetNames, setSheetNames] = useState([]);
  const [currentSheet, setCurrentSheet] = useState('');

  useEffect(() => {
    async function verifyFile() {
      if (file) {
        const fileParts = file.name.split('.');
        const fileExt = fileParts[fileParts.length - 1].toLowerCase();

        if (SPREADSHEET_EXT.includes(fileExt)) {
          const sheetNames = await readSheetNames(file);
          setSheetNames(sheetNames);
          setCurrentSheet(sheetNames[0]);
          setFileExt('xlsx');
        } else if (CSV_EXT.includes(fileExt)) {
          setFileExt('csv');
        }
      }
    }
    verifyFile();
  }, [file]);

  useEffect(() => {
    async function loadXlsxFile() {
      if (file && fileExt === 'xlsx' && currentSheet?.length > 0) {
        const rows = await readXlsxFile(file, { sheet: currentSheet });
        setSample(rows.slice(0, 10));
      }
    }
    loadXlsxFile();
  }, [file, fileExt, currentSheet]);

  useEffect(() => {
    async function loadCsvFile() {
      if (file && fileExt === 'csv') {
        const rows = await readCsvFile(file, csvSeparator);
        setSample(rows.slice(0, 10));
      }
    }
    loadCsvFile();
  }, [file, fileExt]);

  const handleFileLoad = async (evt) => {
    if (evt.target.files && evt.target.files.length > 0) {
      setFile(evt.target.files[0]);
    }
  };

  const handleChangeSheet = (evt) => {
    setCurrentSheet(evt.target.value);
  };

  return (
    <>
      <div className="bg-gray-superlight px-5 py-4 w-100">
        {/* <!-- Cabeçalho Página - start --> */}
        <header>
          <h3 className="fw-bolder c-cyan mb-0">{pageTitle}</h3>
          <p className="path">
            <Link to="">Início</Link> &rsaquo; <span className="current">{pageTitle}</span>
          </p>
        </header>
        {/* <!-- Cabeçalho Página - end --> */}
        <div className="bg-white shadow crud">
          <h2>Importar arquivo</h2>
          <div>
            <input type="file" onChange={handleFileLoad} />
            {sheetNames?.length > 1 && (
              <select onChange={handleChangeSheet}>
                {sheetNames.map((opt, idx) => (
                  <option key={`sheetName${idx}`}>{opt}</option>
                ))}
              </select>
            )}
          </div>
          <div style={{ width: '100%', overflowX: 'scroll' }}>
            {fileExt === 'xlsx' && <SpreadsheetSample sample={sample} />}
            {fileExt === 'csv' && <CsvSample sample={sample} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default IndicatorDataLoader;
