import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { validEmail, validName } from 'helpers/Validators';
import { Actions as RolesDataActions } from 'services/slices/roles';
import api from 'services/api';
import { formatRoleId } from 'helpers/Utils';
import { submitRecaptcha } from 'components/reCaptcha';

export const FinishModal = ({ show, message, onClose }) => {
  return (
    <Modal
      className="inteligente-modal"
      show={show}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
    >
      <Modal.Header>
        <Modal.Title>Receber novidades</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-primary" onClick={onClose}>
          Fechar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

const NewsletterSubscription = () => {
  const INITIAL_USER_DATA = {
    name: '',
    email: '',
    roleCode: '0',
  };

  const [userData, setUserData] = useState(INITIAL_USER_DATA);
  const [modalShow, setModalShow] = useState(false);
  const [modalMessage, setModalMessage] = useState();

  const roles = useSelector((state) => state.rolesData.roles);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(RolesDataActions.loadRoles());
    return () => {
      dispatch(RolesDataActions.reset());
    };
  }, [dispatch]);

  // Referencia para manipular forms: https://git.brainyit.com.br/ish-siem-manager/frontend/-/blob/sprint-3-eslint/src/page/Source/ModalForm.js
  const handleInputChangeData = (evt) => {
    setUserData((currentData) => {
      return { ...currentData, [evt.target.name]: evt.target.value };
    });
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    submitRecaptcha('subscribe').then((token) => {
      api
        .post('/api/public/subscribe', { ...userData, token, termsOfUse: { vesion: '', accepted: true } })
        .then((resp) => {
          setModalMessage({
            type: 0,
            text: 'Seu e-mail foi cadastrado com sucesso.',
          });
          setModalShow(true);
        })
        .catch((err) => {
          console.error(err);
          if (err?.response?.status === 409) {
            setModalMessage({
              type: err.response?.data?.code || 1,
              text: 'ERRO: O e-mail informado já está cadastrado.',
            });
          } else {
            setModalMessage({
              type: 1,
              text: 'ERRO: Não foi possível realizar a inscrição. Tente novamente mais tarde.',
            });
          }
          setModalShow(true);
        });
    });
  };

  return (
    <>
      <section className="newsletter-form py-5 bg-success">
        <div className="container my-5">
          <div className="row">
            <div className="col-12 col-lg-4">
              <h1 className="mb-4 fw-bolder">Fique por dentro das atualizações da pesquisa</h1>
              <p>Cadastre-se e receba avisos por e-mail sempre que uma nova pesquisa for lançada!</p>
            </div>
            <div className="col-12 col-lg-6 offset-lg-2">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label className="form-label" htmlFor="fieldName">
                    Nome
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="fieldName"
                    name="name"
                    defaultValue={userData.name}
                    onChange={handleInputChangeData}
                    placeholder="Digite seu nome completo"
                    maxLength="100"
                  />
                  {validName(userData.name) === false && <p>Digite seu nome completo</p>}
                </div>
                <div className="mb-3">
                  <label className="form-label" htmlFor="fieldEmail">
                    E-mail
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="fieldEmail"
                    name="email"
                    defaultValue={userData.email}
                    onChange={handleInputChangeData}
                    placeholder="Digite seu e-mail"
                    maxLength="100"
                  />
                  {validEmail(userData.email) === false && <p>Digite um e-mail válido</p>}
                </div>
                <div className="mb-4">
                  <label className="form-label">Perfil</label>
                  <select className="form-select" name="roleCode" defaultValue="0" onChange={handleInputChangeData}>
                    <option value="0" disabled>
                      Selecione um perfil
                    </option>
                    {roles &&
                      roles.map((option) => (
                        <option id={formatRoleId(option.text)} key={`role${option.value}`} value={'' + option.value}>
                          {option.text}
                        </option>
                      ))}
                  </select>
                </div>
                <div>
                  <button type="submit" className="btn btn-cyan">
                    Receber novidades
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <FinishModal show={modalShow} message={modalMessage?.text} onClose={() => setModalShow(false)} />
    </>
  );
};

export default NewsletterSubscription;
