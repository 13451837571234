import React from 'react';
import { Link } from 'react-router-dom';
import { getRoleInfo } from 'helpers/userAuthUtils';

const SidebarLink = ({ to, className, children, requiredRoles = [] }) => {
  if (requiredRoles.length === 0) {
    return (
      <li className={className}>
        <Link to={to}>{children}</Link>
      </li>
    );
  } else {
    const roles = getRoleInfo();
    const intersection = requiredRoles.filter((role) => roles.includes(role));
    if (intersection.length > 0) {
      return (
        <li className={className}>
          <Link to={to}>{children}</Link>
        </li>
      );
    } else {
      return <></>;
    }
  }
};

export default SidebarLink;

