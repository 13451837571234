import FieldSelectInput from 'components/form/FieldSelectInput';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Filter = ({ filters, valueSelected, onSelectedOption }) => {
  const navigate = useNavigate();

  const handleSelectCity = (cityData) => {
    navigate(cityData.friendlyName);
  };

  return (
    <>
      {/* <!-- Box Filtros - start --> */}
      <div className="bg-white shadow p-3 mb-4">
        <h6 className="fw-bolder mb-3">Filtros</h6>
        <div className="mb-3">
          <span className="has-icon search">
            <FieldSelectInput
              id="city-autocomplete-post"
              name="cityId"
              initialValue={''}
              placeholder="Digite o município"
              onSelectedOption={handleSelectCity}
            />
          </span>
        </div>
        <div className="row">
          {filters &&
            Object.entries(filters).map(([filterName, filterArray]) => (
              <div key={filterName} className="col-12 col-lg-3">
                <select
                  value={valueSelected}
                  className="form-select form-select-sm"
                  onChange={(evt) => onSelectedOption(evt.target.value)}
                >
                  {filterName === 'est' && <option value="">Estado</option>}
                  {filterName === 'reg' && <option value="">Região</option>}
                  {filterName === 'pop' && <option value="">Habitantes</option>}
                  {filterName === 'arj' && <option value="">Rede de influência</option>}
                  {filterArray.map((f) => (
                    <option key={f.code} value={f.code}>
                      {f.title}
                    </option>
                  ))}
                </select>
              </div>
            ))}
        </div>
      </div>
      {/* <!-- Box Filtros - end --> */}
    </>
  );
};

export default Filter;
