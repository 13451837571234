import React, { Fragment } from 'react';

const Recommendations = ({ cityData, labels, descriptions, showCityName = false }) => {
  return (
    <div className="bg-white shadow p-5">
      <h2 className="c-cyan fw-bold mb-5">Resultado do diagnóstico da maturidade</h2>
      {cityData && Object.keys(cityData).length > 0 && (
        <>
          {showCityName && (
            <h4 className="mb-4">
              {cityData?.name || 'Nome do Município'}/{cityData?.stateAbbr || 'Estado'}
            </h4>
          )}

          <h4 className="c-green mb-3 fw-bold">Nível geral</h4>
          <div className="d-flex align-items-center mb-3">
            <div className={`circle-level bg-${cityData.level} outline subtle me-3`}>
              <span>{cityData.level}</span>
            </div>
            <h5 className="c-gray fw-bold">{labels && cityData && labels[`m${cityData.level}`]?.title}</h5>
          </div>
          {descriptions && Object.keys(descriptions).length > 0 && (
            <>
              <p className="mb-5">{descriptions?.general[`m${cityData.level}`]}</p>

              {Object.entries(descriptions?.dimensions).map(([key, value]) => (
                <Fragment key={`dimension-${key}`}>
                  <h4 className="c-green mb-3 fw-bold">Dimensão: {labels && labels[key]?.title}</h4>
                  <div className="d-flex align-items-center mb-3">
                    <div
                      className={`circle-level bg-${
                        cityData?.dimensions?.filter((dimension) => dimension.code === key)[0].level
                      } outline subtle me-3`}
                    >
                      <span>{cityData?.dimensions?.filter((dimension) => dimension.code === key)[0].level}</span>
                    </div>
                    <h5 className="c-gray fw-bold">
                      {labels &&
                        cityData &&
                        labels[`m${cityData?.dimensions?.filter((dimension) => dimension.code === key)[0].level}`]
                          ?.title}
                    </h5>
                  </div>
                  <div className="bg-white shadow p-5 mb-5">
                    <p className="mb-4">
                      {
                        value[`l${cityData?.dimensions?.filter((dimension) => dimension.code === key)[0].level}`]
                          .description
                      }
                    </p>

                    <div className="bg-gray-dark px-4 py-3">Diretrizes para avançar de nível</div>
                    <div className="bg-gray-superlight p-4">
                      <p>
                        {' '}
                        {
                          value[`l${cityData?.dimensions?.filter((dimension) => dimension.code === key)[0].level}`]
                            .guidelines
                        }
                      </p>
                    </div>
                  </div>
                </Fragment>
              ))}
            </>
          )}
        </>
      )}

      <h2 className="c-cyan fw-bold mb-5">Recomendações Complementares</h2>
      <section>
        <h3 className="c-green mb-3 fw-bold">Carta Brasileira para Cidades Inteligentes</h3>
        <p className="mb-5">
          A Carta Brasileira para Cidades Inteligentes é um documento que expressa uma agenda pública brasileira. A
          finalidade central da Carta é apoiar a promoção de padrões de desenvolvimento urbano sustentável, que levam em
          conta o contexto brasileiro da transformação digital nas cidades.
        </p>
        <a href="https://www.cartacidadesinteligentes.org.br/" rel="nofollow noopener noreferrer" target="_blank">
          https://www.cartacidadesinteligentes.org.br/
        </a>
      </section>
      <hr />
      <section>
        <h3 className="c-green mb-3 fw-bold">Iniciativas e opções de financiamentos</h3>
        <h4 className="c-green mb-3 fw-bold">BNDES</h4>
        <p className="mb-5">Clique nos links abaixo</p>
        <ul>
          <li>
            <a
              href="https://www.bndes.gov.br/wps/portal/site/home/financiamento/guia/guia-do-financiamento"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              Guia de financiamentos
            </a>
          </li>
          <li>
            <a
              href="https://www.bndes.gov.br/wps/portal/site/home/financiamento/produto/bndes-finem-seguranca"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              Segurança Pública
            </a>
          </li>
          <li>
            <a
              href="https://www.bndes.gov.br/wps/portal/site/home/financiamento/produto/bndes-finem-eficiencia-energetica"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              Iluminação Pública
            </a>
          </li>
          <li>
            <a
              href="https://www.bndes.gov.br/wps/portal/site/home/financiamento/produto/bndes-finem-mobilidade-urbana"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              Mobilidade Urbana
            </a>
          </li>
          <li>
            <a
              href="https://www.bndes.gov.br/wps/portal/site/home/financiamento/produto/avancar-saneamento"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              Saneamento
            </a>
          </li>
          <li>
            <a
              href="https://www.bndes.gov.br/wps/portal/site/home/financiamento/produto/bndes-finem-pmat/"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              Gestão Pública
            </a>
          </li>
          <li>
            <a
              href="https://www.bndes.gov.br/wps/portal/site/home/financiamento/produto/bndes-finem-educacao-saude"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              Educação, Saúde e Assistência Social
            </a>
          </li>
          <li>
            <a
              href="https://www.bndes.gov.br/wps/portal/site/home/financiamento/produto/bndes-finem-pmi"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              Desenvolvimento Integrado
            </a>
          </li>
          <li>
            <a
              href="https://www.bndes.gov.br/wps/portal/site/home/financiamento/produto/bndes-fundo-cultural"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              Patrimônio Cultural
            </a>
          </li>
          <li>
            <a
              href="https://www.bndes.gov.br/wps/portal/site/home/financiamento/produto/bndes-finem-meio-ambiente"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              Meio Ambiente
            </a>
          </li>
          <li>
            <a
              href="https://www.bndes.gov.br/wps/portal/site/home/financiamento/produto/bndes-credito-servicos-4.0"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              Serviços 4.0
            </a>
          </li>
          <li>
            <a
              href="https://www.bndes.gov.br/wps/portal/site/home/financiamento/produto/bndes-servicos-estruturacao-de-projetos/"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              Estruturação de Projetos
            </a>
          </li>
        </ul>
        <h4 className="c-green mb-3 fw-bold">Banco de Desenvolvimento da América Latina - CAF</h4>
        <ul>
          <li>
            <a href="https://www.caf.com/pt/" rel="nofollow noopener noreferrer" target="_blank">
              Clique no link
            </a>
          </li>
        </ul>
        <h4 className="c-green mb-3 fw-bold">Banco Internacional de Desenvolvimento - BID</h4>
        <ul>
          <li>
            <a href="https://www.iadb.org/pt" rel="nofollow noopener noreferrer" target="_blank">
              Clique no link
            </a>
          </li>
        </ul>
      </section>
      <hr />
      <section>
        <h3 className="c-green mb-3 fw-bold">Iniciativas e opções para Soluções inteligentes</h3>
        <p className="mb-5">
          Há uma diversidade de soluções disponíveis que podem ser implementadas na sua cidade, para problemas
          concretos.
        </p>
        <ul>
          <li>
            <a
              href="https://oics.cgee.org.br/solucoes-e-casos/solucoes"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              Centro de Gestão e Estudos Estratégicos - CGEE
            </a>
          </li>
          <li>
            <a
              href="http://inteligencia.abdi.com.br/cidades-inteligentes/"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              Agência Brasileira de Desenvolvimento Industrial - ABDI
            </a>
          </li>
          <li>
            <a
              href="https://citinova.mcti.gov.br/plataformas-para-cidades-sustentaveis/"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              CITInova
            </a>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default Recommendations;
