import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import formSettings from 'components/survey/formSettings';
import Questions from 'components/survey/Questions';
import { ModalSurveyMessage } from 'components/survey/ModalToast';
import { Actions as CitySurveyActions } from 'services/citySurvey';
import { formatDateTimeSimple } from 'helpers/dateTimeUtils';
import { getLocaleCode } from 'helpers/Utils';

const SurveyQuestions = ({ surveyCode, title }) => {
  const groupStatus = useSelector((state) => state.citySurvey.groupInfo.status);
  const groupStartDate = useSelector((state) => state.citySurvey.groupInfo.startDate);
  const groupEndDate = useSelector((state) => state.citySurvey.groupInfo.endDate);
  const questions = useSelector((state) => state.citySurvey.groupInfo.surveys[surveyCode]?.questions);
  const answers = useSelector((state) => state.citySurvey.groupInfo.answers[surveyCode]?.answers);
  const validationStatus = useSelector((state) => state.citySurvey.groupInfo.valid[surveyCode]);
  const notificationStatus = useSelector((state) => state.citySurvey.groupInfo.notifications[surveyCode]);
  const lastResponsible = useSelector((state) => state.citySurvey.groupInfo.answers[surveyCode]?.lastResponsible);
  const lastUpdate = useSelector((state) => state.citySurvey.groupInfo.answers[surveyCode]?.lastUpdate);
  const errors = useSelector((state) => state.citySurvey.groupInfo.errors[surveyCode]);
  const loadStatusValues = useSelector((state) => state.citySurvey.loadStatusValues);
  const questionsLoadStatus = useSelector((state) => state.citySurvey.questionsLoadStatus);
  const answersLoadStatus = useSelector((state) => state.citySurvey.answersLoadStatus);
  const answersSaveStatus = useSelector((state) => state.citySurvey.ansersSaveStatus);
  const groupSaveStatus = useSelector((state) => state.citySurvey.groupSaveStatus);
  const dispatch = useDispatch();

  const [showModalSaveResult, setShowModalSaveResult] = useState(false);
  const [validateOnSave, setValidateOnSave] = useState(true);

  // const [clear, setClear] = useState(false);

  // Dispara carregamento das questões
  useEffect(() => {
    if (surveyCode) {
      dispatch(CitySurveyActions.loadQuestions(surveyCode));
    }
  }, [dispatch, surveyCode]);

  // Dispara carregamento das respostas
  useEffect(() => {
    if (surveyCode && questions) {
      dispatch(CitySurveyActions.loadAnswers(surveyCode));
    }
  }, [dispatch, questions, surveyCode]);

  // Verificar/apresentar erros de validação
  // useEffect(() => {
  //   //const shouldModalShow = !updateStatus.loading && formFilledOut?.hasFormFilledOut !== null && updateStatus.successful !== null && finish === true
  //   setShowModalSaveResult(+validationStatus === 0 && Object.keys(errors).length > 0)
  // }, [validationStatus, errors])
  useEffect(() => {
    if (notificationStatus === 1) {
      setShowModalSaveResult(true);
    }
  }, [notificationStatus]);

  const modalMessage = useMemo(() => {
    if (validationStatus === 0 && errors && Object.keys(errors).length > 0) {
      return `As respostas do formulário <strong>${title}</strong> estão incompletas.<br>Por favor, verifique os erros abaixo.`;
    } else if (validationStatus === 1) {
      return `O preenchimento do formulário <strong>${title}</strong> está correto.<br />Lembre-se de finalizar o preenchimento de toda a pesquisa para que sua cidade seja avaliada.`;
    }
    return null;
  }, [validationStatus, errors, title]);

  const formDisabled = useMemo(() => {
    const currentDate = new Date();
    return !(
      answersSaveStatus !== loadStatusValues.LOADING &&
      groupSaveStatus !== loadStatusValues.LOADING &&
      (groupStatus === 0 || groupStatus === 1) &&
      currentDate >= new Date(groupStartDate) &&
      currentDate <= new Date(groupEndDate)
    );
  }, [answersSaveStatus, groupSaveStatus, groupStatus, groupStartDate, groupEndDate, loadStatusValues.LOADING]);

  const btnSaveDisabled = useMemo(() => {
    if (answers) {
      const someChanged = Object.values(answers).some((a) => a.changed === true);
      return !(
        (validateOnSave && (groupStatus === 0 || groupStatus === 1)) ||
        (someChanged &&
          answersLoadStatus !== loadStatusValues.LOADING &&
          answersSaveStatus !== loadStatusValues.LOADING &&
          !formDisabled)
      );
    }
    return true;
  }, [
    answers,
    answersLoadStatus,
    answersSaveStatus,
    formDisabled,
    validateOnSave,
    groupStatus,
    loadStatusValues.LOADING,
  ]);

  const questionsPosition = useMemo(() => {
    let dict = {};
    if (Object.keys(questions).length > 0) {
      let qIdx = 0;
      for (const [qCode, question] of Object.entries(questions)) {
        let options = {};
        let optIdx = 0;
        for (const optCode of Object.keys(question.options)) {
          options = { ...options, [optCode]: ++optIdx };
        }
        dict = { ...dict, [qCode]: { position: ++qIdx, options } };
      }
    }
    return dict;
  }, [questions]);

  // Verifica se tem questões e respostas para exibir
  const showQuestions = useMemo(() => {
    return (
      questionsLoadStatus === loadStatusValues.LOADED_SUCCESS &&
      questions &&
      Object.keys(questions).length > 0 &&
      answersLoadStatus === loadStatusValues.LOADED_SUCCESS &&
      answers &&
      Object.keys(answers).length > 0
    );
  }, [questionsLoadStatus, questions, answersLoadStatus, answers, loadStatusValues.LOADED_SUCCESS]);

  // Salvamento automatico periodico das respostas preenchidas
  // useInterval(
  //   () => {
  //     saveAnswers(false);
  //     setClear(false);
  //   },
  //   groupStatus !== 2 ? formSettings.automaticSaveTimeMS : null,
  //   clear,
  // );

  const getQuestion = (parentCode, questionCode) => {
    return parentCode ? questions[parentCode].children[questionCode] : questions[questionCode];
  };

  const handleOptionChange = (questionCode, optCode, checked, parentCode) => {
    const question = getQuestion(parentCode, questionCode);
    switch (question.type) {
      case formSettings.questionTypes.singleOption:
        dispatch(CitySurveyActions.checkOption(surveyCode, questionCode, optCode, true, checked));
        break;
      case formSettings.questionTypes.multiOption:
        dispatch(CitySurveyActions.checkOption(surveyCode, questionCode, optCode, false, checked));
        break;
      default:
        break;
    }
  };

  const handleOrderChange = (questionCode, optCode, orderValue, parentCode) => {
    const question = getQuestion(parentCode, questionCode);
    if (question.type === formSettings.questionTypes.orderOption) {
      const val = /^[0-9]$/.test(orderValue) ? Number(orderValue) : '';
      if (val === '' || (val <= question.maxQuantity && val >= 1)) {
        dispatch(CitySurveyActions.orderOption(surveyCode, questionCode, optCode, val));
      }
    }
  };

  const handleComplementChange = (questionCode, optCode, complementValue) => {
    dispatch(CitySurveyActions.complementOption(surveyCode, questionCode, optCode, complementValue));
  };

  const handleTextAnswerChange = (questionCode, textValue) => {
    dispatch(CitySurveyActions.textOption(surveyCode, questionCode, textValue));
  };

  const saveAnswers = useCallback(
    (validate = true) => {
      if (surveyCode) {
        dispatch(CitySurveyActions.saveAnswers(surveyCode, validate && validateOnSave));
      }
    },
    [dispatch, surveyCode, validateOnSave],
  );

  const handleCloseModal = useCallback(() => {
    dispatch(CitySurveyActions.cleanNotification(surveyCode));
    setShowModalSaveResult(false);
  }, [dispatch, surveyCode]);

  return (
    <>
      <ModalSurveyMessage
        show={showModalSaveResult}
        surveyCode={surveyCode}
        onHide={handleCloseModal}
        errorsList={errors}
        questionsPosition={questionsPosition}
        message={modalMessage}
        isError={validationStatus === 0 && errors && Object.keys(errors).length > 0}
      />
      {/* <!-- Validate & Save - start --> */}
      <div className="text-end">
        {groupStatus !== 2 && (
          <label className="c-gray smaller-1 me-2">
            <input
              type="checkbox"
              checked={validateOnSave}
              disabled={formDisabled}
              onChange={(evt) => setValidateOnSave(evt.target.checked)}
            />{' '}
            Validar respostas ao salvar
          </label>
        )}
        <button
          type="button"
          id="btn-save-answers-top"
          className="btn btn-success btn-sm"
          disabled={btnSaveDisabled}
          onClick={saveAnswers}
        >
          Salvar temporariamente
        </button>
      </div>
      {/* <!-- Validate & Save - end --> */}

      {/* <!-- Questions - start --> */}
      {showQuestions && (
        <fieldset disabled={formDisabled}>
          <div className="px-5 mx-5" id={`survey-${surveyCode}`}>
            <div className="d-flex justify-content-start">
              {groupStatus === 2 ? (
                <i className="fa-solid fa-check fa-3x c-green me-3"></i>
              ) : (
                <i className="fa-solid fa-pen fa-3x c-green me-3"></i>
              )}
              <div id={surveyCode + '-title'}>
                <h3 className="c-green fw-bold mb-0">{title}</h3>
                {lastUpdate && lastResponsible && (
                  <p>
                    Última atualização: <strong>{formatDateTimeSimple(lastUpdate, getLocaleCode())}</strong> feita por{' '}
                    <strong>{lastResponsible}</strong>
                  </p>
                )}
                {!lastUpdate && <p>Preenchimento não iniciado</p>}
              </div>
            </div>

            <hr className="my-4" />
            <Questions
              surveyCode={surveyCode}
              questions={questions}
              answers={answers}
              errors={errors}
              onOptionChange={handleOptionChange}
              onOrderChange={handleOrderChange}
              onComplementChange={handleComplementChange}
              onTextAnswerChange={handleTextAnswerChange}
              groupStatus={groupStatus}
            />
          </div>
        </fieldset>
      )}
      {/* <!-- Questions - end --> */}

      {/* <!-- Validate & Save - start --> */}
      <div className="text-end mt-5">
        {groupStatus !== 2 && (
          <label className="c-gray smaller-1 me-2">
            <input
              type="checkbox"
              checked={validateOnSave}
              disabled={formDisabled}
              onChange={(evt) => setValidateOnSave(evt.target.checked)}
            />{' '}
            Validar respostas ao salvar
          </label>
        )}
        <button
          type="button"
          id="btn-save-answers-bottom"
          className="btn btn-success btn-sm"
          disabled={btnSaveDisabled}
          onClick={saveAnswers}
        >
          Salvar temporariamente
        </button>
      </div>
      {/* <!-- Validate & Save - end --> */}
    </>
  );
};

export default SurveyQuestions;
