import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { logoutUrl } from 'helpers/govBrUtils';
import { Actions as AuthActions, defaultErrorCode } from 'services/auth';
import { Actions as SupportInfoActions } from 'services/slices/supportInfo';

const LoginLoading = ({ displayProviderName }) => {
  return (
    <div className="p-5 m-5">
      <h1 className="fw-bold c-green">Autenticando com {displayProviderName}</h1>
      <h3>
        Aguarde<span className="dot1">.</span>
        <span className="dot2">.</span>
        <span className="dot3">.</span>
      </h3>
      <div className="m-5 text-center">
        <div className="loading">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

const ErrorMsg = ({ message, code = defaultErrorCode, supportEmail = process.env.REACT_APP_DEFAULT_SUPPORT_EMAIL }) => {
  let msg;
  // OBS SOBREESCREVENDO MSG DO BACKEND
  if (code === 5) {
    msg = {
      title: 'Acesso não autorizado',
      message:
        'Para o acesso à esta área é necessário contactar a governança da plataforma e solicitar o seu cadastro.',
      complement: 'Você será avisado por e-mail assim que o cadastro for concluído.',
    };
  } else if (code === 12) {
    msg = {
      title: 'Cadastro de prefeito em análise',
      message: 'Antes do primeiro acesso, seus dados precisam ser aprovados pela equipe de governança do portal.',
      complement: 'Você será avisado por e-mail assim que seu cadastro for aprovado.',
    };
  } else if (code === 22) {
    msg = {
      title: 'Cadastro de assessor em análise',
      message:
        'Antes do primeiro acesso, seus dados precisam ser aprovados pelo prefeito(a), que deverá estar cadastrado no portal',
      complement: 'Você será avisado por e-mail assim que seu cadastro for aprovado.',
    };
  } else {
    msg = {
      title: 'Acesso não autorizado',
      message,
    };
  }

  return (
    <div className="p-5 m-5">
      <h1 className="fw-bold c-cyan">
        {[12, 22].includes(code) ? (
          <>{msg.title}</>
        ) : (
          <>
            {msg.title} <i className="fa-solid fa-circle-exclamation c-danger"></i>
          </>
        )}
      </h1>
      <h4 className="fw-bold mb-3">{msg.message}</h4>
      {msg.complement && <p className="mb-3">{msg.complement}</p>}
      <p className="mb-3">
        Em caso de dúvidas, favor enviar um e-mail para a equipe de governança da plataforma ({supportEmail}).
      </p>
      <p className="text-center mt-5">
        <a href={logoutUrl} className="btn btn-success">
          Voltar para tela inicial
        </a>
      </p>
    </div>
  );
};

const OAuth2Callback = () => {
  const { providerName } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const authStatus = useSelector((state) => ({
    message: state.auth.message,
    success: state.auth.success,
    errorCode: state.auth.errorCode,
    logging: state.auth.logging,
  }));

  const supportEmail = useSelector((state) => state.supportInfo.email);

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    let codeVerifier = null;
    if (providerName === 'facebook') {
      codeVerifier = sessionStorage.getItem('facebookCodeChallenge');
      // sessionStorage.removeItem('facebookCodeChallenge');
    }
    const governanceUserData = sessionStorage.getItem('governanceUserData');
    const authType = sessionStorage.getItem('authType');
    const governanceUserId = governanceUserData ? JSON.parse(governanceUserData).id : null;

    dispatch(
      AuthActions.oauth2Login(
        params.code,
        providerName,
        codeVerifier,
        governanceUserId,
        authType,
        params.session_state,
        navigate,
      ),
    );
  }, [providerName, dispatch, navigate]);

  useEffect(() => {
    dispatch(SupportInfoActions.getSupportEmail());
  }, [dispatch]);

  // useEffect(() => {
  //   return () => {
  //     console.log('cleaning session storage');
  //     sessionStorage.removeItem('governanceUserData');
  //     sessionStorage.removeItem('authType');
  //   };
  // }, []);

  const displayProviderName =
    providerName === 'govbr' ? 'Gov.BR' : providerName.charAt(0).toUpperCase() + providerName.slice(1);

  return (
    <div className="page bg-cyan">
      <div className="container page">
        <div className="row page justify-content-center">
          <div className="col-12 col-lg-8 bg-white">
            {authStatus?.errorCode > 0 ? (
              <ErrorMsg message={authStatus.message} code={authStatus.errorCode} supportEmail={supportEmail} />
            ) : (
              <LoginLoading displayProviderName={displayProviderName} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OAuth2Callback;
