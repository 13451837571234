import api from 'services/api';

const API_BASE_PATH = '/api/admin/newsletters';

const DEFAULT_QUANTITY = 10;

const findAll = async (page, qt = DEFAULT_QUANTITY, status, q) => {
  const params = {
    page,
    qt,
    status,
    q,
  };
  return await api.get(API_BASE_PATH, { params });
};

const findById = async (id) => {
  return await api.get(`${API_BASE_PATH}/${id}`);
};

const deleteById = async (id) => {
  return await api.delete(`${API_BASE_PATH}/${id}`);
};

const moveToTrash = async (id) => {
  return await api.patch(`${API_BASE_PATH}/${id}/trash`);
};

const restoreFromTrash = async (id) => {
  return await api.patch(`${API_BASE_PATH}/${id}/restore`);
};

const saveNewsletter = async (newsletter) => {
  return await api.post(`${API_BASE_PATH}`, newsletter);
};

const updateNewsletter = async (id, newsletter) => {
  return await api.put(`${API_BASE_PATH}/${id}`, newsletter);
};

const findCitiesById = async (citiesIds) => {
  return await api.get(`/api/cities/search-city`, { params: { citiesIds }, paramsSerializer: { indexes: null } });
};

export default {
  findAll,
  findById,
  deleteById,
  moveToTrash,
  restoreFromTrash,
  saveNewsletter,
  updateNewsletter,
  findCitiesById,
};
