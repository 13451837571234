import React, { useCallback, useEffect, useState } from 'react';
import { getDimensionContainerImg } from 'helpers/DimensionUtils';
import ColumnChart from 'components/charts/admin/ColumnChart';
import { IndicatorSource, IndicatorValue, LabelHelper } from 'components/dashboard/IndicatorUtils';
import ModalIndicatorInfo from '../ModalIndicatorInfo';
import './chart-table-animation.scss';

const Tabs = ({ filters, valueSelected, onSelectedOption }) => (
  <div className="tabs justify-content-between dimensions">
    {filters?.map((f) => (
      <div key={f.code} className={`tab w-25 ${f.iconName} ${f.code === valueSelected ? 'active' : ''}`}>
        <a href={`#dimensoes-${f.code}`} onClick={() => onSelectedOption(f.code)}>
          {f.label}
        </a>
      </div>
    ))}
  </div>
);

const Dimensions = ({ cityData, labels }) => {
  const [tabOptions, setTabOptions] = useState([]);
  const [selectedTabOption, setSelectedTabOption] = useState({});

  const [topicsPerDimension, setTopicsPerDimension] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState({});

  const [showModalIndicator, setShowModalIndicator] = useState(false);
  const [selectedIndicator, setSelectedIndicator] = useState(null);

  useEffect(() => {
    if (cityData && labels) {
      const tabOptionsToSet = cityData.dimensions.map((d) => ({
        code: d.code,
        label: labels[d.code]?.title || d.code,
        iconName: getDimensionContainerImg(d.code, labels[d.code]?.iconName),
      }));
      setTabOptions(tabOptionsToSet);
      setSelectedTabOption(tabOptionsToSet[0]);
    }
  }, [cityData, labels]);

  useEffect(() => {
    const filteredTopics = cityData?.dimensions?.filter((d) => d.code === selectedTabOption?.code)[0]?.topics || [];

    const topicsPerDimensionToSet = filteredTopics?.map((ft) => {
      return {
        ...ft,
        title: labels[ft.code]?.title || ft.code,
      };
    });

    setTopicsPerDimension(topicsPerDimensionToSet);
    if (topicsPerDimensionToSet.length > 0) {
      const topic = topicsPerDimensionToSet[0];
      setSelectedTopic(topic);
      if (topic.indicators.length > 0) {
        setSelectedIndicator(topic.indicators[0]);
      }
    }
  }, [cityData?.dimensions, selectedTabOption?.code, labels]);

  const handleDimensionSelectTab = (dimensionCode) => {
    if (dimensionCode !== selectedTabOption.code) {
      setSelectedTabOption(tabOptions?.filter((to) => to.code === dimensionCode)[0]);
    }
  };

  const handleChangeTableTopic = (direction) => {
    const currentTopicIndex = topicsPerDimension.findIndex((topic) => topic.code === selectedTopic.code);
    const totalTopics = topicsPerDimension.length;
    const availableDirections = {
      prev: () => {
        setSelectedTopic(topicsPerDimension[currentTopicIndex - 1 <= 0 ? totalTopics - 1 : currentTopicIndex - 1]);
      },
      next: () => {
        setSelectedTopic(topicsPerDimension[currentTopicIndex + 1 > totalTopics - 1 ? 0 : currentTopicIndex + 1]);
      },
    };
    if (availableDirections[direction]) {
      availableDirections[direction]();
    }
  };

  const handleSelectTopic = useCallback(
    (topicCode) => {
      setSelectedTopic(topicsPerDimension.filter((tpd) => tpd.code === topicCode)[0]);
    },
    [topicsPerDimension],
  );

  const handleChangeIndicatorModal = useCallback(
    (direction) => {
      const indicators = selectedTopic.indicators;
      const currentIndicatorIndex = indicators.findIndex((ind) => ind.code === selectedIndicator.code);
      const totalIndicators = indicators.length;
      const availableDirections = {
        prev: () => {
          setSelectedIndicator(
            indicators[currentIndicatorIndex - 1 <= 0 ? totalIndicators - 1 : currentIndicatorIndex - 1],
          );
        },
        next: () => {
          setSelectedIndicator(
            indicators[currentIndicatorIndex + 1 > totalIndicators - 1 ? 0 : currentIndicatorIndex + 1],
          );
        },
      };
      if (availableDirections[direction]) {
        availableDirections[direction]();
      }
    },
    [selectedIndicator?.code, selectedTopic],
  );

  const handleShowModalIndicador = (indicatorCode) => {
    const indicators = selectedTopic.indicators;
    const selectedIndicatorIndex = indicators.findIndex((ind) => ind.code === indicatorCode);
    if (selectedIndicatorIndex >= 0 && indicators.length > 0) {
      setSelectedIndicator(indicators[selectedIndicatorIndex]);
      setShowModalIndicator(true);
    }
  };

  const displaySelectedTopicTitle = () => {
    return labels[selectedTopic?.code]?.title;
  };

  const displayLabel = (indicator) => {
    return labels[indicator.code]?.title;
  };

  return (
    <>
      <div className="bg-white shadow">
        <Tabs filters={tabOptions} onSelectedOption={handleDimensionSelectTab} valueSelected={selectedTabOption.code} />
        <div className="p-5">
          <h6 className="fw-bold mb-4" id="maturity-diagnosis">
            Diagnóstico de maturidade dos tópicos
          </h6>
          <div className="chart-cols mb-5">
            <ColumnChart
              chartData={topicsPerDimension}
              chartId={'column-chart-topics-per-dimension'}
              handleClick={handleSelectTopic}
            />
          </div>

          <h6 className="fw-bold mb-4">Indicadores que compõem o tópico</h6>
          <div className="mb-4 mx-auto d-flex justify-content-center align-items-center row">
            <div className="col-3 d-flex justify-content-end">
              <a href="#maturity-diagnosis" onClick={() => handleChangeTableTopic('prev')}>
                <img src="/img/arrows_left.png" alt="Anterior" />
              </a>
            </div>
            <div className="col-6">
              <h3 className="text-center c-cyan fw-bold">{displaySelectedTopicTitle()}</h3>
            </div>
            <div className="col-3 d-flex justify-content-start">
              <a href="#maturity-diagnosis" onClick={() => handleChangeTableTopic('next')}>
                <img src="/img/arrows_right.png" alt="Próximo" />
              </a>
            </div>
          </div>

          <table className="topics-indexes charts topic">
            <colgroup>
              <col style={{ width: '40%', textAlign: 'center' }} />
              <col style={{ width: '15%', textAlign: 'center' }} />
              <col style={{ width: '35%', textAlign: 'center' }} />
              <col style={{ width: '10%', textAlign: 'center' }} />
            </colgroup>
            <thead>
              <tr>
                <th>Indicadores</th>
                <th>
                  Valor <LabelHelper text="Média calculada das bases de dados oficiais do governo" placement="right" />
                </th>
                <th colSpan="2">Nível</th>
              </tr>
            </thead>
            <tbody>
              {selectedTopic?.indicators?.map((ind) => (
                <tr
                  key={`table-row-indicator-${ind.code}`}
                  className={`level-${ind.level}`}
                  onClick={() => handleShowModalIndicador(ind.code)}
                >
                  <td data-icode={ind.code}>{displayLabel(ind)}</td>
                  <td>
                    <IndicatorValue
                      code={ind.code}
                      value={ind.value}
                      digits={ind.digits}
                      unit={labels[ind.code]?.valueUnit}
                      outlier={ind.outlier}
                    />
                    <IndicatorSource code={ind.code} text={labels[ind.code]?.source} />
                  </td>
                  <td>
                    <div className="bar"></div>
                  </td>
                  <td>{ind.level || 'ND'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <ModalIndicatorInfo
        show={showModalIndicator}
        indicator={selectedIndicator}
        labels={labels}
        onClose={() => setShowModalIndicator(false)}
        handleChangeIndicator={handleChangeIndicatorModal}
      />
    </>
  );
};

export default Dimensions;
