import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

export const ConfirmationModal = ({
  id,
  show,
  title,
  message,
  size = 'lg',
  onClose,
  confirmationClassName = 'btn-primary',
  onConfirmation,
}) => {
  return (
    <Modal
      className="inteligente-modal"
      show={show}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      centered
      size={size}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-outline-secondary" onClick={onClose}>
          Não
        </button>
        <button type="button" className={`btn ${confirmationClassName}`} onClick={() => onConfirmation(id)}>
          Sim
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export const UserDisapprovalModal = ({ id, show, itemTitle, title, message, onClose, onConfirmation }) => {
  const [reason, setReason] = useState('');
  const [actionCode, setActionCode] = useState('1');

  const handleActionCode = (evt) => {
    setActionCode(evt.target.value);
  };

  const handleReason = (evt) => {
    setReason(evt.target.value);
  };

  return (
    <Modal
      className="inteligente-modal"
      show={show}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
        <div className="form-group mb-3">
          <label className="form-label fw-semibold">Motivo da recusa</label> <br />
          <textarea
            className="form-control"
            id="disapproval"
            name="reason"
            rows={5}
            value={reason}
            placeholder="Informe o motivo da recusa"
            onChange={handleReason}
          ></textarea>
        </div>
        <div className="form-group mb-3">
          <label className="form-label fw-semibold">O que fazer com o cadastro recusado? </label> <br />
          <div className="form-check form-check-inline mb-4">
            <input
              name="actionCode"
              className="form-check-input"
              type="radio"
              id="keepIt"
              value="1"
              onChange={handleActionCode}
              checked={(actionCode && actionCode === '1') || false}
            />
            <label className="form-check-label mt-2" htmlFor="keepIt">
              Somente manter recusado
            </label>
          </div>
          <div className="form-check form-check-inline mb-4">
            <input
              name="actionCode"
              className="form-check-input"
              type="radio"
              id="removeIt"
              value="99"
              onChange={handleActionCode}
              checked={(actionCode && actionCode === '99') || false}
            />
            <label className="form-check-label mt-2" htmlFor="removeIt">
              Excluir cadastro
            </label>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-outline-secondary" onClick={onClose}>
          Não
        </button>
        <button
          type="button"
          className="btn btn-danger"
          onClick={() => onConfirmation(id, { reason, actionCode, itemTitle })}
        >
          Sim
        </button>
      </Modal.Footer>
    </Modal>
  );
};
