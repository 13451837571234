export const formatDate = (isoDateStr, localeCode = navigator.userLanguage) =>
  new Date(isoDateStr).toLocaleDateString(localeCode);

export const formatDateTime = (isoDateTimeStr, localeCode = navigator.userLanguage) =>
  new Date(isoDateTimeStr).toLocaleString(localeCode);

// Remove os segundos
export const formatDateTimeSimple = (isoDateStr, localeCode = navigator.userLanguage) => {
  const dateStr = new Date(isoDateStr).toLocaleString(localeCode);
  return dateStr.substring(0, dateStr.length - 3);
};

export const addDaysTo = (date, days) => {
  if (!date || !days) return null;
  const futureDate = date;
  return futureDate.setDate(date.getDate() + days);
};

export const tomorrow = (date) => {
  if (!date) return '';
  const futureDate = new Date(Date.parse(date.concat('T00:00:00')));
  return new Date(futureDate.setDate(futureDate.getDate() + 1)).toISOString().substring(0, 10);
};

export const yesterday = (date) => {
  if (!date) return null;
  const pastDate = new Date(Date.parse(date.concat('T00:00:00')));
  return new Date(pastDate.setDate(pastDate.getDate() + 1)).toISOString().substring(0, 10);
};


// Obtém data atual considerando offset do timezone
export const removeTimezoneOffset = (date) => new Date(date.getTime() - new Date().getTimezoneOffset() * 60 * 1000)

export const addTimezoneOffset = (date) => new Date(date.getTime() + new Date().getTimezoneOffset() * 60 * 1000)

export const currentLocalDate = () => removeTimezoneOffset(new Date());

export const currentLocalDateString = () => currentLocalDate().toISOString().substring(0, 10);
