import {
  formatFromGroupValueWithValuesObj,
  formatGroupValue,
  setupDataFromLabels,
  setupFiltersFromLabels,
} from 'helpers/dashboard';
import AdminDashboardService from 'services/admin/dashboard';

import { adminDashboardSlice, adminDashboardCitySlice } from 'slices/admin/dashboard/slice';

export const AdminDashboardSliceActions = adminDashboardSlice.actions;
export const AdminDashboardCitySliceActions = adminDashboardCitySlice.actions;

let valuesObj = {
  dimensions: [],
  topics: [],
  indicators: [],
  maturityLevels: [],
  filters: [],
};

export const Actions = {
  loadData: () => {
    return (dispatch) => {
      dispatch(AdminDashboardSliceActions.loadingStart());
      Promise.allSettled([
        AdminDashboardService.findAllLabels(),
        AdminDashboardService.findAllGroupValues(),
        AdminDashboardService.findAllDescriptions(),
      ])
        .then(([labelsPromise, groupValuesPromise, descriptionsPromise]) => {
          const labelsRes = labelsPromise.value;
          const groupValuesRes = groupValuesPromise.value;
          const descriptionsRes = descriptionsPromise.value;
          valuesObj = {
            ...setupDataFromLabels(labelsRes.data),
            filters: setupFiltersFromLabels(labelsRes.data),
          };
          valuesObj = formatFromGroupValueWithValuesObj(groupValuesRes.data, valuesObj);
          dispatch(
            AdminDashboardSliceActions.success({
              labels: valuesObj,
              groupValues: formatGroupValue(groupValuesRes.data),
              descriptions: descriptionsRes.data,
            }),
          );
        })
        .catch(() => dispatch(AdminDashboardSliceActions.error('Ocorreu um erro ao carregar os dados. ')))
        .finally(() => dispatch(AdminDashboardSliceActions.loadingStop()));
    };
  },
  loadCityItem: (cityFriendlyName) => {
    return (dispatch) => {
      dispatch(AdminDashboardCitySliceActions.loadingStart());
      AdminDashboardService.findByCityFriendlyName(cityFriendlyName)
        .then((resp) => {
          dispatch(AdminDashboardCitySliceActions.success({ item: resp.data }));
        })
        .catch(() => dispatch(AdminDashboardCitySliceActions.error('Ocorreu um erro ao carregar os dados da cidade. ')))
        .finally(() => dispatch(AdminDashboardCitySliceActions.loadingStop()));
    };
  },
  clearData: () => {
    return (dispatch) => {
      dispatch(AdminDashboardSliceActions.reset());
    };
  },
};

const adminDashboardCityReducer = adminDashboardCitySlice.reducer;
const adminDashboardReducer = adminDashboardSlice.reducer;
export default { adminDashboardReducer, adminDashboardCityReducer };
