import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

/*
ATENCAO: PARA FUNCIONAR, DEPENDE DE INCLUIR UMA DISTRIBUICAO self-hosted DO TinyMCE NO DIRETORIO /public
INCLUIR TAMBÉM O ARQUIVO DE LOCALIZACAO pt-BR
INFORMAÇÕES EM https://www.tiny.cloud/docs/tinymce/6/react-pm-host/

TODO: AUTOMATIZAR O postinstall PARA COPIAR ARQUIVOS DO DIRETÓRIO DO node

EXEMPLOS DE USO EM https://tinymce.github.io/tinymce-react/

*/
export default function TinyMCEditor(props) {
  // note that skin and content_css is disabled to avoid the normal
  // loading process and is instead loaded as a string via content_style
  return (
    <Editor
      tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
      init={{
        height: props.height || 500,
        language: 'pt_BR',
        menubar: false,
        plugins: 'link image table code',
        toolbar:
          'undo redo | fontfamily fontsize blocks | bold italic underline strikethrough | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code | help',
        content_style: 'body { font-family: Helvetica,Arial,sans-serif; font-size:14px }',
      }}
      {...props}
    />
  );
}
