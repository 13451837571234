import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import SearchFilters from './SearchFilters';
import ResultSummary from './ResultSummary';
import ResultMap from './ResultMap';
import { Actions as DashboardGroupDataActions } from 'services/dashboardGroupData';
import { Actions as LabelActions } from 'services/labels';
import InputSearchCity from 'components/InputSearchCity';
import { replaceLineBreak } from 'helpers/replaceChar';

export const ModalInfoLevel = ({ show, onHide, info, labels }) => {
  const level = info?.level || 1;
  return (
    <>
      {labels && level && (
        <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} size="lg" className="inteligente-modal">
          <Modal.Header closeButton closeLabel="Fechar">
            <Modal.Title className={`rounded-5 bg-${level} fw-bold px-3 py-2`}>
              <span className="me-4">{level}</span>
              {labels['dm' + level]?.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p dangerouslySetInnerHTML={{ __html: replaceLineBreak(labels['dm' + level]?.description) }}></p>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-outline-secondary" onClick={onHide}>
              Fechar
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

const CitySearch = () => {
  const dispatch = useDispatch();

  const labels = useSelector((state) => state.labels.labels);
  const groupData = useSelector((state) => state.dashboardGroupData.groupData);

  const [selectedOptions, setSelectedOptions] = useState({
    opt2: 'reset',
    opt3: 'reset',
    opt4: 'reset',
    opt5: 'reset',
  });

  const [currentSelection, setCurrentSelection] = useState({ type: 1, value: 'Brasil' });
  const [showModalLevel, setShowModalLevel] = useState(false);
  const [modalLevelInfo, setModalLevelInfo] = useState({ level: 1 });

  const handleShowModalInfo = (level) => {
    setModalLevelInfo((current) => ({ ...current, level }));
    setShowModalLevel(true);
  };

  const handleCloseModalLevel = (evt) => {
    setShowModalLevel(false);
  };

  useEffect(() => {
    dispatch(LabelActions.loadLabels());
    dispatch(DashboardGroupDataActions.loadGroupData());
  }, [dispatch]);

  const groupValues = useMemo(() => {
    if (groupData && labels) {
      console.time('groupValues');
      const options = {
        t1: [], // Pais
        t2: [], // Estado
        t3: [], // Regiao
        t4: [], // Cluster populacional (classe)
        t5: [], // Rede de influência (nivel hierarquia)
      };

      for (const data of groupData) {
        const topics = {};
        // let indicators = []
        for (const indicator of data.indicators) {
          if (!topics[indicator.topicCode]) {
            topics[indicator.topicCode] = {
              indicators: [],
              code: indicator.topicCode,
              dimensionCode: indicator.dimensionCode,
              title: labels[indicator.topicCode]?.title || indicator.topicCode,
            };
          }
          // indicators.push({ ...indicator, title: labels[indicator.code].title })
          topics[indicator.topicCode].indicators.push({
            ...indicator,
            title: labels[indicator.code]?.title || indicator.code,
            unit: labels[indicator.code]?.valueUnit,
            source: labels[indicator.code]?.source || 'Não definido',
          });
        }
        options['t' + data.type].push({ ...data, /* indicators: indicators, */ topics });
      }

      const retVal = {
        t1: options.t1.sort((a, b) => (a.value < b.value ? -1 : 1)),
        t2: options.t2.sort((a, b) => (a.value < b.value ? -1 : 1)),
        t3: options.t3.sort((a, b) => (a.value < b.value ? -1 : 1)),
        t4: options.t4.sort((a, b) => (a.value < b.value ? -1 : 1)),
        t5: options.t5.sort((a, b) => (a.value < b.value ? -1 : 1)),
      };
      console.timeEnd('groupValues');
      return retVal;
    }
  }, [groupData, labels]);

  const handleFilterChange = (type, value) => {
    if (value === 'reset') {
      setCurrentSelection({ type: 1, value: 'Brasil' });
    } else {
      setCurrentSelection({ type, value });
    }
    const updatedOptions = {
      opt2: 'reset',
      opt3: 'reset',
      opt4: 'reset',
      opt5: 'reset',
    };
    setSelectedOptions({ ...updatedOptions, ['opt' + type]: value });
  };

  // eslint-disable-next-line
  const currentGroupValues = useMemo(() => {
    if (groupValues && currentSelection) {
      const filteredArr = groupValues['t' + currentSelection.type].filter(
        (item) => item.filterValue === currentSelection.value || item.value === 'Brasil',
      );
      if (filteredArr.length > 0) {
        return filteredArr[0];
      }
    }
  }, [groupValues, currentSelection]);

  return (
    <div className="py-5 bg-blue-page-top">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-4">
            <h2 className="c-white fw-bolder mb-4">Conheça o nível de maturidade da sua cidade</h2>

            {/* <!-- Box Busca - start --> */}
            <div className="bg-blue-deep p-4 mb-3">
              <h5 className="fw-bold">Encontre o município de interesse</h5>
              <div className="mt-3">
                <InputSearchCity
                  WrapperComponent="span"
                  className="has-icon search"
                  id="fieldSearchCity"
                  name="citySearch"
                  placeholder="Digite o nome do município"
                />
              </div>
            </div>
            {/* <!-- Box Busca - end --> */}

            {/* <!-- Box Filtros - start --> */}
            <SearchFilters
              labels={labels}
              groupValues={groupValues}
              selectedOptions={selectedOptions}
              handleFilterChange={handleFilterChange}
            />
            {/* <!-- Box Filtros - end --> */}

            <hr className="my-5" />

            <div className="text-justify">
              <p>
                A Plataforma <strong>inteli.gente</strong> por meio do diagnóstico de maturidade das cidades,
                possibilita aos gestores públicos federais, estaduais e municipais conhecerem as condições atuais de
                suas cidades, as competências existentes e as necessidades prioritárias para melhorar seu desempenho.
                Formulando ações e políticas públicas para transformar suas cidades em inteligentes e sustentáveis.
              </p>
              <p>
                O diagnóstico permite encontrar caminhos para o desenvolvimento urbano e para a transformação digital de
                maneira sustentável e inclusiva para cada cidade brasileira.
              </p>
            </div>

            <h2 className="c-green mt-5 mb-3 fw-bold">Níveis de maturidade</h2>
            <h5 className="rounded-5 bg-7 fw-bold px-3 py-2 mb-3 clickable" onClick={() => handleShowModalInfo(7)}>
              <span className="me-2">7</span>
              {labels?.m7?.title || 'Nível 7'}
            </h5>
            <h5 className="rounded-5 bg-6 fw-bold px-3 py-2 mb-3 clickable" onClick={() => handleShowModalInfo(6)}>
              <span className="me-2">6</span>
              {labels?.m6?.title || 'Nível 6'}
            </h5>
            <h5 className="rounded-5 bg-5 fw-bold px-3 py-2 mb-3 clickable" onClick={() => handleShowModalInfo(5)}>
              <span className="me-2">5</span>
              {labels?.m5?.title || 'Nível 5'}
            </h5>
            <h5 className="rounded-5 bg-4 fw-bold px-3 py-2 mb-3 clickable" onClick={() => handleShowModalInfo(4)}>
              <span className="me-2">4</span>
              {labels?.m4?.title || 'Nível 4'}
            </h5>
            <h5 className="rounded-5 bg-3 fw-bold px-3 py-2 mb-3 clickable" onClick={() => handleShowModalInfo(3)}>
              <span className="me-2">3</span>
              {labels?.m3?.title || 'Nível 3'}
            </h5>
            <h5 className="rounded-5 bg-2 fw-bold px-3 py-2 mb-3 clickable" onClick={() => handleShowModalInfo(2)}>
              <span className="me-2">2</span>
              {labels?.m2?.title || 'Nível 2'}
            </h5>
            <h5 className="rounded-5 bg-1 fw-bold px-3 py-2 mb-3 clickable " onClick={() => handleShowModalInfo(1)}>
              <span className="me-2">1</span>
              {labels?.m1?.title || 'Nível 1'}
            </h5>
            <ModalInfoLevel
              show={showModalLevel}
              info={modalLevelInfo}
              onHide={handleCloseModalLevel}
              labels={labels}
            />
          </div>
          {/* <!-- Coluna Lateral - end --> */}

          {/* <!-- Coluna Principal - start --> */}
          <div className="col-12 col-lg-8">
            <div className="p-4 bg-white">
              <ResultMap currentValues={currentGroupValues} currentSelection={currentSelection} labels={labels} />
              <hr className="my-5" />
              <ResultSummary
                currentValues={currentGroupValues}
                currentSelection={currentSelection}
                onLevelClick={handleShowModalInfo}
                labels={labels}
              />
            </div>
          </div>
          {/* <!-- Coluna Principal - end --> */}
        </div>
      </div>
    </div>
  );
};

export default CitySearch;
