import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Actions as AuthActions } from 'services/auth';

const LoginMaster = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [authData, setAuthData] = useState({ username: '', password: '' });

  const handleInputChangeData = (evt) => {
    const { name, value } = evt.target;
    setAuthData((current) => ({ ...current, [name]: value }));
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    dispatch(AuthActions.login(authData.username, authData.password, navigate));
    setAuthData({ username: '', password: '' });
  };

  return (
    <div className="page bg-cyan">
      <div className="container page">
        <div className="row page justify-content-center">
          <div className="col-12 col-lg-8 bg-white">
            <div className="p-5 m-5">
              <h1 className="fw-bold c-green">Área restrita</h1>
              <h3>Essa área é destinada exclusivamente aos gestores públicos.</h3>
              <div className="row">
                <form onSubmit={handleSubmit}>
                  <div className="col-12 mb-4">
                    <label htmlFor="fieldUsername" className="form-label d-block ps-3">
                      Nome de usuário
                    </label>
                    <input
                      type="text"
                      id="fieldUsername"
                      className="form-control"
                      name="username"
                      value={authData.username}
                      onChange={handleInputChangeData}
                      autoComplete="off"
                    />
                  </div>
                  <div className="col-12 mb-4">
                    <label htmlFor="fieldPassword" className="form-label d-block ps-3">
                      Senha
                    </label>
                    <input
                      type="password"
                      id="fieldPassword"
                      className="form-control"
                      name="password"
                      value={authData.password}
                      onChange={handleInputChangeData}
                    />
                  </div>
                  <div className="mt-5 text-center">
                    <button type="submit" className="btn btn-primary">
                      Login
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginMaster;
